window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.stream_completed = window.locales.am.lessons.stream.stream_completed || {};window.locales.am.lessons.stream.stream_completed = {...window.locales.am.lessons.stream.stream_completed, ...{
    "course_complete": "ኮርስ ተጠናቅቋል",           
    "exam_complete": "ፈተና ተጠናቅቋል",           
    "continue": "ቀጥል",           
    "return_to_dashboard": "ወደ ዳሽቦርድ ይመለሱ",           
    "exam_is_complete": "የእርስዎ ፈተና ተጠናቅቋል። የእርስዎን ነጥቦች እናሰላለን ከዚያም ወደመጨረሻ ውጤትዎ እናስገባለን።",           
    "share_with_friend": "ከ {{brandName}} ኮርሶች ሊጠቀም ከሚችል ጓደኛዎ ጋር በነጻነት ያጋሩ።",           
    "great_job": "ምርጥ ስራ!"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.stream_completed = window.locales.ar.lessons.stream.stream_completed || {};window.locales.ar.lessons.stream.stream_completed = {...window.locales.ar.lessons.stream.stream_completed, ...{
    "course_complete": "انتهت الدورة",           
    "continue": "استمر",           
    "exam_complete": "امتحان اكتملت",
    "return_to_dashboard": "عودة إلى لوحة التحكم",
    "exam_is_complete": "الامتحان الخاص بك مكتمل. وسوف نقوم بحساب علاماتك وإضافتهم إلى العلامة النهائية الخاصة بك.",           
    "share_with_friend": "لا تتردد في المشاركة مع صديق قد يستفيد من {{brandName}} .",
    "great_job": "عمل رائع!"           
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.stream_completed = window.locales.en.lessons.stream.stream_completed || {};window.locales.en.lessons.stream.stream_completed = {...window.locales.en.lessons.stream.stream_completed, ...{
    "course_complete": "COURSE COMPLETE",
    "exam_complete": "EXAM COMPLETE",
    "continue": "CONTINUE",
    "return_to_dashboard": "RETURN TO DASHBOARD",
    "exam_is_complete": "Your exam is complete. We will calculate your scores and factor them into your final grade.",
    "share_with_friend": "Feel free to share with a friend who might benefit from {{brandName}}'s courses.",
    "great_job": "GREAT JOB!"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.stream_completed = window.locales.es.lessons.stream.stream_completed || {};window.locales.es.lessons.stream.stream_completed = {...window.locales.es.lessons.stream.stream_completed, ...{
    "course_complete": "CURSO COMPLETO",           
    "continue": "CONTINUAR",           
    "exam_complete": "EXAMEN COMPLETO",
    "return_to_dashboard": "Volver al panel",
    "exam_is_complete": "Su examen se haya completado. Vamos a calcular su puntuación y les cuenta en su calificación final.",
    "share_with_friend": "No dudes en compartir con un amigo que podría beneficiarse de los cursos {{brandName}}",
    "great_job": "¡GRAN TRABAJO!"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.stream_completed = window.locales.it.lessons.stream.stream_completed || {};window.locales.it.lessons.stream.stream_completed = {...window.locales.it.lessons.stream.stream_completed, ...{
    "course_complete": "CORSO COMPLETATO",           
    "exam_complete": "ESAME COMPLETATO",           
    "continue": "CONTINUA",           
    "return_to_dashboard": "TORNA ALLA BACHECA",           
    "exam_is_complete": "Il tuo esame è stato completato. Calcoleremo i tuoi punteggi e li includeremo nella tua valutazione finale.",           
    "share_with_friend": "Sentiti libero di condividere con un amico che potrebbe trarre vantaggio dai corsi di {{brandName}}",
    "great_job": "OTTIMO LAVORO!"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.stream_completed = window.locales.zh.lessons.stream.stream_completed || {};window.locales.zh.lessons.stream.stream_completed = {...window.locales.zh.lessons.stream.stream_completed, ...{
    "course_complete": "课程完成",           
    "continue": "继续",           
    "exam_complete": "测验完成",           
    "return_to_dashboard": "返回仪表板",           
    "exam_is_complete": "你的测验已完成。我们将计算你的分数，并把其计入你的期末成绩。",           
    "share_with_friend": "请随意与可能从 {{brandName}}课程中受益的朋友分享。",           
    "great_job": "真不错！"           
}
};