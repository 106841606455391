window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.student_dashboard_offline_box = window.locales.am.lessons.stream.student_dashboard_offline_box || {};window.locales.am.lessons.stream.student_dashboard_offline_box = {...window.locales.am.lessons.stream.student_dashboard_offline_box, ...{
    "you_are": "እርስዎ",           
    "offline": "ከመስመር ውጪ ነዎት",           
    "keep_going": "መሄድዎን ይቀጥሉ! የበይነመረብ ግንኙነትን ስናገኝ ሂደትዎን እናጣምራለን።",           
    "you_are_offline": "ከመስመር ውጭ ነዎት"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.student_dashboard_offline_box = window.locales.ar.lessons.stream.student_dashboard_offline_box || {};window.locales.ar.lessons.stream.student_dashboard_offline_box = {...window.locales.ar.lessons.stream.student_dashboard_offline_box, ...{
    "you_are": "أنت",
    "offline": "غير متصل  بالانترنت",           
    "keep_going": "واصل التقدم! سنقوم بمزامنة تقدمك عند الاتصال بالإنترنت.",           
    "you_are_offline": "انت غير متصل"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.student_dashboard_offline_box = window.locales.en.lessons.stream.student_dashboard_offline_box || {};window.locales.en.lessons.stream.student_dashboard_offline_box = {...window.locales.en.lessons.stream.student_dashboard_offline_box, ...{
    "you_are": "YOU ARE",
    "offline": "OFFLINE",
    "keep_going": "Keep going! We will sync your progress when we detect an internet connection.",
    "you_are_offline": "You Are Offline"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.student_dashboard_offline_box = window.locales.es.lessons.stream.student_dashboard_offline_box || {};window.locales.es.lessons.stream.student_dashboard_offline_box = {...window.locales.es.lessons.stream.student_dashboard_offline_box, ...{
    "you_are": "USTED ESTÁ",
    "offline": "DESCONECTADO",
    "keep_going": "¡Sigue adelante! Sincronizaremos su progreso cuando detectemos una conexión a Internet.",
    "you_are_offline": "Estas desconectado"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.student_dashboard_offline_box = window.locales.it.lessons.stream.student_dashboard_offline_box || {};window.locales.it.lessons.stream.student_dashboard_offline_box = {...window.locales.it.lessons.stream.student_dashboard_offline_box, ...{
    "you_are": "SEI",
    "offline": "DISCONNESSO",
    "keep_going": "Continua così! Sincronizzeremo i tuoi progressi quando rileviamo una connessione Internet.",
    "you_are_offline": "Sei offline"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.student_dashboard_offline_box = window.locales.zh.lessons.stream.student_dashboard_offline_box || {};window.locales.zh.lessons.stream.student_dashboard_offline_box = {...window.locales.zh.lessons.stream.student_dashboard_offline_box, ...{
    "you_are": "你已",           
    "offline": "离线",           
    "keep_going": "继续加油！一旦网络连接恢复，我们会同步你的进度。",           
    "you_are_offline": "您离线了"
}
};