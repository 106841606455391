window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.stream_dashboard = window.locales.am.lessons.stream.stream_dashboard || {};window.locales.am.lessons.stream.stream_dashboard = {...window.locales.am.lessons.stream.stream_dashboard, ...{
    "add_to_my_courses": "ወደእኔ ኮርሶች ያክሉ",           
    "additional_resources": "ተጨማሪ ግብአቶች",           
    "beta_message": "የቤታ ኮርሶች አሁንም በሂደት ላይ ናቸው! ኮርሶቹ የትምህርቶቹን ቅድሚያ ስሪቶች ለመሞከር ለሚፈልጉ ጀብደኛ ተማሪዎች ይገኛሉ።",           
    "chapter_list_caption": "{number, plural, =1{ምዕራፍ} other{ምዕራፎች}}",           
    "chapter_number_x": "ምዕራፍ {{number}}",           
    "chapter_number_x_lower": "ምዕራፍ {{number}}",           
    "coming_soon": "በቅርብ ቀን!",           
    "coming_soon_block": "በቅርቡ<br>ይመጣል",           
    "coming_soon_inline": "በቅርቡ ይመጣል",           
    "coming_soon_come_back_later": "ያሉትን ሁለንም የቤታ ትምህርቶች አጠናቅቀዋል! <br class='hidden-xs hidden-sm'> የበለጠ በሚታተምበት ጊዜ በኋላ ላይ መልሰው ያረጋግጡ።",           
    "complete_to_unlock_x": "<strong>{{title}}</strong>ለመክፈት ያጠናቅቁ",           
    "course_details": "<br>ኮርስ ዝርዝሮች",           
    "course_highlights": "የኮርስ ዋና ነጥቦች",           
    "course_information": "የኮርስ መረጃ",           
    "course_summaries": "የኮርስ ማጠቃለያዎች",           
    "credits": "ክሬዲቶች",           
    "download_certificate": "ሰርተፍኬት ያውርዱ",           
    "explore_key_terms": "ቁልፍ ቃላትን ያስሱ",           
    "join": "ይቀላቀሉ{{brandName}}",           
    "join_for_upsell_desc": "የ{{brandName}} የኮርስ ቤተመጻህፍትን ለማግኘት መለያ ይጠይቃል።",           
    "key_terms": "ቁልፍ ቃላት",           
    "key_terms_description": "ቁልፍ ቃላት ይህን ትምህርት በሚወሰድበት ጊዜ ልዩ ትኩረት ይገባቸዋል ብሎ {{brandName}} የሚያንባቸው ሃሳቦች ናቸው። ከዚህ በታች ያለውን ዝርዝር ማንኛዉም ቃል ነካ በማድረግ ሲተዋወቅ ወደነበረው ትምህርት በቀጥታ ይሂዱ።",           
    "learn_more": "ተጨማሪ ይማሩ",           
    "lesson_number_x": "ትምህርት {{number}}",           
    "lesson_number_x_lower": "ትምህርት {{number}}",           
    "login": "ይግቡ",           
    "minutes": "ደቂቃዎች",           
    "or": "ወይም",           
    "ready_to_begin_start_learning": "ለመጀመር ዝግጁ ነዎት? <br class=\"visible-xs\"> አሁን መማር ይጀምሩ።",           
    "recommended_study": "የሚመከር ጥናት",           
    "related_courses": "ግንኙነት ያላቸው ኮርሶች",           
    "resources": "ግብአቶች",           
    "saved_to_my_courses": "ወደ እኔ ኮርሶች ተቀምጠዋል",           
    "signing_certificate": "ሰርተፍኬት በመፈረም ላይ",           
    "show_all": "ሁሉንም አሳይ",           
    "smartcase": "SMARTCASE",           
    "test": "ፈተና",           
    "sorry_subscription_required": "ይቅርታ! ይህንን ኮርስ ለማግኘት የደንበኝነት ምዝገባ ያስፈግዎታል።",           
    "start_course": "ኮርሱን ይጀምሩ",           
    "start_learning_now": "አሁን መማር ይጀምሩ።",           
    "subscribe_to_unlock": "ለመክፈት ሰብስክራይብ ያድርጉ",           
    "subscription_required": "የደንበኝነት ምዝገባ ያስፈልጋል",           
    "tap_to_download": "ለማውረድ ነካ ያድርጉ",           
    "tap_to_remove": "ለማስወገድ ነካ ያድርጉ",           
    "unlocked": "ተከፍቷል!",           
    "we_suggest_following_x": "<strong>{{title}}</strong>ከመጀመርዎ በፊት በሚከተሉት ኮርሶች ውስጥ ካለው የትምህርት መሳሪያ ጋር እንዲተዋወቁ እንጠቁምዎታለን፤",           
    "ready_to_begin_exam": "ለመጀመር ዝግጁ ነዎት? አሁን ፈተናዎን ይጀምሩ።",           
    "you_will_have_x_hours": "ፈተናዎን ከጀመሩበት ጊዜ አንስቶ ለማጠናቀቅ {{numHours}} ሰአት አለዎት።",           
    "start": "ጀምር",           
    "start_exam": "ፈተና ይጀምሩ",           
    "exam_timer": "የፈተና ጊዜ መቁጠሪያ",           
    "after_launching_first_you_will_have": "የመጀመሪያዉ ትምህርት ከጀመሩ በኋላ፣ ፈተናውን ለማጠናቀቅ <div class='time-left'>{{timeLeft | hoursMinutesSeconds}}</div> አለዎት።",           
    "after_launching_next_you_will_have": "ቀጣዩን ትምህርት ከጀመሩ በኋላ፣ ፈተናውን ለማጠናቀቅ <div class='time-left'>{{timeLeft | hoursMinutesSeconds}}</div> አለዎት።",           
    "you_have_x_remaining": "እርስዎ <div class='time-left'>{{timeLeft | hoursMinutesSeconds}}</div> ይቀርዎታል",           
    "schedule": "መርሃግብር",           
    "exam_begins": "ፈተናው ጀምሯል",           
    "exam_ends": "ፈተናው አብቅቷል",           
    "time_limit": "የጊዜ ገደብ",           
    "the_exam_time_will_begin": "ጊዜ ቆጣሪው የመጀመሪያዎን ትምህርት ሲከፍቱ ይጀምራል። ከ {{timeLimitHours}} ሰአት በኋላ፣ ፈተናዎ ጊዜዎ ይጠናቀቃል፣ እና ምንም ተጨማሪ መልሶች ተቀባይነት አይኖራቸውም።",           
    "view_schedule": "መርሃግብር ይመልከቱ",           
    "ready_to_begin": "ለመጀመር ዝግጁ ነዎት?",           
    "completion_instructions_for_summary": "ለመክፈት {{titlesText}} ያጠናቅቁ!",           
    "exam_score": "የፈተና ውጤት",           
    "title_not_available": "ርዕስ አይገኝም",
    "external_links_intro": "የ {{institutionBrandName}} ትምህርቶች ብዙውን ጊዜ ተማሪዎች ስለ አንድ ርዕሰ ጉዳይ የበለጠ የሚማሩባቸው ከውጭ መጣጥፎች እና ሌሎች ድረ-ገጾች ጋር ይገናኛሉ። የእያንዳንዱ ኮርስ ማጠናቀቂያ አካል ተማሪዎች እነዚህን ጽሑፎች እንዲያነቡ ይጠበቅባቸዋል። ወደ URL ለማሰስ ከዚህ በታች ያለውን ማንኛውንም አገናኝ ይንኩ።",
    "external_links": "የተመደበ ንባብ",
    "ready_to_begin_verify_identity": "ለመጀመር ዝግጁ ነዎት? ፈተናውን ለመክፈት ማንነትዎን ያረጋግጡ።",
    "ready_to_continue_verify_identity": "ለመቀጠል ዝግጁ ነዎት? ፈተናውን ለመክፈት ማንነትዎን ያረጋግጡ።",
    "verified_identity_thanks": "ማንነትዎን ስላረጋገጡ እናመሰግናለን። ፈተናህን አሁን ጀምር።",
    "verify_identity": "ማንነትን አረጋግጥ",
    "optional": "አማራጭ",
    "num_lessons_completed": "ከ {{total}} ትምህርቶች {{count}} ተጠናቋል",
    "num_lessons_completed_short": "{{count}} ከ {{total}} ትምህርቶች",
    "course_description": "የኮርሱ መግለጫ",
    "completed_score": "ተጠናቅቋል {{score}}%"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.stream_dashboard = window.locales.ar.lessons.stream.stream_dashboard || {};window.locales.ar.lessons.stream.stream_dashboard = {...window.locales.ar.lessons.stream.stream_dashboard, ...{
    "add_to_my_courses": "أضف إلى دوراتي",           
    "additional_resources": "الموارد الإضافية",           
    "beta_message": "الدورات التجريبية لا تزال قيد الاستخدام! إنها متاحة للمتعلمين المغامرين الذين يرغبون في تجربة الإصدارات القديمة من الدروس.",           
    "chapter_list_caption": "{number, plural, =1{فصل} other{الفصول}}",            
    "chapter_number_x": "الفصل {{number}}",           
    "chapter_number_x_lower": "الفصل {{number}}",           
    "coming_soon": "قريباً!",           
    "coming_soon_block": "قريباً",           
    "coming_soon_inline": "قريباً",           
    "coming_soon_come_back_later": "لقد أكملت جميع الدروس التجريبية المتاحة! <br class='hidden-xs hidden-sm'> تحقق من الأمر مرةً أخرى في وقتٍ لاحق عندما يتم نشر المزيد من الدروس.",           
    "complete_to_unlock_x": "أكمل لفتح <strong>{{title}}</strong>",           
    "course_details": "تفاصيل<br>الدورة",           
    "course_highlights": "مميزات الدورة",           
    "course_information": "معلومات الدورة",           
    "course_summaries": "ملخصات الدورة",           
    "credits": "الحقوق",           
    "download_certificate": "تحميل الشهادة",           
    "explore_key_terms": "اكتشف المصطلحات الأساسية",           
    "key_terms": "المصطلحات الأساسية",           
    "learn_more": "تعلم المزيد",           
    "lesson_number_x": "الدرس {{number}}",           
    "lesson_number_x_lower": "الدرس {{number}}",           
    "login": "تسجيل الدخول",           
    "minutes": "دقائق",           
    "or": "أو",           
    "ready_to_begin_start_learning": "هل أنت مستعد للبدء؟ <br class=\"visible-xs\"> ابدأ في التعلم الآن.",           
    "recommended_study": "الدراسة الموصى بها",           
    "related_courses": "الدورات ذات الصلة",           
    "resources": "الموارد",           
    "saved_to_my_courses": "تم الحفظ إلى دوراتي",           
    "signing_certificate": "جاري توقيع الشهادة",           
    "show_all": "عرض الكل",           
    "smartcase": "SMARTCASE",           
    "sorry_subscription_required": "عذراً! إنك تحتاج إلى الاشتراك لكي تتمكن من الدخول إلى هذه الدورة.",           
    "start_course": "ابدأ الدورة",           
    "start_learning_now": "ابدأ في التعلم الآن.",           
    "subscribe_to_unlock": "اشترك لفتح جميع الدورات",           
    "subscription_required": "الاشتراك مطلوب",           
    "tap_to_download": "انقر تحميل",           
    "tap_to_remove": "انقر للإزالة",           
    "unlocked": "مفتوحة!",           
    "we_suggest_following_x": "إننا نقترح عليك أن تقوم بالإطلاع على المواد العلمية في الدورات التالية قبل البدء <strong>{{title}}</strong>:",           
    "test": "اختبار",
    "you_will_have_x_hours": "سيكون لديك {{numHours}} ساعة من وقت بدء الامتحان لإنجاز ذلك.",
    "start_exam": "بدء الامتحان",
    "exam_timer": "امتحان TIMER",
    "after_launching_first_you_will_have": "بعد إطلاق الدرس الأول، سيكون لديك <div class='time-left' style=\";text-align:right;direction:rtl\"> {{timeLeft | hoursMinutesSeconds}} </div> لإكمال الامتحان.",
    "after_launching_next_you_will_have": "بعد إطلاق الدرس الأول، سيكون لديك <div class='time-left' style=\";text-align:right;direction:rtl\"> {{timeLeft | hoursMinutesSeconds}} </div> لإكمال الامتحان.",
    "you_have_x_remaining": "عندك <div class='time-left' style=\";text-align:right;direction:rtl\"> {{timeLeft | hoursMinutesSeconds}} </div> REMAINING",
    "schedule": "جدول",
    "exam_begins": "تباشر الامتحانات",
    "exam_ends": "تنتهي الامتحانات",
    "time_limit": "الحد الزمني",
    "the_exam_time_will_begin": "ستبدأ توقيت الامتحان عند بدء الدرس الأول الخاص بك. بعد {{timeLimitHours}} ساعة، والامتحان الخاص بك يغلق، وسيتم قبول أية إجابات إضافية.",
    "view_schedule": "عرض الجدول الزمني",
    "ready_to_begin_exam": "استعداد لبدء؟ بدء الامتحان الخاص بك الآن.",
    "start": "بداية",
    "ready_to_begin": "استعداد لبدء؟",
    "completion_instructions_for_summary": "أكمل {{titlesText}} لإلغاء القفل!",
    "exam_score": "درجة الامتحان",
    "join": "انضم إلى {{brandName}}",
    "join_for_upsell_desc": "يتطلب الوصول إلى مكتبة الدورات التدريبية {{brandName}} حسابًا.",
    "key_terms_description": "المصطلحات الأساسية هي المفاهيم التي يعتقد {{brandName}} أنها تستحق اهتمامًا خاصًا عند دراسة هذا الموضوع. انقر فوق أي مصطلح رئيسي في القائمة أدناه للتنقل مباشرةً إلى الدرس الذي تم تقديمه فيه.",
    "title_not_available": "العنوان غير متوفر",
    "external_links_intro": "غالبًا ما ترتبط دروس {{institutionBrandName}} بالمقالات الخارجية وصفحات الويب الأخرى حيث يمكن للطلاب معرفة المزيد حول موضوع معين. يتوقع من الطلاب قراءة هذه المقالات كجزء من إكمال كل دورة. اضغط على أي رابط أدناه للانتقال إلى عنوان URL.",
    "external_links": "القراءة المعينة",
    "ready_to_begin_verify_identity": "جاهز للبدء؟ تحقق من هويتك لفتح الامتحان.",
    "ready_to_continue_verify_identity": "جاهز للمتابعة؟ تحقق من هويتك لفتح الامتحان.",
    "verified_identity_thanks": "شكرا للتحقق من هويتك. ابدأ الامتحان الآن.",
    "verify_identity": "التحقق من الهوية",
    "optional": "خياري",
    "num_lessons_completed": "{{count}} من {{total}} درسًا مكتملًا",
    "num_lessons_completed_short": "{{count}} من {{total}} درسًا",
    "course_description": "وصف الدورة",
    "completed_score": "تم الانتهاء: {{score}}%"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.stream_dashboard = window.locales.en.lessons.stream.stream_dashboard || {};window.locales.en.lessons.stream.stream_dashboard = {...window.locales.en.lessons.stream.stream_dashboard, ...{
    "add_to_my_courses": "ADD TO MY COURSES",
    "additional_resources": "Additional Resources",
    "beta_message": "Beta courses are still in progress! They're available to adventurous learners who want to try early versions of lessons.",
    "chapter_list_caption": "{number, plural, =1{CHAPTER} other{CHAPTERS}}",                                                                        
    "chapter_number_x": "CHAPTER {{number}}",
    "chapter_number_x_lower": "Chapter {{number}}",
    "coming_soon": "Coming Soon!",
    "coming_soon_block": "COMING<br>SOON",
    "coming_soon_inline": "COMING SOON",
    "coming_soon_come_back_later": "You've completed all available Beta lessons! <br class='hidden-xs hidden-sm'> Check back later when more are published.",
    "complete_to_unlock_x": "Complete to unlock <strong>{{title}}</strong>",
    "course_details": "COURSE<br>DETAILS",
    "course_highlights": "COURSE HIGHLIGHTS",
    "course_information": "COURSE INFORMATION",
    "course_summaries": "Course Summaries",
    "credits": "CREDITS",
    "download_certificate": "Download Certificate",
    "explore_key_terms": "EXPLORE KEY TERMS",
    "join": "Join {{brandName}}",
    "join_for_upsell_desc": "Access to {{brandName}}'s course library requires an account.",
    "key_terms": "KEY TERMS",
    "key_terms_description": "Key terms are concepts that {{brandName}} believes deserve special attention when studying this subject. Tap any key term in the list below to navigate directly to the lesson in which it was introduced.",
    "learn_more": "LEARN MORE",
    "lesson_number_x": "LESSON {{number}}",
    "lesson_number_x_lower": "Lesson {{number}}",
    "login": "LOGIN",
    "minutes": "MINUTES",
    "or": "OR",
    "ready_to_begin_start_learning": "Ready to begin? <br class=\"visible-xs\"> Start learning now.",
    "recommended_study": "RECOMMENDED STUDY",
    "related_courses": "RELATED COURSES",
    "resources": "RESOURCES",
    "saved_to_my_courses": "SAVED TO MY COURSES",
    "signing_certificate": "Signing Certificate",
    "show_all": "SHOW ALL",
    "smartcase": "SMARTCASE",
    "test": "TEST",
    "sorry_subscription_required": "Sorry! You need a subscription to access this course.",
    "start_course": "START COURSE",
    "start_learning_now": "Start learning now.",
    "subscribe_to_unlock": "Subscribe to Unlock",
    "subscription_required": "Subscription Required",
    "tap_to_download": "Tap to download",
    "tap_to_remove": "Tap to Remove",
    "unlocked": "Unlocked!",
    "we_suggest_following_x": "We suggest you have familiarity with the material in the following courses before beginning <strong>{{title}}</strong>:",
    "num_lessons_completed": "{{count}} of {{total}} lessons completed",
    "num_lessons_completed_short": "{{count}} of {{total}} lessons",
    "ready_to_begin_exam": "Ready to begin? Start your exam now.",
    "you_will_have_x_hours": "You’ll have {{numHours}} hours from the time you start the exam to complete it.",
    "start": "START",
    "start_exam": "Start Exam",
    "exam_timer": "EXAM TIMER",
    "after_launching_first_you_will_have": "After launching the first lesson, you will have <div class='time-left'>{{timeLeft | hoursMinutesSeconds}}</div> to complete the exam.",
    "after_launching_next_you_will_have": "After launching the next lesson, you will have <div class='time-left'>{{timeLeft | hoursMinutesSeconds}}</div> to complete the exam.",
    "you_have_x_remaining": "YOU HAVE <div class='time-left'>{{timeLeft | hoursMinutesSeconds}}</div> REMAINING",
    "schedule": "SCHEDULE",
    "exam_begins": "EXAM BEGINS",
    "exam_ends": "EXAM ENDS",
    "time_limit": "TIME LIMIT",
    "the_exam_time_will_begin": "The exam timer will begin when you launch your first lesson. After {{timeLimitHours}} hours, your exam will close, and no additional answers will be accepted.",
    "view_schedule": "VIEW SCHEDULE",
    "ready_to_begin": "Ready to begin?",
    "completion_instructions_for_summary": "Complete {{titlesText}} to unlock!",
    "exam_score": "Exam Score",
    "external_links": "ASSIGNED READING",
    "external_links_intro": "{{institutionBrandName}} lessons often link to external articles and other webpages where students can learn more about a particular subject. Students are expected to read these articles as part of the completion of each course. Tap on any link below to navigate to the URL.",
    "title_not_available": "Title Not Available",
    "optional": "Optional",
    "course_description": "Course Description",
    "completed_score": "Completed: {{score}}%",

                                   
    "ready_to_begin_verify_identity": "Ready to begin? Verify your identity to unlock the exam.",
    "ready_to_continue_verify_identity": "Ready to continue? Verify your identity to unlock the exam.",
    "verified_identity_thanks": "Thanks for verifying your identity. Start your exam now.",
    "verify_identity": "VERIFY IDENTITY"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.stream_dashboard = window.locales.es.lessons.stream.stream_dashboard || {};window.locales.es.lessons.stream.stream_dashboard = {...window.locales.es.lessons.stream.stream_dashboard, ...{
    "add_to_my_courses": "AÑADIR A MIS CURSOS",           
    "additional_resources": "Recursos adicionales",           
    "chapter_list_caption": "{number, plural, =1{CAPÍTULO} other{CAPÍTULOS}}",                                                                                   
    "chapter_number_x": "CAPÍTULO {{number}}",           
    "chapter_number_x_lower": "Capítulo {{number}}",           
    "coming_soon": "¡Próximamente!",           
    "complete_to_unlock_x": "Completa para desbloquear <strong>{{title}}</strong>",           
    "course_details": "DETALLES DEL <br>CURSO",           
    "course_highlights": "LO MÁS DESTACADO DEL CURSO",           
    "course_information": "INFORMACIÓN DEL CURSO",           
    "course_summaries": "Resúmenes del curso",           
    "credits": "CRÉDITOS",           
    "download_certificate": "Descargar Certificado",           
    "explore_key_terms": "EXPLORAR TÉRMINOS CLAVES",           
    "key_terms": "TÉRMINOS CLAVE",           
    "learn_more": "SABER MÁS",           
    "lesson_number_x": "LECCIÓN {{number}}",           
    "lesson_number_x_lower": "Lección {{number}}",           
    "login": "INICIAR SESIÓN",           
    "minutes": "MINUTOS",           
    "or": "O",           
    "ready_to_begin_start_learning": "¿Listo para comenzar? <br class=\"visible-xs\"> Empieza a aprender ahora.",           
    "recommended_study": "ESTUDIO RECOMENDADO",           
    "related_courses": "CURSOS RELACIONADOS",           
    "resources": "RECURSOS",           
    "saved_to_my_courses": "GUARDADO EN MIS CURSOS",           
    "signing_certificate": "Certificado de firma",           
    "show_all": "MOSTRAR TODOS",           
    "smartcase": "SMARTCASE",           
    "sorry_subscription_required": "¡Lo lamentamos! Se necesita una suscripción para acceder a este curso.",           
    "start_course": "INICIAR CURSO",           
    "start_learning_now": "Empieza a aprender ahora.",           
    "subscribe_to_unlock": "Suscríbete para desbloquear",           
    "subscription_required": "Suscripción obligatoria",           
    "tap_to_download": "Pulsa para descargar",           
    "tap_to_remove": "Pulsa para eliminar",           
    "unlocked": "¡Desbloqueado!",           
    "we_suggest_following_x": "Te sugerimos que estés familiarizado con el material de los cursos siguientes antes de empezar <strong>{{title}}</strong>:",           
    "beta_message": "¡Los cursos beta todavía están! Están a disposición de los estudiantes aventureros que quieran probar las primeras versiones de las clases.",           
    "coming_soon_block": "¡PRÓXIMAMENTE!",           
    "coming_soon_inline": "¡PRÓXIMAMENTE!",           
    "coming_soon_come_back_later": "¡Has completado todas las lecciones Beta disponibles! <br class='hidden-xs hidden-sm'> Vuelve más tarde, cuando haya más publicadas.",           
    "test": "PRUEBA",
    "you_will_have_x_hours": "Dispondrá de {{numHours}} horas desde el momento de iniciar el examen para completarlo.",
    "start_exam": "Comience examen",
    "exam_timer": "cronómetro del examen",
    "after_launching_first_you_will_have": "Tras el lanzamiento de la primera lección, tendrá <div class='time-left'> {{timeLeft | hoursMinutesSeconds}} </div> para completar el examen.",
    "after_launching_next_you_will_have": "Tras el lanzamiento de la primera lección, tendrá <div class='time-left'> {{timeLeft | hoursMinutesSeconds}} </div> para completar el examen.",
    "you_have_x_remaining": "TIENES <div class='time-left'> {{timeLeft | hoursMinutesSeconds}} </div> RESTANTE",
    "schedule": "PROGRAMAR",
    "exam_begins": "EXAMEN COMIENZA",
    "exam_ends": "EXTREMOS DE EXAMEN",
    "time_limit": "LÍMITE DE TIEMPO",
    "the_exam_time_will_begin": "El temporizador de examen comenzará cuando inicia su primera lección. Después {{timeLimitHours}} horas, su examen se cerrará y no se aceptarán respuestas adicionales.",
    "view_schedule": "VER PROGRAMA",
    "ready_to_begin_exam": "Listo para comenzar? Podrá comenzar el examen ahora.",
    "start": "COMIENZO",
    "ready_to_begin": "Listo para comenzar?",
    "completion_instructions_for_summary": "Complete {{titlesText}} para {{titlesText}} !",
    "exam_score": "Puntuación del examen",
    "join": "Únase a {{brandName}}",
    "join_for_upsell_desc": "El acceso a la biblioteca de cursos de {{brandName}} requiere una cuenta.",
    "key_terms_description": "Los términos clave son conceptos que {{brandName}} cree que merecen especial atención al estudiar este tema. Toque cualquier término clave en la lista a continuación para navegar directamente a la lección en la que se introdujo.",
    "title_not_available": "Título no disponible",
    "external_links_intro": "Las lecciones de {{institutionBrandName}} a menudo se vinculan a artículos externos y otras páginas web donde los estudiantes pueden obtener más información sobre un tema en particular. Se espera que los estudiantes lean estos artículos como parte de la finalización de cada curso. Toque cualquier enlace a continuación para navegar a la URL.",
    "external_links": "LECTURA ASIGNADA",
    "ready_to_begin_verify_identity": "¿Listo para comenzar? Verifique su identidad para desbloquear el examen.",
    "ready_to_continue_verify_identity": "¿Listo para continuar? Verifique su identidad para desbloquear el examen.",
    "verified_identity_thanks": "Gracias por verificar su identidad. Comience su examen ahora.",
    "verify_identity": "VERIFICAR IDENTIDAD",
    "optional": "Opcional",
    "num_lessons_completed": "{{count}} de {{total}} lecciones completadas",
    "num_lessons_completed_short": "{{count}} de {{total}} lecciones",
    "course_description": "Descripción del curso",
    "completed_score": "Completado: {{score}}%"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.stream_dashboard = window.locales.it.lessons.stream.stream_dashboard || {};window.locales.it.lessons.stream.stream_dashboard = {...window.locales.it.lessons.stream.stream_dashboard, ...{
    "add_to_my_courses": "AGGIUNGI AI MIEI CORSI",           
    "additional_resources": "Risorse ulteriori",           
    "beta_message": "I corsi beta sono ancora attivi! Sono disponibili per quegli studenti avventurosi che vogliono provare le prime versioni delle lezioni.",           
    "chapter_list_caption": "{number, plural, =1{CAPITOLO} other{CAPITOLI}}",           
    "chapter_number_x": "CAPITOLO {{number}}",           
    "chapter_number_x_lower": "Capitolo {{number}}",           
    "coming_soon": "In arrivo!",           
    "coming_soon_block": "IN<br>ARRIVO",           
    "coming_soon_inline": "IN ARRIVO",           
    "coming_soon_come_back_later": "Hai completato tutte le lezioni Beta disponibili! <br class='hidden-xs hidden-sm'> Torna quando ne saranno pubblicate di nuove.",           
    "complete_to_unlock_x": "Completa per sbloccare <strong>{{title}}</strong>",           
    "course_details": "DETTAGLI<br>CORSO",           
    "course_highlights": "ELEMENTI CHIAVE DEL CORSO",           
    "course_information": "INFORMAZIONI CORSO",           
    "course_summaries": "Riepiloghi Corso",           
    "credits": "RICONOSCIMENTI",           
    "download_certificate": "Scarica Diploma",           
    "explore_key_terms": "ESPLORA PAROLE CHIAVE",           
    "key_terms": "PAROLE CHIAVE",           
    "learn_more": "MAGGIORI INFORMAZIONI",           
    "lesson_number_x": "LEZIONE {{number}}",           
    "lesson_number_x_lower": "Lezione {{number}}",           
    "login": "ACCESSO",           
    "minutes": "MINUTI",           
    "or": "O",           
    "ready_to_begin_start_learning": "Pronto a partire? <br class=\"visible-xs\"> Comincia subito a imparare.",           
    "recommended_study": "STUDI SUGGERITI",           
    "related_courses": "CORSI CORRELATI",           
    "resources": "RISORSE",           
    "saved_to_my_courses": "SALVATO NEI MIEI CORSI",           
    "signing_certificate": "Firma Certificato",           
    "show_all": "MOSTRA TUTTO",           
    "smartcase": "SMARTCASE",           
    "test": "TEST",           
    "sorry_subscription_required": "Siamo spiacenti! Hai bisogno di un abbonamento per accedere a questo corso.",           
    "start_course": "INIZIA CORSO",           
    "start_learning_now": "Comincia subito a imparare.",           
    "subscribe_to_unlock": "Abbonati per Sbloccare",           
    "subscription_required": "Abbonamento Richiesto",           
    "tap_to_download": "Tocca per scaricare",           
    "tap_to_remove": "Tocca per rimuovere",           
    "unlocked": "Sbloccato!",           
    "we_suggest_following_x": "Prima di cominciare, ti consigliamo di maturare una certa familiarità con il materiale dei seguenti corsi<strong>{{title}}</strong>:",           
    "ready_to_begin_exam": "Pronto a cominciare? Inizia subito il tuo esame.",           
    "you_will_have_x_hours": "Avrai {{numHours}} ore dall'orario di inizio dell'esame per completarlo.",           
    "start": "INIZIA",           
    "start_exam": "Inizia Esame",           
    "exam_timer": "TIMER ESAME",           
    "after_launching_first_you_will_have": "Una volta avviata la prima lezione, avrai <div class='time-left'>{{timeLeft | hoursMinutesSeconds}}</div> per completare l'esame.",           
    "after_launching_next_you_will_have": "Una volta avviata la prossima lezione, avrai <div class='time-left'>{{timeLeft | hoursMinutesSeconds}}</div> per completare l'esame.",           
    "you_have_x_remaining": "HAI <div class='time-left'>{{timeLeft | hoursMinutesSeconds}}</div> RESIDUI",           
    "schedule": "CALENDARIO",           
    "exam_begins": "INIZIO ESAME",           
    "exam_ends": "FINE ESAME",           
    "time_limit": "TEMPO LIMITE",           
    "the_exam_time_will_begin": "Il timer dell'esame partirà appena avviata la tua prima lezione. Dopo {{timeLimitHours}} ore il tuo esame si chiuderà, e non sarà accettata alcuna risposta ulteriore.",           
    "view_schedule": "VISUALIZZA CALENDARIO",           
    "ready_to_begin": "Pronto a cominciare?",           
    "completion_instructions_for_summary": "Completa {{titlesText}} per sbloccare!",           
    "exam_score": "Punteggio dell'esame",
    "join": "Iscriviti a {{brandName}}",
    "join_for_upsell_desc": "L'accesso alla libreria dei corsi di {{brandName}} richiede un account.",
    "key_terms_description": "I termini chiave sono concetti che {{brandName}} ritiene meritino un'attenzione particolare quando si studia questa materia. Tocca un termine chiave nell'elenco seguente per navigare direttamente alla lezione in cui è stato introdotto.",
    "title_not_available": "Titolo non disponibile",
    "external_links_intro": "Le lezioni {{institutionBrandName}} spesso si collegano ad articoli esterni e ad altre pagine web in cui gli studenti possono saperne di più su un particolare argomento. Gli studenti sono tenuti a leggere questi articoli come parte del completamento di ogni corso. Toccare uno dei collegamenti sottostanti per accedere all'URL.",
    "external_links": "LETTURA ASSEGNATA",
    "ready_to_begin_verify_identity": "Pronto per iniziare? Verifica la tua identità per sbloccare l'esame.",
    "ready_to_continue_verify_identity": "Pronto a continuare? Verifica la tua identità per sbloccare l'esame.",
    "verified_identity_thanks": "Grazie per aver verificato la tua identità. Inizia ora il tuo esame.",
    "verify_identity": "VERIFICARE L'IDENTITA'",
    "optional": "Opzionale",
    "num_lessons_completed": "{{count}} di {{total}} lezioni completate",
    "num_lessons_completed_short": "{{count}} di {{total}} lezioni",
    "course_description": "Descrizione del corso",
    "completed_score": "Completato: {{score}}%"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.stream_dashboard = window.locales.zh.lessons.stream.stream_dashboard || {};window.locales.zh.lessons.stream.stream_dashboard = {...window.locales.zh.lessons.stream.stream_dashboard, ...{
    "add_to_my_courses": "添加到我的课程",           
    "additional_resources": "附加资源",           
    "beta_message": "测试版课程仍在开发中！它们供大胆的学习者尝试课业的早期版本。",           
    "chapter_list_caption": "{number, plural, =1{章} other{章}}",           
    "chapter_number_x": "第 {{number}} 章",           
    "chapter_number_x_lower": "第 {{number}} 章",           
    "coming_soon": "即将推出！",           
    "coming_soon_block": "即将<br>推出",           
    "coming_soon_inline": "即将推出",           
    "coming_soon_come_back_later": "您已完成所有可用的测试版课业！<br class='hidden-xs hidden-sm'>请在以后发布更多课业时再回来查看。",           
    "complete_to_unlock_x": "完成以解锁 <strong>{{title}}</strong>",           
    "course_details": "课程<br>详细信息",           
    "course_highlights": "课程要点",           
    "course_information": "课程信息",           
    "course_summaries": "课程摘要",           
    "credits": "开发者名单",           
    "download_certificate": "下载证书",           
    "explore_key_terms": "浏览关键术语",           
    "key_terms": "关键术语",           
    "learn_more": "了解更多",           
    "lesson_number_x": "第 {{number}} 课",           
    "lesson_number_x_lower": "第 {{number}} 课",           
    "login": "登录",           
    "minutes": "分钟",           
    "or": "或者",           
    "ready_to_begin_start_learning": "准备好开始了吗？<br class=\"visible-xs\">立即开始学习。",           
    "recommended_study": "推荐学习",           
    "related_courses": "相关课程",           
    "resources": "资源",           
    "saved_to_my_courses": "已保存到我的课程",           
    "signing_certificate": "签署证书",           
    "show_all": "全部显示",           
    "smartcase": "SMARTCASE",           
    "sorry_subscription_required": "抱歉！您需要订阅才能访问此课程。",           
    "start_course": "开始课程",           
    "start_learning_now": "立即开始学习。",           
    "subscribe_to_unlock": "订阅以解锁",           
    "subscription_required": "需要订阅",           
    "tap_to_download": "点按以下载",           
    "tap_to_remove": "点按以删除",           
    "unlocked": "已解锁！",           
    "we_suggest_following_x": "我们建议您先熟悉以下课程中的材料，然后才开始 <strong>{{title}}</strong>：",           
    "test": "测试",           
    "you_will_have_x_hours": "自测验开始起，你可在{{numHours}}小时内完成该测验。",           
    "start_exam": "开始测验",           
    "exam_timer": "测验计时",           
    "after_launching_first_you_will_have": "自第一节课启动后，你可在 <div class='time-left'>{{timeLeft | hoursMinutesSeconds}}</div>内完成测验。",           
    "after_launching_next_you_will_have": "自下一节课启动后，你可在 <div class='time-left'>{{timeLeft | hoursMinutesSeconds}}</div>内完成测验。",           
    "you_have_x_remaining": "你还剩余<div class='time-left'>{{timeLeft | hoursMinutesSeconds}}</div>",           
    "schedule": "课程表",           
    "exam_begins": "测验开始",           
    "exam_ends": "测验结束",           
    "time_limit": "时间限制",           
    "the_exam_time_will_begin": "当你开始第一节课时，测验计时器就会开始计时。在{{timeLimitHours}}小时之后，你的测验将结束，不可再进行作答。",           
    "view_schedule": "查看课程表",           
    "ready_to_begin_exam": "准备开始？开始你的测验。",           
    "start": "开始",           
    "ready_to_begin": "准备开始？",           
    "completion_instructions_for_summary": "完成{{titlesText}}已解锁！",           
    "exam_score": "测验分数",           
    "join": "加入{{brandName}}",           
    "join_for_upsell_desc": "登录账号方可进入{{brandName}}的课程库。",           
    "key_terms_description": "关键术语是{{brandName}}认为在研究这门学科时值得特别注意的概念。点击下面列表中的任何关键术语，就可以直接导航至相关介绍课程。",           
    "title_not_available": "标题不可用",
    "external_links_intro": "{{institutionBrandName}}课程通常链接到外部文章和其他网页，学生可以在其中了解有关特定主题的更多信息。作为完成每门课程的一部分，学生应阅读这些文章。点击下面的任何链接以导航到该 URL。",
    "external_links": "指定阅读材料",
    "ready_to_begin_verify_identity": "准备好开始了吗？验证您的身份以解锁考试。",
    "ready_to_continue_verify_identity": "准备好继续了吗？验证您的身份以解锁考试。",
    "verified_identity_thanks": "感谢您验证您的身份。现在开始你的考试。",
    "verify_identity": "验证身份",
    "optional": "选修的",
    "num_lessons_completed": "已完成{{count}}节课，共{{total}}节课",
    "num_lessons_completed_short": "{{count}}节课，共{{total}}节课",
    "course_description": "课程描述",
    "completed_score": "已完成： {{score}}%"
}
};