window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.lesson = window.locales.am.lessons.lesson || {};window.locales.am.lessons.lesson.offline_mode_consent_modal = window.locales.am.lessons.lesson.offline_mode_consent_modal || {};window.locales.am.lessons.lesson.offline_mode_consent_modal = {...window.locales.am.lessons.lesson.offline_mode_consent_modal, ...{
    "header": "ከመጀመርህ በፊት...",
    "caption": "ከመስመር ውጭ በሚሆኑበት ጊዜ {{brandName}} በጥናት ሥርዓተ ትምህርትዎን በንቃት ማውረድ ይችላል። ይህን ባህሪ ማንቃት ይፈልጋሉ?",
    "enabled": "ነቅቷል",
    "disabled": "ተሰናክሏል።",
    "button_text": "አስቀምጥ እና ቀጥል",
    "fine_print": "ይህንን በማንኛውም ጊዜ መለወጥ ይችላሉ።<br /> ምርጫዎችዎን በመጎብኘት."
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.lesson = window.locales.ar.lessons.lesson || {};window.locales.ar.lessons.lesson.offline_mode_consent_modal = window.locales.ar.lessons.lesson.offline_mode_consent_modal || {};window.locales.ar.lessons.lesson.offline_mode_consent_modal = {...window.locales.ar.lessons.lesson.offline_mode_consent_modal, ...{
    "header": "قبل ان تبدأ...",
    "caption": "يمكن لـ {{brandName}} تنزيل منهجك الدراسي بشكل استباقي للدراسة عندما تكون غير متصل بالإنترنت. هل ترغب في تمكين هذه الميزة؟",
    "enabled": "تفعيل",
    "disabled": "تعطيل",
    "button_text": "حفظ ومتابعة",
    "fine_print": "يمكنك تغيير هذا في أي وقت<br /> من خلال زيارة التفضيلات الخاصة بك."
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.lesson = window.locales.en.lessons.lesson || {};window.locales.en.lessons.lesson.offline_mode_consent_modal = window.locales.en.lessons.lesson.offline_mode_consent_modal || {};window.locales.en.lessons.lesson.offline_mode_consent_modal = {...window.locales.en.lessons.lesson.offline_mode_consent_modal, ...{
    "header": "Before you begin...",
    "caption": "{{brandName}} can proactively download your curriculum for study when you're offline. Would you like to enable this feature?",
    "fine_print": "You can change this at any time<br /> by visiting your Preferences.",
    "enabled": "Enabled",
    "disabled": "Disabled",
    "button_text": "Save and Continue"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.lesson = window.locales.es.lessons.lesson || {};window.locales.es.lessons.lesson.offline_mode_consent_modal = window.locales.es.lessons.lesson.offline_mode_consent_modal || {};window.locales.es.lessons.lesson.offline_mode_consent_modal = {...window.locales.es.lessons.lesson.offline_mode_consent_modal, ...{
    "header": "Antes de que empieces...",
    "caption": "{{brandName}} puede descargar de forma proactiva su plan de estudios para estudiarlo cuando no está conectado. ¿Le gustaría habilitar esta función?",
    "enabled": "Activado",
    "disabled": "Discapacitado",
    "button_text": "Guardar y continuar",
    "fine_print": "Usted puede cambiar esto en cualquier momento<br /> visitando sus Preferencias."
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.lesson = window.locales.it.lessons.lesson || {};window.locales.it.lessons.lesson.offline_mode_consent_modal = window.locales.it.lessons.lesson.offline_mode_consent_modal || {};window.locales.it.lessons.lesson.offline_mode_consent_modal = {...window.locales.it.lessons.lesson.offline_mode_consent_modal, ...{
    "header": "Prima di iniziare...",
    "caption": "{{brandName}} può scaricare in modo proattivo il tuo curriculum per lo studio quando sei offline. Vuoi abilitare questa funzione?",
    "enabled": "Abilitato",
    "disabled": "Disabilitato",
    "button_text": "Salva e continua",
    "fine_print": "Puoi cambiarlo in qualsiasi momento<br /> visitando le tue Preferenze."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.lesson = window.locales.zh.lessons.lesson || {};window.locales.zh.lessons.lesson.offline_mode_consent_modal = window.locales.zh.lessons.lesson.offline_mode_consent_modal || {};window.locales.zh.lessons.lesson.offline_mode_consent_modal = {...window.locales.zh.lessons.lesson.offline_mode_consent_modal, ...{
    "header": "在你开始之前...",
    "caption": "{{brandName}} 可以在您离线时主动下载您的课程以进行学习。您要启用此功能吗？",
    "enabled": "启用",
    "disabled": "已禁用",
    "button_text": "保存并继续",
    "fine_print": "您可以随时更改此设置<br />通过访问您的首选项。"
}
};