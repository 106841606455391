window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.lesson = window.locales.am.lessons.lesson || {};window.locales.am.lessons.lesson.lesson_start_screen = window.locales.am.lessons.lesson.lesson_start_screen || {};window.locales.am.lessons.lesson.lesson_start_screen = {...window.locales.am.lessons.lesson.lesson_start_screen, ...{
    "welcome": "እንኳን በደህና መጡ!",           
    "what_you_will_learn": "በዚህ ትምህርት ውስጥ ምን የሚማሩት"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.lesson = window.locales.ar.lessons.lesson || {};window.locales.ar.lessons.lesson.lesson_start_screen = window.locales.ar.lessons.lesson.lesson_start_screen || {};window.locales.ar.lessons.lesson.lesson_start_screen = {...window.locales.ar.lessons.lesson.lesson_start_screen, ...{
    "welcome": "مرحباً بك!",           
    "what_you_will_learn": "ما الذي سوف تتعلمه في هذا الدرس"           
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.lesson = window.locales.en.lessons.lesson || {};window.locales.en.lessons.lesson.lesson_start_screen = window.locales.en.lessons.lesson.lesson_start_screen || {};window.locales.en.lessons.lesson.lesson_start_screen = {...window.locales.en.lessons.lesson.lesson_start_screen, ...{
    "welcome": "WELCOME!",
    "what_you_will_learn": "WHAT YOU'LL LEARN IN THIS LESSON"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.lesson = window.locales.es.lessons.lesson || {};window.locales.es.lessons.lesson.lesson_start_screen = window.locales.es.lessons.lesson.lesson_start_screen || {};window.locales.es.lessons.lesson.lesson_start_screen = {...window.locales.es.lessons.lesson.lesson_start_screen, ...{
    "welcome": "¡BIENVENIDO!",           
    "what_you_will_learn": "LO QUE APRENDERÁS EN ESTA LECCIÓN",           
    "what_you_will_review": "LO QUE REPASARÁS"           
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.lesson = window.locales.it.lessons.lesson || {};window.locales.it.lessons.lesson.lesson_start_screen = window.locales.it.lessons.lesson.lesson_start_screen || {};window.locales.it.lessons.lesson.lesson_start_screen = {...window.locales.it.lessons.lesson.lesson_start_screen, ...{
    "welcome": "BENVENUTO!",           
    "what_you_will_learn": "COSA IMPARERAI IN QUESTA LEZIONE"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.lesson = window.locales.zh.lessons.lesson || {};window.locales.zh.lessons.lesson.lesson_start_screen = window.locales.zh.lessons.lesson.lesson_start_screen || {};window.locales.zh.lessons.lesson.lesson_start_screen = {...window.locales.zh.lessons.lesson.lesson_start_screen, ...{
    "welcome": "欢迎！",           
    "what_you_will_learn": "您将在本课中学习的内容",           
    "what_you_will_review": "您将复习的内容"           
}
};