window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.lesson = window.locales.am.lessons.lesson || {};window.locales.am.lessons.lesson.lesson_finish_screen_demo_content = window.locales.am.lessons.lesson.lesson_finish_screen_demo_content || {};window.locales.am.lessons.lesson.lesson_finish_screen_demo_content = {...window.locales.am.lessons.lesson.lesson_finish_screen_demo_content, ...{
    "start_free_trial": "ነፃ ሙከራዎን ይጀምሩ",           
    "learn_more": "ተጨማሪ ይማሩ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.lesson = window.locales.ar.lessons.lesson || {};window.locales.ar.lessons.lesson.lesson_finish_screen_demo_content = window.locales.ar.lessons.lesson.lesson_finish_screen_demo_content || {};window.locales.ar.lessons.lesson.lesson_finish_screen_demo_content = {...window.locales.ar.lessons.lesson.lesson_finish_screen_demo_content, ...{
    "start_free_trial": "ابدأ تجربتك المجانية",           
    "learn_more": "تعلم المزيد"           
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.lesson = window.locales.en.lessons.lesson || {};window.locales.en.lessons.lesson.lesson_finish_screen_demo_content = window.locales.en.lessons.lesson.lesson_finish_screen_demo_content || {};window.locales.en.lessons.lesson.lesson_finish_screen_demo_content = {...window.locales.en.lessons.lesson.lesson_finish_screen_demo_content, ...{
    "start_free_trial": "Start your free trial",
    "learn_more": "Learn more"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.lesson = window.locales.es.lessons.lesson || {};window.locales.es.lessons.lesson.lesson_finish_screen_demo_content = window.locales.es.lessons.lesson.lesson_finish_screen_demo_content || {};window.locales.es.lessons.lesson.lesson_finish_screen_demo_content = {...window.locales.es.lessons.lesson.lesson_finish_screen_demo_content, ...{
    "start_free_trial": "Comienza tu prueba gratuita",           
    "learn_more": "Saber más"           
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.lesson = window.locales.it.lessons.lesson || {};window.locales.it.lessons.lesson.lesson_finish_screen_demo_content = window.locales.it.lessons.lesson.lesson_finish_screen_demo_content || {};window.locales.it.lessons.lesson.lesson_finish_screen_demo_content = {...window.locales.it.lessons.lesson.lesson_finish_screen_demo_content, ...{
    "start_free_trial": "Comincia la tua prova gratuita",           
    "learn_more": "Maggiori informazioni"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.lesson = window.locales.zh.lessons.lesson || {};window.locales.zh.lessons.lesson.lesson_finish_screen_demo_content = window.locales.zh.lessons.lesson.lesson_finish_screen_demo_content || {};window.locales.zh.lessons.lesson.lesson_finish_screen_demo_content = {...window.locales.zh.lessons.lesson.lesson_finish_screen_demo_content, ...{
    "start_free_trial": "开始您的免费试用",           
    "learn_more": "了解更多"           
}
};