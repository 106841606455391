window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.student_dashboard_schedule_interview = window.locales.am.lessons.stream.student_dashboard_schedule_interview || {};window.locales.am.lessons.stream.student_dashboard_schedule_interview = {...window.locales.am.lessons.stream.student_dashboard_schedule_interview, ...{
    "interview": "መርሃግብር ያስይዙ",           
    "required": "ይህ <b>አስፈላጊ</b> ቃለመጠይቅ ነው። ለበለጠ ዝርዝሮች እባክዎ ኢሜይልዎን ይመልከቱ።",           
    "schedule_interview": "የቃለመጠይቅ መርሃግብር ያውጡ",           
    "close": "ገጠመ<i class=\"fas fa-multiply\"></i>",
    "schedule": "መርሐግብር<i class=\"fas fa-chevron-up\"></i>",
    "schedule_your": "የእርስዎን መርሐግብር ያስይዙ",
    "schedule_your_interview": "ቃለ መጠይቅዎን ያቅዱ",
    "conduct_your": "የእርስዎን ያካሂዱ",
    "congratulations": "እንኳን ደስ አላችሁ! ለአጭር የመግቢያ ቃለ መጠይቅ ተመርጠዋል። ለዝርዝሮች ኢሜልዎን ይመልከቱ።",
    "conduct_interview": "ቃለ መጠይቅ ማካሄድ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.student_dashboard_schedule_interview = window.locales.ar.lessons.stream.student_dashboard_schedule_interview || {};window.locales.ar.lessons.stream.student_dashboard_schedule_interview = {...window.locales.ar.lessons.stream.student_dashboard_schedule_interview, ...{
    "interview": "مقابلة",
    "required": "هذه مقابلة <b>مطلوبة</b> . يرجى التحقق من بريدك الالكتروني لمزيد من التفاصيل.",
    "schedule_interview": "مقابلة الجدول الزمني",
    "close": "قريب<i class=\"fas fa-multiply\"></i>",
    "schedule": "برنامج<i class=\"fas fa-chevron-up\"></i>",
    "schedule_your": "جدولة",
    "schedule_your_interview": "حدد موعد مقابلتك",
    "conduct_your": "سلوكك",
    "congratulations": "تهانينا! لقد تم اختيارك لإجراء مقابلة قبول قصيرة. تحقق من بريدك الإلكتروني للحصول على التفاصيل.",
    "conduct_interview": "إجراء المقابلة"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.student_dashboard_schedule_interview = window.locales.en.lessons.stream.student_dashboard_schedule_interview || {};window.locales.en.lessons.stream.student_dashboard_schedule_interview = {...window.locales.en.lessons.stream.student_dashboard_schedule_interview, ...{
    "schedule_your": "Schedule Your",
    "conduct_your": "Conduct Your",
    "interview": "Interview",
    "congratulations": "Congratulations! You have been selected for a brief admissions interview. Check your email for details.",
    "conduct_interview": "Conduct Interview",
    "required": "This is a <b>required</b> interview. Please check your email for more details.",
    "schedule_interview": "Schedule Interview",
    "schedule_your_interview": "Schedule Your Interview",
    "close": "Close <i class=\"fas fa-multiply\"></i>",
    "schedule": "Schedule <i class=\"fas fa-chevron-up\"></i>"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.student_dashboard_schedule_interview = window.locales.es.lessons.stream.student_dashboard_schedule_interview || {};window.locales.es.lessons.stream.student_dashboard_schedule_interview = {...window.locales.es.lessons.stream.student_dashboard_schedule_interview, ...{
    "interview": "Entrevista",
    "required": "Esta es una entrevista <b>obligatoria</b> . Por favor revise su correo electrónico para más detalles.",
    "schedule_interview": "Entrevista programada",
    "close": "Cerca<i class=\"fas fa-multiply\"></i>",
    "schedule": "Calendario<i class=\"fas fa-chevron-up\"></i>",
    "schedule_your": "Programa tu",
    "schedule_your_interview": "Programe su entrevista",
    "conduct_your": "Realiza tu",
    "congratulations": "¡Felicidades! Ha sido seleccionado para una breve entrevista de admisión. Consulte su correo electrónico para obtener más detalles.",
    "conduct_interview": "Realizar la entrevista"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.student_dashboard_schedule_interview = window.locales.it.lessons.stream.student_dashboard_schedule_interview || {};window.locales.it.lessons.stream.student_dashboard_schedule_interview = {...window.locales.it.lessons.stream.student_dashboard_schedule_interview, ...{
    "interview": "Colloquio",
    "required": "Questa è un'intervista <b>richiesta</b> . Si prega di controllare la tua e-mail per maggiori dettagli.",
    "schedule_interview": "Programma Intervista",
    "close": "Chiudere<i class=\"fas fa-multiply\"></i>",
    "schedule": "Programma<i class=\"fas fa-chevron-up\"></i>",
    "schedule_your": "Pianifica il tuo",
    "schedule_your_interview": "Pianifica il tuo colloquio",
    "conduct_your": "Conduci il tuo",
    "congratulations": "Congratulazioni! Sei stato selezionato per un breve colloquio di ammissione. Controlla la tua email per i dettagli.",
    "conduct_interview": "Condurre l'intervista"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.student_dashboard_schedule_interview = window.locales.zh.lessons.stream.student_dashboard_schedule_interview || {};window.locales.zh.lessons.stream.student_dashboard_schedule_interview = {...window.locales.zh.lessons.stream.student_dashboard_schedule_interview, ...{
    "interview": "面试",           
    "required": "这是一场<b>必要</b>面试。请查看你的邮件以了解更多详情。",           
    "schedule_interview": "安排面试",           
    "close": "关<i class=\"fas fa-multiply\"></i>",
    "schedule": "日程<i class=\"fas fa-chevron-up\"></i>",
    "schedule_your": "安排你的",
    "schedule_your_interview": "安排面试",
    "conduct_your": "进行你的",
    "congratulations": "恭喜！您已被选中参加简短的招生面试。请查看您的电子邮件了解详情。",
    "conduct_interview": "进行面试"
}
};