window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.student_dashboard_welcome_box = window.locales.am.lessons.stream.student_dashboard_welcome_box || {};window.locales.am.lessons.stream.student_dashboard_welcome_box = {...window.locales.am.lessons.stream.student_dashboard_welcome_box, ...{
    "welcome_generic": "<p class=\"hr-caption\">እንኳን ወደ {{brandName}} በደህና መጡ፣<br class=\"hidden-xs\"> {{name}} ! </p><hr class=\"hr-hexagon lightest-beige-bg hidden-xs\" /><div class=\"sub-title\"><p class=\"message smarter\"> ከእኛ ጋር በማጥናት ጊዜዎን እንደሚደሰቱ ተስፋ እናደርጋለን።<br><br> መልካም ትምህርት!</p></div>"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.student_dashboard_welcome_box = window.locales.ar.lessons.stream.student_dashboard_welcome_box || {};window.locales.ar.lessons.stream.student_dashboard_welcome_box = {...window.locales.ar.lessons.stream.student_dashboard_welcome_box, ...{
    "welcome_generic": "<p class=\"hr-caption\" style=\";text-align:right;direction:rtl\">مرحبًا بك في {{brandName}} ،<br class=\"hidden-xs\"> {{name}} ! </p><hr class=\"hr-hexagon lightest-beige-bg hidden-xs\" /><div class=\"sub-title\" style=\";text-align:right;direction:rtl\"><p class=\"message smarter\" style=\";text-align:right;direction:rtl\"> نأمل أن تستمتع بوقتك في الدراسة معنا.<br><br> نتمنى لك الاستمتاع بالتعلم!</p></div>"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.student_dashboard_welcome_box = window.locales.en.lessons.stream.student_dashboard_welcome_box || {};window.locales.en.lessons.stream.student_dashboard_welcome_box = {...window.locales.en.lessons.stream.student_dashboard_welcome_box, ...{
    "welcome_generic": "<p class=\"hr-caption\">Welcome to {{brandName}}, <br class=\"hidden-xs\">{{name}}!</p><hr class=\"hr-hexagon lightest-beige-bg hidden-xs\" /><div class=\"sub-title\"><p class=\"message smarter\">We hope you enjoy your time studying with us.<br><br>Happy Learning!</p></div>"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.student_dashboard_welcome_box = window.locales.es.lessons.stream.student_dashboard_welcome_box || {};window.locales.es.lessons.stream.student_dashboard_welcome_box = {...window.locales.es.lessons.stream.student_dashboard_welcome_box, ...{
    "welcome_generic": "<p class=\"hr-caption\">Bienvenido a {{brandName}} ,<br class=\"hidden-xs\"> {{name}} ! </p><hr class=\"hr-hexagon lightest-beige-bg hidden-xs\" /><div class=\"sub-title\"><p class=\"message smarter\"> Esperamos que disfrute de su tiempo estudiando con nosotros.<br><br> ¡Feliz aprendizaje!</p></div>"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.student_dashboard_welcome_box = window.locales.it.lessons.stream.student_dashboard_welcome_box || {};window.locales.it.lessons.stream.student_dashboard_welcome_box = {...window.locales.it.lessons.stream.student_dashboard_welcome_box, ...{
    "welcome_generic": "<p class=\"hr-caption\">Benvenuto in {{brandName}} ,<br class=\"hidden-xs\"> {{name}} ! </p><hr class=\"hr-hexagon lightest-beige-bg hidden-xs\" /><div class=\"sub-title\"><p class=\"message smarter\"> Ci auguriamo che ti piaccia studiare con noi.<br><br> Buon apprendimento!</p></div>"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.student_dashboard_welcome_box = window.locales.zh.lessons.stream.student_dashboard_welcome_box || {};window.locales.zh.lessons.stream.student_dashboard_welcome_box = {...window.locales.zh.lessons.stream.student_dashboard_welcome_box, ...{
    "welcome_generic": "<p class=\"hr-caption\">欢迎来到{{brandName}} ，<br class=\"hidden-xs\"> {{name}} ！ </p><hr class=\"hr-hexagon lightest-beige-bg hidden-xs\" /><div class=\"sub-title\"><p class=\"message smarter\">我们希望您享受与我们一起学习的时光。<br><br>快乐学习！</p></div>"
}
};