window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.top_message = window.locales.am.lessons.stream.top_message || {};window.locales.am.lessons.stream.top_message = {...window.locales.am.lessons.stream.top_message, ...{
    "top_message_business_admin_desktop": "<p>እንኳን ወደ {{institutionName}} በደህና መጡ!</p><p> የ MBA እና Executive MBA ሥርዓተ-ትምህርትን፣ የኛን 15,000+ የተመራቂዎች አውታረ መረብ እና መጪ አለምአቀፍ ክስተቶችን ያስሱ። በ {{applicationDeadlineDate}} ቀነ ገደብ ያመልክቱ።</p>",
    "top_message_business_admin_desktop_applied": "<p>እንኳን ወደ {{institutionName}} በደህና መጡ!</p><p> የ MBA እና Executive MBA ሥርዓተ-ትምህርትን፣ የኛን 15,000+ የተመራቂዎች አውታረ መረብ እና መጪ አለምአቀፍ ክስተቶችን ያስሱ።</p>",
    "top_message_business_admin_mobile": "<p>የዘመናዊውን MBA ሥርዓተ ትምህርት እና የቀድሞ ተማሪዎች አውታረ መረብን ያስሱ።</p>",
    "top_message_generic_desktop": "<p>እንኳን ወደ {{institutionName}} በደህና መጡ!</p><p> የ {{programTitle}} ስርአተ ትምህርቱን፣ ከ15,000+ የተመራቂ ተማሪዎች አውታረ መረብ እና መጪ አለምአቀፍ ክስተቶችን ያስሱ። በ {{applicationDeadlineDate}} ቀነ ገደብ ያመልክቱ።</p>",
    "top_message_generic_desktop_applied": "<p>እንኳን ወደ {{institutionName}} በደህና መጡ!</p><p> የ {{programTitle}} ስርአተ ትምህርቱን፣ ከ15,000+ የተመራቂ ተማሪዎች አውታረ መረብ እና መጪ አለምአቀፍ ክስተቶችን ያስሱ።</p>",
    "top_message_generic_mobile": "<p>የዘመናዊውን {{programTitle}} ስርአተ ትምህርት እና የቀድሞ ተማሪዎች አውታረመረብ ያስሱ።</p>",
    "top_message_settings_has_moved": "<p>ቅንብሮች ተንቀሳቅሰዋል!</p>",
    "top_message_msba_desktop": "<p>እንኳን ወደ {{institutionName}} በደህና መጡ!</p><p> የMSBA ስርአተ ትምህርትን፣ የኛን 15,000+ የምሩቃን አውታረ መረብ እና መጪ አለምአቀፍ ክስተቶችን ያስሱ። በ {{applicationDeadlineDate}} ቀነ ገደብ ያመልክቱ።</p>",
    "top_message_msba_desktop_applied": "<p>እንኳን ወደ {{institutionName}} በደህና መጡ!</p><p> የMSBA ስርአተ ትምህርትን፣ የኛን 15,000+ የተመራቂዎች አውታረ መረብ እና መጪ አለምአቀፍ ክስተቶችን ያስሱ።</p>",
    "top_message_msba_mobile": "<p>የዘመናዊውን MSBA ሥርዓተ ትምህርት እና የቀድሞ ተማሪዎች መረብን ያስሱ።</p>",
    "top_message_msse_desktop": "<p>እንኳን ወደ {{institutionName}} በደህና መጡ!</p><p> የMSSE ስርአተ ትምህርትን፣ የኛን 15,000+ የተመራቂዎች አውታረ መረብ እና መጪ አለምአቀፍ ክስተቶችን ያስሱ። በ {{applicationDeadlineDate}} ቀነ ገደብ ያመልክቱ።</p>",
    "top_message_msse_desktop_applied": "<p>እንኳን ወደ {{institutionName}} በደህና መጡ!</p><p> የMSSE ስርአተ ትምህርትን፣ የኛን 15,000+ የምሩቃን አውታረ መረብ እና መጪ አለምአቀፍ ክስተቶችን ያስሱ።</p>",
    "top_message_msse_mobile": "<p>የዘመናዊውን MSSE ሥርዓተ ትምህርት እና የቀድሞ ተማሪዎች መረብን ያስሱ።</p>"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.top_message = window.locales.ar.lessons.stream.top_message || {};window.locales.ar.lessons.stream.top_message = {...window.locales.ar.lessons.stream.top_message, ...{
    "top_message_business_admin_desktop": "<p style=\";text-align:right;direction:rtl\">مرحبًا بك في {{institutionName}} !</p><p style=\";text-align:right;direction:rtl\"> استكشف منهج ماجستير إدارة الأعمال وماجستير إدارة الأعمال التنفيذية ، وشبكة الخريجين التي يزيد عددها عن 15000 ، والأحداث العالمية القادمة. التقديم بحلول الموعد النهائي {{applicationDeadlineDate}} .</p>",
    "top_message_business_admin_desktop_applied": "<p style=\";text-align:right;direction:rtl\">مرحبًا بك في {{institutionName}} !</p><p style=\";text-align:right;direction:rtl\"> استكشف منهج ماجستير إدارة الأعمال وماجستير إدارة الأعمال التنفيذية ، وشبكة الخريجين التي يزيد عددها عن 15000 ، والأحداث العالمية القادمة.</p>",
    "top_message_business_admin_mobile": "<p style=\";text-align:right;direction:rtl\">استكشف المناهج وشبكة الخريجين في ماجستير إدارة الأعمال الحديث.</p>",
    "top_message_generic_desktop": "<p style=\";text-align:right;direction:rtl\">مرحبًا بك في {{institutionName}} !</p><p style=\";text-align:right;direction:rtl\"> استكشف {{programTitle}} وشبكة الخريجين التي يزيد عددها عن 15000 والأحداث العالمية القادمة. التقديم بحلول الموعد النهائي {{applicationDeadlineDate}} .</p>",
    "top_message_generic_desktop_applied": "<p style=\";text-align:right;direction:rtl\">مرحبًا بك في {{institutionName}} !</p><p style=\";text-align:right;direction:rtl\"> استكشف {{programTitle}} وشبكة الخريجين التي يزيد عددها عن 15000 والأحداث العالمية القادمة.</p>",
    "top_message_generic_mobile": "<p style=\";text-align:right;direction:rtl\">استكشف المناهج وشبكة الخريجين في {{programTitle}} الحديثة.</p>",
    "top_message_settings_has_moved": "<p style=\";text-align:right;direction:rtl\">تم نقل الإعدادات!</p>",
    "top_message_msba_desktop": "<p style=\";text-align:right;direction:rtl\">مرحبًا بك في {{institutionName}} !</p><p style=\";text-align:right;direction:rtl\"> استكشف منهج MSBA، وشبكة خريجينا التي تضم أكثر من 15000، والأحداث العالمية القادمة. تقدم بطلبك بحلول الموعد النهائي {{applicationDeadlineDate}} .</p>",
    "top_message_msba_desktop_applied": "<p style=\";text-align:right;direction:rtl\">مرحبًا بك في {{institutionName}} !</p><p style=\";text-align:right;direction:rtl\"> استكشف منهج MSBA، وشبكة خريجينا التي تضم أكثر من 15000، والأحداث العالمية القادمة.</p>",
    "top_message_msba_mobile": "<p style=\";text-align:right;direction:rtl\">استكشف المناهج الدراسية وشبكة الخريجين في MSBA الحديث.</p>",
    "top_message_msse_desktop": "<p style=\";text-align:right;direction:rtl\">مرحبًا بك في {{institutionName}} !</p><p style=\";text-align:right;direction:rtl\"> استكشف منهج MSSE، وشبكة خريجينا التي تضم أكثر من 15000، والأحداث العالمية القادمة. تقدم بطلبك بحلول الموعد النهائي {{applicationDeadlineDate}} .</p>",
    "top_message_msse_desktop_applied": "<p style=\";text-align:right;direction:rtl\">مرحبًا بك في {{institutionName}} !</p><p style=\";text-align:right;direction:rtl\"> استكشف منهج MSSE، وشبكة خريجينا التي تضم أكثر من 15000، والأحداث العالمية القادمة.</p>",
    "top_message_msse_mobile": "<p style=\";text-align:right;direction:rtl\">استكشف المناهج الدراسية وشبكة الخريجين في MSSE الحديثة.</p>"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.top_message = window.locales.en.lessons.stream.top_message || {};window.locales.en.lessons.stream.top_message = {...window.locales.en.lessons.stream.top_message, ...{
    "top_message_business_admin_desktop": "<p>Welcome to {{institutionName}}!</p><p>Explore the MBA and Executive MBA curriculum, our 15,000+ alumni network, and upcoming global events. Apply by the {{applicationDeadlineDate}} deadline.</p>",
    "top_message_business_admin_desktop_applied": "<p>Welcome to {{institutionName}}!</p><p>Explore the MBA and Executive MBA curriculum, our 15,000+ alumni network, and upcoming global events.</p>",
    "top_message_business_admin_mobile": "<p>Explore the curriculum and alumni network of the modern MBA.</p>",
    "top_message_msba_desktop": "<p>Welcome to {{institutionName}}!</p><p>Explore the MSBA curriculum, our 15,000+ alumni network, and upcoming global events. Apply by the {{applicationDeadlineDate}} deadline.</p>",
    "top_message_msba_desktop_applied": "<p>Welcome to {{institutionName}}!</p><p>Explore the MSBA curriculum, our 15,000+ alumni network, and upcoming global events.</p>",
    "top_message_msba_mobile": "<p>Explore the curriculum and alumni network of the modern MSBA.</p>",
    "top_message_msse_desktop": "<p>Welcome to {{institutionName}}!</p><p>Explore the MSSE curriculum, our 15,000+ alumni network, and upcoming global events. Apply by the {{applicationDeadlineDate}} deadline.</p>",
    "top_message_msse_desktop_applied": "<p>Welcome to {{institutionName}}!</p><p>Explore the MSSE curriculum, our 15,000+ alumni network, and upcoming global events.</p>",
    "top_message_msse_mobile": "<p>Explore the curriculum and alumni network of the modern MSSE.</p>",
    "top_message_generic_desktop": "<p>Welcome to {{institutionName}}!</p><p>Explore the {{programTitle}} curriculum, our 15,000+ alumni network, and upcoming global events. Apply by the {{applicationDeadlineDate}} deadline.</p>",
    "top_message_generic_desktop_applied": "<p>Welcome to {{institutionName}}!</p><p>Explore the {{programTitle}} curriculum, our 15,000+ alumni network, and upcoming global events.</p>",
    "top_message_generic_mobile": "<p>Explore the curriculum and alumni network of the modern {{programTitle}}.</p>",
    "top_message_settings_has_moved": "<p>Settings has moved!</p>"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.top_message = window.locales.es.lessons.stream.top_message || {};window.locales.es.lessons.stream.top_message = {...window.locales.es.lessons.stream.top_message, ...{
    "top_message_business_admin_desktop": "<p>¡Bienvenido a {{institutionName}} !</p><p> Explore el plan de estudios de MBA y Executive MBA, nuestra red de más de 15 000 ex alumnos y los próximos eventos globales. Solicite antes de la fecha límite de {{applicationDeadlineDate}} .</p>",
    "top_message_business_admin_desktop_applied": "<p>¡Bienvenido a {{institutionName}} !</p><p> Explore el plan de estudios de MBA y Executive MBA, nuestra red de más de 15 000 ex alumnos y los próximos eventos globales.</p>",
    "top_message_business_admin_mobile": "<p>Explore el plan de estudios y la red de ex alumnos del MBA moderno.</p>",
    "top_message_generic_desktop": "<p>¡Bienvenido a {{institutionName}} !</p><p> Explora el plan de estudios de {{programTitle}} , nuestra red de más de 15 000 ex alumnos y los próximos eventos globales. Solicite antes de la fecha límite de {{applicationDeadlineDate}} .</p>",
    "top_message_generic_desktop_applied": "<p>¡Bienvenido a {{institutionName}} !</p><p> Explora el plan de estudios de {{programTitle}} , nuestra red de más de 15 000 ex alumnos y los próximos eventos globales.</p>",
    "top_message_generic_mobile": "<p>Explore el plan de estudios y la red de ex alumnos del {{programTitle}} moderno.</p>",
    "top_message_settings_has_moved": "<p>¡La configuración se ha movido!</p>",
    "top_message_msba_desktop": "<p>¡Bienvenido a {{institutionName}} !</p><p> Explore el plan de estudios de MSBA, nuestra red de más de 15 000 ex alumnos y los próximos eventos globales. Presente su solicitud antes de la fecha límite {{applicationDeadlineDate}} .</p>",
    "top_message_msba_desktop_applied": "<p>¡Bienvenido a {{institutionName}} !</p><p> Explore el plan de estudios de MSBA, nuestra red de más de 15 000 ex alumnos y los próximos eventos globales.</p>",
    "top_message_msba_mobile": "<p>Explore el plan de estudios y la red de ex alumnos de la MSBA moderna.</p>",
    "top_message_msse_desktop": "<p>¡Bienvenido a {{institutionName}} !</p><p> Explore el plan de estudios de MSSE, nuestra red de más de 15 000 ex alumnos y los próximos eventos globales. Presente su solicitud antes de la fecha límite {{applicationDeadlineDate}} .</p>",
    "top_message_msse_desktop_applied": "<p>¡Bienvenido a {{institutionName}} !</p><p> Explore el plan de estudios de MSSE, nuestra red de más de 15 000 ex alumnos y los próximos eventos globales.</p>",
    "top_message_msse_mobile": "<p>Explore el plan de estudios y la red de exalumnos de la MSSE moderna.</p>"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.top_message = window.locales.it.lessons.stream.top_message || {};window.locales.it.lessons.stream.top_message = {...window.locales.it.lessons.stream.top_message, ...{
    "top_message_business_admin_desktop": "<p>Benvenuto in {{institutionName}} !</p><p> Esplora il curriculum MBA ed Executive MBA, la nostra rete di oltre 15.000 ex studenti e i prossimi eventi globali. Fai domanda entro la scadenza del {{applicationDeadlineDate}} .</p>",
    "top_message_business_admin_desktop_applied": "<p>Benvenuto in {{institutionName}} !</p><p> Esplora il curriculum MBA ed Executive MBA, la nostra rete di oltre 15.000 ex studenti e i prossimi eventi globali.</p>",
    "top_message_business_admin_mobile": "<p>Esplora il curriculum e la rete di ex studenti del moderno MBA.</p>",
    "top_message_generic_desktop": "<p>Benvenuto in {{institutionName}} !</p><p> Esplora il curriculum di {{programTitle}} , la nostra rete di oltre 15.000 ex studenti e i prossimi eventi globali. Fai domanda entro la scadenza del {{applicationDeadlineDate}} .</p>",
    "top_message_generic_desktop_applied": "<p>Benvenuto in {{institutionName}} !</p><p> Esplora il curriculum di {{programTitle}} , la nostra rete di oltre 15.000 ex studenti e i prossimi eventi globali.</p>",
    "top_message_generic_mobile": "<p>Esplora il curriculum e la rete di ex studenti del moderno {{programTitle}} .</p>",
    "top_message_settings_has_moved": "<p>Le impostazioni sono state spostate!</p>",
    "top_message_msba_desktop": "<p>Benvenuto a {{institutionName}} !</p><p> Esplora il curriculum MSBA, la nostra rete di oltre 15.000 ex studenti e i prossimi eventi globali. Presenta domanda entro la scadenza del {{applicationDeadlineDate}} .</p>",
    "top_message_msba_desktop_applied": "<p>Benvenuto a {{institutionName}} !</p><p> Esplora il curriculum MSBA, la nostra rete di oltre 15.000 ex studenti e i prossimi eventi globali.</p>",
    "top_message_msba_mobile": "<p>Esplora il curriculum e la rete di ex studenti del moderno MSBA.</p>",
    "top_message_msse_desktop": "<p>Benvenuto a {{institutionName}} !</p><p> Esplora il curriculum MSSE, la nostra rete di oltre 15.000 ex studenti e i prossimi eventi globali. Presenta domanda entro la scadenza del {{applicationDeadlineDate}} .</p>",
    "top_message_msse_desktop_applied": "<p>Benvenuto a {{institutionName}} !</p><p> Esplora il curriculum MSSE, la nostra rete di oltre 15.000 ex studenti e i prossimi eventi globali.</p>",
    "top_message_msse_mobile": "<p>Esplora il curriculum e la rete di ex studenti del moderno MSSE.</p>"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.top_message = window.locales.zh.lessons.stream.top_message || {};window.locales.zh.lessons.stream.top_message = {...window.locales.zh.lessons.stream.top_message, ...{
    "top_message_business_admin_desktop": "<p>欢迎来到{{institutionName}} ！</p><p>探索 MBA 和 Executive MBA 课程、我们的 15,000 多名校友网络以及即将举行的全球活动。在{{applicationDeadlineDate}}截止日期前申请。</p>",
    "top_message_business_admin_desktop_applied": "<p>欢迎来到{{institutionName}} ！</p><p>探索 MBA 和 Executive MBA 课程、我们的 15,000 多名校友网络以及即将举行的全球活动。</p>",
    "top_message_business_admin_mobile": "<p>探索现代 MBA 的课程和校友网络。</p>",
    "top_message_generic_desktop": "<p>欢迎来到{{institutionName}} ！</p><p>探索{{programTitle}}课程、我们的 15,000 多个校友网络和即将举行的全球活动。在{{applicationDeadlineDate}}截止日期前申请。</p>",
    "top_message_generic_desktop_applied": "<p>欢迎来到{{institutionName}} ！</p><p>探索{{programTitle}}课程、我们的 15,000 多个校友网络和即将举行的全球活动。</p>",
    "top_message_generic_mobile": "<p>探索现代{{programTitle}}的课程和校友网络。</p>",
    "top_message_settings_has_moved": "<p>设置已移动！</p>",
    "top_message_msba_desktop": "<p>欢迎来到{{institutionName}} ！</p><p>了解 MSBA 课程、我们的 15,000 多名校友网络以及即将举行的全球活动。请在{{applicationDeadlineDate}}截止日期前申请。</p>",
    "top_message_msba_desktop_applied": "<p>欢迎来到{{institutionName}} ！</p><p>探索 MSBA 课程、我们的 15,000 多名校友网络以及即将举行的全球活动。</p>",
    "top_message_msba_mobile": "<p>探索现代 MSBA 的课程和校友网络。</p>",
    "top_message_msse_desktop": "<p>欢迎来到{{institutionName}} ！</p><p>了解 MSSE 课程、我们的 15,000 多名校友网络以及即将举行的全球活动。请在{{applicationDeadlineDate}}截止日期前申请。</p>",
    "top_message_msse_desktop_applied": "<p>欢迎来到{{institutionName}} ！</p><p>探索 MSSE 课程、我们的 15,000 多名校友网络以及即将举行的全球活动。</p>",
    "top_message_msse_mobile": "<p>探索现代MSSE的课程和校友网络。</p>"
}
};