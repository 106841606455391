window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.lesson = window.locales.am.lessons.lesson || {};window.locales.am.lessons.lesson.lesson_finish_screen_footer = window.locales.am.lessons.lesson.lesson_finish_screen_footer || {};window.locales.am.lessons.lesson.lesson_finish_screen_footer = {...window.locales.am.lessons.lesson.lesson_finish_screen_footer, ...{
    "additional_thoughts": "ተጨማሪ ሀሳቦች (አማራጭ)",           
    "almost_there": "ሊደርሱ ነው! ይህንን SMARTCASE ለማጠናቀቅ {{minimumScoreInt}}% ወይም ከዚያ በላይ ውጤት ያስመዘግቡ።",           
    "boring": "አሰልቺ",           
    "challenging": "ፈታኝ",           
    "confusing": "ግራ የሚያጋባ",           
    "continue": "ቀጥል",           
    "easy": "ቀላል",           
    "fast": "ፈጣን",           
    "feedback_submitted": "ግብረ መልስ ገብቷል። <strong>አመሰግናለሁ!</strong>",           
    "fun": "አስገራሚ",           
    "give_feedback": "በዚህ ትምህርት ላይ <strong>ግብረ መልስ ይስጡ</strong>",           
    "next": "ቀጣይ",           
    "next_lesson": "ቀጣይ ትምህርት፡-",           
    "not_useful": "ጠቃሚ ያልሆነ",           
    "ok_why": "እሺ! አውራ ጣት ለምን ወደ ታች ይወርዳል?",           
    "retry_smartcase": "SMARTCASE እንደገና ሞክር",           
    "submit_feedback": "<br/>ግብረ መልስ ያስገቡ",           
    "thanks_why": "አመሰግናለሁ! አውራ ጣት ለምን ወደ ላይ ይነሳል?",           
    "too_easy": "በጣም ቀላል",           
    "too_hard": "በጣም ከባድ",           
    "useful": "ጠቃሚ",           
    "next_lesson_or_smartcase": "{isSmartCase, select, true{ቀጥልበት SMARTCASE:} other {ቀጣይ ትምህርት፡-}}"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.lesson = window.locales.ar.lessons.lesson || {};window.locales.ar.lessons.lesson.lesson_finish_screen_footer = window.locales.ar.lessons.lesson.lesson_finish_screen_footer || {};window.locales.ar.lessons.lesson.lesson_finish_screen_footer = {...window.locales.ar.lessons.lesson.lesson_finish_screen_footer, ...{
    "almost_there": "لقد أوشكنا على الانتهاء! حقق {{minimumScoreInt}}% أو أعلى لإكمال SMARTCASE هذا.",           
    "boring": "ممل",           
    "challenging": "تحدي",           
    "confusing": "مربك",           
    "continue": "استمر",           
    "easy": "سهل",           
    "fast": "سريع",           
    "feedback_submitted": "تم إرسال الآراء والاقتراحات. <strong>شكراً لك!</strong>",           
    "fun": "ممتع",           
    "give_feedback": "<strong>امنحنا آرائك واقتراحاتك</strong> بشأن هذا الدرس",           
    "next": "التالى",           
    "next_lesson": "الدرس التالي:",           
    "not_useful": "غير مفيد",           
    "ok_why": "حسناً! لماذا يتم قلب الصفحات لأسفل؟",           
    "retry_smartcase": "جرّب SMARTCASE مرةً أخرى",           
    "submit_feedback": "إرسال <br/>الآراء والاقتراحات",           
    "thanks_why": "شكراً لك! لماذا يتم قلب الصفحات لأعلى؟",           
    "too_easy": "سهل جداً",           
    "too_hard": "صعب جداً",           
    "useful": "مفيد",           
    "additional_thoughts": "أفكار إضافية (اختياري)",
    "next_lesson_or_smartcase": "{isSmartCase, select, true{SMARTCASE: أكمل إلى} other{الدرس التالي:}}"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.lesson = window.locales.en.lessons.lesson || {};window.locales.en.lessons.lesson.lesson_finish_screen_footer = window.locales.en.lessons.lesson.lesson_finish_screen_footer || {};window.locales.en.lessons.lesson.lesson_finish_screen_footer = {...window.locales.en.lessons.lesson.lesson_finish_screen_footer, ...{
    "additional_thoughts": "additional thoughts (optional)",
    "almost_there": "Almost there! Score {{minimumScoreInt}}% or higher to complete this SMARTCASE.",
    "boring": "Boring",
    "challenging": "Challenging",
    "confusing": "Confusing",
    "continue": "Continue",
    "easy": "Easy",
    "fast": "Fast",
    "feedback_submitted": "Feedback submitted. <strong>Thank you!</strong>",
    "fun": "Fun",
    "give_feedback": "<strong>Give feedback</strong> on this lesson",
    "next": "Next",
    "next_lesson": "NEXT LESSON:",
    "not_useful": "Not Useful",
    "ok_why": "OK! Why the thumbs down?",
    "retry_smartcase": "RETRY SMARTCASE",
    "submit_feedback": "SUBMIT<br/>FEEDBACK",
    "thanks_why": "Thanks! Why the thumbs up?",
    "too_easy": "Too Easy",
    "too_hard": "Too Hard",
    "useful": "Useful",
    "next_lesson_or_smartcase": "{isSmartCase, select, true{CONTINUE TO SMARTCASE:} other{NEXT LESSON:}}"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.lesson = window.locales.es.lessons.lesson || {};window.locales.es.lessons.lesson.lesson_finish_screen_footer = window.locales.es.lessons.lesson.lesson_finish_screen_footer || {};window.locales.es.lessons.lesson.lesson_finish_screen_footer = {...window.locales.es.lessons.lesson.lesson_finish_screen_footer, ...{
    "boring": "Aburrido",           
    "challenging": "Desafiante",           
    "confusing": "Confuso",           
    "continue": "Continuar",           
    "easy": "Fácil",           
    "fast": "Rápido",           
    "feedback_submitted": "Comentarios enviados. <strong>¡Gracias!</strong>",           
    "fun": "Divertido",           
    "give_feedback": "<strong>Deja comentarios</strong> sobre esta lección",           
    "next": "Siguiente",           
    "next_lesson": "SIGUIENTE LECCIÓN:",           
    "not_useful": "Inútil",           
    "ok_why": "¡OK! ¿Por qué pones el pulgar hacia abajo?",           
    "submit_feedback": "ENVIAR<br/>COMENTARIOS",           
    "thanks_why": "¡Gracias! ¿Por qué pones el pulgar hacia arriba?",           
    "too_easy": "Demasiado fácil",           
    "too_hard": "Demasiado difícil",           
    "useful": "Útil",           
    "almost_there": "¡Casi lo logra! Obtenga {{minimumScoreInt}}% o más para  completar este SMARTCASE.",           
    "retry_smartcase": "REINTENTAR SMARTCASE",           
    "additional_thoughts": "pensamientos adicionales (opcional)",
    "next_lesson_or_smartcase": "{isSmartCase, select, true{CONTINUAR A SMARTCASE:} other{SIGUIENTE LECCIÓN:}}"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.lesson = window.locales.it.lessons.lesson || {};window.locales.it.lessons.lesson.lesson_finish_screen_footer = window.locales.it.lessons.lesson.lesson_finish_screen_footer || {};window.locales.it.lessons.lesson.lesson_finish_screen_footer = {...window.locales.it.lessons.lesson.lesson_finish_screen_footer, ...{
    "additional_thoughts": "considerazioni aggiuntive (opzionale)",           
    "almost_there": "Ci sei quasi! Totalizza un punteggio di almeno {{minimumScoreInt}}% per completare questo SMARTCASE.",           
    "boring": "Noioso",           
    "challenging": "Impegnativo",           
    "confusing": "Confuso",           
    "continue": "Continua",           
    "easy": "Facile",           
    "fast": "Veloce",           
    "feedback_submitted": "Feedback inoltrato. <strong>Grazie!</strong>",           
    "fun": "Divertente",           
    "give_feedback": "<strong>Invia feedback</strong> su questa lezione",           
    "next": "Avanti",           
    "next_lesson": "LEZIONE SUCCESSIVA:",           
    "not_useful": "Non utile",           
    "ok_why": "OK! Perché disapprovi?",           
    "retry_smartcase": "RIPROVA SMARTCASE",           
    "submit_feedback": "INOLTRA<br/>FEEDBACK",           
    "thanks_why": "Grazie! Perché approvi?",           
    "too_easy": "Troppo semplice",           
    "too_hard": "Troppo difficile",           
    "useful": "Utile",           
    "next_lesson_or_smartcase": "{isSmartCase, select, true{CONTINUARE A SMARTCASE:} other{LEZIONE SUCCESSIVA:}}"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.lesson = window.locales.zh.lessons.lesson || {};window.locales.zh.lessons.lesson.lesson_finish_screen_footer = window.locales.zh.lessons.lesson.lesson_finish_screen_footer || {};window.locales.zh.lessons.lesson.lesson_finish_screen_footer = {...window.locales.zh.lessons.lesson.lesson_finish_screen_footer, ...{
    "boring": "无聊",           
    "challenging": "有挑战性",           
    "confusing": "困惑",           
    "continue": "继续",           
    "easy": "容易",           
    "fast": "快速",           
    "feedback_submitted": "已提交反馈。<strong>谢谢！</strong>",           
    "fun": "有趣",           
    "give_feedback": "针对此课业<strong>提供反馈</strong>",           
    "next": "下一个",           
    "next_lesson": "下一课：",           
    "not_useful": "没用",           
    "ok_why": "OK！为什么不喜欢？",           
    "submit_feedback": "提交<br/>反馈",           
    "thanks_why": "谢谢！为什么喜欢？",           
    "too_easy": "太容易",           
    "too_hard": "太难",           
    "useful": "有用",           
    "almost_there": "马上就完成了！获得 {{minimumScoreInt}}% 或者更高的分数来完成这个 SMARTCASE。",           
    "retry_smartcase": "重新尝试 SMARTCASE。",           
    "additional_thoughts": "其他想法（选填）",           
    "next_lesson_or_smartcase": "{isSmartCase, select, true{继续 SMARTCASE:} other{下一课：}}"
}
};