window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.models = window.locales.am.lessons.models || {};window.locales.am.lessons.models.lesson = window.locales.am.lessons.models.lesson || {};window.locales.am.lessons.models.lesson.lesson_player_view_model = window.locales.am.lessons.models.lesson.lesson_player_view_model || {};window.locales.am.lessons.models.lesson.lesson_player_view_model = {...window.locales.am.lessons.models.lesson.lesson_player_view_model, ...{
    "lesson_x_of_x": "{{index}} የ {{lessonCount}} ትምህርት",           
    "must_be_online_exit_or_retry": "ይህ ትምህርት ሊጠናቀቅ የሚችለው በመስመር ላይ ሲሆኑ ብቻ ነው። ይህንን ትምህርት ለማጠናቀቅ በመስመር ላይ ይሂዱ።",
    "must_be_online_exit_or_retry_assessment": "SMARTCASES ሊጠናቀቅ የሚችለው በመስመር ላይ ሲሆኑ ብቻ ነው። ይህንን ትምህርት ለማጠናቀቅ በመስመር ላይ ይሂዱ።",
    "must_be_online_exit_or_retry_test": "ፈተናዎች ሊጠናቀቁ የሚችሉት በመስመር ላይ ሲሆኑ ብቻ ነው። ይህንን ትምህርት ለማጠናቀቅ በመስመር ላይ ይሂዱ።",
    "exit_lesson": "ትምህርት ውጣ",
    "retry": "እንደገና ይሞክሩ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.models = window.locales.ar.lessons.models || {};window.locales.ar.lessons.models.lesson = window.locales.ar.lessons.models.lesson || {};window.locales.ar.lessons.models.lesson.lesson_player_view_model = window.locales.ar.lessons.models.lesson.lesson_player_view_model || {};window.locales.ar.lessons.models.lesson.lesson_player_view_model = {...window.locales.ar.lessons.models.lesson.lesson_player_view_model, ...{
    "lesson_x_of_x": "{{index}} درس من أصل {{lessonCount}}",           
    "must_be_online_exit_or_retry": "لا يمكن إكمال هذا الدرس إلا عندما تكون متصلاً بالإنترنت. اتصل بالإنترنت لإكمال هذا الدرس.",
    "must_be_online_exit_or_retry_assessment": "لا يمكن إكمال SMARTCASES إلا عندما تكون متصلاً بالإنترنت. اتصل بالإنترنت لإكمال هذا الدرس.",
    "must_be_online_exit_or_retry_test": "لا يمكن إكمال الاختبارات إلا عندما تكون متصلاً بالإنترنت. اتصل بالإنترنت لإكمال هذا الدرس.",
    "exit_lesson": "الخروج من الدرس",
    "retry": "أعد المحاولة"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.models = window.locales.en.lessons.models || {};window.locales.en.lessons.models.lesson = window.locales.en.lessons.models.lesson || {};window.locales.en.lessons.models.lesson.lesson_player_view_model = window.locales.en.lessons.models.lesson.lesson_player_view_model || {};window.locales.en.lessons.models.lesson.lesson_player_view_model = {...window.locales.en.lessons.models.lesson.lesson_player_view_model, ...{
    "lesson_x_of_x": "Lesson {{index}} of {{lessonCount}}",
    "must_be_online_exit_or_retry": "This lesson can only be completed when you are online. Go online to complete this lesson.",
    "must_be_online_exit_or_retry_assessment": "SMARTCASES can only be completed when you are online. Go online to complete this lesson.",
    "must_be_online_exit_or_retry_test": "Exams can only be completed when you are online. Go online to complete this lesson.",
    "exit_lesson": "Exit Lesson",
    "retry": "Retry"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.models = window.locales.es.lessons.models || {};window.locales.es.lessons.models.lesson = window.locales.es.lessons.models.lesson || {};window.locales.es.lessons.models.lesson.lesson_player_view_model = window.locales.es.lessons.models.lesson.lesson_player_view_model || {};window.locales.es.lessons.models.lesson.lesson_player_view_model = {...window.locales.es.lessons.models.lesson.lesson_player_view_model, ...{
    "lesson_x_of_x": "Lección {{index}} de {{lessonCount}}",           
    "must_be_online_exit_or_retry": "Esta lección solo se puede completar cuando esté en línea. Conéctese en línea para completar esta lección.",
    "must_be_online_exit_or_retry_assessment": "SMARTCASES sólo se puede completar cuando esté en línea. Conéctese en línea para completar esta lección.",
    "must_be_online_exit_or_retry_test": "Los exámenes solo se pueden completar cuando estás en línea. Conéctese en línea para completar esta lección.",
    "exit_lesson": "Salir de la lección",
    "retry": "Rever"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.models = window.locales.it.lessons.models || {};window.locales.it.lessons.models.lesson = window.locales.it.lessons.models.lesson || {};window.locales.it.lessons.models.lesson.lesson_player_view_model = window.locales.it.lessons.models.lesson.lesson_player_view_model || {};window.locales.it.lessons.models.lesson.lesson_player_view_model = {...window.locales.it.lessons.models.lesson.lesson_player_view_model, ...{
    "lesson_x_of_x": "Lezione {{index}} di {{lessonCount}}",           
    "must_be_online_exit_or_retry": "Questa lezione può essere completata solo quando sei online. Vai online per completare questa lezione.",
    "must_be_online_exit_or_retry_assessment": "Gli SMARTCASES possono essere completati solo quando sei online. Vai online per completare questa lezione.",
    "must_be_online_exit_or_retry_test": "Gli esami possono essere completati solo quando sei online. Vai online per completare questa lezione.",
    "exit_lesson": "Esci dalla lezione",
    "retry": "Riprova"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.models = window.locales.zh.lessons.models || {};window.locales.zh.lessons.models.lesson = window.locales.zh.lessons.models.lesson || {};window.locales.zh.lessons.models.lesson.lesson_player_view_model = window.locales.zh.lessons.models.lesson.lesson_player_view_model || {};window.locales.zh.lessons.models.lesson.lesson_player_view_model = {...window.locales.zh.lessons.models.lesson.lesson_player_view_model, ...{
    "lesson_x_of_x": "第 {{index}} 课，共 {{lessonCount}} 课",           
    "must_be_online_exit_or_retry": "本课程只能在在线时完成。在线完成本课程。",
    "must_be_online_exit_or_retry_assessment": "SMARTCASES 仅当您在线时才能完成。在线完成本课程。",
    "must_be_online_exit_or_retry_test": "考试只能在在线时完成。在线完成本课程。",
    "exit_lesson": "退出课程",
    "retry": "重试"
}
};