window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.student_dashboard_schedule = window.locales.am.lessons.stream.student_dashboard_schedule || {};window.locales.am.lessons.stream.student_dashboard_schedule = {...window.locales.am.lessons.stream.student_dashboard_schedule, ...{
    "required_courses": "አስፈላጊ ኮርሶች",           
    "project_resources": "የፕሮጀክት ግብአቶች",           
    "optional_courses": "አማራጭ ኮርሶች",           
    "view_full_schedule": "ሙሉ መርሃግብር ይመልከቱ",           
    "submit_to_projects": "ወደ <a href=\"mailto:{{emailAddress}}\">{{emailAddress}}</a>ያስገቡ",           
    "at": "በ",           
    "in_the_room": "በ <b>{{room}}</b> ክፍል ውስጥ",           
    "no_events_found": "ምንም ክስተት አልተገኘም።",           
    "multiple_times": "ብዙ ጊዜ",
    "select_an_event": "አንድ ክስተት ይምረጡ",
    "today": "ዛሬ",
    "full_schedule": "ሙሉ መርሃ ግብር"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.student_dashboard_schedule = window.locales.ar.lessons.stream.student_dashboard_schedule || {};window.locales.ar.lessons.stream.student_dashboard_schedule = {...window.locales.ar.lessons.stream.student_dashboard_schedule, ...{
    "required_courses": "المقررات المطلوبة",            
    "project_resources": "موارد المشروع",
    "optional_courses": "دورات اختيارية",
    "view_full_schedule": "عرض الجدول الكامل",
    "at": "في",
    "in_the_room": "في <b>{{room}}</b>",
    "submit_to_projects": "إرسال إلى <a href=\"mailto:{{emailAddress}}\">{{emailAddress}}</a>",
    "no_events_found": "لم يتم العثور على أحداث.",
    "multiple_times": "عدة مرات",
    "select_an_event": "حدد حدثا",
    "today": "اليوم",
    "full_schedule": "الجدول الزمني الكامل"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.student_dashboard_schedule = window.locales.en.lessons.stream.student_dashboard_schedule || {};window.locales.en.lessons.stream.student_dashboard_schedule = {...window.locales.en.lessons.stream.student_dashboard_schedule, ...{
               
    "required_courses": "Required Courses",
    "project_resources": "Project Resources",
    "optional_courses": "Optional Courses",
    "view_full_schedule": "View Full Schedule",
    "submit_to_projects": "Submit to <a href=\"mailto:{{emailAddress}}\">{{emailAddress}}</a>",
    "no_events_found": "No events found.",
    "multiple_times": "Multiple Times",
    "select_an_event": "Select an Event",
    "today": "Today",
    "full_schedule": "Full Schedule",

                
    "at": "at",
    "in_the_room": "in the <b>{{room}}</b> room"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.student_dashboard_schedule = window.locales.es.lessons.stream.student_dashboard_schedule || {};window.locales.es.lessons.stream.student_dashboard_schedule = {...window.locales.es.lessons.stream.student_dashboard_schedule, ...{
    "required_courses": "Cursos requeridos",
    "project_resources": "Recursos del proyecto",
    "optional_courses": "Cursos opcionales",
    "view_full_schedule": "Ver el horario completo",
    "at": "a",
    "in_the_room": "en la <b>{{room}}</b>",
    "submit_to_projects": "Enviar a <a href=\"mailto:{{emailAddress}}\">{{emailAddress}}</a>",
    "no_events_found": "No se encontraron eventos.",
    "multiple_times": "Varias veces",
    "select_an_event": "Seleccione un evento",
    "today": "Este Dia",
    "full_schedule": "Agenda completa"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.student_dashboard_schedule = window.locales.it.lessons.stream.student_dashboard_schedule || {};window.locales.it.lessons.stream.student_dashboard_schedule = {...window.locales.it.lessons.stream.student_dashboard_schedule, ...{
    "required_courses": "Corsi richiesti",
    "project_resources": "Risorse del progetto",
    "optional_courses": "Corsi opzionali",
    "view_full_schedule": "Visualizza la programmazione completa",
    "at": "a",
    "in_the_room": "nella <b>{{room}}</b> room",
    "submit_to_projects": "Invia a <a href=\"mailto:{{emailAddress}}\">{{emailAddress}}</a>",
    "no_events_found": "Nessun evento trovato.",
    "multiple_times": "Più volte",
    "select_an_event": "Seleziona un evento",
    "today": "In data odierna",
    "full_schedule": "Programma completo"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.student_dashboard_schedule = window.locales.zh.lessons.stream.student_dashboard_schedule || {};window.locales.zh.lessons.stream.student_dashboard_schedule = {...window.locales.zh.lessons.stream.student_dashboard_schedule, ...{
    "required_courses": "必修课程",           
    "project_resources": "项目资源",           
    "optional_courses": "选修课程",           
    "view_full_schedule": "查看完整课程表",           
    "at": "在",           
    "in_the_room": "在 <b>{{room}}</b>房间",           
    "submit_to_projects": "提交至 <a href=\"mailto:{{emailAddress}}\">{{emailAddress}}</a>",           
    "no_events_found": "未找到任何事件。",
    "multiple_times": "多次",
    "select_an_event": "选择一个事件",
    "today": "今天",
    "full_schedule": "完整时间表"
}
};