window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.student_dashboard_program_box = window.locales.am.lessons.stream.student_dashboard_program_box || {};window.locales.am.lessons.stream.student_dashboard_program_box = {...window.locales.am.lessons.stream.student_dashboard_program_box, ...{
    "apply_now": "የተሟላ ማመልከቻ",           
    "download_certificate": "ሰርተፍኬት ያውርዱ",           
    "register": "አሁኑኑ ይመዝገቡ",           
    "mobile_status_applied": "ማመልከቻው ገብቷል!",           
    "mobile_status_pre_accepted_registered": "ለ <br> ትምህርት ተመዝግበዋል።",           
    "mobile_status_pre_accepted": "ማመልከቻው ተቀባይነት አግኝቷል!",           
    "mobile_status_accepted": "ማመልከቻው ተቀባይነት አግኝቷል!",           
    "mobile_status_rejected": "ይቅርታ፣ ተቀባይነትን አላገኙም<br> ነበር። <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">ደግመው ያመልክቱ!</span>",           
    "mobile_status_rejected_after_pre_accepted": "ይቅርታ፣ ተቀባይነትን አላገኙም<br> ነበር። <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">ደግመው ያመልክቱ!</span>",           
    "mobile_status_expelled": "የእርስዎ ምዝገባ <br> ካሁን በኋላ ንቁ አይደለም።",           
    "mobile_status_foundations_complete": "ማመልከቻው ገብቷል!",           
    "mobile_status_deferred": "መመዝገብዎን አዘግይተዉታል።",           
    "mobile_status_program_failed": "የወዳቂ <br> ነጥብ አግኝተዋል። <span class=\"faq-link\" ng-click=\"openFAQ()\">የእኛን በተደጋጋሚ የሚጠየቁ ጥያቄዎች ይጎብኙ</span>።",           
    "mobile_status_pre_accepted_not_registered_not_open": "ምዝገባ የሚከፈተው በ<br>{{registrationOpenDate}}።",           
    "progress_application": "ማመልከቻ",           
    "progress_foundations": "የቢዝነስ መሰረቶች",           
    "progress_response": "ምላሽ",           
    "progress_begin": "አቅጣጫ",           
    "progress_decision": "ውሳኔ",           
    "mba_programs": "የ{{brandName}} MBA <br class=\"hidden-xs hidden-sm\"> ፕሮግራሞች",           
    "subtitle_degree_program_applied_foundations_complete": "ታላቅ ስራ! ማመልከቻዎን በተመለከተ በቅርቡ ከእኛ ይሰማሉ ፡፡",
    "subtitle_not_registered_not_open": "{{brandName}} {{programTitle}} በመቀበልዎ እንኳን ደስ ያለዎት! ምዝገባው በ {{registrationOpenDate}} ላይ ይከፈታል።",
    "subtitle_degree_program_accepted": "ለዲግሪ ብቁ ለመሆን ሁሉንም {{numConcentrations}} ለፕሮግራሙ መርሃግብር እና ለተሟላ መስፈርቶች <span class=\"faq-link\" ng-click=\"openFAQ()\">የእኛን ዲግሪ ተደጋጋሚ ጥያቄዎች ይጎብኙ ፡",
    "subtitle_degree_program_accepted_not_started": "{{brandName}} {{programTitle}} እንኳን በደህና መጡ! ለመጀመር, ሙሉ <strong>{{title}}</strong> , የመጀመሪያ {{numConcentrations}} በ {{programTitle}} ሥርዓተ ትምህርት ውስጥ በመልቀቃቸው.<br><br> ለዲግሪ ብቁ ለመሆን ሁሉንም {{numConcentrations}} ለፕሮግራሙ መርሃግብር እና ለተሟላ መስፈርቶች <span class=\"faq-link\" ng-click=\"openFAQ()\">የእኛን ዲግሪ ተደጋጋሚ ጥያቄዎች ይጎብኙ ፡",
    "subtitle_degree_program_accepted_not_started_foundations_complete": "{{brandName}} {{programTitle}} እንኳን በደህና መጡ! በኢሜባ ሥርዓተ-ትምህርት ውስጥ <strong>{{title}}</strong> ፣ ከ {{numConcentrations}}<br><br> ለዲግሪ ብቁ ለመሆን ሁሉንም {{numConcentrations}} ለፕሮግራሙ መርሃግብር እና ለተሟላ መስፈርቶች <span class=\"faq-link\" ng-click=\"openFAQ()\">የእኛን ዲግሪ ተደጋጋሚ ጥያቄዎች ይጎብኙ ፡",
    "subtitle_degree_program_program_complete": "ለዲግሪ ብቁ ለመሆን ሁሉንም {{numConcentrations}} ለፕሮግራሙ መርሃግብር እና ለተሟላ መስፈርቶች <span class=\"faq-link\" ng-click=\"openFAQ()\">የእኛን ዲግሪ ተደጋጋሚ ጥያቄዎች ይጎብኙ ፡",
    "subtitle_degree_program_program_failed": "ለዲግሪ ብቁ ለመሆን ሁሉንም {{numConcentrations}} ለፕሮግራሙ መርሃግብር እና ለተሟላ መስፈርቶች <span class=\"faq-link\" ng-click=\"openFAQ()\">የእኛን ዲግሪ ተደጋጋሚ ጥያቄዎች ይጎብኙ ፡",
    "subtitle_degree_program_expelled": "የ {{programTitle}} ሁኔታዎ ከአሁን በኋላ አይሠራም። ከሙሉ ፕሮግራሙ ውስጥ ኮርሶችን ለመምረጥ ቀጣይነት ባለው መዳረሻ እንደሚደሰቱ ተስፋ እናደርጋለን ፡፡<br/><br/> ለተጨማሪ መረጃ በ <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>",
    "subtitle_degree_program_deferred": "የ {{programTitle}} ምዝገባዎን ለሌላ ጊዜ አስተላልፈዋል። ወደ {{programTitle}} ማመልከት ከማመልከትዎ በፊት ምዝገባውን ለሌላ ጊዜ ማስተላለፍ የሚፈቀድለት እባክዎ ልብ ይበሉ። ማንኛውም ጥያቄ ካለዎት እባክዎ በ <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> ኢሜይል ያድርጉልን።",
    "subtitle_career_network_only_accepted": "ወደ ስራ ኔትወርክ እንኳን በደህና መጡ! ከድርጅቶች ጥያቄዎችን እየተጠበቁ እያለ የእኛን ክፍት ኮርሶች ናሙና መውሰድ ይችላሉ።",           
    "subtitle_career_network_only_accepted_not_started": "ወደ ስራ ኔትወርክ እንኳን በደህና መጡ! ከድርጅቶች ጥያቄዎችን እየተጠበቁ እያለ የእኛን ክፍት ኮርሶች ናሙና መውሰድ ይችላሉ።",           
    "subtitle_career_network_only_accepted_not_started_foundations_complete": "ወደ ስራ ኔትወርክ እንኳን በደህና መጡ! ከድርጅቶች ጥያቄዎችን እየተጠበቁ እያለ የእኛን ክፍት ኮርሶች ናሙና መውሰድ ይችላሉ።",           
    "subtitle_career_network_only_rejected": "ይቅርታ፣ የእርስዎ ማመልከቻ ለስራ ኔትወርክ ተቀባይነትን አላገኘም ነበር።",           
    "subtitle_career_network_only_expelled": "የእርስዎ የስራ ኔትወርክ ሁኔታ ካሁን በኋላ ንቁ አይደለም።",           
    "subtitle_certificates_accepted": "ለሰርተፍኬቱ ብቁ ለመሆን ሁሉንም የ {{numConcentrations}} ክምችቶች ይጠናቅቁ። ለበለጠ ዝርዝሮች፣ <span class=\"faq-link\" ng-click=\"openFAQ()\">የእኛን በተደጋጋሚ የሚጠየቁ ጥያቄዎች ይጎብኙ</span>።",           
    "subtitle_certificates_accepted_not_started": "ወደ {{cohortTitle}} ፕሮግራም እንኳን በደህና መጡ! ለመጀመር፣ <strong>{{title}}</strong>ን ያጠናቅቁ ይህም {{numConcentrations}}በካሪኩለ፣ኡ ውስጥ የክምችቶች<br><br>የመጀመሪያ የሆነው ነው። ለሰርተፍኬቱ ብቁ ለመሆን ሁሉንም {{numConcentrations}}ያጠናቅቁ። ለበለጠ ዝርዝሮች፣ <span class=\"faq-link\" ng-click=\"openFAQ()\">የእኛን በተደጋጋሚ የሚጠየቁ ጥያቄዎች ይጎብኙ</span>።",           
    "subtitle_certificates_accepted_not_started_foundations_complete": "ወደ {{cohortTitle}} ፕሮግራም እንኳን በደህና መጡ! የ <strong>{{title}}</strong>ን አጠናቅቀዋል ይህም በካሪኩለሙ ውስጥ የ{{numConcentrations}}ክምችቶች<br><br>የመጀመሪያ የሆነው ነው። ለሰርተፍኬቱ ብቁ ለመሆን ሁሉንም {{numConcentrations}}ያጠናቅቁ። ለበለጠ ዝርዝሮች፣ <span class=\"faq-link\" ng-click=\"openFAQ()\">የእኛን በተደጋጋሚ የሚጠየቁ ጥያቄዎች ይጎብኙ</span>።",           
    "subtitle_certificates_program_complete": "የ {{cohortTitle}} ስላጠናቀቁ እንኳን ደስ አለዎት።",           
    "subtitle_certificates_program_failed": "በሚያሳዝን ሁኔታ፣ የእርስዎ የተደመረ የመጨረሻ ነጥብ ሰርተፍኬቱን ለማጠናቀቅ የሚያስፈልገውን ዝቅተኛውን ነጥብ አላሟላም። ለበለጠ ዝርዝሮች <span class=\"faq-link\" ng-click=\"openFAQ()\">የእኛን በተደጋጋሚ የሚጠየቁ ጥያቄዎች ይጎብኙ</span>።",           
    "subtitle_certificates_rejected": "ይቅርታ፣ የእርስዎ ማመልከቻ ለ{{cohortTitle}} ፕሮግራም ተቀባይነትን አላገኘም ነበር።",           
    "subtitle_certificates_rejected_after_pre_accepted": "ይቅርታ፣ የእርስዎ ማመልከቻ ለ{{cohortTitle}} ፕሮግራም ተቀባይነትን አላገኘም ነበር።",           
    "subtitle_certificates_expelled": "የእርስዎ ምዝገባ  ካሁን በኋላ ንቁ አይደለም። ከሙሉ ፕሮግራሙ ኮርሶችን ለመይምረጡ ቀጣይነት ባለው መዳረሻ እንደተደሰቱ ተስፋ እናደርጋለን።",           
    "view_degree_faq": "በተደጋጋሚ የሚጠየቁ ጥያቄዎችን ይመልከቱ",           
    "certificate_help_text": "የእርስዎን ሰርተፍኬት ለማውረድ በዴስክቶፕ ኮምፒውተር በመለያ ይግቡ።",           
    "subtitle_jordanian_math_accepted": "ወደ {{cohortTitle}} ፕሮግራም እንኳን በደህና መጡ።",           
    "subtitle_jordanian_math_accepted_not_started": "ወደ {{cohortTitle}} ፕሮግራም እንኳን በደህና መጡ! ለመጀመር፣ <strong>{{title}}</strong>ን ያጠናቅቁ ይህም በካሪኩለሙ ውስጥ የ{{numConcentrations}}ክምችቶች የመጀመሪያ የሆነው ነው። ",           
    "subtitle_jordanian_math_accepted_not_started_foundations_complete": "ወደ {{cohortTitle}} ፕሮግራም እንኳን በደህና መጡ! እርስዎ አስቀድመው <strong>{{title}}</strong>ን አጠናቅቀዋል ይህም በካሪኩለሙ ውስጥ የ{{numConcentrations}}ክምችቶች የመጀመሪያ የሆነው ነው።",           
    "subtitle_jordanian_math_program_complete": "የ {{cohortTitle}} ፕሮግራም ስላጠናቀቁ እንኳን ደስ አለዎት።",           
    "subtitle_jordanian_math_program_failed": "በሚያሳዝን ሁኔታ፣ የእርስዎ የተደመረ የመጨረሻ ነጥብ ፕሮግራሙን ለማጠናቀቅ የሚያስፈልገውን ዝቅተኛውን ነጥብ አላሟላም። ለበለጠ ዝርዝሮች <span class=\"faq-link\" ng-click=\"openFAQ()\">የእኛን በተደጋጋሚ የሚጠየቁ ጥያቄዎች ይጎብኙ</span>።",           
    "subtitle_jordanian_math_expelled": "የእርስዎ ምዝገባ  ካሁን በኋላ ንቁ አይደለም። ከሙሉ ፕሮግራሙ ኮርሶችን ለመይምረጡ ቀጣይነት ባለው መዳረሻ እንደተደሰቱ ተስፋ እናደርጋለን።",           
    "mobile_status_rejected_no_reapply_cta": "ይቅርታ፣ ተቀባይነትን አላገኙም<br> ነበር።",           
    "mobile_status_rejected_after_pre_accepted_no_reapply_cta": "ይቅርታ፣ ተቀባይነትን አላገኙም<br> ነበር።",           
    "subtitle_career_network_only_rejected_no_reapply_cta": "ይቅርታ፣ የእርስዎ ማመልከቻ ለስራ ኔትወርክ ተቀባይነትን አላገኘም ነበር።",           
    "subtitle_certificates_rejected_no_reapply_cta": "ይቅርታ፣ የእርስዎ ማመልከቻ ለ{{cohortTitle}} ፕሮግራም ተቀባይነትን አላገኘም ነበር።",           
    "subtitle_certificates_rejected_after_pre_accepted_no_reapply_cta": "ይቅርታ ፣ የእርስዎ መተግበሪያ ለ {{cohortTitle}} ፕሮግራም ተቀባይነት አላገኘም ፡",
    "mobile_status_pre_accepted_not_registered": "ማመልከቻ ተቀባይነት አግኝቷል!",
    "subtitle_degree_program_rejected": "ይቅርታ፣ ማመልከቻዎ ለዲግሪ ፕሮግራሙ ተቀባይነት አላገኘም። ሆኖም፣ እውቀትዎን ለማራመድ በክፍት ኮርሶቻችን መማርዎን መቀጠል ይችላሉ፣ፍፁም ነፃ፣ እና አሁን <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">ለሚቀጥለው ክፍል እንደገና ለማመልከት ብቁ ነዎት።",
    "subtitle_degree_program_rejected_after_pre_accepted": "ይቅርታ፣ ከዚህ ቀደም የዲግሪ ፕሮግራሙን መቀላቀል አልቻልክም። ሆኖም፣ እውቀትዎን ለማራመድ በክፍት ኮርሶቻችን መማርዎን መቀጠል ይችላሉ፣ፍፁም ነፃ፣ እና አሁን <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">ለሚቀጥለው ክፍል እንደገና ለማመልከት ብቁ ነዎት።",
    "close": "ገጠመ<i class=\"fas fa-multiply\"></i>",
    "view_status": "የእይታ ሁኔታ<i class=\"fas fa-chevron-up\"></i>",
    "subtitle_degree_program_rejected_no_reapply_cta": "የዲግሪ ፕሮግራሙን ባይቀላቀሉም ከ {{reapplicationDate}} ጀምሮ እንደገና ማመልከት ይችላሉ። እስከዚያው ድረስ፣ ክፍት ኮርሶችን በነፃ ማግኘት ትችላለህ።",
    "subtitle_degree_program_rejected_after_pre_accepted_no_reapply_cta": "የዲግሪ ፕሮግራሙን ባይቀላቀሉም ከ {{reapplicationDate}} ጀምሮ እንደገና ማመልከት ይችላሉ። እስከዚያው ድረስ፣ ክፍት ኮርሶችን በነፃ ማግኘት ትችላለህ።",
    "apply_to": "ወደ {{brandName}} ያመልክቱ",
    "application_received": "ማመልከቻ ተቀብሏል።",
    "subtitle_not_applied": "የሚቀጥለው የመግቢያ ዑደት የመጨረሻ ቀን እየቀረበ ነው።",
    "subtitle_degree_program_applied": "ማመልከቻ ተቀብሏል! ለዲግሪው ዝግጁ መሆንዎን ለማሳየት <strong>{{title}}</strong> ያጠናቅቁ። የመጨረሻ ውሳኔዎች የሚላኩት በ {{decisionDate}} ላይ ነው። መልካም እድል",
    "subtitle_not_registered": "ልዩ የሆነ የአለም አቀፍ ተማሪዎች ቡድንን በመቀላቀል ወደ ቀጣዩ የ {{brandName}} {{programTitle}} ክፍል ገብተሃል። ቦታዎን ለማረጋገጥ በ {{registrationDeadline}} ይመዝገቡ።",
    "mobile_status_not_applied": "የሚቀጥለው የመግቢያ ዑደት የመጨረሻ ቀን እየቀረበ ነው።",
    "congrats": "እንኳን ደስ ያለህ<br class=\"visible-md visible-lg\" /> ገብተሃል!",
    "subtitle_degree_program_expelled_no_reapply_cta": "የእርስዎ {{programTitle}} ሁኔታ ከአሁን በኋላ ንቁ አይደለም። ከሙሉ ፕሮግራሙ ኮርሶችን ለመምረጥ ቀጣይነት ባለው ተደራሽነት እንደተደሰቱ ተስፋ እናደርጋለን።<br/><br/> ለበለጠ መረጃ በ <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> ያግኙን",
    "subtitle_generic_applied": "ማመልከቻ ተቀብሏል! ለፕሮግራሙ ዝግጁ መሆንዎን ለማሳየት <strong>{{title}}</strong> ያጠናቅቁ። መልካም ምኞት!",
    "subtitle_generic_applied_foundations_complete": "ታላቅ ስራ! ማመልከቻህን በተመለከተ በቅርቡ ከእኛ ትሰማለህ።",
    "subtitle_generic_accepted": "ፕሮግራሙን ለማጠናቀቅ ሁሉንም {{numConcentrations}} ትኩረትን ያጠናቅቁ።",
    "subtitle_generic_accepted_not_started": "እንኳን ወደ {{programTitle}} በደህና መጡ! ለመጀመር በስርአተ ትምህርቱ ውስጥ ከ {{numConcentrations}} የመጀመሪያ የሆነውን <strong>{{title}}</strong> ያጠናቅቁ።<br><br> ፕሮግራሙን ለማጠናቀቅ ሁሉንም {{numConcentrations}} ያጠናቅቁ።",
    "subtitle_generic_accepted_not_started_foundations_complete": "እንኳን ወደ {{programTitle}} በደህና መጡ! በስርአተ ትምህርቱ ውስጥ ከ {{numConcentrations}} የመጀመሪያ የሆነውን <strong>{{title}}</strong> አጠናቅቀዋል።<br><br> ፕሮግራሙን ለማጠናቀቅ ሁሉንም {{numConcentrations}} ያጠናቅቁ።",
    "subtitle_generic_program_complete": "{{programTitle}} ን ስላጠናቀቁ እንኳን ደስ ያለዎት።",
    "subtitle_generic_program_failed": "ፕሮግራሙን ለማጠናቀቅ ሁሉንም {{numConcentrations}} ትኩረትን ያጠናቅቁ።",
    "subtitle_generic_deferred": "የ {{programTitle}} ምዝገባዎን ለሌላ ጊዜ አስተላልፈዋል። ማንኛቸውም ጥያቄዎች ካሉዎት፣ እባክዎ በ <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> ኢሜይል ያድርጉልን።",
    "subtitle_generic_expelled": "የእርስዎ ምዝገባ ከአሁን በኋላ ንቁ አይደለም። ከሙሉ ፕሮግራሙ ኮርሶችን ለመምረጥ ቀጣይነት ባለው ተደራሽነት እንደተደሰቱ ተስፋ እናደርጋለን።",
    "subtitle_generic_rejected": "ይቅርታ፣ ማመልከቻህ ለ {{programTitle}} ተቀባይነት አላገኘም።",
    "subtitle_generic_rejected_after_pre_accepted": "ይቅርታ፣ ማመልከቻህ ለ {{programTitle}} ተቀባይነት አላገኘም።",
    "subtitle_generic_rejected_no_reapply_cta": "ይቅርታ፣ ማመልከቻህ ለ {{programTitle}} ተቀባይነት አላገኘም።",
    "subtitle_generic_rejected_after_pre_accepted_no_reapply_cta": "ይቅርታ፣ ማመልከቻህ ለ {{programTitle}} ተቀባይነት አላገኘም።",
    "mobile_status_expelled_no_reapply_cta": "የእርስዎ ምዝገባ ነው።<br> ከአሁን በኋላ ንቁ አይደለም.",
    "subtitle_generic_expelled_no_reapply_cta": "የእርስዎ ምዝገባ ከአሁን በኋላ ንቁ አይደለም። ከሙሉ ፕሮግራሙ ኮርሶችን ለመምረጥ በቀጣይነት እንደተደሰቱ ተስፋ እናደርጋለን።",
    "subtitle_career_network_only_expelled_no_reapply_cta": "የእርስዎ የስራ አውታረ መረብ ሁኔታ ከአሁን በኋላ ንቁ አይደለም።",
    "subtitle_certificates_expelled_no_reapply_cta": "የእርስዎ ምዝገባ ከአሁን በኋላ ንቁ አይደለም። ከሙሉ ፕሮግራሙ ኮርሶችን ለመምረጥ በቀጣይነት እንደተደሰቱ ተስፋ እናደርጋለን።",
    "subtitle_jordanian_math_expelled_no_reapply_cta": "የእርስዎ ምዝገባ ከአሁን በኋላ ንቁ አይደለም። ከሙሉ ፕሮግራሙ ኮርሶችን ለመምረጥ በቀጣይነት እንደተደሰቱ ተስፋ እናደርጋለን።",
    "subtitle_registered": "<p>እንኳን ደስ አላችሁ! በ {{brandName}} {{programTitle}} ውስጥ ተመዝግበው ቦታዎን አስጠብቀዋል። በዓለም ዙሪያ ካሉ ልዩ ተማሪዎች ክፍል ጋር ሊቀላቀሉ ነው።</p><p> ፕሮግራምህ በ {{startDateLong}} ይጀምራል። የአቅጣጫ ዝግጅቶችን እና የኮርስ ተደራሽነትን በተመለከተ በኢሜል እና በዳሽቦርድዎ ላይ ማሳወቂያዎችን ይከታተሉ።</p>",
    "subtitle_registered_program_guide": "<p>እንኳን ደስ አላችሁ! በ {{brandName}} {{programTitle}} ውስጥ ተመዝግበው ቦታዎን አስጠብቀዋል። በዓለም ዙሪያ ካሉ ልዩ ተማሪዎች ክፍል ጋር ሊቀላቀሉ ነው።</p><p> ፕሮግራምህ በ {{startDateLong}} ይጀምራል። የአቅጣጫ ዝግጅቶችን እና የኮርስ ተደራሽነትን በተመለከተ በኢሜል እና በዳሽቦርድዎ ላይ ማሳወቂያዎችን ይከታተሉ።</p><p> እስካሁን ካላደረጉት ስለ ኮርሶችዎ እና የጊዜ ሰሌዳዎ ተጨማሪ ዝርዝሮችን ለማግኘት <a href=\"{{programGuideUrl}}\" target=\"_blank\">የፕሮግራም መመሪያን</a> ይከልሱ።</p>",
    "subtitle_registered_no_orientation": "<p>እንኳን ደስ አላችሁ! በ {{brandName}} {{programTitle}} ውስጥ ተመዝግበው ቦታዎን አስጠብቀዋል። በዓለም ዙሪያ ካሉ ልዩ ተማሪዎች ክፍል ጋር ሊቀላቀሉ ነው።</p><p> ፕሮግራምህ በ {{startDateLong}} ላይ ይጀምራል። የኮርስ ተደራሽነትን በተመለከተ በኢሜል እና በዳሽቦርድዎ ላይ ማሳወቂያዎችን ይከታተሉ።</p>",
    "subtitle_registered_no_orientation_program_guide": "<p>እንኳን ደስ አላችሁ! በ {{brandName}} {{programTitle}} ውስጥ ተመዝግበው ቦታዎን አስጠብቀዋል። በዓለም ዙሪያ ካሉ ልዩ ተማሪዎች ክፍል ጋር ሊቀላቀሉ ነው።</p><p> ፕሮግራምህ በ {{startDateLong}} ላይ ይጀምራል። የኮርስ ተደራሽነትን በተመለከተ በኢሜል እና በዳሽቦርድዎ ላይ ማሳወቂያዎችን ይከታተሉ።</p><p> እስካሁን ካላደረጉት ስለ ኮርሶችዎ እና የጊዜ ሰሌዳዎ ተጨማሪ ዝርዝሮችን ለማግኘት <a href=\"{{programGuideUrl}}\" target=\"_blank\">የፕሮግራም መመሪያን</a> ይከልሱ።</p>",
    "subtitle_certificates_applied": "ማመልከቻ ተቀብሏል! ለፕሮግራሙ ዝግጁ መሆንዎን ለማሳየት <strong>{{title}}</strong> ያጠናቅቁ። መልካም ምኞት!",
    "subtitle_certificates_applied_foundations_complete": "ታላቅ ስራ! ማመልከቻህን በተመለከተ በቅርቡ ከእኛ ትሰማለህ።",
    "subtitle_not_applied_nominee": "እንደ የተመረጠ {{brandName}} እጩ፣ ተመራጭ የቅበላ ግምት ይደርስዎታል።",
    "mobile_status_program_complete": "ፕሮግራሙን ጨርሰሃል።",
    "msse_program": "{{brandName}} የ MSSE ፕሮግራም",
    "msba_program": "{{brandName}} MSBA ፕሮግራም"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.student_dashboard_program_box = window.locales.ar.lessons.stream.student_dashboard_program_box || {};window.locales.ar.lessons.stream.student_dashboard_program_box = {...window.locales.ar.lessons.stream.student_dashboard_program_box, ...{
    "apply_now": "أكمل الطلب",           
    "mobile_status_applied": "تم تقديم الطلب!",           
    "mobile_status_rejected": "عذراً، إنك لم <br> تُقبل. <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">إعادة التقديم!</span>",           
    "mobile_status_expelled": "لم تعد حالة MBA الخاصة بك <br> نشطة.",           
    "mobile_status_foundations_complete": "تم تقديم الطلب!",           
    "mobile_status_deferred": "لقد أرجأت التحاقك بـ MBA الخاصة بك.",           
    "progress_application": "طلب",           
    "progress_foundations": "أسس الأعمال",           
    "progress_response": "الرد",           
    "download_certificate": "تحميل الشهادة",
    "progress_begin": "اتجاه",
    "subtitle_degree_program_applied_foundations_complete": "عمل عظيم! ستسمع منا قريبا بشأن طلبك.",
    "subtitle_degree_program_accepted": "أكمل جميع {{numConcentrations}} للتأهل للحصول على درجة. لجدول البرنامج ومتطلبات كاملة، <span class=\"faq-link\" ng-click=\"openFAQ()\">زيارة لدينا أسئلة وأجوبة درجة .",
    "subtitle_degree_program_program_complete": "أكمل جميع {{numConcentrations}} للتأهل للحصول على درجة. لجدول البرنامج ومتطلبات كاملة، <span class=\"faq-link\" ng-click=\"openFAQ()\">زيارة لدينا أسئلة وأجوبة درجة .",
    "subtitle_career_network_only_accepted": "مرحبا بكم في الشبكة المهنية! يمكنك أخذ عينات من دوراتنا المفتوحة أثناء الانتظار للحصول على استفسارات من الشركات.",
    "subtitle_career_network_only_accepted_not_started": "مرحبا بكم في الشبكة المهنية! يمكنك أخذ عينات من دوراتنا المفتوحة أثناء الانتظار للحصول على استفسارات من الشركات.",
    "subtitle_career_network_only_accepted_not_started_foundations_complete": "مرحبا بكم في الشبكة المهنية! يمكنك أخذ عينات من دوراتنا المفتوحة أثناء الانتظار للحصول على استفسارات من الشركات.",
    "subtitle_career_network_only_rejected": "عذرا، لم يتم قبول طلبك في الشبكة الوظيفية.",
    "subtitle_career_network_only_expelled": "لم تعد حالة الشبكة المهنية نشطة.",
    "view_degree_faq": "عرض الأسئلة الشائعة",
    "mobile_status_program_failed": "لقد تلقيت إخفاقا <br> أحرز هدفاً. <span class=\"faq-link\" ng-click=\"openFAQ()\">زيارة التعليمات .",
    "subtitle_degree_program_program_failed": "أكمل جميع {{numConcentrations}} للتأهل للحصول على درجة. لجدول البرنامج ومتطلبات كاملة، <span class=\"faq-link\" ng-click=\"openFAQ()\">قم بزيارة أسئلة وأجوبة درجة لدينا .",
    "mobile_status_accepted": "قبول التطبيق!",
    "register": "سجل الان",
    "mobile_status_pre_accepted_registered": "أنت مسجل <br> للفئة.",
    "mobile_status_pre_accepted": "قبول التطبيق!",
    "subtitle_certificates_accepted": "أكمل جميع {{numConcentrations}} للتأهل للحصول على الشهادة. لمزيد من التفاصيل، <span class=\"faq-link\" ng-click=\"openFAQ()\">يرجى زيارة الأسئلة الشائعة .",
    "subtitle_certificates_accepted_not_started": "مرحبا بك في برنامج {{cohortTitle}} ! للبدء، أكمل <strong>{{title}}</strong> ، أول تركيز {{numConcentrations}} في المناهج الدراسية. <br><br> أكمل جميع {{numConcentrations}} للتأهل للحصول على الشهادة. لمزيد من التفاصيل، <span class=\"faq-link\" ng-click=\"openFAQ()\">يرجى زيارة الأسئلة الشائعة .",
    "subtitle_certificates_accepted_not_started_foundations_complete": "مرحبا بك في برنامج {{cohortTitle}} ! لقد أكملت بالفعل <strong>{{title}}</strong> ، وهي أول {{numConcentrations}} في المنهاج الدراسي. <br><br> أكمل جميع {{numConcentrations}} للتأهل للحصول على الشهادة. لمزيد من التفاصيل، <span class=\"faq-link\" ng-click=\"openFAQ()\">يرجى زيارة الأسئلة الشائعة .",
    "subtitle_certificates_program_complete": "تهانينا على إكمال {{cohortTitle}} .",
    "subtitle_certificates_program_failed": "ولسوء الحظ، لم تحقق النتيجة النهائية المجمعة الحد الأدنى المطلوب لإنهاء الشهادة. لمزيد من التفاصيل <span class=\"faq-link\" ng-click=\"openFAQ()\">زيارة التعليمات .",
    "subtitle_certificates_rejected": "عذرا، لم يتم قبول {{cohortTitle}} برنامج {{cohortTitle}} .",
    "subtitle_certificates_expelled": "لم يعد تسجيلك نشطا. نأمل أن تستمتع استمرار الوصول إلى دورات مختارة من البرنامج الكامل.",
    "progress_decision": "قرار",
    "certificate_help_text": "قم بتسجيل الدخول باستخدام كمبيوتر سطح مكتب تحميل الشهادة الخاصة بك.",
    "subtitle_jordanian_math_accepted": "مرحبًا بك في برنامج {{cohortTitle}} .",
    "subtitle_jordanian_math_accepted_not_started": "مرحبًا بِك في برنامج {{cohortTitle}} ! لتبدأ ، أَكمل <strong>{{title}}</strong> ، الأول من {{numConcentrations}}  وحدات في المنهج الدراسي.",
    "subtitle_jordanian_math_accepted_not_started_foundations_complete": "مرحبًا بك في برنامج {{cohortTitle}} ! لقد أكملت بالفعل <strong>{{title}}</strong> ، أول تركيز {{numConcentrations}} في المنهج الدراسي.",
    "subtitle_jordanian_math_program_complete": "تهانينا على إكمال برنامج {{cohortTitle}} .",
    "subtitle_jordanian_math_program_failed": "لسوء الحظ ، لم تحقق النتيجة النهائية المجمعة الحد الأدنى المطلوب لإكمال البرنامج. لمزيد من التفاصيل ، <span class=\"faq-link\" ng-click=\"openFAQ()\">يرجى زيارة الأسئلة الشائعة .",
    "subtitle_jordanian_math_expelled": "التسجيل الخاص بك لم يعد نشطًا. نأمل أن تستمتع باستمرار الوصول إلى الدورات التدريبية المختارة من البرنامج الكامل.",
    "mobile_status_rejected_after_pre_accepted": "آسف ، أنت لم تكن كذلك <br> وافقت. <span class=\"faq-link\", ng-click=\"gotoMbaSettings()\">تطبيق!",
    "subtitle_certificates_rejected_after_pre_accepted": "عذرًا ، لم يتم قبول طلبك لبرنامج {{cohortTitle}} .",
    "mba_programs": "{{brandName}} ماجستير في إدارة الأعمال <br class=\"hidden-xs hidden-sm\"> برامج",
    "subtitle_degree_program_accepted_not_started": "مرحبًا بك في {{brandName}} {{programTitle}}! للبدء ، أكمل <strong>{{title}}</strong> ، أول تركيز {{numConcentrations}} في منهج {{programTitle}}. <br><br> أكمل جميع {{numConcentrations}} للتأهل للدرجة. للحصول على جدول البرنامج والمتطلبات الكاملة ، <span class=\"faq-link\" ng-click=\"openFAQ()\">تفضل بزيارة الأسئلة الشائعة حول الشهادة .",
    "subtitle_degree_program_accepted_not_started_foundations_complete": "مرحبًا بك في {{brandName}} {{programTitle}}! لقد أكملت بالفعل <strong>{{title}}</strong> ، أول تركيز {{numConcentrations}} في منهج {{programTitle}}. <br><br> أكمل جميع {{numConcentrations}} للتأهل للدرجة. للحصول على جدول البرنامج والمتطلبات الكاملة ، <span class=\"faq-link\" ng-click=\"openFAQ()\">تفضل بزيارة الأسئلة الشائعة حول الشهادة .",
    "subtitle_degree_program_expelled": "حالة {{programTitle}} لم تعد نشطة. نأمل أن تستمتع باستمرار الوصول إلى الدورات التدريبية المختارة من البرنامج الكامل. <br/><br/> لمزيد من المعلومات ، اتصل بنا على <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>",
    "subtitle_degree_program_deferred": "لقد أجلت تسجيل {{programTitle}} الخاص بك. يرجى ملاحظة أنه لا يُسمح لك إلا بتأجيل التسجيل مرتين قبل أن تضطر إلى تقديم طلب إلى {{programTitle}}. إذا كانت لديك أي أسئلة ، فيرجى مراسلتنا عبر البريد الإلكتروني على <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> .",
    "mobile_status_rejected_no_reapply_cta": "آسف ، لم تكن كذلك <br> قبلت.",
    "mobile_status_rejected_after_pre_accepted_no_reapply_cta": "آسف ، لم تكن كذلك <br> قبلت.",
    "subtitle_career_network_only_rejected_no_reapply_cta": "عذرًا ، لم يتم قبول طلبك في شبكة Career Network.",
    "subtitle_certificates_rejected_no_reapply_cta": "عذرًا ، لم يتم قبول طلبك لبرنامج {{cohortTitle}} .",
    "subtitle_certificates_rejected_after_pre_accepted_no_reapply_cta": "عذرًا ، لم يتم قبول طلبك لبرنامج {{cohortTitle}} .",
    "mobile_status_pre_accepted_not_registered_not_open": "يفتح التسجيل في<br> {{registrationOpenDate}}.",
    "subtitle_not_registered_not_open": "تهانينا على قبولك {{brandName}} {{programTitle}}! يفتح التسجيل في {{registrationOpenDate}}.",
    "mobile_status_pre_accepted_not_registered": "تم قبول الطلب!",
    "subtitle_degree_program_rejected": "عذرًا ، لم يتم قبول طلبك في برنامج الشهادة. ومع ذلك ، يمكنك مواصلة التعلم من خلال دوراتنا المفتوحة لتعزيز معرفتك ، مجانًا تمامًا ، وأنت الآن مؤهل لإعادة <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">التقديم للصف التالي .",
    "subtitle_degree_program_rejected_after_pre_accepted": "عذرًا ، لم تتمكن من الالتحاق ببرنامج الشهادة سابقًا. ومع ذلك ، يمكنك مواصلة التعلم من خلال دوراتنا المفتوحة لتعزيز معرفتك ، مجانًا تمامًا ، وأنت الآن مؤهل لإعادة <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">التقديم للصف التالي .",
    "close": "قريب<i class=\"fas fa-multiply\"></i>",
    "view_status": "عرض الحالة<i class=\"fas fa-chevron-up\"></i>",
    "subtitle_degree_program_rejected_no_reapply_cta": "على الرغم من أنك لن تنضم إلى برنامج الشهادة ، إلا أنه يمكنك إعادة التقديم بدءًا من {{reapplicationDate}} . في غضون ذلك ، ستحتفظ بإمكانية الوصول المجاني إلى الدورات التدريبية المفتوحة.",
    "subtitle_degree_program_rejected_after_pre_accepted_no_reapply_cta": "على الرغم من أنك لن تنضم إلى برنامج الشهادة ، إلا أنه يمكنك إعادة التقديم بدءًا من {{reapplicationDate}} . في غضون ذلك ، ستحتفظ بإمكانية الوصول المجاني إلى الدورات التدريبية المفتوحة.",
    "apply_to": "تنطبق على {{brandName}}",
    "application_received": "تم استلام الطلب",
    "subtitle_not_applied": "اقترب الموعد النهائي لدورة القبول التالية.",
    "subtitle_degree_program_applied": "تم استلام الطلب! أكمل <strong>{{title}}</strong> لإثبات استعدادك للحصول على الدرجة. يتم إرسال القرارات النهائية في {{decisionDate}}. حظًا سعيدًا!",
    "subtitle_not_registered": "لقد تم قبولك في فصل {{brandName}} ، {{programTitle}} إلى مجموعة استثنائية من الطلاب العالميين. لتأكيد مكانك ، سجّل قبل {{registrationDeadline}} .",
    "mobile_status_not_applied": "اقترب الموعد النهائي لدورة القبول التالية.",
    "congrats": "تهانينا،<br class=\"visible-md visible-lg\" /> اهلا بك!",
    "subtitle_degree_program_expelled_no_reapply_cta": "لم تعد حالة {{programTitle}} الخاصة بك نشطة. نأمل أن تستمتع بالوصول المستمر إلى دورات مختارة من البرنامج الكامل.<br/><br/> لمزيد من المعلومات ، اتصل بنا على <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>",
    "subtitle_generic_applied": "تم استلام الطلب! أكمل <strong>{{title}}</strong> لإثبات استعدادك للبرنامج. حظ سعيد!",
    "subtitle_generic_applied_foundations_complete": "عمل عظيم! سوف تسمع منا قريبًا بخصوص طلبك.",
    "subtitle_generic_accepted": "أكمل جميع تركيزات {{numConcentrations}} لإكمال البرنامج.",
    "subtitle_generic_accepted_not_started": "مرحبًا بك في {{programTitle}} ! للبدء ، أكمل <strong>{{title}}</strong> ، أول تركيز من {{numConcentrations}} في المنهج الدراسي.<br><br> أكمل جميع {{numConcentrations}} لإكمال البرنامج.",
    "subtitle_generic_accepted_not_started_foundations_complete": "مرحبًا بك في {{programTitle}} ! لقد أكملت بالفعل <strong>{{title}}</strong> ، أول تركيز لـ {{numConcentrations}} في المنهج الدراسي.<br><br> أكمل جميع {{numConcentrations}} لإكمال البرنامج.",
    "subtitle_generic_program_complete": "تهانينا على إكمال {{programTitle}} .",
    "subtitle_generic_program_failed": "أكمل جميع تركيزات {{numConcentrations}} لإكمال البرنامج.",
    "subtitle_generic_deferred": "لقد أرجأت تسجيلك في {{programTitle}} . إذا كانت لديك أي أسئلة ، يرجى مراسلتنا عبر البريد الإلكتروني على <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> .",
    "subtitle_generic_expelled": "لم يعد تسجيلك نشطًا. نأمل أن تستمتع بالوصول المستمر إلى دورات مختارة من البرنامج الكامل.",
    "subtitle_generic_rejected": "عذرًا ، لم يتم قبول طلبك للحصول على {{programTitle}} .",
    "subtitle_generic_rejected_after_pre_accepted": "عذرًا ، لم يتم قبول طلبك للحصول على {{programTitle}} .",
    "subtitle_generic_rejected_no_reapply_cta": "عذرًا ، لم يتم قبول طلبك للحصول على {{programTitle}} .",
    "subtitle_generic_rejected_after_pre_accepted_no_reapply_cta": "عذرًا ، لم يتم قبول طلبك للحصول على {{programTitle}} .",
    "mobile_status_expelled_no_reapply_cta": "التسجيل الخاص بك هو<br> لم تعد نشطة.",
    "subtitle_generic_expelled_no_reapply_cta": "لم يعد تسجيلك نشطًا. نأمل أن تستمتع بالوصول المستمر إلى دورات مختارة من البرنامج الكامل.",
    "subtitle_career_network_only_expelled_no_reapply_cta": "لم تعد حالة الشبكة المهنية الخاصة بك نشطة.",
    "subtitle_certificates_expelled_no_reapply_cta": "لم يعد تسجيلك نشطًا. نأمل أن تستمتع بالوصول المستمر إلى دورات مختارة من البرنامج الكامل.",
    "subtitle_jordanian_math_expelled_no_reapply_cta": "لم يعد تسجيلك نشطًا. نأمل أن تستمتع بالوصول المستمر إلى دورات مختارة من البرنامج الكامل.",
    "subtitle_registered": "<p style=\";text-align:right;direction:rtl\">تهانينا! لقد سجلت وحصلت على مكانك في {{brandName}} {{programTitle}} . أنت على وشك الانضمام إلى فصل من الطلاب المتفوقين من جميع أنحاء العالم.</p><p style=\";text-align:right;direction:rtl\"> يبدأ برنامجك في {{startDateLong}} . ترقب الإخطارات عبر البريد الإلكتروني وعلى لوحة المعلومات الخاصة بك فيما يتعلق بأحداث التوجيه والوصول إلى الدورة التدريبية.</p>",
    "subtitle_registered_program_guide": "<p style=\";text-align:right;direction:rtl\">تهانينا! لقد سجلت وحصلت على مكانك في {{brandName}} {{programTitle}} . أنت على وشك الانضمام إلى فصل من الطلاب المتفوقين من جميع أنحاء العالم.</p><p style=\";text-align:right;direction:rtl\"> يبدأ برنامجك في {{startDateLong}} . ترقب الإخطارات عبر البريد الإلكتروني وعلى لوحة المعلومات الخاصة بك فيما يتعلق بأحداث التوجيه والوصول إلى الدورة التدريبية.</p><p style=\";text-align:right;direction:rtl\"> إذا لم تكن قد قمت بذلك بالفعل ، فراجع <a href=\"{{programGuideUrl}}\" target=\"_blank\">دليل البرنامج</a> للحصول على مزيد من التفاصيل حول الدورات التدريبية والجدول الزمني.</p>",
    "subtitle_registered_no_orientation": "<p style=\";text-align:right;direction:rtl\">تهانينا! لقد قمت بالتسجيل وتأمين مكانك في {{brandName}} {{programTitle}} . أنت على وشك الانضمام إلى صف من الطلاب المتميزين من جميع أنحاء العالم.</p><p style=\";text-align:right;direction:rtl\"> يبدأ برنامجك في {{startDateLong}} . ترقب الإشعارات عبر البريد الإلكتروني وعلى لوحة التحكم الخاصة بك فيما يتعلق بالوصول إلى الدورة التدريبية.</p>",
    "subtitle_registered_no_orientation_program_guide": "<p style=\";text-align:right;direction:rtl\">تهانينا! لقد قمت بالتسجيل وتأمين مكانك في {{brandName}} {{programTitle}} . أنت على وشك الانضمام إلى صف من الطلاب المتميزين من جميع أنحاء العالم.</p><p style=\";text-align:right;direction:rtl\"> يبدأ برنامجك في {{startDateLong}} . ترقب الإشعارات عبر البريد الإلكتروني وعلى لوحة التحكم الخاصة بك فيما يتعلق بالوصول إلى الدورة التدريبية.</p><p style=\";text-align:right;direction:rtl\"> إذا لم تكن قد قمت بذلك بالفعل، فراجع <a href=\"{{programGuideUrl}}\" target=\"_blank\">دليل البرنامج</a> للحصول على مزيد من التفاصيل حول دوراتك وجدولك الزمني.</p>",
    "subtitle_certificates_applied": "تم استلام الطلب! أكمل <strong>{{title}}</strong> لإثبات استعدادك للبرنامج. حظ سعيد!",
    "subtitle_certificates_applied_foundations_complete": "عمل عظيم! ستسمع منا قريبًا بخصوص طلبك.",
    "subtitle_not_applied_nominee": "باعتبارك أحد مرشحي {{brandName}} ، ستحصل على اعتبارات القبول التفضيلية.",
    "mobile_status_program_complete": "لقد أكملت البرنامج.",
    "msse_program": "برنامج MSSE {{brandName}}",
    "msba_program": "برنامج MSBA {{brandName}}"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.student_dashboard_program_box = window.locales.en.lessons.stream.student_dashboard_program_box || {};window.locales.en.lessons.stream.student_dashboard_program_box = {...window.locales.en.lessons.stream.student_dashboard_program_box, ...{
    "apply_now": "Complete Application",
    "download_certificate": "Download Certificate",
    "register": "Register Now",
    "mobile_status_applied": "Application submitted!",
    "mobile_status_pre_accepted_registered": "You are registered <br> for the class.",
    "mobile_status_pre_accepted_not_registered": "Application accepted!",                                 
    "mobile_status_pre_accepted": "Application accepted!",
    "mobile_status_accepted": "Application accepted!",
    "mobile_status_rejected": "Sorry, you were not<br>accepted. <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">Reapply!</span>",
    "mobile_status_rejected_after_pre_accepted": "Sorry, you were not<br>accepted. <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">Reapply!</span>",
    "mobile_status_rejected_no_reapply_cta": "Sorry, you were not<br>accepted.",
    "mobile_status_rejected_after_pre_accepted_no_reapply_cta": "Sorry, you were not<br>accepted.",
    "mobile_status_expelled": "Your enrollment is <br> no longer active.",
    "mobile_status_expelled_no_reapply_cta": "Your enrollment is <br> no longer active.",
    "mobile_status_foundations_complete": "Application submitted!",
    "mobile_status_deferred": "You’ve deferred your enrollment.",
    "mobile_status_program_failed": "You received a failing <br>score. <span class=\"faq-link\" ng-click=\"openFAQ()\">Visit our FAQ</span>.",
    "mobile_status_program_complete": "You have completed the program.",
    "mobile_status_not_applied": "The deadline for the next admission cycle is approaching.",
    "mobile_status_pre_accepted_not_registered_not_open": "Registration opens on <br> {{registrationOpenDate}}.",
    "progress_application": "Application",
    "progress_foundations": "Business Foundations",
    "progress_response": "Response",
    "progress_begin": "Orientation",
    "progress_decision": "Decision",
    "mba_programs": "{{brandName}} MBA Programs",
    "msse_program": "{{brandName}} MSSE Program",
    "msba_program": "{{brandName}} MSBA Program",
    "apply_to": "Apply to {{brandName}}",
    "application_received": "Application Received",
    "view_degree_faq": "View FAQ",
    "congrats": "Congratulations, <br class=\"visible-md visible-lg\" /> You're In!",
    "close": "Close <i class=\"fas fa-multiply\"></i>",
    "view_status": "View Status <i class=\"fas fa-chevron-up\"></i>",
    "certificate_help_text": "Log in using a desktop computer to download your certificate.",
    "subtitle_not_applied": "The deadline for the next admission cycle is approaching.",
    "subtitle_not_applied_nominee": "As a select {{brandName}} Nominee, you'll receive preferential Admissions consideration.",
    "subtitle_degree_program_applied": "Application received! Complete <strong>{{title}}</strong> to demonstrate your readiness for the degree. Final decisions are sent on {{decisionDate}}. Good luck!",
    "subtitle_degree_program_applied_foundations_complete": "Great work! You'll hear from us soon regarding your application.",
    "subtitle_not_registered": "You have been admitted to the upcoming {{brandName}} {{programTitle}} class, joining an exceptional group of global students. To confirm your place, register by {{registrationDeadline}}.",
    "subtitle_not_registered_not_open": "Congratulations on your acceptance to the {{brandName}} {{programTitle}}! Registration opens on {{registrationOpenDate}}.",
    "subtitle_registered": "<p>Congratulations! You have registered and secured your place in the {{brandName}} {{programTitle}}. You're about to join a class of exceptional students from around the world.</p> <p>Your program starts on {{startDateLong}}. Keep an eye out for notifications via email and on your dashboard regarding orientation events and course access.</p>",
    "subtitle_registered_program_guide": "<p>Congratulations! You have registered and secured your place in the {{brandName}} {{programTitle}}. You're about to join a class of exceptional students from around the world.</p> <p>Your program starts on {{startDateLong}}. Keep an eye out for notifications via email and on your dashboard regarding orientation events and course access.</p> <p>If you haven’t already, review the <a href=\"{{programGuideUrl}}\" target=\"_blank\">Program Guide</a> for more details about your courses and schedule.</p>",
    "subtitle_registered_no_orientation": "<p>Congratulations! You have registered and secured your place in the {{brandName}} {{programTitle}}. You're about to join a class of exceptional students from around the world.</p> <p>Your program starts on {{startDateLong}}. Keep an eye out for notifications via email and on your dashboard regarding course access.</p>",
    "subtitle_registered_no_orientation_program_guide": "<p>Congratulations! You have registered and secured your place in the {{brandName}} {{programTitle}}. You're about to join a class of exceptional students from around the world.</p> <p>Your program starts on {{startDateLong}}. Keep an eye out for notifications via email and on your dashboard regarding course access.</p> <p>If you haven’t already, review the <a href=\"{{programGuideUrl}}\" target=\"_blank\">Program Guide</a> for more details about your courses and schedule.</p>",
    "subtitle_generic_applied": "Application received! Complete <strong>{{title}}</strong> to demonstrate your readiness for the program. Good luck!",
    "subtitle_generic_applied_foundations_complete": "Great work! You'll hear from us soon regarding your application.",
    "subtitle_generic_accepted": "Complete all {{numConcentrations}} concentrations to complete the program.",
    "subtitle_generic_accepted_not_started": "Welcome to the {{programTitle}}! To get started, complete <strong>{{title}}</strong>, the first of {{numConcentrations}} concentrations in the curriculum.<br><br>Complete all {{numConcentrations}} to complete the program.",
    "subtitle_generic_accepted_not_started_foundations_complete": "Welcome to the {{programTitle}}! You've already completed <strong>{{title}}</strong>, the first of {{numConcentrations}} concentrations in the curriculum.<br><br>Complete all {{numConcentrations}} to complete the program.",
    "subtitle_generic_program_complete": "Congratulations on completing the {{programTitle}}.",
    "subtitle_generic_program_failed": "Complete all {{numConcentrations}} concentrations to complete the program.",
    "subtitle_generic_deferred": "You've deferred your {{programTitle}} enrollment. If you have any questions, please email us at <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>.",
    "subtitle_generic_expelled": "Your enrollment is no longer active. We hope you enjoy continued access to select courses from the full program.",
    "subtitle_generic_expelled_no_reapply_cta": "Your enrollment is no longer active. We hope you enjoy continued access to select courses from the full program.",
    "subtitle_generic_rejected": "Sorry, your application wasn’t accepted for the {{programTitle}}.",
    "subtitle_generic_rejected_after_pre_accepted": "Sorry, your application wasn’t accepted for the {{programTitle}}.",
    "subtitle_generic_rejected_no_reapply_cta": "Sorry, your application wasn’t accepted for the {{programTitle}}.",
    "subtitle_generic_rejected_after_pre_accepted_no_reapply_cta": "Sorry, your application wasn’t accepted for the {{programTitle}}.",
    "subtitle_degree_program_accepted": "Complete all {{numConcentrations}} concentrations to qualify for the degree. For the program schedule and complete requirements, <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our degree FAQ</span>.",
    "subtitle_degree_program_accepted_not_started": "Welcome to the {{brandName}} {{programTitle}}! To get started, complete <strong>{{title}}</strong>, the first of {{numConcentrations}} concentrations in the {{programTitle}} curriculum.<br><br>Complete all {{numConcentrations}} to qualify for the degree. For the program schedule and complete requirements, <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our degree FAQ</span>.",
    "subtitle_degree_program_accepted_not_started_foundations_complete": "Welcome to the {{brandName}} {{programTitle}}! You've already completed <strong>{{title}}</strong>, the first of {{numConcentrations}} concentrations in the {{programTitle}} curriculum.<br><br>Complete all {{numConcentrations}} to qualify for the degree. For the program schedule and complete requirements, <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our degree FAQ</span>.",
    "subtitle_degree_program_program_complete": "Complete all {{numConcentrations}} concentrations to qualify for the degree. For the program schedule and complete requirements, <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our degree FAQ</span>.",
    "subtitle_degree_program_program_failed": "Complete all {{numConcentrations}} concentrations to qualify for the degree. For the program schedule and complete requirements, <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our degree FAQ</span>.",
    "subtitle_degree_program_rejected": "Sorry, your application wasn’t accepted to the degree program. However, you can continue learning with our open courses to advance your knowledge, absolutely free, and you are now eligible to <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">reapply for the next class</span>.",
    "subtitle_degree_program_rejected_no_reapply_cta": "Though you won’t be joining the degree program, you can reapply beginning {{reapplicationDate}}. In the meantime, you’ll maintain free access to the open courses.",
    "subtitle_degree_program_rejected_after_pre_accepted": "Sorry, you weren't able to join the degree program previously. However, you can continue learning with our open courses to advance your knowledge, absolutely free, and you are now eligible to <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">reapply for the next class</span>.",
    "subtitle_degree_program_rejected_after_pre_accepted_no_reapply_cta": "Though you won’t be joining the degree program, you can reapply beginning {{reapplicationDate}}. In the meantime, you’ll maintain free access to the open courses.",
    "subtitle_degree_program_expelled": "Your {{programTitle}} status is no longer active. We hope you enjoy continued access to select courses from the full program.<br/><br/>For more information, contact us at <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>",
    "subtitle_degree_program_expelled_no_reapply_cta": "Your {{programTitle}} status is no longer active. We hope you enjoy continued access to select courses from the full program.<br/><br/>For more information, contact us at <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>",
    "subtitle_degree_program_deferred": "You've deferred your {{programTitle}} enrollment. Please note that you are only allowed to defer enrollment twice before you have to reapply to the {{programTitle}}. If you have any questions, please email us at <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>.",
    "subtitle_career_network_only_accepted": "Welcome to the Career Network! You may sample our open courses while you wait for inquiries from companies.",
    "subtitle_career_network_only_accepted_not_started": "Welcome to the Career Network! You may sample our open courses while you wait for inquiries from companies.",
    "subtitle_career_network_only_accepted_not_started_foundations_complete": "Welcome to the Career Network! You may sample our open courses while you wait for inquiries from companies.",
    "subtitle_career_network_only_rejected": "Sorry, your application wasn’t accepted to the Career Network.",
    "subtitle_career_network_only_rejected_no_reapply_cta": "Sorry, your application wasn’t accepted to the Career Network.",
    "subtitle_career_network_only_expelled": "Your Career Network status is no longer active.",
    "subtitle_career_network_only_expelled_no_reapply_cta": "Your Career Network status is no longer active.",
    "subtitle_certificates_accepted": "Complete all {{numConcentrations}} concentrations to qualify for the certificate. For more details, <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our FAQ</span>.",
    "subtitle_certificates_accepted_not_started": "Welcome to the {{programTitle}} program! To get started, complete <strong>{{title}}</strong>, the first of {{numConcentrations}} concentrations in the curriculum.<br><br>Complete all {{numConcentrations}} to qualify for the certificate. For more details, <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our FAQ</span>.",
    "subtitle_certificates_accepted_not_started_foundations_complete": "Welcome to the {{programTitle}} program! You've already completed <strong>{{title}}</strong>, the first of {{numConcentrations}} concentrations in the curriculum.<br><br>Complete all {{numConcentrations}} to qualify for the certificate. For more details, <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our FAQ</span>.",
    "subtitle_certificates_applied": "Application received! Complete <strong>{{title}}</strong> to demonstrate your readiness for the program. Good luck!",
    "subtitle_certificates_applied_foundations_complete": "Great work! You'll hear from us soon regarding your application.",
    "subtitle_certificates_program_complete": "Congratulations on completing the {{programTitle}}.",
    "subtitle_certificates_program_failed": "Unfortunately, your combined final score did not meet the minimum required for completion of the certificate. For more details <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our FAQ</span>.",
    "subtitle_certificates_rejected": "Sorry, your application wasn’t accepted for the {{programTitle}} program.",
    "subtitle_certificates_rejected_after_pre_accepted": "Sorry, your application wasn’t accepted for the {{programTitle}} program.",
    "subtitle_certificates_rejected_no_reapply_cta": "Sorry, your application wasn’t accepted for the {{programTitle}} program.",
    "subtitle_certificates_rejected_after_pre_accepted_no_reapply_cta": "Sorry, your application wasn’t accepted for the {{programTitle}} program.",
    "subtitle_certificates_expelled": "Your enrollment is no longer active. We hope you enjoy continued access to select courses from the full program.",
    "subtitle_certificates_expelled_no_reapply_cta": "Your enrollment is no longer active. We hope you enjoy continued access to select courses from the full program.",
    "subtitle_jordanian_math_accepted": "Welcome to the {{programTitle}} program.",
    "subtitle_jordanian_math_accepted_not_started": "Welcome to the {{programTitle}} program! To get started, complete <strong>{{title}}</strong>, the first of {{numConcentrations}} concentrations in the curriculum.",
    "subtitle_jordanian_math_accepted_not_started_foundations_complete": "Welcome to the {{programTitle}} program! You've already completed <strong>{{title}}</strong>, the first of {{numConcentrations}} concentrations in the curriculum.",
    "subtitle_jordanian_math_program_complete": "Congratulations on completing the {{programTitle}} program.",
    "subtitle_jordanian_math_program_failed": "Unfortunately, your combined final score did not meet the minimum required for completion of the program. For more details <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our FAQ</span>.",
    "subtitle_jordanian_math_expelled": "Your enrollment is no longer active. We hope you enjoy continued access to select courses from the full program.",
    "subtitle_jordanian_math_expelled_no_reapply_cta": "Your enrollment is no longer active. We hope you enjoy continued access to select courses from the full program."
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.student_dashboard_program_box = window.locales.es.lessons.stream.student_dashboard_program_box || {};window.locales.es.lessons.stream.student_dashboard_program_box = {...window.locales.es.lessons.stream.student_dashboard_program_box, ...{
    "apply_now": "Solicitud completa",           
    "mobile_status_applied": "¡Solicitud presentada!",           
    "mobile_status_foundations_complete": "¡Solicitud presentada!",           
    "progress_application": "Solicitud",           
    "progress_foundations": "Fundamentos del negocio",           
    "progress_response": "Respuesta",           
    "download_certificate": "Descargar Certificado",
    "mobile_status_rejected": "Perdon no eras <br> aceptado. <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">¡Aplicar de nuevo!",
    "mobile_status_expelled": "Su inscripción es <br> Ya no está activo.",
    "mobile_status_deferred": "Ha diferido su inscripción.",
    "progress_begin": "Orientación",
    "subtitle_degree_program_applied_foundations_complete": "¡Buen trabajo! Recibirá pronto noticias de su solicitud.",
    "subtitle_degree_program_accepted": "Complete todas las {{numConcentrations}} para calificar para el grado. Para la programación del programa y los requisitos completos, <span class=\"faq-link\" ng-click=\"openFAQ()\">visite nuestro FAQ grado .",
    "subtitle_degree_program_program_complete": "Complete todas las concentraciones de {{numConcentrations}} para calificar para el grado. Para conocer la programación y los requisitos completos, <span class=\"faq-link\" ng-click=\"openFAQ()\">visite nuestro FAQ de grado .",
    "subtitle_career_network_only_accepted": "¡Bienvenidos a la Red de Carreras! Usted puede probar nuestros cursos abiertos mientras espera las preguntas de las empresas.",
    "subtitle_career_network_only_accepted_not_started": "¡Bienvenidos a la Red de Carreras! Usted puede probar nuestros cursos abiertos mientras espera las preguntas de las empresas.",
    "subtitle_career_network_only_accepted_not_started_foundations_complete": "¡Bienvenidos a la Red de Carreras! Usted puede probar nuestros cursos abiertos mientras espera las preguntas de las empresas.",
    "subtitle_career_network_only_rejected": "Lo sentimos, su solicitud no fue aceptada en la Red de Carreras.",
    "subtitle_career_network_only_expelled": "Su estado de Red de Carrera ya no está activo.",
    "view_degree_faq": "Ver preguntas frecuentes",
    "mobile_status_program_failed": "Recibiste un fallo <br> Puntuación. <span class=\"faq-link\" ng-click=\"openFAQ()\">Visite nuestro FAQ .",
    "subtitle_degree_program_program_failed": "Complete todas las concentraciones de {{numConcentrations}} para calificar para el grado. Para conocer la programación y los requisitos completos, <span class=\"faq-link\" ng-click=\"openFAQ()\">visite nuestro FAQ de grado .",
    "mobile_status_accepted": "Aplicación aceptada!",
    "register": "Regístrate ahora",
    "mobile_status_pre_accepted_registered": "Estás registrado <br> para la clase.",
    "mobile_status_pre_accepted": "Solicitud aceptada!",
    "subtitle_certificates_accepted": "Complete todas las concentraciones de {{numConcentrations}} para calificar para el certificado. Para más detalles, <span class=\"faq-link\" ng-click=\"openFAQ()\">visite nuestras Preguntas frecuentes .",
    "subtitle_certificates_accepted_not_started": "¡Bienvenido al programa {{cohortTitle}} ! Para comenzar, completa <strong>{{title}}</strong> , la primera de {{numConcentrations}} concentraciones en el plan de estudios. <br><br> Complete todas las {{numConcentrations}} para calificar para el certificado. Para más detalles, <span class=\"faq-link\" ng-click=\"openFAQ()\">visite nuestras Preguntas frecuentes .",
    "subtitle_certificates_accepted_not_started_foundations_complete": "¡Bienvenido al programa {{cohortTitle}} ! Ya completó <strong>{{title}}</strong> , la primera de {{numConcentrations}} concentraciones en el plan de estudios. <br><br> Complete todas las {{numConcentrations}} para calificar para el certificado. Para más detalles, <span class=\"faq-link\" ng-click=\"openFAQ()\">visite nuestras Preguntas frecuentes .",
    "subtitle_certificates_program_complete": "Felicidades por completar el {{cohortTitle}} .",
    "subtitle_certificates_program_failed": "Desafortunadamente, su puntaje final combinado no alcanzó el mínimo requerido para completar el certificado. Para más detalles, <span class=\"faq-link\" ng-click=\"openFAQ()\">visita nuestras Preguntas frecuentes .",
    "subtitle_certificates_rejected": "Lo sentimos, su aplicación no fue aceptada para el programa {{cohortTitle}} .",
    "subtitle_certificates_expelled": "Su inscripción ya no está activa. Esperamos que disfrute de acceso continuo a cursos seleccionados del programa completo.",
    "progress_decision": "Decisión",
    "certificate_help_text": "Inicie sesión usando una computadora de escritorio para descargar su certificado.",
    "subtitle_jordanian_math_accepted": "Bienvenido al programa {{cohortTitle}} .",
    "subtitle_jordanian_math_accepted_not_started": "Bienvenido al programa {{cohortTitle}} Para comenzar, complete <strong>{{title}}</strong> , la primera de las {{numConcentrations}} concentraciones en el currículo.",
    "subtitle_jordanian_math_accepted_not_started_foundations_complete": "Bienvenido al programa {{cohortTitle}} Ya ha completado <strong>{{title}}</strong> , la primera de {{numConcentrations}} concentraciones en el currículo.",
    "subtitle_jordanian_math_program_complete": "Felicitaciones por completar el programa {{cohortTitle}} .",
    "subtitle_jordanian_math_program_failed": "Desafortunadamente, su puntaje final combinado no cumplió con el mínimo requerido para completar el programa. Para más detalles <span class=\"faq-link\" ng-click=\"openFAQ()\">visite nuestras preguntas frecuentes .",
    "subtitle_jordanian_math_expelled": "Su inscripción ya no está activa. Esperamos que disfrute del acceso continuo a cursos seleccionados del programa completo.",
    "mobile_status_rejected_after_pre_accepted": "Lo siento no estabas <br> aceptado. <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">¡Aplicar de nuevo!",
    "subtitle_certificates_rejected_after_pre_accepted": "Lo sentimos, su solicitud no fue aceptada para el programa {{cohortTitle}} .",
    "mba_programs": "{{brandName}} MBA <br class=\"hidden-xs hidden-sm\"> Programas",
    "subtitle_degree_program_accepted_not_started": "¡Bienvenido a la {{brandName}} {{programTitle}}! Para comenzar, complete <strong>{{title}}</strong> , la primera de las concentraciones de {{numConcentrations}} en el plan de estudios de EMBA. <br><br> Completa todas las {{numConcentrations}} para calificar para el título. Para el horario del programa y los requisitos completos, <span class=\"faq-link\" ng-click=\"openFAQ()\">visite nuestras preguntas frecuentes sobre títulos .",
    "subtitle_degree_program_accepted_not_started_foundations_complete": "¡Bienvenido a la {{brandName}} {{programTitle}}! Ya ha completado <strong>{{title}}</strong> , la primera de las concentraciones de {{numConcentrations}} en el plan de estudios de EMBA. <br><br> Completa todas las {{numConcentrations}} para calificar para el título. Para el horario del programa y los requisitos completos, <span class=\"faq-link\" ng-click=\"openFAQ()\">visite nuestras preguntas frecuentes sobre títulos .",
    "subtitle_degree_program_expelled": "Su estado {{programTitle}} ya no está activo. Esperamos que disfrute de un acceso continuo a cursos seleccionados del programa completo. <br/><br/> Para obtener más información, contáctenos en <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>",
    "subtitle_degree_program_deferred": "Ha diferido su inscripción en {{programTitle}}. Tenga en cuenta que solo se le permite diferir la inscripción dos veces antes de tener que volver a presentar una solicitud al {{programTitle}}. Si tiene alguna pregunta, envíenos un correo electrónico a <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> .",
    "mobile_status_rejected_no_reapply_cta": "Lo siento, no estabas <br> aceptado.",
    "mobile_status_rejected_after_pre_accepted_no_reapply_cta": "Lo siento, no estabas <br> aceptado.",
    "subtitle_career_network_only_rejected_no_reapply_cta": "Lo sentimos, su solicitud no fue aceptada en la Red de Carreras.",
    "subtitle_certificates_rejected_no_reapply_cta": "Lo sentimos, su solicitud no fue aceptada para el programa {{cohortTitle}} .",
    "subtitle_certificates_rejected_after_pre_accepted_no_reapply_cta": "Lo sentimos, su solicitud no fue aceptada para el programa {{cohortTitle}} .",
    "mobile_status_pre_accepted_not_registered_not_open": "El registro se abre el<br> {{registrationOpenDate}}.",
    "subtitle_not_registered_not_open": "¡Felicitaciones por su aceptación a la {{programTitle}} de {{brandName}} El registro se abre el {{registrationOpenDate}}.",
    "mobile_status_pre_accepted_not_registered": "¡Solicitud aceptada!",
    "subtitle_degree_program_rejected": "Lo sentimos, su solicitud no fue aceptada para el programa de grado. Sin embargo, puede continuar aprendiendo con nuestros cursos abiertos para mejorar su conocimiento, absolutamente gratis, y ahora es elegible para <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">volver a postularse para la próxima clase .",
    "subtitle_degree_program_rejected_after_pre_accepted": "Lo sentimos, no pudiste unirte al programa de grado anteriormente. Sin embargo, puede continuar aprendiendo con nuestros cursos abiertos para mejorar su conocimiento, absolutamente gratis, y ahora es elegible para <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">volver a postularse para la próxima clase .",
    "close": "Cerca<i class=\"fas fa-multiply\"></i>",
    "view_status": "Ver el estado de<i class=\"fas fa-chevron-up\"></i>",
    "subtitle_degree_program_rejected_no_reapply_cta": "Aunque no se unirá al programa de grado, puede volver a presentar su solicitud a partir del {{reapplicationDate}} . Mientras tanto, mantendrá el acceso gratuito a los cursos abiertos.",
    "subtitle_degree_program_rejected_after_pre_accepted_no_reapply_cta": "Aunque no se unirá al programa de grado, puede volver a presentar su solicitud a partir del {{reapplicationDate}} . Mientras tanto, mantendrá el acceso gratuito a los cursos abiertos.",
    "apply_to": "Aplicar a {{brandName}}",
    "application_received": "Aplicación recibida",
    "subtitle_not_applied": "Se acerca la fecha límite para el próximo ciclo de admisión.",
    "subtitle_degree_program_applied": "¡Aplicación recibida! Complete <strong>{{title}}</strong> para demostrar su preparación para el título. Las decisiones finales se envían el {{decisionDate}}. ¡Buena suerte!",
    "subtitle_not_registered": "Ha sido admitido en la próxima clase de {{brandName}} {{programTitle}} , uniéndose a un grupo excepcional de estudiantes globales. Para confirmar su lugar, regístrese antes de {{registrationDeadline}} .",
    "mobile_status_not_applied": "Se acerca la fecha límite para el próximo ciclo de admisión.",
    "congrats": "Felicidades,<br class=\"visible-md visible-lg\" /> ¡Estas en!",
    "subtitle_degree_program_expelled_no_reapply_cta": "Tu estado de {{programTitle}} ya no está activo. Esperamos que disfrute del acceso continuo a cursos selectos del programa completo.<br/><br/> Para obtener más información, contáctenos en <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>",
    "subtitle_generic_applied": "¡Aplicación recibida! Complete <strong>{{title}}</strong> para demostrar su preparación para el programa. ¡Buena suerte!",
    "subtitle_generic_applied_foundations_complete": "¡Buen trabajo! Pronto tendrá noticias nuestras sobre su solicitud.",
    "subtitle_generic_accepted": "Complete todas las {{numConcentrations}} concentraciones para completar el programa.",
    "subtitle_generic_accepted_not_started": "¡Bienvenido a {{programTitle}} ! Para comenzar, complete <strong>{{title}}</strong> , la primera de {{numConcentrations}} concentraciones en el plan de estudios.<br><br> Complete todas las {{numConcentrations}} para completar el programa.",
    "subtitle_generic_accepted_not_started_foundations_complete": "¡Bienvenido a {{programTitle}} ! Ya completó <strong>{{title}}</strong> , la primera de {{numConcentrations}} concentraciones en el plan de estudios.<br><br> Complete todas las {{numConcentrations}} para completar el programa.",
    "subtitle_generic_program_complete": "Felicitaciones por completar el {{programTitle}} .",
    "subtitle_generic_program_failed": "Complete todas las {{numConcentrations}} concentraciones para completar el programa.",
    "subtitle_generic_deferred": "Ha aplazado su inscripción en {{programTitle}} . Si tiene alguna pregunta, envíenos un correo electrónico a <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> .",
    "subtitle_generic_expelled": "Su inscripción ya no está activa. Esperamos que disfrute del acceso continuo a cursos selectos del programa completo.",
    "subtitle_generic_rejected": "Lo sentimos, tu solicitud no fue aceptada para el {{programTitle}} .",
    "subtitle_generic_rejected_after_pre_accepted": "Lo sentimos, tu solicitud no fue aceptada para el {{programTitle}} .",
    "subtitle_generic_rejected_no_reapply_cta": "Lo sentimos, tu solicitud no fue aceptada para el {{programTitle}} .",
    "subtitle_generic_rejected_after_pre_accepted_no_reapply_cta": "Lo sentimos, tu solicitud no fue aceptada para el {{programTitle}} .",
    "mobile_status_expelled_no_reapply_cta": "Su inscripción es<br> ya no está activo.",
    "subtitle_generic_expelled_no_reapply_cta": "Su inscripción ya no está activa. Esperamos que disfrute del acceso continuo a cursos selectos del programa completo.",
    "subtitle_career_network_only_expelled_no_reapply_cta": "Su estado de Career Network ya no está activo.",
    "subtitle_certificates_expelled_no_reapply_cta": "Su inscripción ya no está activa. Esperamos que disfrute del acceso continuo a cursos selectos del programa completo.",
    "subtitle_jordanian_math_expelled_no_reapply_cta": "Su inscripción ya no está activa. Esperamos que disfrute del acceso continuo a cursos selectos del programa completo.",
    "subtitle_registered": "<p>¡Felicidades! Te has registrado y asegurado tu lugar en {{brandName}} {{programTitle}} . Estás a punto de unirte a una clase de estudiantes excepcionales de todo el mundo.</p><p> Su programa comienza el {{startDateLong}} . Esté atento a las notificaciones por correo electrónico y en su tablero con respecto a los eventos de orientación y el acceso al curso.</p>",
    "subtitle_registered_program_guide": "<p>¡Felicidades! Te has registrado y asegurado tu lugar en {{brandName}} {{programTitle}} . Estás a punto de unirte a una clase de estudiantes excepcionales de todo el mundo.</p><p> Su programa comienza el {{startDateLong}} . Esté atento a las notificaciones por correo electrónico y en su tablero con respecto a los eventos de orientación y el acceso al curso.</p><p> Si aún no lo ha hecho, revise la <a href=\"{{programGuideUrl}}\" target=\"_blank\">Guía del programa</a> para obtener más detalles sobre sus cursos y horarios.</p>",
    "subtitle_registered_no_orientation": "<p>¡Felicidades! Te has registrado y asegurado tu lugar en {{brandName}} {{programTitle}} . Estás a punto de unirte a una clase de estudiantes excepcionales de todo el mundo.</p><p> Su programa comienza el {{startDateLong}} . Esté atento a las notificaciones por correo electrónico y en su panel de control sobre el acceso al curso.</p>",
    "subtitle_registered_no_orientation_program_guide": "<p>¡Felicidades! Te has registrado y asegurado tu lugar en {{brandName}} {{programTitle}} . Estás a punto de unirte a una clase de estudiantes excepcionales de todo el mundo.</p><p> Su programa comienza el {{startDateLong}} . Esté atento a las notificaciones por correo electrónico y en su panel de control sobre el acceso al curso.</p><p> Si aún no lo ha hecho, revise la <a href=\"{{programGuideUrl}}\" target=\"_blank\">Guía del programa</a> para obtener más detalles sobre sus cursos y cronograma.</p>",
    "subtitle_certificates_applied": "¡Aplicación recibida! Complete <strong>{{title}}</strong> para demostrar su preparación para el programa. ¡Buena suerte!",
    "subtitle_certificates_applied_foundations_complete": "¡Buen trabajo! Pronto tendrá noticias nuestras sobre su solicitud.",
    "subtitle_not_applied_nominee": "Como nominado seleccionado de {{brandName}} , recibirás consideración de admisión preferencial.",
    "mobile_status_program_complete": "Has completado el programa.",
    "msse_program": "Programa MSSE {{brandName}}",
    "msba_program": "Programa MSBA {{brandName}}"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.student_dashboard_program_box = window.locales.it.lessons.stream.student_dashboard_program_box || {};window.locales.it.lessons.stream.student_dashboard_program_box = {...window.locales.it.lessons.stream.student_dashboard_program_box, ...{
    "apply_now": "Completa Candidatura",           
    "download_certificate": "Scarica Diploma",           
    "mobile_status_applied": "Candidatura inviata!",           
    "mobile_status_accepted": "Candidatura accettata!",           
    "mobile_status_rejected": "Siamo spiacenti, <br>non sei stato ammesso. <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">Ricandidati!</span>",           
    "mobile_status_expelled": "La tua iscrizione <br> non è più attiva.",           
    "mobile_status_foundations_complete": "Candidatura inviata!",           
    "mobile_status_deferred": "Hai rinviato la tua iscrizione.",           
    "mobile_status_program_failed": "Hai ricevuto un punteggio <br>non sufficiente. <span class=\"faq-link\" ng-click=\"openFAQ()\">Visita la nostra FAQ</span>.",           
    "progress_application": "Candidatura",           
    "progress_foundations": "Fondamenti di Impresa",           
    "progress_response": "Risposta",           
    "progress_begin": "Orientamento",           
    "subtitle_degree_program_applied_foundations_complete": "Lavoro eccellente! Avrai presto nostre notizie riguardo la tua candidatura.",           
    "subtitle_degree_program_accepted": "Completa tutti i {{numConcentrations}} indirizzi per ottenere l'idoneità al diploma. Per il calendario del programma e i requisiti completi, <span class=\"faq-link\" ng-click=\"openFAQ()\">visita la FAQ del diploma</span>.",           
    "subtitle_degree_program_program_complete": "Completa tutti i {{numConcentrations}} indirizzi per ottenere l'idoneità al diploma. Per il calendario del programma e i requisiti completi, <span class=\"faq-link\" ng-click=\"openFAQ()\">visita la FAQ del diploma</span>.",           
    "subtitle_degree_program_program_failed": "Completa tutti i {{numConcentrations}} indirizzi per ottenere l'idoneità al diploma. Per il calendario del programma e i requisiti completi, <span class=\"faq-link\" ng-click=\"openFAQ()\">visita la FAQ del diploma</span>.",           
    "subtitle_career_network_only_accepted": "Benvenuto alla Rete Professionale! Puoi provare i nostri corsi liberi mentre aspetti richieste dalle aziende.",           
    "subtitle_career_network_only_accepted_not_started": "Benvenuto alla Rete Professionale! Puoi provare i nostri corsi liberi mentre aspetti richieste dalle aziende.",           
    "subtitle_career_network_only_accepted_not_started_foundations_complete": "Benvenuto alla Rete Professionale! Puoi provare i nostri corsi liberi mentre aspetti richieste dalle aziende.",           
    "subtitle_career_network_only_rejected": "Siamo spiacenti, la tua candidatura per la Rete Professionale non è stata accettata.",           
    "subtitle_career_network_only_expelled": "Il tuo stato Rete Professionale non è più attivo.",           
    "view_degree_faq": "Visualizza FAQ",           
    "register": "Registrati ora",
    "mobile_status_pre_accepted_registered": "Sei registrato <br> per la classe.",
    "mobile_status_pre_accepted": "Applicazione accettata!",
    "subtitle_certificates_accepted": "Completare tutte le concentrazioni {{numConcentrations}} per qualificarsi per il certificato. Per maggiori dettagli, <span class=\"faq-link\" ng-click=\"openFAQ()\">visita le nostre FAQ .",
    "subtitle_certificates_accepted_not_started": "Benvenuto nel programma {{cohortTitle}} ! Per iniziare, completa <strong>{{title}}</strong> , la prima delle concentrazioni {{numConcentrations}} nel programma. <br><br> Completa tutte le {{numConcentrations}} per qualificarsi per il certificato. Per maggiori dettagli, <span class=\"faq-link\" ng-click=\"openFAQ()\">visita le nostre FAQ .",
    "subtitle_certificates_accepted_not_started_foundations_complete": "Benvenuto nel programma {{cohortTitle}} ! Hai già completato <strong>{{title}}</strong> , il primo di {{numConcentrations}} concentrazioni nel curriculum. <br><br> Completa tutte le {{numConcentrations}} per qualificarsi per il certificato. Per maggiori dettagli, <span class=\"faq-link\" ng-click=\"openFAQ()\">visita le nostre FAQ .",
    "subtitle_certificates_program_complete": "Congratulazioni per aver completato il {{cohortTitle}} .",
    "subtitle_certificates_program_failed": "Sfortunatamente, il tuo punteggio finale combinato non ha raggiunto il minimo richiesto per il completamento del certificato. Per maggiori dettagli <span class=\"faq-link\" ng-click=\"openFAQ()\">visita le nostre FAQ .",
    "subtitle_certificates_rejected": "Siamo spiacenti, la tua domanda non è stata accettata per il programma {{cohortTitle}} .",
    "subtitle_certificates_expelled": "La tua iscrizione non è più attiva. Ci auguriamo che tu possa continuare ad accedere a corsi selezionati dal programma completo.",
    "progress_decision": "Decisione",
    "certificate_help_text": "Accedi utilizzando un computer desktop per scaricare il certificato.",
    "subtitle_jordanian_math_accepted": "Benvenuto nel programma {{cohortTitle}} .",
    "subtitle_jordanian_math_accepted_not_started": "Benvenuto nel programma {{cohortTitle}} ! Per iniziare, completa <strong>{{title}}</strong> , la prima delle concentrazioni {{numConcentrations}} nel programma.",
    "subtitle_jordanian_math_accepted_not_started_foundations_complete": "Benvenuto nel programma {{cohortTitle}} ! Hai già completato <strong>{{title}}</strong> , il primo di {{numConcentrations}} concentrazioni nel curriculum.",
    "subtitle_jordanian_math_program_complete": "Congratulazioni per aver completato il programma {{cohortTitle}} .",
    "subtitle_jordanian_math_program_failed": "Sfortunatamente, il tuo punteggio finale combinato non ha raggiunto il minimo richiesto per il completamento del programma. Per maggiori dettagli <span class=\"faq-link\" ng-click=\"openFAQ()\">visita le nostre FAQ .",
    "subtitle_jordanian_math_expelled": "La tua iscrizione non è più attiva. Ci auguriamo che tu possa continuare ad accedere a corsi selezionati dal programma completo.",
    "mobile_status_rejected_after_pre_accepted": "Scusa, non lo eri <br> accettato. <span class=\"faq-link\", ng-click=\"gotoMbaSettings()\">Riapplicare!",
    "subtitle_certificates_rejected_after_pre_accepted": "Siamo spiacenti, la tua domanda non è stata accettata per il programma {{cohortTitle}} .",
    "mba_programs": "{{brandName}} MBA <br class=\"hidden-xs hidden-sm\"> programmi",
    "subtitle_degree_program_accepted_not_started": "Benvenuto {{brandName}} ! Per iniziare, completa <strong>{{title}}</strong> , la prima delle concentrazioni di {{numConcentrations}} nel curriculum {{programTitle}}. <br><br> Completa tutte le {{numConcentrations}} per qualificarti per il grado. Per la pianificazione del programma e i requisiti completi, <span class=\"faq-link\" ng-click=\"openFAQ()\">visitare le domande frequenti sui diplomi .",
    "subtitle_degree_program_accepted_not_started_foundations_complete": "Benvenuto {{brandName}} ! Hai già completato <strong>{{title}}</strong> , la prima delle concentrazioni di {{numConcentrations}} nel curriculum {{programTitle}}. <br><br> Completa tutte le {{numConcentrations}} per qualificarti per il grado. Per la pianificazione del programma e i requisiti completi, <span class=\"faq-link\" ng-click=\"openFAQ()\">visitare le domande frequenti sui diplomi .",
    "subtitle_degree_program_expelled": "Il tuo stato {{programTitle}} non è più attivo. Ci auguriamo che possiate godere dell'accesso continuo a determinati corsi del programma completo. <br/><br/> Per ulteriori informazioni, contattaci a <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>",
    "subtitle_degree_program_deferred": "Hai rinviato la tua registrazione {{programTitle}}. Tieni presente che puoi posticipare la registrazione solo due volte prima di dover presentare nuovamente domanda all'{{programTitle}}. In caso di domande, inviare un'e-mail a <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> .",
    "mobile_status_rejected_no_reapply_cta": "Scusa, non lo eri <br> accettato.",
    "mobile_status_rejected_after_pre_accepted_no_reapply_cta": "Scusa, non lo eri <br> accettato.",
    "subtitle_career_network_only_rejected_no_reapply_cta": "Spiacenti, la tua candidatura non è stata accettata dalla rete di carriera.",
    "subtitle_certificates_rejected_no_reapply_cta": "Spiacenti, la tua richiesta non è stata accettata per il programma {{cohortTitle}} .",
    "subtitle_certificates_rejected_after_pre_accepted_no_reapply_cta": "Spiacenti, la tua richiesta non è stata accettata per il programma {{cohortTitle}} .",
    "mobile_status_pre_accepted_not_registered_not_open": "La registrazione si apre il<br> {{registrationOpenDate}}.",
    "subtitle_not_registered_not_open": "Congratulazioni per la tua accettazione {{brandName}} ! La registrazione si apre il {{registrationOpenDate}}.",
    "mobile_status_pre_accepted_not_registered": "Domanda accettata!",
    "subtitle_degree_program_rejected": "Spiacenti, la tua domanda di ammissione al corso di laurea non è stata accettata. Tuttavia, puoi continuare ad imparare con i nostri corsi aperti per migliorare le tue conoscenze, in modo assolutamente gratuito, e ora puoi candidarti <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">nuovamente per il prossimo corso .",
    "subtitle_degree_program_rejected_after_pre_accepted": "Spiacenti, non eri in grado di iscriverti al corso di laurea in precedenza. Tuttavia, puoi continuare ad imparare con i nostri corsi aperti per migliorare le tue conoscenze, in modo assolutamente gratuito, e ora puoi candidarti <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">nuovamente per il prossimo corso .",
    "close": "Chiudere<i class=\"fas fa-multiply\"></i>",
    "view_status": "Visualizza stato<i class=\"fas fa-chevron-up\"></i>",
    "subtitle_degree_program_rejected_no_reapply_cta": "Anche se non parteciperai al corso di laurea, puoi presentare nuovamente domanda a partire dal giorno {{reapplicationDate}} . Nel frattempo, manterrai libero l'accesso ai corsi aperti.",
    "subtitle_degree_program_rejected_after_pre_accepted_no_reapply_cta": "Anche se non parteciperai al corso di laurea, puoi presentare nuovamente domanda a partire dal giorno {{reapplicationDate}} . Nel frattempo, manterrai libero l'accesso ai corsi aperti.",
    "apply_to": "Applica a {{brandName}}",
    "application_received": "Applicazione ricevuta",
    "subtitle_not_applied": "Si avvicina la scadenza del prossimo ciclo di ammissione.",
    "subtitle_degree_program_applied": "Applicazione ricevuta! Completa <strong>{{title}}</strong> per dimostrare la tua preparazione per la laurea. Le decisioni finali vengono inviate il {{decisionDate}}. Buona fortuna!",
    "subtitle_not_registered": "Sei stato ammesso al prossimo corso {{brandName}} {{programTitle}} , entrando a far parte di un eccezionale gruppo di studenti globali. Per confermare il tuo posto, registrati entro {{registrationDeadline}} .",
    "mobile_status_not_applied": "Si avvicina la scadenza del prossimo ciclo di ammissione.",
    "congrats": "Congratulazioni,<br class=\"visible-md visible-lg\" /> Sei in!",
    "subtitle_degree_program_expelled_no_reapply_cta": "Il tuo stato {{programTitle}} non è più attivo. Ci auguriamo che tu possa godere dell'accesso continuo a corsi selezionati dal programma completo.<br/><br/> Per ulteriori informazioni, contattaci all'indirizzo <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>",
    "subtitle_generic_applied": "Applicazione ricevuta! Completa <strong>{{title}}</strong> per dimostrare la tua preparazione per il programma. Buona fortuna!",
    "subtitle_generic_applied_foundations_complete": "Ottimo lavoro! Ci sentiremo presto in merito alla tua candidatura.",
    "subtitle_generic_accepted": "Completa tutte le {{numConcentrations}} concentrazioni per completare il programma.",
    "subtitle_generic_accepted_not_started": "Benvenuto nel {{programTitle}} ! Per iniziare, completa <strong>{{title}}</strong> , il primo di {{numConcentrations}} concentrazioni nel curriculum.<br><br> Completa tutti {{numConcentrations}} per completare il programma.",
    "subtitle_generic_accepted_not_started_foundations_complete": "Benvenuto nel {{programTitle}} ! Hai già completato <strong>{{title}}</strong> , il primo di {{numConcentrations}} concentrazioni nel curriculum.<br><br> Completa tutti {{numConcentrations}} per completare il programma.",
    "subtitle_generic_program_complete": "Congratulazioni per aver completato il {{programTitle}} .",
    "subtitle_generic_program_failed": "Completa tutte le {{numConcentrations}} concentrazioni per completare il programma.",
    "subtitle_generic_deferred": "Hai rinviato la tua iscrizione a {{programTitle}} . In caso di domande, inviare un'e-mail a <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> .",
    "subtitle_generic_expelled": "La tua iscrizione non è più attiva. Ci auguriamo che tu possa godere dell'accesso continuo a corsi selezionati dal programma completo.",
    "subtitle_generic_rejected": "Siamo spiacenti, la tua domanda non è stata accettata per il {{programTitle}} .",
    "subtitle_generic_rejected_after_pre_accepted": "Siamo spiacenti, la tua domanda non è stata accettata per il {{programTitle}} .",
    "subtitle_generic_rejected_no_reapply_cta": "Siamo spiacenti, la tua domanda non è stata accettata per il {{programTitle}} .",
    "subtitle_generic_rejected_after_pre_accepted_no_reapply_cta": "Siamo spiacenti, la tua domanda non è stata accettata per il {{programTitle}} .",
    "mobile_status_expelled_no_reapply_cta": "La tua iscrizione è<br> non più attivo.",
    "subtitle_generic_expelled_no_reapply_cta": "La tua iscrizione non è più attiva. Ci auguriamo che tu possa godere dell'accesso continuo a corsi selezionati dal programma completo.",
    "subtitle_career_network_only_expelled_no_reapply_cta": "Il tuo stato Career Network non è più attivo.",
    "subtitle_certificates_expelled_no_reapply_cta": "La tua iscrizione non è più attiva. Ci auguriamo che tu possa godere dell'accesso continuo a corsi selezionati dal programma completo.",
    "subtitle_jordanian_math_expelled_no_reapply_cta": "La tua iscrizione non è più attiva. Ci auguriamo che tu possa godere dell'accesso continuo a corsi selezionati dal programma completo.",
    "subtitle_registered": "<p>Congratulazioni! Ti sei registrato e ti sei assicurato un posto nel {{brandName}} {{programTitle}} . Stai per unirti a una classe di studenti eccezionali provenienti da tutto il mondo.</p><p> Il tuo programma inizia il {{startDateLong}} . Tieni d'occhio le notifiche via email e sulla tua dashboard riguardanti gli eventi di orientamento e l'accesso ai corsi.</p>",
    "subtitle_registered_program_guide": "<p>Congratulazioni! Ti sei registrato e ti sei assicurato un posto nel {{brandName}} {{programTitle}} . Stai per unirti a una classe di studenti eccezionali provenienti da tutto il mondo.</p><p> Il tuo programma inizia il {{startDateLong}} . Tieni d'occhio le notifiche via email e sulla tua dashboard riguardanti gli eventi di orientamento e l'accesso ai corsi.</p><p> Se non l'hai già fatto, consulta la <a href=\"{{programGuideUrl}}\" target=\"_blank\">Guida del programma</a> per maggiori dettagli sui tuoi corsi e sul programma.</p>",
    "subtitle_registered_no_orientation": "<p>Congratulazioni! Ti sei registrato e ti sei assicurato un posto nel {{brandName}} {{programTitle}} . Stai per unirti a una classe di studenti eccezionali provenienti da tutto il mondo.</p><p> Il tuo programma inizia il {{startDateLong}} . Tieni d'occhio le notifiche via email e sulla dashboard relative all'accesso al corso.</p>",
    "subtitle_registered_no_orientation_program_guide": "<p>Congratulazioni! Ti sei registrato e ti sei assicurato un posto nel {{brandName}} {{programTitle}} . Stai per unirti a una classe di studenti eccezionali provenienti da tutto il mondo.</p><p> Il tuo programma inizia il {{startDateLong}} . Tieni d'occhio le notifiche via email e sulla dashboard relative all'accesso al corso.</p><p> Se non l'hai già fatto, consulta la <a href=\"{{programGuideUrl}}\" target=\"_blank\">Guida al programma</a> per ulteriori dettagli sui corsi e sul programma.</p>",
    "subtitle_certificates_applied": "Applicazione ricevuta! Completa <strong>{{title}}</strong> per dimostrare che sei pronto per il programma. Buona fortuna!",
    "subtitle_certificates_applied_foundations_complete": "Ottimo lavoro! Avrai presto nostre notizie in merito alla tua candidatura.",
    "subtitle_not_applied_nominee": "In qualità di candidato selezionato per {{brandName}} , riceverai una considerazione preferenziale per l'ammissione.",
    "mobile_status_program_complete": "Hai completato il programma.",
    "msse_program": "Programma MSSE {{brandName}}",
    "msba_program": "Programma MSBA {{brandName}}"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.student_dashboard_program_box = window.locales.zh.lessons.stream.student_dashboard_program_box || {};window.locales.zh.lessons.stream.student_dashboard_program_box = {...window.locales.zh.lessons.stream.student_dashboard_program_box, ...{
    "apply_now": "立即预申请",           
    "mobile_status_applied": "已提交申请",           
    "mobile_status_foundations_complete": "已提交申请",           
    "progress_application": "申请",           
    "progress_foundations": "商务基础",           
    "progress_response": "答复",           
    "download_certificate": "下载证书",           
    "mobile_status_rejected": "很抱歉，你未被<br>录取。<span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">重新申请！</span>",           
    "mobile_status_expelled": "你的注册不再<br>有效。",           
    "mobile_status_deferred": "你已将注册延期。",           
    "progress_begin": "定向",           
    "subtitle_degree_program_applied_foundations_complete": "做得太棒了！我们很快会就你的申请给出答复。",           
    "subtitle_degree_program_accepted": "完成所有的{{numConcentrations}}合集以获得学位。有关课程时间表和完整要求，<span class=\"faq-link\" ng-click=\"openFAQ()\">请查看我们的学位常见问题</span>。",           
    "subtitle_degree_program_program_complete": "完成所有的{{numConcentrations}}合集以获得学位。有关课程时间表和完整要求，<span class=\"faq-link\" ng-click=\"openFAQ()\">请查看我们的学位常见问题</span>。",           
    "subtitle_career_network_only_accepted": "欢迎加入求职网络！在等待公司咨询的同时，你可以试上我们的公开课程。",           
    "subtitle_career_network_only_accepted_not_started": "欢迎加入求职网络！在等待公司咨询的同时，你可以试上我们的公开课程。",           
    "subtitle_career_network_only_accepted_not_started_foundations_complete": "欢迎加入求职网络！在等待公司咨询的同时，你可以试上我们的公开课程。",           
    "subtitle_career_network_only_rejected": "很抱歉，你的申请未经求职网络审核通过。",           
    "subtitle_career_network_only_expelled": "你的求职网络状态不再有效。",           
    "view_degree_faq": "查看常见问题",           
    "mobile_status_program_failed": "你的分数不及格<br>。<span class=\"faq-link\" ng-click=\"openFAQ()\">查看我们的常见问题</span>。",           
    "subtitle_degree_program_program_failed": "完成所有的{{numConcentrations}}合集以获得学位。有关课程时间表和完整要求，<span class=\"faq-link\" ng-click=\"openFAQ()\">请查看我们的学位常见问题</span>。",           
    "mobile_status_accepted": "申请已通过！",           
    "register": "立即注册",           
    "mobile_status_pre_accepted_registered": "你已报名<br>该门课。",           
    "mobile_status_pre_accepted": "申请已通过！",           
    "subtitle_certificates_accepted": "完成所有的{{numConcentrations}}合集以获得证书。更多详情，<span class=\"faq-link\" ng-click=\"openFAQ()\">请查看我们的常见问题</span>。",           
    "subtitle_certificates_accepted_not_started": "欢迎加入{{cohortTitle}}课程！首先，完成<strong>{{title}}</strong>，这是课程中的第一个{{numConcentrations}}合集。<br><br>完成所有{{numConcentrations}}以获得证书。更多详情，<span class=\"faq-link\" ng-click=\"openFAQ()\">请查看我们的常见问题</span>。",           
    "subtitle_certificates_accepted_not_started_foundations_complete": "欢迎加入{{cohortTitle}}课程！你已完成<strong>{{title}}</strong>，这是课程中的第一个{{numConcentrations}}合集。<br><br>完成所有{{numConcentrations}}以获得证书。更多详情，<span class=\"faq-link\" ng-click=\"openFAQ()\">请查看我们的常见问题</span>。",           
    "subtitle_certificates_program_complete": "恭喜你已完成{{cohortTitle}}。",           
    "subtitle_certificates_program_failed": "很遗憾，你的最终综合得分未达到获得证书的最低要求。更多详情，<span class=\"faq-link\" ng-click=\"openFAQ()\">请查看我们的常见问题</span>。",           
    "subtitle_certificates_rejected": "很抱歉，你的{{cohortTitle}}课程申请未经通过。",           
    "subtitle_certificates_expelled": "你的注册不再有效。我们希望你继续从整个课程中选择课程进行学习。",           
    "progress_decision": "决定",           
    "certificate_help_text": "使用桌面计算机登录以下载你的证书。",           
    "subtitle_jordanian_math_accepted": "欢迎加入{{cohortTitle}}课程。",           
    "subtitle_jordanian_math_accepted_not_started": "欢迎加入{{cohortTitle}}课程！首先，完成<strong>{{title}}</strong>，这是课程中的第一个{{numConcentrations}}合集。",           
    "subtitle_jordanian_math_accepted_not_started_foundations_complete": "欢迎加入{{cohortTitle}}课程！你已完成<strong>{{title}}</strong>，这是课程中的第一个{{numConcentrations}}合集。",           
    "subtitle_jordanian_math_program_complete": "恭喜你已完成{{cohortTitle}}课程。",           
    "subtitle_jordanian_math_program_failed": "很遗憾，你的最终综合得分未达到完成课程的最低要求。更多详情，<span class=\"faq-link\" ng-click=\"openFAQ()\">请查看我们的常见问题</span>。",           
    "subtitle_jordanian_math_expelled": "你的注册不再有效。我们希望你继续从整个课程中选择课程进行学习。",           
    "mobile_status_rejected_after_pre_accepted": "很抱歉，你未被<br>录取。<span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">重新申请！</span>",           
    "subtitle_certificates_rejected_after_pre_accepted": "很抱歉，你的{{cohortTitle}}课程申请未经通过。",           
    "mba_programs": "{{brandName}} 工商管理硕士（MBA ）<br class=\"hidden-xs hidden-sm\">课程",           
    "subtitle_degree_program_accepted_not_started": "欢迎来到{{brandName}}高级工商管理硕士（EMBA）课程！首先，完成<strong>{{title}}</strong>，这是高级工商管理硕士（EMBA）课程中的第一个{{numConcentrations}}合集。<br><br>完成所有{{numConcentrations}}以获得学位。有关课程时间表和完整要求，<span class=\"faq-link\" ng-click=\"openFAQ()\">请查看我们的学位常见问题</span>。",           
    "subtitle_degree_program_accepted_not_started_foundations_complete": "欢迎来到{{brandName}}高级工商管理硕士（EMBA）课程！你已完成<strong>{{title}}</strong>，这是高级工商管理硕士（EMBA）课程中的第一个{{numConcentrations}}合集。<br><br>完成所有{{numConcentrations}}以获得学位。有关课程时间表和完整要求，<span class=\"faq-link\" ng-click=\"openFAQ()\">请查看我们的学位常见问题</span>。",           
    "subtitle_degree_program_expelled": "你的高级工商管理硕士（EMBA）学位不再有效。我们希望你继续从整个课程中选择课程进行学习。<br/><br/>更多信息，请通过<a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>联系我们。",           
    "subtitle_degree_program_deferred": "你推迟了高级工商管理硕士（EMBA）课程的注册。请注意，在你必须重新申请高级工商管理硕士（EMBA ）课程之前，你只能推迟注册两次。如有任何疑问，请发送邮件至<a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>。",           
    "mobile_status_rejected_no_reapply_cta": "很抱歉，你未被<br>录取。",           
    "mobile_status_rejected_after_pre_accepted_no_reapply_cta": "很抱歉，你未被<br>录取。",           
    "subtitle_career_network_only_rejected_no_reapply_cta": "很抱歉，你的申请未经求职网络审核通过。",           
    "subtitle_certificates_rejected_no_reapply_cta": "很抱歉，你的{{cohortTitle}}课程申请未经通过。",           
    "subtitle_certificates_rejected_after_pre_accepted_no_reapply_cta": "很抱歉，你的{{cohortTitle}}课程申请未经通过。",           
    "mobile_status_pre_accepted_not_registered_not_open": "报名将在<br>{{registrationOpenDate}}开启。",           
    "subtitle_not_registered_not_open": "祝贺你已被{{brandName}}高级工商管理硕士（EMBA）课程录取！报名将在{{registrationOpenDate}}开启。",           
    "mobile_status_pre_accepted_not_registered": "申请接受！",
    "subtitle_degree_program_rejected": "抱歉，您的申请没有被学位课程录取。但是，您可以继续通过我们的开放课程学习以提升您的知识，完全免费，您现在有资格<span class=\"faq-link\", ng-click=\"gotoMbaSettings()\">重新申请下一堂课。",
    "subtitle_degree_program_rejected_after_pre_accepted": "抱歉，您之前无法加入学位课程。但是，您可以继续通过我们的开放课程学习以提升您的知识，完全免费，您现在有资格<span class=\"faq-link\", ng-click=\"gotoMbaSettings()\">重新申请下一堂课。",
    "close": "关<i class=\"fas fa-multiply\"></i>",
    "view_status": "查看状态<i class=\"fas fa-chevron-up\"></i>",
    "subtitle_degree_program_rejected_no_reapply_cta": "虽然您不会参加学位课程，但您可以从{{reapplicationDate}}开始重新申请。同时，您将继续免费访问公开课程。",
    "subtitle_degree_program_rejected_after_pre_accepted_no_reapply_cta": "虽然您不会参加学位课程，但您可以从{{reapplicationDate}}开始重新申请。同时，您将继续免费访问公开课程。",
    "apply_to": "适用于{{brandName}}",
    "application_received": "申请已收到",
    "subtitle_not_applied": "下一个录取周期的截止日期临近。",
    "subtitle_degree_program_applied": "申请已收到！完成<strong>{{title}}</strong>以证明您已准备好攻读学位。最终决定于{{decisionDate}}发送。祝你好运！",
    "subtitle_not_registered": "您已被即将到来的{{brandName}} {{programTitle}} {programTitle}} 课程录取，加入了一群杰出的全球学生。要确认您的位置，请在{{registrationDeadline}} 。",
    "mobile_status_not_applied": "下一个录取周期的截止日期临近。",
    "congrats": "恭喜你<br class=\"visible-md visible-lg\", />你在！",
    "subtitle_degree_program_expelled_no_reapply_cta": "您的{{programTitle}}状态不再有效。我们希望您喜欢继续访问完整计划中的精选课程。<br/><br/>如需更多信息，请通过<a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>联系我们",
    "subtitle_generic_applied": "申请已收到！完成<strong>{{title}}</strong>以证明您已准备好参加该计划。祝你好运！",
    "subtitle_generic_applied_foundations_complete": "做得好！您很快就会收到我们关于您的申请的消息。",
    "subtitle_generic_accepted": "完成所有{{numConcentrations}}个浓度以完成该计划。",
    "subtitle_generic_accepted_not_started": "欢迎来到{{programTitle}} ！要开始，请完成<strong>{{title}}</strong> ，这是课程中第一个{{numConcentrations}}集中点。<br><br>完成所有{{numConcentrations}}以完成该计划。",
    "subtitle_generic_accepted_not_started_foundations_complete": "欢迎来到{{programTitle}} ！您已经完成了<strong>{{title}}</strong> ，这是课程中的第一个{{numConcentrations}}专业。<br><br>完成所有{{numConcentrations}}以完成该计划。",
    "subtitle_generic_program_complete": "恭喜您完成{{programTitle}} 。",
    "subtitle_generic_program_failed": "完成所有{{numConcentrations}}浓度以完成该计划。",
    "subtitle_generic_deferred": "您已推迟{{programTitle}}注册。如果您有任何疑问，请发送电子邮件至<a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>联系我们。",
    "subtitle_generic_expelled": "您的注册不再有效。我们希望您喜欢继续访问完整计划中的精选课程。",
    "subtitle_generic_rejected": "抱歉，您的{{programTitle}}申请未被接受。",
    "subtitle_generic_rejected_after_pre_accepted": "抱歉，您的{{programTitle}}申请未被接受。",
    "subtitle_generic_rejected_no_reapply_cta": "抱歉，您的{{programTitle}}申请未被接受。",
    "subtitle_generic_rejected_after_pre_accepted_no_reapply_cta": "抱歉，您的{{programTitle}}申请未被接受。",
    "mobile_status_expelled_no_reapply_cta": "您的注册是<br>不再活跃。",
    "subtitle_generic_expelled_no_reapply_cta": "您的注册不再有效。我们希望您喜欢继续访问完整计划中的精选课程。",
    "subtitle_career_network_only_expelled_no_reapply_cta": "您的职业网络状态不再有效。",
    "subtitle_certificates_expelled_no_reapply_cta": "您的注册不再有效。我们希望您喜欢继续访问完整计划中的精选课程。",
    "subtitle_jordanian_math_expelled_no_reapply_cta": "您的注册不再有效。我们希望您喜欢继续访问完整计划中的精选课程。",
    "subtitle_registered": "<p>恭喜！您已注册并在{{brandName}} {{programTitle}}中获得一席之地。你即将加入一个由来自世界各地的优秀学生组成的班级。</p><p>您的程序从{{startDateLong}}开始。请留意通过电子邮件和您的仪表板上有关迎新活动和课程访问的通知。</p>",
    "subtitle_registered_program_guide": "<p>恭喜！您已注册并在{{brandName}} {{programTitle}}中获得一席之地。你即将加入一个由来自世界各地的优秀学生组成的班级。</p><p>您的程序从{{startDateLong}}开始。请留意通过电子邮件和您的仪表板上有关迎新活动和课程访问的通知。</p><p>如果您还没有，请查看<a href=\"{{programGuideUrl}}\", target=\"_blank\">计划指南</a>以了解有关您的课程和时间表的更多详细信息。</p>",
    "subtitle_registered_no_orientation": "<p>恭喜！您已在{{brandName}} {{programTitle}}中注册并确保您的位置。您即将加入一个由来自世界各地的优秀学生组成的班级。</p><p>您的计划于{{startDateLong}}开始。请留意通过电子邮件和仪表板上有关课程访问的通知。</p>",
    "subtitle_registered_no_orientation_program_guide": "<p>恭喜！您已在{{brandName}} {{programTitle}}中注册并确保您的位置。您即将加入一个由来自世界各地的优秀学生组成的班级。</p><p>您的计划于{{startDateLong}}开始。请留意通过电子邮件和仪表板上有关课程访问的通知。</p><p>如果您还没有阅读<a href=\"{{programGuideUrl}}\", target=\"_blank\">计划指南</a>，了解有关您的课程和日程安排的更多详细信息。</p>",
    "subtitle_certificates_applied": "申请已收到！完成<strong>{{title}}</strong>以证明您已准备好参加该计划。祝你好运！",
    "subtitle_certificates_applied_foundations_complete": "做得好！您很快就会收到我们关于您的申请的消息。",
    "subtitle_not_applied_nominee": "作为{{brandName}}的精选提名人，您将获得优先录取考虑。",
    "mobile_status_program_complete": "您已完成該計劃。",
    "msse_program": "{{brandName}} MSSE 计划",
    "msba_program": "{{brandName}} MSBA 课程"
}
};