window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.accepted_application = window.locales.am.lessons.stream.accepted_application || {};window.locales.am.lessons.stream.accepted_application = {...window.locales.am.lessons.stream.accepted_application, ...{
    "congrats": "እንኳን ደስ ያለዎት",           
    "you_have_been": "እርስዎ",           
    "accepted": "ተቀባይነት አግኝቷል",           
    "to_the": "ደ{{brandName}}",           
    "to": "ለ",           
    "class_of_x": "{{classDateString}} ክፍል ተቀባይነትን አግኝተዋል",           
    "share": "ያጋሩ",           
    "get_started": "ይጀምሩ",           
    "facebook_icon": "የፌስቡክ አዶ",           
    "twitter_icon": "የትዊተር አዶ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.accepted_application = window.locales.ar.lessons.stream.accepted_application || {};window.locales.ar.lessons.stream.accepted_application = {...window.locales.ar.lessons.stream.accepted_application, ...{
    "congrats": "تهانينا",           
    "you_have_been": "لقد تم",           
    "accepted": "قبولك",           
    "share": "مشاركة",           
    "get_started": "لتبدأ",           
    "facebook_icon": "أيقونة فيس بوك",           
    "twitter_icon": "أيقونة تويتر",           
    "to": "إلى",
    "to_the": "إلى {{brandName}}",
    "class_of_x": "فئة {{classDateString}}"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.accepted_application = window.locales.en.lessons.stream.accepted_application || {};window.locales.en.lessons.stream.accepted_application = {...window.locales.en.lessons.stream.accepted_application, ...{
    "congrats": "CONGRATULATIONS",
    "you_have_been": "You have been",
    "accepted": "ACCEPTED",
    "to_the": "to the",
    "to": "to",
    "class_of_x": "CLASS OF {{classDateString}}",
    "share": "SHARE",
    "get_started": "GET STARTED",
    "facebook_icon": "Facebook Icon",
    "twitter_icon": "Twitter Icon"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.accepted_application = window.locales.es.lessons.stream.accepted_application || {};window.locales.es.lessons.stream.accepted_application = {...window.locales.es.lessons.stream.accepted_application, ...{
    "congrats": "FELICIDADES",           
    "you_have_been": "Usted ha sido",           
    "accepted": "ACEPTADO",           
    "share": "COMPARTIR",           
    "get_started": "EMPEZAR",           
    "facebook_icon": "Icono de Facebook",           
    "twitter_icon": "Icono de Twitter",           
    "to": "a",
    "to_the": "a",
    "class_of_x": "CLASE DE {{classDateString}}"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.accepted_application = window.locales.it.lessons.stream.accepted_application || {};window.locales.it.lessons.stream.accepted_application = {...window.locales.it.lessons.stream.accepted_application, ...{
    "congrats": "CONGRATULAZIONI",           
    "you_have_been": "Sei stato",           
    "accepted": "AMMESSO",           
    "to": "al programma",           
    "share": "CONDIVIDI",           
    "get_started": "COMINCIA",           
    "facebook_icon": "Icona Facebook",           
    "twitter_icon": "Icona Twitter",           
    "to_the": "al",
    "class_of_x": "CLASSE DI {{classDateString}}"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.accepted_application = window.locales.zh.lessons.stream.accepted_application || {};window.locales.zh.lessons.stream.accepted_application = {...window.locales.zh.lessons.stream.accepted_application, ...{
    "congrats": "恭喜",           
    "you_have_been": "您已经",           
    "accepted": "被录取",           
    "share": "分享",           
    "get_started": "开始",           
    "facebook_icon": "Facebook 图标",           
    "twitter_icon": "Twitter 图标",           
    "to": "给",           
    "to_the": "给",           
    "class_of_x": "{{classDateString}}班级"           
}
};