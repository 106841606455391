window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.lesson = window.locales.am.lessons.lesson || {};window.locales.am.lessons.lesson.frame_list = window.locales.am.lessons.lesson.frame_list || {};window.locales.am.lessons.lesson.frame_list.frame = window.locales.am.lessons.lesson.frame_list.frame || {};window.locales.am.lessons.lesson.frame_list.frame.componentized = window.locales.am.lessons.lesson.frame_list.frame.componentized || {};window.locales.am.lessons.lesson.frame_list.frame.componentized.component = window.locales.am.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.am.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board = window.locales.am.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board || {};window.locales.am.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board = {...window.locales.am.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board, ...{
    "all_done": "ሁሉም ተጠናቅቋል!"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.lesson = window.locales.ar.lessons.lesson || {};window.locales.ar.lessons.lesson.frame_list = window.locales.ar.lessons.lesson.frame_list || {};window.locales.ar.lessons.lesson.frame_list.frame = window.locales.ar.lessons.lesson.frame_list.frame || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized = window.locales.ar.lessons.lesson.frame_list.frame.componentized || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized.component = window.locales.ar.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board = window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board = {...window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board, ...{
    "all_done": "تم إنجاز كل شيء!"           
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.lesson = window.locales.en.lessons.lesson || {};window.locales.en.lessons.lesson.frame_list = window.locales.en.lessons.lesson.frame_list || {};window.locales.en.lessons.lesson.frame_list.frame = window.locales.en.lessons.lesson.frame_list.frame || {};window.locales.en.lessons.lesson.frame_list.frame.componentized = window.locales.en.lessons.lesson.frame_list.frame.componentized || {};window.locales.en.lessons.lesson.frame_list.frame.componentized.component = window.locales.en.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.en.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board = window.locales.en.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board || {};window.locales.en.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board = {...window.locales.en.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board, ...{
    "all_done": "ALL DONE!"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.lesson = window.locales.es.lessons.lesson || {};window.locales.es.lessons.lesson.frame_list = window.locales.es.lessons.lesson.frame_list || {};window.locales.es.lessons.lesson.frame_list.frame = window.locales.es.lessons.lesson.frame_list.frame || {};window.locales.es.lessons.lesson.frame_list.frame.componentized = window.locales.es.lessons.lesson.frame_list.frame.componentized || {};window.locales.es.lessons.lesson.frame_list.frame.componentized.component = window.locales.es.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.es.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board = window.locales.es.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board || {};window.locales.es.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board = {...window.locales.es.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board, ...{
    "all_done": "¡TODO LISTO!"           
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.lesson = window.locales.it.lessons.lesson || {};window.locales.it.lessons.lesson.frame_list = window.locales.it.lessons.lesson.frame_list || {};window.locales.it.lessons.lesson.frame_list.frame = window.locales.it.lessons.lesson.frame_list.frame || {};window.locales.it.lessons.lesson.frame_list.frame.componentized = window.locales.it.lessons.lesson.frame_list.frame.componentized || {};window.locales.it.lessons.lesson.frame_list.frame.componentized.component = window.locales.it.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.it.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board = window.locales.it.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board || {};window.locales.it.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board = {...window.locales.it.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board, ...{
    "all_done": "FINITO!"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.lesson = window.locales.zh.lessons.lesson || {};window.locales.zh.lessons.lesson.frame_list = window.locales.zh.lessons.lesson.frame_list || {};window.locales.zh.lessons.lesson.frame_list.frame = window.locales.zh.lessons.lesson.frame_list.frame || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized = window.locales.zh.lessons.lesson.frame_list.frame.componentized || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized.component = window.locales.zh.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board = window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board = {...window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.tile_prompt_board, ...{
    "all_done": "全部完成！"           
}
};