window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.student_dashboard_boxes = window.locales.am.lessons.stream.student_dashboard_boxes || {};window.locales.am.lessons.stream.student_dashboard_boxes = {...window.locales.am.lessons.stream.student_dashboard_boxes, ...{
    "heading_admissions_info": "የመግቢያ ጥያቄ?",
    "heading_admissions_timeline": "",
    "heading_complete_application": "ወደ {{institutionName}} ያመልክቱ",
    "heading_find_friends": "በ {{institutionName}} ውስጥ ጓደኞችን ያግኙ",
    "heading_follow_us": "{{institutionName}} ን ተከተል",
    "heading_our_degree_programs": "የእኛ ዲግሪ ፕሮግራሞች",
    "heading_our_tuition_model": "የእኛ የትምህርት ሞዴል",
    "heading_register_for_program": "",
    "heading_not_joining_program_can_reapply": "",
    "heading_not_joining_program_cannot_reapply": "",
    "heading_schedule_your_interview": "",
    "heading_view_upcoming_events": "መጪ ክስተቶችን ይመልከቱ",
    "content_admissions_timeline": "",
    "content_admitted_students_zoom": "",
    "content_complete_application": "የሚቀጥለው የመግቢያ ዑደት የመጨረሻ ቀን እየቀረበ ነው።",
    "content_find_friends": "ለስራ ባልደረቦች እና ጓደኞች የተመራቂዎች አውታረ መረብን ያስሱ።",
    "content_follow_us": "በLinkedIn ገጻችን ላይ ተማሪዎችን፣ ዜናዎችን፣ ዝግጅቶችን እና የቀድሞ ተማሪዎችን ያግኙ።",
    "content_our_tuition_model": "እኛ መራጮች ነን፣ ግን ከገባን ተመጣጣኝ ነው። የከፍተኛ ደረጃ ትምህርትን በፈጠራ የትምህርት ሞዴል እንዴት እያሰፋን እንዳለን ይወቁ።",
    "content_register_for_program": "",
    "content_not_joining_program_can_reapply": "",
    "content_not_joining_program_cannot_reapply": "",
    "content_schedule_your_interview": "",
    "content_view_upcoming_events": "ተማሪዎች በዓለም ዙሪያ በሚገኙ ምናባዊ እና በአካል ተገኝተው ይሳተፋሉ።",
    "button_preview_the_network": "አውታረ መረቡን አስቀድመው ይመልከቱ",
    "button_view_students_and_alumni": "ተማሪዎችን እና የቀድሞ ተማሪዎችን ይመልከቱ",
    "button_follow_us": "ተከተሉን",
    "button_the_mba": "MBA",
    "button_the_executive_mba": "አስፈፃሚ MBA",
    "button_learn_more": "ተጨማሪ እወቅ",
    "button_complete_application": "የተሟላ መተግበሪያ",
    "button_view_events": "ክስተቶችን ይመልከቱ",
    "not_available_while_offline": "ከመስመር ውጭ ሆኖ አይገኝም።",
    "close": "ገጠመ",
    "schedule_video_chat": "<a ng-click=\"onClick('{{link}}', '{{type}}')\">1፡1 የቪዲዮ ውይይት</a> ያቅዱ",
    "email": "ኢሜይል <a ng-click=\"onClick('{{link}}', '{{type}}')\">{{link}}</a>",
    "contact_us": "አግኙን",
    "chat_with_admissions": "አሁን <a ng-click=\"onClick('{{link}}', '{{type}}')\">ከመግቢያዎች</a> ጋር በቀጥታ ይወያዩ",
    "quick_answers": "ለፈጣን መልሶች",
    "more_details": "ለበለጠ ዝርዝር",
    "heading_admitted_students_zoom_save_the_date": "ቀኑን ማኖር!",
    "heading_admitted_students_zoom_q_and_a": "ገቢ ተማሪዎች ጥያቄ እና መልስ",
    "content_admitted_students_zoom_you_are_invited": "በ {{webinarMonthDay}} ወደ መጪ ተማሪዎች ጥያቄ እና መልስ ተጋብዘዋል። ምንም እንኳን ሁሉም የተቀበሉ ተማሪዎች እንዲሳተፉ በብርቱ ቢበረታቱም እንደ አማራጭ ነው።",
    "content_admitted_students_if_you_missed_the_zoom": "ለሁሉም ተቀባይነት ላገኙ ተማሪዎች የማጉላት ጥያቄ እና መልስ ካለፈዎት፣ ጊዜው አልረፈደም።",
    "watch_recording": "ቀረጻ ይመልከቱ",
    "heading_company_funding": "{{featuredCompanyName}} የትምህርት ገንዘብ ድጋፍ",
    "heading_employer_funding": "የአሰሪ ትምህርት የገንዘብ ድጋፍ",
    "content_company_funding": "አንዳንድ ሰራተኞች ለ {{featuredCompanyName}} የትምህርት ጥቅማጥቅሞች ብቁ ናቸው። የገንዘብ ድጋፍ ሰጪ ቡድናችን ከHR ጋር መገናኘትን ማማከር እና ማመቻቸት ይችላል። በ {{fundingEmailAddress}}።",
    "content_employer_funding": "አንዳንድ ኩባንያዎች ትምህርታቸውን ስፖንሰር በማድረግ ሠራተኞች ላይ ኢንቨስት ያደርጋሉ። የገንዘብ ድጋፍ ሰጪ ቡድናችን ከHR ጋር መገናኘትን ማማከር እና ማመቻቸት ይችላል። በ {{fundingEmailAddress}}።",
    "button_contact_us": "አግኙን",
    "heading_upload_documents": "ሰነዶችን ይስቀሉ",
    "content_our_degree_programs": "የ {{institutionName}} ባህላዊ ከፍተኛ ትምህርትን ስለሚያስተጓጉሉ አዳዲስ የዲግሪ መርሃ ግብሮች የበለጠ ይረዱ።",
    "content_upload_documents": "ተቀባይነት ካገኘን፣ ለከፍተኛ ዲግሪዎ ይፋዊ ግልባጮች እንፈልጋለን። አሁን ጅምር ጀምር።",
    "heading_find_company_students": "{{companyName}} ተማሪዎችን ያግኙ",
    "heading_scholarship_opportunities": "የስኮላርሺፕ እድሎች",
    "button_view_and_schedule": "ይመልከቱ እና መርሐግብር ያስይዙ",
    "heading_upcoming_events": "መጪ ክስተቶች",
    "this_week_in_slack": "ይህንን ሳምንት በ Slack ላይ",           
    "heading_recent_courses": "የቅርብ ጊዜ ኮርሶች",
    "button_upcoming_events": "{{hasMoreEvents ? 'ተጨማሪ ክስተቶችን ይመልከቱ' : 'ሁሉንም ክስተቶች ይመልከቱ' }}",
    "view_event_tooltip_details_unavailable": "የክስተት ዝርዝሮች ለ MBA እና ለስራ አስፈፃሚ MBA ተማሪዎች እና ተማሪዎች ብቻ ይገኛሉ።",
    "heading_enrollment": "ምዝገባዎን ያጠናቅቁ",
    "heading_identity_verification": "የማንነት ማረጋገጫ",
    "button_next_steps": "ቀጣይ እርምጃዎች",           
    "button_update_name": "ስም ያዘምኑ",           
    "heading_slack": "በዚህ ሳምንት በ Slack ውስጥ",
    "heading_diploma_check": "የዲፕሎማ ማረጋገጫ",
    "heading_diploma_frames": "የዲፕሎማ ፍሬሞች",
    "heading_transcripts": "ግልባጮች",
    "heading_prepare_for_graduation": "ለምረቃ ተዘጋጁ",
    "heading_start_date": "የመጀመሪያ ቀን {{startDate}}",
    "content_diploma_check": "ስምህ በዲፕሎማህ ላይ እንዲታይ በፈለከው መንገድ ይታያል? የፖስታ አድራሻው ትክክል ነው?",
    "content_diploma_frames": "ስኬትህን አሳይ። የዲፕሎማ ፍሬሞችን ያስሱ።",
    "content_transcripts": "የኳንቲክ ግልባጭዎ ኦፊሴላዊ ወይም መደበኛ ያልሆነ ቅጂ ይፈልጋሉ?",
    "button_update_address": "አድራሻ አዘምን",
    "button_browse_frames": "ፍሬሞችን አስስ",
    "button_share": "አጋራ",
    "button_view_details": "ዝርዝሮችን ይመልከቱ",
    "status": "ሁኔታ",
    "start_date": "የመጀመሪያ ቀን",
    "view_full_schedule": "ሙሉ መርሃ ግብሩን ይመልከቱ",
    "schedule_coming_soon": "መርሐግብር በቅርቡ ይመጣል",
    "welcome_to_the": "እንኳን በደህና መጡ",
    "week_0_message": "የፕሮግራሙን መርሃ ግብር ለመመልከት እና ምናልባትም ኮርሶችን ለመጀመር ጥሩ ጊዜ አሁን ነው። በ Slack ላይ መመዝገብዎን አይርሱ!",
    "week_1_message": "የስርዓተ ትምህርት ሁኔታዎን እና ስራዎችዎን ለማየት በየሳምንቱ እዚህ ተመልሰው ይመልከቱ።",
    "not_on_track": "በትራክ ላይ አይደለም።",
    "on_track": "ትራክ ላይ",
    "on_track_finished": "ትራክ ላይ",
    "almost_there": "ሊደርስ ነው!",
    "check_schedule": "የዚህ ሳምንት ስራዎች ከዚህ በታች ያለውን መርሃ ግብር ይመልከቱ።",
    "good_work": "መልካም ስራህን ቀጥይበት!",
    "complete_the_following": "የሚከተለውን {num, plural, =1{ course } other{ ኮርሶች }} ይሙሉ",
    "num_more_courses": "+ {num} ተጨማሪ {num, plural, =1{ ኮርስ } other{ ኮርሶች }}",
    "show_less": "- ያነሰ አሳይ",
    "submit_the_following_projects": "የሚከተሉትን ፕሮጄክቶች ወይም አቀራረቦች ያቅርቡ",
    "submit_to_email": "አስረክብ ለ <a href=\"mailto:{{projectSubmissionEmail}}\">{{projectSubmissionEmail}}</a>",
    "no_project_documents": "ምንም የፕሮጀክት ሰነዶች የሉም።",
    "graduation_date": "የምርቃት ቀን",
    "failed_unfortunately": "እንደ አለመታደል ሆኖ የምረቃ መስፈርቶችን አላሟሉም። ወደ ኮርስ ቤተ-መጽሐፍትዎ መዳረሻን ይቀጥላሉ. ለበለጠ መረጃ በ <a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a> ያግኙን።",
    "num_more_projects": "+ {num} ተጨማሪ {num, plural, =1{ project } other{ ፕሮጀክቶች }}",
    "graduation_show_date": "የምረቃ ቀን {{graduationDate}}",
    "heading_the_program_title": "{{programTitle}}",
    "finished": "ጨርሷል",

    "heading_view_featured_events": "ተለይተው የቀረቡ ክስተቶችን ይመልከቱ",
    "button_view_featured_events": "ተለይተው የቀረቡ ክስተቶችን ይመልከቱ",
    "heading_congrats_you_have_graduated": "<span class=\"subtitle\">እንኳን ደስ አላችሁ!</span> <span>ተመርቀዋል</span>",
    "heading_accepted": "<span class=\"subtitle\">እንኳን ደስ አላችሁ!</span> <span>ተቀባይነት አግኝተዋል</span>",
    "week_0_message_no_slack": "የፕሮግራሙን መርሃ ግብር ለመመልከት እና ምናልባትም ኮርሶችን ለመጀመር ጥሩ ጊዜ አሁን ነው።",
    "submit_project": "ፕሮጀክት አስገባ",
    "content_diploma_check_digital_only": "ስምህ በዲፕሎማህ ላይ እንዲታይ በፈለከው መንገድ ይታያል?",
    "heading_executive_education": "አዲስ! አስፈፃሚ ትምህርት",
    "button_view_programs": "ፕሮግራሞችን ይመልከቱ",
    "heading_digital_certificate_check": "የምስክር ወረቀት ማረጋገጥ",
    "content_digital_certificate_check": "ስምህ በእውቅና ማረጋገጫህ ላይ እንዲታይ በፈለከው መንገድ ይታያል?",
    "program_completion_date": "የፕሮግራሙ ማጠናቀቂያ ቀን",
    "heading_check_your_certificate": "የምስክር ወረቀትዎን ያረጋግጡ",
    "certificate_completion_date": "የምስክር ወረቀት ማጠናቀቂያ ቀን",
    "program_completion_show_date": "የፕሮግራሙ ማጠናቀቂያ ቀን {{graduationDate}}",
    "certificate_show_date": "የምስክር ወረቀት የሚጠናቀቅበት ቀን {{graduationDate}}",
    "you_will_be_notified_when_cert_is_available": "የእርስዎ ዲጂታል ሰርተፍኬት ሲገኝ የኢሜይል ማሳወቂያ ይደርስዎታል። እባክዎን ከዚህ በታች ያሉትን የምስክር ወረቀት ቼክ ዕቃዎችን ማጠናቀቅዎን ያረጋግጡ።",
    "complete_diploma_check": "የምረቃ ቀንዎ እየመጣ ነው! እባኮትን ሁሉንም የፕሮግራም መስፈርቶች እና ከዚህ በታች ያሉትን የዲፕሎማ ቼክ እቃዎች ማጠናቀቅዎን ያረጋግጡ።",
    "call_us": "በ <a ng-click=\"onClick('{{link}}', '{{type}}')\">{{link}}</a> ይደውሉልን/ዋትስአፕ",
    "heading_mobile_identity_verification": "የማንነት ማረጋገጫ",
    "heading_mobile_offline": "<span class=\"text-xs\"><i class=\"fa fa-cloud-slash inline-block me-2\"></i>ከመስመር ውጭ ነዎት",
    "heading_mobile_schedule_interview": "ቃለ መጠይቅዎን ያቅዱ",
    "button_schedule": "መርሐግብር",
    "heading_late_project_submission": "ያለፈው ሳምንት {num, plural, =1{ ፕሮጀክት } other{ ፕሮጀክቶች }}",
    "submit_projects_in_case": "እስካሁን ያላደረጉት ከሆነ፣ እባክዎ የሚከተለውን {num, plural, =1{ ፕሮጀክት ወይም አቀራረብ } other{ ፕሮጀክቶች ወይም አቀራረቦች }} ያስገቡ፡-",
    "academic_probation": "የአካዳሚክ ሙከራ",
    "contact_academic_advising": "የሙከራ ሁኔታዎን ለማፅዳት እቅድ ለመወያየት ወይም ቀጠሮ ለመያዝ <a href=\"mailto:{{academicAdvisingEmail}}\">{{academicAdvisingEmail}}</a> ያግኙ <a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">።</a>",
    "button_msba": "MS የንግድ ትንታኔ",
    "button_msse": "MS ሶፍትዌር ምህንድስና",
    "heading_mobile_conduct_interview": "ቃለ መጠይቅዎን ያካሂዱ",
    "button_conduct_interview": "ቃለ መጠይቅ ማካሄድ",
    "missing_graduation_requirements": "የምረቃ መስፈርቶች ይጎድላሉ",
    "complete_num_specializations": "የምረቃ መስፈርቶችን ለማሟላት እባኮትን {num} ተጨማሪ {num, plural, =1{ ስፔሻላይዜሽን } other{ ስፔሻላይዜሽን }} ይሙሉ።",
    "content_admissions_info": "በ <a ng-click=\"onClick('{{admissionsEmail}}', 'email')\">{{admissionsEmail}}</a> ኢሜይል ይላኩልን ወይም ከቡድናችን አባል ጋር <a ng-click=\"onClick('{{scheduleVideoChatUrl}}', 'schedule_video_chat')\">1፡1 የቪዲዮ ውይይት</a> ቀጠሮ ያዝ።",
    "heading_open_courses": "የእኛ ክፍት ኮርሶች",
    "button_more": "ተጨማሪ",
    "content_open_courses_business_admin": "የቢዝነስ ፋውንዴሽን ቀልድ እና መስተጋብርን ለማስተማር እና ከዚያም መሰረታዊ የንግድ ጽንሰ-ሀሳቦችን ይጠቀማሉ። መሻሻል በሁለቱም የመግቢያ እና የስኮላርሺፕ ውሳኔዎች ውስጥ ይካተታል።",
    "content_open_courses_business_analytics": "የኤምኤስቢኤ ፋውንዴሽን ቀልድ እና መስተጋብርን ለማስተማር እና ከዚያም መሰረታዊ የንግድ ትንተና ፅንሰ-ሀሳቦችን ይጠቀማሉ። መሻሻል በሁለቱም የመግቢያ እና የስኮላርሺፕ ውሳኔዎች ውስጥ ይካተታል።",
    "content_open_courses_software_engineering": "የ MSSE ፋውንዴሽን ቀልድ እና መስተጋብርን ለማስተማር እና ከዚያም የሲሚንቶ መሰረታዊ የሶፍትዌር ምህንድስና ፅንሰ ሀሳቦችን ይጠቀማሉ። መሻሻል በሁለቱም የመግቢያ እና የስኮላርሺፕ ውሳኔዎች ውስጥ ይካተታል።",
    "certificate_extension_notice": "የኮርሱን ስራ ሰፊ መዳረሻ ሰጥተነዋል። የምስክር ወረቀትዎን ለማግኘት መስፈርቶች ከላይ በተጠቀሰው ቀን መሞላት አለባቸው።",
    "certificate_completion_deadline": "የምስክር ወረቀት ማጠናቀቂያ <strong>የመጨረሻ ቀን</strong>",
    "certificate_show_deadline": "የምስክር ወረቀት ማጠናቀቂያ <strong>የመጨረሻ ቀን</strong> : {{completionDeadline}}"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.student_dashboard_boxes = window.locales.ar.lessons.stream.student_dashboard_boxes || {};window.locales.ar.lessons.stream.student_dashboard_boxes = {...window.locales.ar.lessons.stream.student_dashboard_boxes, ...{
    "heading_admissions_info": "سؤال القبول؟",
    "heading_admissions_timeline": "",
    "heading_complete_application": "التقدم بطلب إلى {{institutionName}}",
    "heading_find_friends": "ابحث عن أصدقاء في {{institutionName}}",
    "heading_follow_us": "تابع {{institutionName}}",
    "heading_our_degree_programs": "برامج شهاداتنا",
    "heading_our_tuition_model": "نموذج التعليم لدينا",
    "heading_register_for_program": "",
    "heading_not_joining_program_can_reapply": "",
    "heading_not_joining_program_cannot_reapply": "",
    "heading_schedule_your_interview": "",
    "heading_view_upcoming_events": "عرض الأحداث القادمة",
    "content_admissions_timeline": "",
    "content_admitted_students_zoom": "",
    "content_complete_application": "اقترب الموعد النهائي لدورة القبول التالية.",
    "content_find_friends": "تصفح شبكة الخريجين للزملاء والأصدقاء.",
    "content_follow_us": "ابحث عن الطلاب والأخبار والأحداث وقصص الخريجين على صفحتنا على LinkedIn.",
    "content_our_tuition_model": "نحن انتقائيون ، لكن في متناولنا إذا تم قبولنا. تعرف على كيفية توسيع نطاق الوصول إلى أعلى مستويات التعليم من خلال نموذج تعليمي مبتكر.",
    "content_register_for_program": "",
    "content_not_joining_program_can_reapply": "",
    "content_not_joining_program_cannot_reapply": "",
    "content_schedule_your_interview": "",
    "content_view_upcoming_events": "يحضر الطلاب الأحداث الافتراضية والشخصية حول العالم.",
    "button_preview_the_network": "معاينة الشبكة",
    "button_view_students_and_alumni": "عرض الطلاب والخريجين",
    "button_follow_us": "تابعنا",
    "button_the_mba": "ماجستير في إدارة الأعمال",
    "button_the_executive_mba": "ماجستير في إدارة الأعمال التنفيذية",
    "button_learn_more": "يتعلم أكثر",
    "button_complete_application": "التطبيق الكامل",
    "button_view_events": "عرض الأحداث",
    "not_available_while_offline": "غير متاح بلا إنترنت.",
    "close": "قريب",
    "schedule_video_chat": "حدد <a ng-click=\"onClick('{{link}}', '{{type}}')\">موعدًا لدردشة فيديو 1: 1</a>",
    "email": "إرسال بريد إلكتروني <a ng-click=\"onClick('{{link}}', '{{type}}')\">{{link}}</a>",
    "contact_us": "اتصل بنا",
    "chat_with_admissions": "الدردشة مباشرة مع <a ng-click=\"onClick('{{link}}', '{{type}}')\">القبول</a> الآن",
    "quick_answers": "للحصول على إجابات سريعة",
    "more_details": "لمزيد من التفاصيل",
    "heading_admitted_students_zoom_save_the_date": "احفظ التاريخ!",
    "heading_admitted_students_zoom_q_and_a": "الطلاب الوافدون سؤال وجواب",
    "content_admitted_students_zoom_you_are_invited": "أنت مدعو إلى أسئلة وأجوبة Zoom للطلاب الوافدين في {{webinarMonthDay}} . إنه اختياري ، على الرغم من تشجيع جميع الطلاب المقبولين بشدة على الحضور.",
    "content_admitted_students_if_you_missed_the_zoom": "إذا فاتتك Zoom Q & A لجميع الطلاب المقبولين ، فلم يفت الأوان بعد.",
    "watch_recording": "مشاهدة التسجيل",
    "heading_company_funding": "{{featuredCompanyName}} تمويل الرسوم الدراسية",
    "heading_employer_funding": "تمويل صاحب العمل",
    "content_company_funding": "بعض الموظفين مؤهلين للحصول على مزايا التعليم {{featuredCompanyName}}. يمكن لفريق التمويل لدينا تقديم المشورة وتسهيل الاتصال بالموارد البشرية. اتصل بنا على {{fundingEmailAddress}}.",
    "content_employer_funding": "تستثمر بعض الشركات في الموظفين من خلال رعاية رسومهم الدراسية. يمكن لفريق التمويل لدينا تقديم المشورة وتسهيل الاتصال بالموارد البشرية. اتصل بنا على {{fundingEmailAddress}}.",
    "button_contact_us": "اتصل بنا",
    "heading_upload_documents": "تحميل المستندات",
    "content_our_degree_programs": "تعرف على المزيد حول برامج الدرجات العلمية المبتكرة لـ {{institutionName}} التي تعطل التعليم العالي التقليدي.",
    "content_upload_documents": "إذا تم قبولك ، فسنحتاج إلى نسخ رسمية لأعلى درجة لديك. احصل على السبق الآن.",
    "heading_find_company_students": "ابحث عن طلاب {{companyName}}",
    "heading_scholarship_opportunities": "فرص المنح الدراسية",
    "button_view_and_schedule": "عرض وجدول",
    "heading_upcoming_events": "الأحداث القادمة",
    "heading_slack": "هذا الأسبوع في سلاك",
    "heading_recent_courses": "الدورات الأخيرة",
    "button_upcoming_events": "{{hasMoreEvents ? 'عرض كل الأحداث' :'عرض المزيد من الأحداث' }}",
    "view_event_tooltip_details_unavailable": "تفاصيل الحدث متاحة فقط لطلاب ماجستير إدارة الأعمال وماجستير إدارة الأعمال التنفيذية والخريجين.",
    "heading_enrollment": "أكمل التسجيل الخاص بك",
    "heading_identity_verification": "التحقق من الهوية",
    "button_next_steps": "الخطوات التالية",
    "heading_diploma_check": "التحقق من الدبلومة",
    "heading_diploma_frames": "إطارات الدبلوم",
    "heading_transcripts": "النصوص",
    "heading_prepare_for_graduation": "الاستعداد للتخرج",
    "heading_start_date": "تاريخ البدء: {{startDate}}",
    "content_diploma_check": "هل يظهر اسمك بالطريقة التي تريدها أن يظهر على شهادتك؟ هل العنوان البريدي دقيق؟",
    "content_diploma_frames": "اعرض إنجازك. تصفح إطارات الدبلومات.",
    "content_transcripts": "هل تحتاج إلى نسخة رسمية أو غير رسمية من نص Quantic الخاص بك؟",
    "button_update_name": "تحديث الاسم",
    "button_update_address": "تحديث العنوان",
    "button_browse_frames": "تصفح الإطارات",
    "button_share": "يشارك",
    "button_view_details": "عرض التفاصيل",
    "status": "حالة",
    "start_date": "تاريخ البدء",
    "view_full_schedule": "عرض الجدول الزمني الكامل",
    "schedule_coming_soon": "جدول قريبا",
    "welcome_to_the": "أهلا بك في",
    "week_0_message": "حان الوقت الآن لإلقاء نظرة على جدول البرنامج وربما الحصول على السبق في الدورات. لا تنس التسجيل في Slack!",
    "week_1_message": "تحقق مرة أخرى هنا كل أسبوع لمعرفة حالة منهجك وواجباتك.",
    "not_on_track": "ليس على المسار الصحيح",
    "on_track": "على الطريق",
    "on_track_finished": "على الطريق",
    "almost_there": "اوشكت على الوصول!",
    "check_schedule": "تحقق من الجدول أدناه لتعيينات هذا الأسبوع.",
    "good_work": "ثابر على العمل الجيد!",
    "complete_the_following": "أكمل {num, plural, =1{ course } other{ course }} التالية:",
    "num_more_courses": "+ {عدد} أكثر {num, plural, =1{ course } other{ course }}",
    "show_less": "- عرض أقل",
    "submit_the_following_projects": "أرسل المشاريع أو العروض التقديمية التالية:",
    "submit_to_email": "إرسال إلى: <a href=\"mailto:{{projectSubmissionEmail}}\">{{projectSubmissionEmail}}</a>",
    "no_project_documents": "لا توجد مستندات للمشروع.",
    "graduation_date": "تاريخ التخرج",
    "failed_unfortunately": "لسوء الحظ ، لم تستوفِ متطلبات التخرج. ستحتفظ بحق الوصول إلى مكتبة المقرر الدراسي الخاصة بك. لمزيد من المعلومات ، اتصل بنا على <a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a> .",
    "num_more_projects": "+ {عدد} أكثر {num, plural, =1{ project } other{ projects }}",
    "graduation_show_date": "تاريخ التخرج: {{graduationDate}}",
    "heading_the_program_title": "{{programTitle}}",
    "finished": "تم الانتهاء من",
    "heading_view_featured_events": "عرض الأحداث المميزة",
    "button_view_featured_events": "عرض الأحداث المميزة",
    "heading_congrats_you_have_graduated": "<span class=\"subtitle\">تهانينا!</span> <span>لقد تخرجت</span>",
    "heading_accepted": "<span class=\"subtitle\">تهانينا!</span> <span>لقد تم قبولك</span>",
    "week_0_message_no_slack": "حان الوقت الآن لإلقاء نظرة على جدول البرنامج وربما الحصول على السبق في الدورات.",
    "submit_project": "إرسال المشروع",
    "content_diploma_check_digital_only": "هل يظهر اسمك بالطريقة التي تريد أن يظهر بها في شهادتك؟",
    "heading_executive_education": "جديد! التعليم التنفيذي",
    "button_view_programs": "عرض البرامج",
    "heading_digital_certificate_check": "فحص الشهادة",
    "content_digital_certificate_check": "هل يظهر اسمك بالطريقة التي تريد أن يظهر بها على شهادتك؟",
    "program_completion_date": "تاريخ انتهاء البرنامج",
    "heading_check_your_certificate": "تحقق من شهادتك",
    "certificate_completion_date": "تاريخ انتهاء الشهادة",
    "program_completion_show_date": "تاريخ انتهاء البرنامج: {{graduationDate}}",
    "certificate_show_date": "تاريخ إكمال الشهادة: {{graduationDate}}",
    "you_will_be_notified_when_cert_is_available": "ستتلقى إشعارًا بالبريد الإلكتروني عندما تكون شهادتك الرقمية متاحة. يرجى التأكد من إكمال عناصر التحقق من الشهادة أدناه.",
    "complete_diploma_check": "موعد تخرجك يقترب! يرجى التأكد من إكمال جميع متطلبات البرنامج بالإضافة إلى عناصر التحقق من الدبلوم أدناه.",
    "call_us": "اتصل بنا/واتساب على <a ng-click=\"onClick('{{link}}', '{{type}}')\">{{link}}</a>",
    "heading_mobile_identity_verification": "التحقق من الهوية",
    "heading_mobile_offline": "<span class=\"text-xs\"><i class=\"fa fa-cloud-slash inline-block me-2\"></i>انت غير متصل",
    "heading_mobile_schedule_interview": "جدولة المقابلة الخاصة بك",
    "button_schedule": "جدول",
    "heading_late_project_submission": "{num, plural, =1{ مشروع } other{ المشاريع }} للأسبوع الماضي",
    "submit_projects_in_case": "إذا لم تكن قد قمت بذلك بالفعل، فيرجى إرسال ما يلي {num, plural, =1{ مشروع أو عرض تقديمي } other{ المشاريع أو العروض التقديمية }} :",
    "academic_probation": "الإنذار الأكاديمي",
    "contact_academic_advising": "اتصل بـ <a href=\"mailto:{{academicAdvisingEmail}}\">{{academicAdvisingEmail}}</a> لمناقشة خطة لمسح حالة الاختبار الخاصة بك أو تحديد موعد <a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">هنا</a> .",
    "button_msba": "تحليلات الأعمال ماجستير",
    "button_msse": "هندسة البرمجيات ماجستير",
    "heading_mobile_conduct_interview": "قم بإجراء المقابلة الخاصة بك",
    "button_conduct_interview": "إجراء المقابلة",
    "missing_graduation_requirements": "متطلبات التخرج مفقودة",
    "complete_num_specializations": "يرجى إكمال {num} المزيد من {num, plural, =1{ التخصص } other{ التخصصات }} لتلبية متطلبات التخرج.",
    "content_admissions_info": "أرسل لنا بريدًا إلكترونيًا على <a ng-click=\"onClick('{{admissionsEmail}}', 'email')\">{{admissionsEmail}}</a> أو قم بجدولة <a ng-click=\"onClick('{{scheduleVideoChatUrl}}', 'schedule_video_chat')\">محادثة فيديو فردية</a> مع أحد أعضاء فريقنا.",
    "heading_open_courses": "دوراتنا المفتوحة",
    "button_more": "أكثر",
    "content_open_courses_business_admin": "تستخدم أساسيات الأعمال الفكاهة والتفاعلية لتدريس المفاهيم الأساسية للأعمال ثم ترسيخها. يتم أخذ التقدم في الاعتبار عند اتخاذ قرارات القبول والمنح الدراسية.",
    "content_open_courses_business_analytics": "تستخدم مؤسسات MSBA الفكاهة والتفاعلية لتدريس مفاهيم تحليل الأعمال الأساسية ثم ترسيخها. يتم أخذ التقدم في الاعتبار عند اتخاذ قرارات القبول والمنح الدراسية.",
    "content_open_courses_software_engineering": "تستخدم أساسيات MSSE الفكاهة والتفاعلية لتدريس مفاهيم هندسة البرمجيات الأساسية ثم ترسيخها. يتم أخذ التقدم في الاعتبار عند اتخاذ قرارات القبول والمنح الدراسية.",
    "certificate_extension_notice": "لقد منحك حق الوصول الموسع إلى المقرر الدراسي. يجب استكمال المتطلبات بحلول التاريخ المذكور أعلاه للحصول على شهادتك.",
    "certificate_completion_deadline": "<strong>الموعد النهائي</strong> لإتمام الشهادة",
    "certificate_show_deadline": "<strong>الموعد النهائي</strong> لاستكمال الشهادة: {{completionDeadline}}"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.student_dashboard_boxes = window.locales.en.lessons.stream.student_dashboard_boxes || {};window.locales.en.lessons.stream.student_dashboard_boxes = {...window.locales.en.lessons.stream.student_dashboard_boxes, ...{
               
    "heading_admissions_info": "Admissions Question?",
    "heading_admissions_timeline": "",
    "heading_admitted_students_zoom_save_the_date": "Save the Date!",
    "heading_admitted_students_zoom_q_and_a": "Incoming Students Q&A",
    "heading_complete_application": "Apply to {{institutionName}}",
    "heading_company_funding": "{{featuredCompanyName}} Tuition Funding",
    "heading_employer_funding": "Employer Tuition Funding",
    "heading_find_friends": "Find Friends in {{institutionName}}",
    "heading_find_company_students": "Find {{companyName}} Students",
    "heading_follow_us": "Follow {{institutionName}}",
    "heading_our_degree_programs": "Our Degree Programs",
    "heading_our_tuition_model": "Our Tuition Model",
    "heading_register_for_program": "",
    "heading_not_joining_program_can_reapply": "",
    "heading_not_joining_program_cannot_reapply": "",
    "heading_schedule_your_interview": "",
    "heading_scholarship_opportunities": "Scholarship Opportunities",
    "heading_upload_documents": "Upload Documents",
    "heading_view_upcoming_events": "View Upcoming Events",
    "heading_view_featured_events": "View Featured Events",
    "heading_upcoming_events": "Upcoming Events",
    "heading_slack": "This Week In Slack",
    "heading_recent_courses": "Recent Courses",
    "heading_enrollment": "Complete Your Enrollment",
    "heading_identity_verification": "Identity Verification",
    "heading_diploma_check": "Diploma Check",
    "heading_digital_certificate_check": "Certificate Check",
    "heading_diploma_frames": "Diploma Frames",
    "heading_transcripts": "Transcripts",
    "heading_prepare_for_graduation": "Prepare for Graduation",
    "heading_check_your_certificate": "Check your Certificate",
    "heading_congrats_you_have_graduated": "<span class=\"subtitle\">Congratulations!</span> <span>You have Graduated</span>",
    "heading_the_program_title": "The {{programTitle}}",
    "heading_start_date": "Start Date: {{startDate}}",
    "heading_accepted": "<span class=\"subtitle\">Congratulations!</span> <span>You've Been Accepted</span>",
    "heading_executive_education": "New! Executive Education",
    "heading_mobile_identity_verification": "Identity Verification",
    "heading_mobile_offline": "<span class=\"text-xs\"><i class=\"fa fa-cloud-slash inline-block me-2\"></i>You Are Offline</span>",
    "heading_mobile_schedule_interview": "Schedule Your Interview",
    "heading_mobile_conduct_interview": "Conduct Your Interview",
    "heading_late_project_submission": "Last Week's {num, plural, =1{Project} other{Projects}}",
    "heading_open_courses": "Our Open Courses",

                   
    "content_admissions_info": "Email us at <a ng-click=\"onClick('{{admissionsEmail}}', 'email')\">{{admissionsEmail}}</a> or schedule a <a ng-click=\"onClick('{{scheduleVideoChatUrl}}', 'schedule_video_chat')\">1:1 video chat</a> with a member of our team.",
    "content_admissions_timeline": "",
    "content_admitted_students_zoom": "",
    "content_complete_application": "The deadline for the next admission cycle is approaching.",
    "content_company_funding": "Some employees are eligible for {{featuredCompanyName}} tuition benefits. Our funding team can advise and facilitate contact with HR. Reach us at {{fundingEmailAddress}}.",
    "content_employer_funding": "Some companies invest in employees by sponsoring their tuition. Our funding team can advise and facilitate contact with HR. Reach us at {{fundingEmailAddress}}.",
    "content_find_friends": "Browse the alumni network for colleagues and friends.",
    "content_follow_us": "Find students, news, events, and alumni stories on our LinkedIn page.",
    "content_our_degree_programs": "Learn more about {{institutionName}}'s innovative degree programs disrupting traditional higher education.",
    "content_our_tuition_model": "We're selective, but affordable if admitted. Learn how we're broadening access to top tier education with an innovative tuition model.",
    "content_register_for_program": "",
    "content_not_joining_program_can_reapply": "",
    "content_not_joining_program_cannot_reapply": "",
    "content_schedule_your_interview": "",
    "content_upload_documents": "If admitted, we’ll need official transcripts for your highest degree. Get a head start now.",
    "content_view_upcoming_events": "Students attend virtual and in-person events around the world.",
    "content_admitted_students_zoom_you_are_invited": "You're invited to an Incoming Students Zoom Q&A on {{webinarMonthDay}}. It is optional, though all admitted students are strongly encouraged to attend.",
    "content_admitted_students_if_you_missed_the_zoom": "If you missed the Zoom Q&A for all admitted students, it's not too late.",
    "content_diploma_check": "Does your name appear the way you'd like it to be shown on your diploma? Is the mailing address accurate?",
    "content_diploma_check_digital_only": "Does your name appear the way you'd like it to be shown on your diploma?",
    "content_digital_certificate_check": "Does your name appear the way you'd like it to be shown on your certificate?",
    "content_diploma_frames": "Showcase your achievement. Browse diploma frames.",
    "content_transcripts": "Do you need an official or unofficial copy of your Quantic transcript?",
    "content_open_courses_business_admin": "Business Foundations use humor and interactivity to teach and then cement foundational business concepts. Progress is factored into both admission and scholarship decisions.",
    "content_open_courses_business_analytics": "MSBA Foundations use humor and interactivity to teach and then cement foundational business analytics concepts. Progress is factored into both admission and scholarship decisions.",
    "content_open_courses_software_engineering": "MSSE Foundations use humor and interactivity to teach and then cement foundational software engineering concepts. Progress is factored into both admission and scholarship decisions.",

              
    "button_preview_the_network": "Preview the Network",
    "button_view_students_and_alumni": "View Students and Alumni",
    "button_follow_us": "Follow Us",
    "button_the_mba": "The MBA",
    "button_the_executive_mba": "The Executive MBA",
    "button_msba": "MS Business Analytics",
    "button_msse": "MS Software Engineering",
    "button_learn_more": "Learn More",
    "button_complete_application": "Complete Application",
    "button_view_events": "View Events",
    "button_view_featured_events": "View Featured Events",
    "button_view_and_schedule": "View and Schedule",
    "watch_recording": "Watch Recording",
    "button_contact_us": "Contact Us",
    "button_upcoming_events": "{{hasMoreEvents ? 'View More Events' : 'View All Events' }}",
    "button_next_steps": "Next Steps",
    "button_update_name": "Update Name",
    "button_update_address": "Update Address",
    "button_browse_frames": "Browse Frames",
    "button_share": "Share",
    "button_view_details": "View Details",
    "button_view_programs": "View Programs",
    "button_schedule": "Schedule",
    "button_conduct_interview": "Conduct Interview",
    "button_more": "More",

           
    "not_available_while_offline": "Not available while offline.",
    "close": "Close",
    "view_event_tooltip_details_unavailable": "Event details are only available to MBA and Executive MBA students and alumni.",

                          
    "schedule_video_chat": "Schedule a <a ng-click=\"onClick('{{link}}', '{{type}}')\">1:1 video chat</a>",
    "email": "Email <a ng-click=\"onClick('{{link}}', '{{type}}')\">{{link}}</a>",
    "call_us": "Call/WhatsApp us at <a ng-click=\"onClick('{{link}}', '{{type}}')\">{{link}}</a>",
    "contact_us": "Contact Us",
    "chat_with_admissions": "Chat live with <a ng-click=\"onClick('{{link}}', '{{type}}')\">Admissions</a> now",
    "quick_answers": "For Quick Answers",
    "more_details": "For More Details",

                          
    "status": "Status",
    "start_date": "Start Date",
    "view_full_schedule": "View Full Schedule",
    "schedule_coming_soon": "Schedule Coming Soon",
    "welcome_to_the": "Welcome to the",
    "week_0_message": "Now’s a good time to take a look at the program schedule and perhaps get a head start on courses. Don’t forget to register on Slack!",
    "week_0_message_no_slack": "Now’s a good time to take a look at the program schedule and perhaps get a head start on courses.",
    "week_1_message": "Check back here each week to see your curriculum status and assignments.",
    "not_on_track": "Not on Track",
    "on_track": "On Track",
    "on_track_finished": "On Track",
    "almost_there": "Almost There!",
    "missing_graduation_requirements": "Missing Graduation Requirements",
    "academic_probation": "Academic Probation",
    "check_schedule": "Check the schedule below for this week’s assignments.",
    "good_work": "Keep up the good work!",
    "complete_the_following": "Complete the following {num, plural, =1{course} other{courses}}:",
    "num_more_courses": "+ {num} more {num, plural, =1{course} other{courses}}",
    "show_less": "- show less",
    "submit_the_following_projects": "Submit the following projects or presentations:",
    "submit_to_email": "Submit to: <a href=\"mailto:{{projectSubmissionEmail}}\">{{projectSubmissionEmail}}</a>",
    "submit_project": "Submit Project",
    "no_project_documents": "No project documents.",
    "graduation_date": "Graduation Date",
    "finished": "Finished",
    "program_completion_date": "Program Completion Date",
    "certificate_completion_date": "Certificate Completion Date",
    "certificate_completion_deadline": "Certificate Completion <strong>Deadline</strong>",
    "certificate_extension_notice": "We have granted you extended access to the coursework. Requirements must be completed by the above date in order to earn your certificate.",
    "complete_diploma_check": "Your graduation date is coming up! Please be sure you have completed all program requirements as well as the Diploma Check items below.",
    "you_will_be_notified_when_cert_is_available": "You will receive an email notification when your digital certificate is available. Please be sure to complete the Certificate Check items below.",
    "failed_unfortunately": "Unfortunately, you did not meet the graduation requirements. You will retain access to your course library. For more information, contact us at <a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a>.",
    "num_more_projects": "+ {num} more {num, plural, =1{project} other{projects}}",
    "graduation_show_date": "Graduation Date: {{graduationDate}}",
    "program_completion_show_date": "Program Completion Date: {{graduationDate}}",
    "certificate_show_date": "Certificate Completion Date: {{graduationDate}}",
    "certificate_show_deadline": "Certificate Completion <strong>Deadline</strong>: {{completionDeadline}}",
    "submit_projects_in_case": "In case you haven't already, please submit the following {num, plural, =1{project or presentation} other{projects or presentations}}:",
    "contact_academic_advising": "Contact <a href=\"mailto:{{academicAdvisingEmail}}\">{{academicAdvisingEmail}}</a> to discuss a plan to clear your probationary status or schedule an appointment <a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">here</a>.",
    "complete_num_specializations": "Please complete {num} more {num, plural, =1{specialization} other{specializations}} to meet graduation requirements."
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.student_dashboard_boxes = window.locales.es.lessons.stream.student_dashboard_boxes || {};window.locales.es.lessons.stream.student_dashboard_boxes = {...window.locales.es.lessons.stream.student_dashboard_boxes, ...{
    "heading_admissions_info": "Pregunta de admisiones?",
    "heading_admissions_timeline": "",
    "heading_complete_application": "Aplicar a {{institutionName}}",
    "heading_find_friends": "Encuentra amigos en {{institutionName}}",
    "heading_follow_us": "Seguir {{institutionName}}",
    "heading_our_degree_programs": "Nuestros programas de grado",
    "heading_our_tuition_model": "Nuestro modelo de matrícula",
    "heading_register_for_program": "",
    "heading_not_joining_program_can_reapply": "",
    "heading_not_joining_program_cannot_reapply": "",
    "heading_schedule_your_interview": "",
    "heading_view_upcoming_events": "Ver próximos eventos",
    "content_admissions_timeline": "",
    "content_admitted_students_zoom": "",
    "content_complete_application": "Se acerca la fecha límite para el próximo ciclo de admisión.",
    "content_find_friends": "Explore la red de ex alumnos en busca de colegas y amigos.",
    "content_follow_us": "Encuentre historias de estudiantes, noticias, eventos y exalumnos en nuestra página de LinkedIn.",
    "content_our_tuition_model": "Somos selectivos, pero asequibles si son admitidos. Descubra cómo estamos ampliando el acceso a la educación de primer nivel con un modelo de matrícula innovador.",
    "content_register_for_program": "",
    "content_not_joining_program_can_reapply": "",
    "content_not_joining_program_cannot_reapply": "",
    "content_schedule_your_interview": "",
    "content_view_upcoming_events": "Los estudiantes asisten a eventos virtuales y presenciales en todo el mundo.",
    "button_preview_the_network": "Vista previa de la red",
    "button_view_students_and_alumni": "Ver alumnos y ex alumnos",
    "button_follow_us": "Síguenos",
    "button_the_mba": "el mba",
    "button_the_executive_mba": "El MBA Ejecutivo",
    "button_learn_more": "Aprende más",
    "button_complete_application": "Solicitud completa",
    "button_view_events": "Ver eventos",
    "not_available_while_offline": "No disponible sin conexión.",
    "close": "Cerrar",
    "schedule_video_chat": "Programe un <a ng-click=\"onClick('{{link}}', '{{type}}')\">chat de video 1: 1</a>",
    "email": "Correo electrónico <a ng-click=\"onClick('{{link}}', '{{type}}')\">{{link}}</a>",
    "contact_us": "Contáctenos",
    "chat_with_admissions": "Chatea en vivo con <a ng-click=\"onClick('{{link}}', '{{type}}')\">Admisiones</a> ahora",
    "quick_answers": "Para respuestas rápidas",
    "more_details": "Para más detalles",
    "heading_admitted_students_zoom_save_the_date": "¡Reserva!",
    "heading_admitted_students_zoom_q_and_a": "Preguntas y respuestas para estudiantes entrantes",
    "content_admitted_students_zoom_you_are_invited": "Está invitado a una sesión de preguntas y respuestas de Zoom para estudiantes entrantes el {{webinarMonthDay}} . Es opcional, aunque se recomienda encarecidamente a todos los estudiantes admitidos que asistan.",
    "content_admitted_students_if_you_missed_the_zoom": "Si se perdió las preguntas y respuestas de Zoom para todos los estudiantes admitidos, no es demasiado tarde.",
    "watch_recording": "Ver grabación",
    "heading_company_funding": "{{featuredCompanyName}} Financiación de la matrícula",
    "heading_employer_funding": "Financiamiento de la matrícula del empleador",
    "content_company_funding": "Algunos empleados son elegibles para los beneficios de matrícula de {{featuredCompanyName}}. Nuestro equipo de financiación puede asesorar y facilitar el contacto con RRHH. Contáctenos en {{fundingEmailAddress}}.",
    "content_employer_funding": "Algunas empresas invierten en los empleados patrocinando su matrícula. Nuestro equipo de financiación puede asesorar y facilitar el contacto con RRHH. Contáctenos en {{fundingEmailAddress}}.",
    "button_contact_us": "Contáctenos",
    "heading_upload_documents": "subir documentos",
    "content_our_degree_programs": "Obtén más información sobre los innovadores programas de grado de {{institutionName}} que revolucionan la educación superior tradicional.",
    "content_upload_documents": "Si es admitido, necesitaremos las transcripciones oficiales de su título más alto. Obtenga una ventaja ahora.",
    "heading_find_company_students": "Buscar estudiantes {{companyName}}",
    "heading_scholarship_opportunities": "Oportunidades de becas",
    "button_view_and_schedule": "Ver y programar",
    "heading_upcoming_events": "Próximos Eventos",
    "heading_slack": "Esta semana en holgura",
    "heading_recent_courses": "Cursos Recientes",
    "button_upcoming_events": "{{hasMoreEvents ? 'Ver más eventos' : 'Ver todos los eventos' }}",
    "view_event_tooltip_details_unavailable": "Los detalles del evento solo están disponibles para estudiantes y ex alumnos de MBA y Executive MBA.",
    "heading_enrollment": "Complete su inscripción",
    "heading_identity_verification": "Verificación de identidad",
    "button_next_steps": "Próximos pasos",
    "heading_diploma_check": "Verificación de diploma",
    "heading_diploma_frames": "marcos de diploma",
    "heading_transcripts": "Transcripciones",
    "heading_prepare_for_graduation": "Prepárate para la graduación",
    "heading_start_date": "Fecha de inicio: {{startDate}}",
    "content_diploma_check": "¿Aparece su nombre de la forma en que le gustaría que se muestre en su diploma? ¿Es precisa la dirección postal?",
    "content_diploma_frames": "Muestre su logro. Explore los marcos de los diplomas.",
    "content_transcripts": "¿Necesita una copia oficial o no oficial de su transcripción de Quantic?",
    "button_update_name": "Actualizar nombre",
    "button_update_address": "Actualizar dirección",
    "button_browse_frames": "Examinar marcos",
    "button_share": "Compartir",
    "button_view_details": "Ver detalles",
    "status": "Estado",
    "start_date": "Fecha de inicio",
    "view_full_schedule": "Ver horario completo",
    "schedule_coming_soon": "Horario próximamente",
    "welcome_to_the": "Bienvenida a la",
    "week_0_message": "Ahora es un buen momento para echar un vistazo al cronograma del programa y tal vez obtener una ventaja inicial en los cursos. ¡No olvides registrarte en Slack!",
    "week_1_message": "Vuelve aquí cada semana para ver el estado de tu plan de estudios y las tareas.",
    "not_on_track": "No en camino",
    "on_track": "A tiempo",
    "on_track_finished": "A tiempo",
    "almost_there": "¡Casi llegamos!",
    "check_schedule": "Consulte el cronograma a continuación para las tareas de esta semana.",
    "good_work": "¡Sigan con el buen trabajo!",
    "complete_the_following": "Completa los siguientes {num, plural, =1{ curso } other{ cursos }} :",
    "num_more_courses": "+ {num} más {num, plural, =1{ curso } other{ cursos }}",
    "show_less": "- Muestra menos",
    "submit_the_following_projects": "Presentar los siguientes proyectos o presentaciones:",
    "submit_to_email": "Enviar a: <a href=\"mailto:{{projectSubmissionEmail}}\">{{projectSubmissionEmail}}</a>",
    "no_project_documents": "Sin documentos de proyecto.",
    "graduation_date": "Fecha de Graduación",
    "failed_unfortunately": "Desafortunadamente, no cumplió con los requisitos de graduación. Conservará el acceso a su biblioteca de cursos. Para obtener más información, contáctenos en <a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a> .",
    "num_more_projects": "+ {num} más {num, plural, =1{ proyecto } other{ proyectos }}",
    "graduation_show_date": "Fecha de graduación: {{graduationDate}}",
    "heading_the_program_title": "El {{programTitle}}",
    "finished": "Finalizado",
    "heading_view_featured_events": "Ver eventos destacados",
    "button_view_featured_events": "Ver eventos destacados",
    "heading_congrats_you_have_graduated": "<span class=\"subtitle\">¡Felicidades!</span> <span>Te has graduado</span>",
    "heading_accepted": "<span class=\"subtitle\">¡Felicidades!</span> <span>Has sido aceptado</span>",
    "week_0_message_no_slack": "Ahora es un buen momento para echar un vistazo al cronograma del programa y tal vez obtener una ventaja inicial en los cursos.",
    "submit_project": "Enviar proyecto",
    "content_diploma_check_digital_only": "¿Su nombre aparece como le gustaría que apareciera en su diploma?",
    "heading_executive_education": "¡Nuevo! Educación ejecutiva",
    "button_view_programs": "Ver programas",
    "heading_digital_certificate_check": "Verificación de certificado",
    "content_digital_certificate_check": "¿Su nombre aparece como le gustaría que apareciera en su certificado?",
    "program_completion_date": "Fecha de finalización del programa",
    "heading_check_your_certificate": "Consulta tu certificado",
    "certificate_completion_date": "Fecha de finalización del certificado",
    "program_completion_show_date": "Fecha de finalización del programa: {{graduationDate}}",
    "certificate_show_date": "Fecha de finalización del certificado: {{graduationDate}}",
    "you_will_be_notified_when_cert_is_available": "Recibirás una notificación por correo electrónico cuando tu certificado digital esté disponible. Asegúrese de completar los elementos de verificación del certificado a continuación.",
    "complete_diploma_check": "¡Se acerca tu fecha de graduación! Asegúrese de haber completado todos los requisitos del programa, así como los elementos de verificación del diploma a continuación.",
    "call_us": "Llámanos/WhatsApp al <a ng-click=\"onClick('{{link}}', '{{type}}')\">{{link}}</a>",
    "heading_mobile_identity_verification": "Verificación de identidad",
    "heading_mobile_offline": "<span class=\"text-xs\"><i class=\"fa fa-cloud-slash inline-block me-2\"></i>Estas desconectado",
    "heading_mobile_schedule_interview": "Programe su entrevista",
    "button_schedule": "Cronograma",
    "heading_late_project_submission": "{num, plural, =1{ Proyecto } other{ Proyectos }} de la semana pasada",
    "submit_projects_in_case": "En caso de que aún no lo haya hecho, envíe lo siguiente {num, plural, =1{ proyecto o presentación } other{ proyectos o presentaciones }} :",
    "academic_probation": "Período académico de prueba",
    "contact_academic_advising": "Comuníquese con <a href=\"mailto:{{academicAdvisingEmail}}\">{{academicAdvisingEmail}}</a> para discutir un plan para borrar su estado de prueba o programar una cita <a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">aquí</a> .",
    "button_msba": "Análisis empresarial de MS",
    "button_msse": "Maestría en Ingeniería de Software",
    "heading_mobile_conduct_interview": "Realice su entrevista",
    "button_conduct_interview": "Realizar la entrevista",
    "missing_graduation_requirements": "Requisitos de graduación faltantes",
    "complete_num_specializations": "Complete {num} más {num, plural, =1{ especialización } other{ especializaciones }} para cumplir con los requisitos de graduación.",
    "content_admissions_info": "Envíenos un correo electrónico a <a ng-click=\"onClick('{{admissionsEmail}}', 'email')\">{{admissionsEmail}}</a> o programe un <a ng-click=\"onClick('{{scheduleVideoChatUrl}}', 'schedule_video_chat')\">chat de video individual</a> con un miembro de nuestro equipo.",
    "heading_open_courses": "Nuestros cursos abiertos",
    "button_more": "Más",
    "content_open_courses_business_admin": "Los fundamentos empresariales utilizan el humor y la interactividad para enseñar y luego consolidar conceptos empresariales fundamentales. El progreso se tiene en cuenta tanto en las decisiones de admisión como en las de becas.",
    "content_open_courses_business_analytics": "Los fundamentos de la MSBA utilizan el humor y la interactividad para enseñar y luego consolidar los conceptos básicos de análisis empresarial. El progreso se tiene en cuenta tanto en las decisiones de admisión como en las de becas.",
    "content_open_courses_software_engineering": "Los fundamentos de MSSE utilizan el humor y la interactividad para enseñar y luego consolidar los conceptos básicos de ingeniería de software. El progreso se tiene en cuenta tanto en las decisiones de admisión como en las de becas.",
    "certificate_extension_notice": "Le hemos otorgado acceso extendido al curso. Los requisitos deben completarse antes de la fecha indicada para obtener su certificado.",
    "certificate_completion_deadline": "<strong>Fecha límite</strong> para la finalización del certificado",
    "certificate_show_deadline": "<strong>Fecha límite</strong> para completar el certificado: {{completionDeadline}}"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.student_dashboard_boxes = window.locales.it.lessons.stream.student_dashboard_boxes || {};window.locales.it.lessons.stream.student_dashboard_boxes = {...window.locales.it.lessons.stream.student_dashboard_boxes, ...{
    "heading_admissions_info": "Domanda di ammissione?",
    "heading_admissions_timeline": "",
    "heading_complete_application": "Candidati a {{institutionName}}",
    "heading_find_friends": "Trova amici in {{institutionName}}",
    "heading_follow_us": "Segui {{institutionName}}",
    "heading_our_degree_programs": "I nostri Corsi di Laurea",
    "heading_our_tuition_model": "Il nostro modello di insegnamento",
    "heading_register_for_program": "",
    "heading_not_joining_program_can_reapply": "",
    "heading_not_joining_program_cannot_reapply": "",
    "heading_schedule_your_interview": "",
    "heading_view_upcoming_events": "Visualizza i prossimi eventi",
    "content_admissions_timeline": "",
    "content_admitted_students_zoom": "",
    "content_complete_application": "Si avvicina la scadenza del prossimo ciclo di ammissione.",
    "content_find_friends": "Sfoglia la rete di ex studenti per colleghi e amici.",
    "content_follow_us": "Trova studenti, notizie, eventi e storie di ex studenti sulla nostra pagina LinkedIn.",
    "content_our_tuition_model": "Siamo selettivi, ma abbordabili se ammessi. Scopri come stiamo ampliando l'accesso all'istruzione di alto livello con un modello di insegnamento innovativo.",
    "content_register_for_program": "",
    "content_not_joining_program_can_reapply": "",
    "content_not_joining_program_cannot_reapply": "",
    "content_schedule_your_interview": "",
    "content_view_upcoming_events": "Gli studenti partecipano a eventi virtuali e di persona in tutto il mondo.",
    "button_preview_the_network": "Anteprima della rete",
    "button_view_students_and_alumni": "Visualizza Studenti ed ex studenti",
    "button_follow_us": "Seguici",
    "button_the_mba": "L'MBA",
    "button_the_executive_mba": "L'Executive MBA",
    "button_learn_more": "Per saperne di più",
    "button_complete_application": "Applicazione completa",
    "button_view_events": "Visualizza gli eventi",
    "not_available_while_offline": "Non disponibile offline.",
    "close": "Chiudere",
    "schedule_video_chat": "Pianifica una <a ng-click=\"onClick('{{link}}', '{{type}}')\">chat video 1:1</a>",
    "email": "E-mail <a ng-click=\"onClick('{{link}}', '{{type}}')\">{{link}}</a>",
    "contact_us": "Contattaci",
    "chat_with_admissions": "Chatta dal vivo con <a ng-click=\"onClick('{{link}}', '{{type}}')\">Ammissioni</a> ora",
    "quick_answers": "Per risposte rapide",
    "more_details": "Per ulteriori dettagli",
    "heading_admitted_students_zoom_save_the_date": "Salva la data!",
    "heading_admitted_students_zoom_q_and_a": "Domande e risposte degli studenti in arrivo",
    "content_admitted_students_zoom_you_are_invited": "Sei invitato a una domanda e risposta su Zoom degli studenti in arrivo il {{webinarMonthDay}} . È facoltativo, sebbene tutti gli studenti ammessi siano fortemente incoraggiati a partecipare.",
    "content_admitted_students_if_you_missed_the_zoom": "Se ti sei perso le domande e risposte su Zoom per tutti gli studenti ammessi, non è troppo tardi.",
    "watch_recording": "Guarda la registrazione",
    "heading_company_funding": "{{featuredCompanyName}} Finanziamento delle tasse scolastiche",
    "heading_employer_funding": "Finanziamento delle tasse scolastiche del datore di lavoro",
    "content_company_funding": "Alcuni dipendenti hanno diritto ai vantaggi delle tasse scolastiche di {{featuredCompanyName}}. Il nostro team di finanziamento può consigliare e facilitare il contatto con le risorse umane. Contattaci a {{fundingEmailAddress}}.",
    "content_employer_funding": "Alcune aziende investono nei dipendenti sponsorizzando le loro lezioni. Il nostro team di finanziamento può consigliare e facilitare il contatto con le risorse umane. Contattaci a {{fundingEmailAddress}}.",
    "button_contact_us": "Contattaci",
    "heading_upload_documents": "Carica documenti",
    "content_our_degree_programs": "Scopri di più sugli innovativi corsi di laurea di {{institutionName}} che rivoluzionano l'istruzione superiore tradizionale.",
    "content_upload_documents": "Se ammesso, avremo bisogno di trascrizioni ufficiali per il tuo grado più alto. Ottieni un vantaggio ora.",
    "heading_find_company_students": "Trova studenti {{companyName}}",
    "heading_scholarship_opportunities": "Opportunità di borse di studio",
    "button_view_and_schedule": "Visualizza e programma",
    "heading_upcoming_events": "Prossimi eventi",
    "heading_slack": "Questa settimana al rallentatore",
    "heading_recent_courses": "Corsi recenti",
    "button_upcoming_events": "{{hasMoreEvents ? 'Visualizzare più eventi' : 'Visualizza tutti gli eventi'}}",
    "view_event_tooltip_details_unavailable": "I dettagli dell'evento sono disponibili solo per gli studenti e gli ex studenti MBA ed Executive MBA.",
    "heading_enrollment": "Completa la tua iscrizione",
    "heading_identity_verification": "Verifica dell'identità",
    "button_next_steps": "Prossimi passi",
    "heading_diploma_check": "Controllo del diploma",
    "heading_diploma_frames": "Cornici per diploma",
    "heading_transcripts": "Trascrizioni",
    "heading_prepare_for_graduation": "Preparati per la laurea",
    "heading_start_date": "Data di inizio: {{startDate}}",
    "content_diploma_check": "Il tuo nome compare nel modo in cui vorresti fosse riportato sul tuo diploma? L'indirizzo postale è corretto?",
    "content_diploma_frames": "Mostra il tuo risultato. Sfoglia le cornici dei diplomi.",
    "content_transcripts": "Hai bisogno di una copia ufficiale o non ufficiale della tua trascrizione quantica?",
    "button_update_name": "Aggiorna nome",
    "button_update_address": "Aggiorna indirizzo",
    "button_browse_frames": "Sfoglia le cornici",
    "button_share": "Condividere",
    "button_view_details": "Visualizza dettagli",
    "status": "Stato",
    "start_date": "Data d'inizio",
    "view_full_schedule": "Visualizza il programma completo",
    "schedule_coming_soon": "Programma in arrivo",
    "welcome_to_the": "Benvenuto a",
    "week_0_message": "Ora è un buon momento per dare un'occhiata al palinsesto e magari ottenere un vantaggio sui corsi. Non dimenticare di registrarti su Slack!",
    "week_1_message": "Torna qui ogni settimana per vedere lo stato del tuo curriculum e i compiti.",
    "not_on_track": "Non in pista",
    "on_track": "In pista",
    "on_track_finished": "In pista",
    "almost_there": "Quasi lì!",
    "check_schedule": "Controlla il programma qui sotto per gli incarichi di questa settimana.",
    "good_work": "Continua così!",
    "complete_the_following": "Completa quanto segue {num, plural, =1{ corso } other{ corsi }} :",
    "num_more_courses": "+ {num} altri {num, plural, =1{ corso } other{ corsi }}",
    "show_less": "- mostra meno",
    "submit_the_following_projects": "Invia i seguenti progetti o presentazioni:",
    "submit_to_email": "Invia a: <a href=\"mailto:{{projectSubmissionEmail}}\">{{projectSubmissionEmail}}</a>",
    "no_project_documents": "Nessun documento di progetto.",
    "graduation_date": "Data di laurea",
    "failed_unfortunately": "Sfortunatamente, non hai soddisfatto i requisiti di laurea. Conserverai l'accesso alla libreria del tuo corso. Per ulteriori informazioni, contattaci all'indirizzo <a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a> .",
    "num_more_projects": "+ {num} altri {num, plural, =1{ progetto } other{ progetti }}",
    "graduation_show_date": "Data di laurea: {{graduationDate}}",
    "heading_the_program_title": "Il {{programTitle}}",
    "finished": "Finito",
    "heading_view_featured_events": "Visualizza gli eventi in primo piano",
    "button_view_featured_events": "Visualizza gli eventi in primo piano",
    "heading_congrats_you_have_graduated": "<span class=\"subtitle\">Congratulazioni!</span> <span>Ti sei laureato</span>",
    "heading_accepted": "<span class=\"subtitle\">Congratulazioni!</span> <span>Sei stato accettato</span>",
    "week_0_message_no_slack": "Ora è un buon momento per dare un'occhiata al palinsesto e magari ottenere un vantaggio sui corsi.",
    "submit_project": "Invia progetto",
    "content_diploma_check_digital_only": "Il tuo nome appare nel modo in cui vorresti che fosse mostrato sul tuo diploma?",
    "heading_executive_education": "Nuovo! Formazione esecutiva",
    "button_view_programs": "Visualizza programmi",
    "heading_digital_certificate_check": "Controllo del certificato",
    "content_digital_certificate_check": "Il tuo nome appare nel modo in cui vorresti che fosse mostrato sul tuo certificato?",
    "program_completion_date": "Data di completamento del programma",
    "heading_check_your_certificate": "Controlla il tuo certificato",
    "certificate_completion_date": "Data di completamento del certificato",
    "program_completion_show_date": "Data di completamento del programma: {{graduationDate}}",
    "certificate_show_date": "Data di completamento del certificato: {{graduationDate}}",
    "you_will_be_notified_when_cert_is_available": "Riceverai una notifica via email quando il tuo certificato digitale sarà disponibile. Assicurati di completare le voci di verifica del certificato riportate di seguito.",
    "complete_diploma_check": "La data della tua laurea si avvicina! Assicurati di aver completato tutti i requisiti del programma e gli elementi di verifica del diploma riportati di seguito.",
    "call_us": "Chiamaci/WhatsApp al numero <a ng-click=\"onClick('{{link}}', '{{type}}')\">{{link}}</a>",
    "heading_mobile_identity_verification": "Verifica dell'identità",
    "heading_mobile_offline": "<span class=\"text-xs\"><i class=\"fa fa-cloud-slash inline-block me-2\"></i>Sei offline",
    "heading_mobile_schedule_interview": "Pianifica la tua intervista",
    "button_schedule": "Programma",
    "heading_late_project_submission": "{num, plural, =1{ Progetto } other{ Progetti }} della settimana scorsa",
    "submit_projects_in_case": "Se non l'hai già fatto, invia il seguente {num, plural, =1{ progetto o presentazione } other{ progetti o presentazioni }} :",
    "academic_probation": "Prova accademica",
    "contact_academic_advising": "Contatta <a href=\"mailto:{{academicAdvisingEmail}}\">{{academicAdvisingEmail}}</a> per discutere un piano per cancellare il tuo stato di prova o fissare un appuntamento <a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">qui</a> .",
    "button_msba": "Analisi aziendale MS",
    "button_msse": "Ingegneria del software MS",
    "heading_mobile_conduct_interview": "Conduci la tua intervista",
    "button_conduct_interview": "Condurre l'intervista",
    "missing_graduation_requirements": "Requisiti di laurea mancanti",
    "complete_num_specializations": "Completa {num} altre {num, plural, =1{ specializzazione } other{ specializzazioni }} per soddisfare i requisiti di laurea.",
    "content_admissions_info": "Inviaci un'e-mail all'indirizzo <a ng-click=\"onClick('{{admissionsEmail}}', 'email')\">{{admissionsEmail}}</a> o programma una <a ng-click=\"onClick('{{scheduleVideoChatUrl}}', 'schedule_video_chat')\">videochiamata individuale</a> con un membro del nostro team.",
    "heading_open_courses": "I nostri corsi aperti",
    "button_more": "Di più",
    "content_open_courses_business_admin": "Le Business Foundations utilizzano umorismo e interattività per insegnare e poi consolidare concetti aziendali fondamentali. Il progresso è preso in considerazione sia nelle decisioni di ammissione che in quelle di borsa di studio.",
    "content_open_courses_business_analytics": "Le fondazioni MSBA utilizzano umorismo e interattività per insegnare e poi consolidare i concetti fondamentali di business analytics. I progressi vengono presi in considerazione sia nelle decisioni di ammissione che in quelle di borsa di studio.",
    "content_open_courses_software_engineering": "Le fondazioni MSSE utilizzano umorismo e interattività per insegnare e poi consolidare i concetti fondamentali dell'ingegneria del software. I progressi vengono presi in considerazione sia nelle decisioni di ammissione che in quelle di borsa di studio.",
    "certificate_extension_notice": "Ti abbiamo concesso un accesso esteso al corso. I requisiti devono essere completati entro la data sopra indicata per ottenere il tuo certificato.",
    "certificate_completion_deadline": "<strong>Scadenza</strong> per il completamento del certificato",
    "certificate_show_deadline": "<strong>Scadenza</strong> per il completamento del certificato: {{completionDeadline}}"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.student_dashboard_boxes = window.locales.zh.lessons.stream.student_dashboard_boxes || {};window.locales.zh.lessons.stream.student_dashboard_boxes = {...window.locales.zh.lessons.stream.student_dashboard_boxes, ...{
    "heading_admissions_info": "招生问题？",
    "heading_admissions_timeline": "",
    "heading_complete_application": "适用于{{institutionName}}",
    "heading_find_friends": "在{{institutionName}}中寻找朋友",
    "heading_follow_us": "关注{{institutionName}}",
    "heading_our_degree_programs": "我们的学位课程",
    "heading_our_tuition_model": "我们的学费模式",
    "heading_register_for_program": "",
    "heading_not_joining_program_can_reapply": "",
    "heading_not_joining_program_cannot_reapply": "",
    "heading_schedule_your_interview": "",
    "heading_view_upcoming_events": "查看即将举行的活动",
    "content_admissions_timeline": "",
    "content_admitted_students_zoom": "",
    "content_complete_application": "下一个录取周期的截止日期临近。",
    "content_find_friends": "浏览同事和朋友的校友网络。",
    "content_follow_us": "在我们的 LinkedIn 页面上查找学生、新闻、活动和校友故事。",
    "content_our_tuition_model": "我们是有选择性的，但如果被录取的话，我们是负担得起的。了解我们如何通过创新的学费模式扩大接受顶级教育的机会。",
    "content_register_for_program": "",
    "content_not_joining_program_can_reapply": "",
    "content_not_joining_program_cannot_reapply": "",
    "content_schedule_your_interview": "",
    "content_view_upcoming_events": "学生参加世界各地的虚拟和面对面活动。",
    "button_preview_the_network": "预览网络",
    "button_view_students_and_alumni": "查看学生和校友",
    "button_follow_us": "跟着我们",
    "button_the_mba": "工商管理硕士",
    "button_the_executive_mba": "行政工商管理硕士",
    "button_learn_more": "学到更多",
    "button_complete_application": "完成申请",
    "button_view_events": "查看活动",
    "not_available_while_offline": "离线时不可用。",
    "close": "关",
    "schedule_video_chat": "安排<a ng-click=\"onClick('{{link}}', '{{type}}')\">1:1 视频聊天</a>",
    "email": "电子邮件<a ng-click=\"onClick('{{link}}', '{{type}}')\">{{link}}</a>",
    "contact_us": "联系我们",
    "chat_with_admissions": "立即与<a ng-click=\"onClick('{{link}}', '{{type}}')\">招生</a>部实时聊天",
    "quick_answers": "快速解答",
    "more_details": "更多细节",
    "heading_admitted_students_zoom_save_the_date": "保存日期！",
    "heading_admitted_students_zoom_q_and_a": "新生问答",
    "content_admitted_students_zoom_you_are_invited": "您被邀请参加{{webinarMonthDay}}的新生 Zoom Q&A。它是可选的，但强烈鼓励所有被录取的学生参加。",
    "content_admitted_students_if_you_missed_the_zoom": "如果您错过了针对所有录取学生的 Zoom 问答环节，现在还为时不晚。",
    "watch_recording": "观看录音",
    "heading_company_funding": "{{featuredCompanyName}}学费资助",
    "heading_employer_funding": "雇主学费资助",
    "content_company_funding": "一些员工有资格获得{{featuredCompanyName}}的学费福利。我们的资助团队可以提供建议并促进与人力资源部门的联系。通过{{fundingEmailAddress}}联系我们。",
    "content_employer_funding": "一些公司通过赞助他们的学费来投资于员工。我们的资助团队可以提供建议并促进与人力资源部门的联系。通过{{fundingEmailAddress}}联系我们。",
    "button_contact_us": "联系我们",
    "heading_upload_documents": "上传文件",
    "content_our_degree_programs": "详细了解{{institutionName}}颠覆传统高等教育的创新学位课程。",
    "content_upload_documents": "如果被录取，我们将需要您最高学位的官方成绩单。立即开始。",
    "heading_find_company_students": "寻找{{companyName}}学生",
    "heading_scholarship_opportunities": "奖学金机会",
    "button_view_and_schedule": "查看和安排",
    "heading_upcoming_events": "即将举行的活动",
    "this_week_in_slack": "Slack中的本周课程",           
    "heading_recent_courses": "最近的课程",
    "button_upcoming_events": "{{hasMoreEvents ? '查看更多活动' : '查看所有活动' }}",
    "view_event_tooltip_details_unavailable": "活动详情仅适用于 MBA 和 Executive MBA 学生和校友。",
    "heading_enrollment": "完成注册",
    "heading_identity_verification": "身份验证",
    "button_next_steps": "下一步",           
    "button_update_name": "更新名字",           
    "heading_slack": "本周松弛",
    "heading_diploma_check": "文凭检查",
    "heading_diploma_frames": "文凭框架",
    "heading_transcripts": "成绩单",
    "heading_prepare_for_graduation": "准备毕业",
    "heading_start_date": "开始日期： {{startDate}}",
    "content_diploma_check": "您的名字是否按照您希望在文凭上显示的方式出现？邮寄地址是否准确？",
    "content_diploma_frames": "展示你的成就。浏览文凭框架。",
    "content_transcripts": "您需要 Quantic 成绩单的官方或非官方副本吗？",
    "button_update_address": "更新地址",
    "button_browse_frames": "浏览相框",
    "button_share": "分享",
    "button_view_details": "查看详情",
    "status": "地位",
    "start_date": "开始日期",
    "view_full_schedule": "查看完整时间表",
    "schedule_coming_soon": "时间表即将推出",
    "welcome_to_the": "欢迎来到",
    "week_0_message": "现在是查看课程安排的好时机，也许可以抢先开始课程。不要忘记在 Slack 上注册！",
    "week_1_message": "每周回来查看您的课程状态和作业。",
    "not_on_track": "不在正轨",
    "on_track": "步入正轨",
    "on_track_finished": "步入正轨",
    "almost_there": "差不多了！",
    "check_schedule": "查看下面的时间表以了解本周的作业。",
    "good_work": "保持良好的工作！",
    "complete_the_following": "完成以下{num, plural, =1{ course } other{ courses }} ：",
    "num_more_courses": "+ {num} 更多{num, plural, =1{ course } other{ courses }}",
    "show_less": "- 显示较少",
    "submit_the_following_projects": "提交以下项目或演示文稿：",
    "submit_to_email": "提交至： <a href=\"mailto:{{projectSubmissionEmail}}\">{{projectSubmissionEmail}}</a>",
    "no_project_documents": "没有项目文件。",
    "graduation_date": "毕业日期",
    "failed_unfortunately": "不幸的是，你没有达到毕业要求。您将保留对课程库的访问权限。如需更多信息，请通过<a href=\"mailto:{{brandEmail}}\", target=\"_blank\">{{brandEmail}}</a>联系我们。",
    "num_more_projects": "+ {num} 更多{num, plural, =1{ project } other{ projects }}",
    "graduation_show_date": "毕业日期： {{graduationDate}}",
    "heading_the_program_title": "{{programTitle}}",
    "finished": "完成的",
    "heading_view_featured_events": "查看特色活动",
    "button_view_featured_events": "查看特色活动",
    "heading_congrats_you_have_graduated": "<span class=\"subtitle\">恭喜！</span><span>你已经毕业了</span>",
    "heading_accepted": "<span class=\"subtitle\">恭喜！</span> <span>你被录取了</span>",
    "week_0_message_no_slack": "现在是查看课程安排的好时机，也许可以抢先开始课程。",
    "submit_project": "提交项目",
    "content_diploma_check_digital_only": "您的名字是否按照您希望的文凭上的方式显示？",
    "heading_executive_education": "新的！高管教育",
    "button_view_programs": "查看节目",
    "heading_digital_certificate_check": "证书检查",
    "content_digital_certificate_check": "您的姓名是否按照您希望在证书上显示的方式显示？",
    "program_completion_date": "计划完成日期",
    "heading_check_your_certificate": "检查您的证书",
    "certificate_completion_date": "证书完成日期",
    "program_completion_show_date": "课程完成日期： {{graduationDate}}",
    "certificate_show_date": "证书完成日期： {{graduationDate}}",
    "you_will_be_notified_when_cert_is_available": "当您的数字证书可用时，您将收到电子邮件通知。请务必完成以下证书检查项目。",
    "complete_diploma_check": "你的毕业日期快到了！请确保您已完成所有课程要求以及下面的文凭检查项目。",
    "call_us": "请致电/WhatsApp 我们： <a ng-click=\"onClick('{{link}}', '{{type}}')\">{{link}}</a>",
    "heading_mobile_identity_verification": "身份验证",
    "heading_mobile_offline": "<span class=\"text-xs\"><i class=\"fa fa-cloud-slash inline-block me-2\"></i>您离线了",
    "heading_mobile_schedule_interview": "安排面试",
    "button_schedule": "日程",
    "heading_late_project_submission": "上周的{num, plural, =1{项目} other{项目}}",
    "submit_projects_in_case": "如果您还没有提交，请提交以下{num, plural, =1{项目或演示文稿} other{项目或演示文稿}} ：",
    "academic_probation": "学术留校察看",
    "contact_academic_advising": "联系<a href=\"mailto:{{academicAdvisingEmail}}\">{{academicAdvisingEmail}}</a>讨论清除试用期状态的计划或<a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">在此</a>安排预约。",
    "button_msba": "商业分析硕士",
    "button_msse": "软件工程硕士",
    "heading_mobile_conduct_interview": "进行面试",
    "button_conduct_interview": "进行面试",
    "missing_graduation_requirements": "缺少毕业要求",
    "complete_num_specializations": "请完成{num}更多{num, plural, =1{ 专业化 } other{ 专业化 }}以满足毕业要求。",
    "content_admissions_info": "请发送电子邮件至<a ng-click=\"onClick('{{admissionsEmail}}', 'email')\">{{admissionsEmail}}</a>或与我们团队的成员安排<a ng-click=\"onClick('{{scheduleVideoChatUrl}}', 'schedule_video_chat')\">一对一视频聊天</a>。",
    "heading_open_courses": "我们的公开课程",
    "button_more": "更多的",
    "content_open_courses_business_admin": "商业基础课程采用幽默和互动的方式进行教学，然后巩固基础商业概念。课程的进展是录取和奖学金决定的考虑因素。",
    "content_open_courses_business_analytics": "MSBA Foundations 采用幽默和互动的方式进行教学，然后巩固基础商业分析概念。进度是录取和奖学金决定的考虑因素。",
    "content_open_courses_software_engineering": "MSSE Foundations 采用幽默和互动的方式进行教学，然后巩固基础软件工程概念。进步是录取和奖学金决定的考虑因素。",
    "certificate_extension_notice": "我们已授予您延长课程访问权限。您必须在上述日期之前完成要求才能获得证书。",
    "certificate_completion_deadline": "证书完成截止<strong>日期</strong>",
    "certificate_show_deadline": "证书完成截止<strong>日期</strong>： {{completionDeadline}}"
}
};