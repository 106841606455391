window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.lesson = window.locales.am.lessons.lesson || {};window.locales.am.lessons.lesson.frame_list = window.locales.am.lessons.lesson.frame_list || {};window.locales.am.lessons.lesson.frame_list.frame = window.locales.am.lessons.lesson.frame_list.frame || {};window.locales.am.lessons.lesson.frame_list.frame.componentized = window.locales.am.lessons.lesson.frame_list.frame.componentized || {};window.locales.am.lessons.lesson.frame_list.frame.componentized.component = window.locales.am.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.am.lessons.lesson.frame_list.frame.componentized.component.answer_list = window.locales.am.lessons.lesson.frame_list.frame.componentized.component.answer_list || {};window.locales.am.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons = window.locales.am.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons || {};window.locales.am.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons = {...window.locales.am.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons, ...{
    "poll_label_0": "ሀ",           
    "poll_label_1": "ለ",           
    "poll_label_2": "ሐ",           
    "poll_label_3": "መ",           
    "poll_label_4": "ሰ",           
    "poll_label_5": "ረ",           
    "poll_label_6": "ሸ",           
    "poll_label_7": "ቀ",           
    "poll_label_8": "በ",           
    "poll_label_9": "ተ",           
    "poll_label_10": "ቸ",           
    "select_multiple_answers": "ብዙ መልሶችን ይምረጡ",           
    "select_all_answers": "ሁሉንም መልሶች ይምረጡ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.lesson = window.locales.ar.lessons.lesson || {};window.locales.ar.lessons.lesson.frame_list = window.locales.ar.lessons.lesson.frame_list || {};window.locales.ar.lessons.lesson.frame_list.frame = window.locales.ar.lessons.lesson.frame_list.frame || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized = window.locales.ar.lessons.lesson.frame_list.frame.componentized || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized.component = window.locales.ar.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.answer_list = window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.answer_list || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons = window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons = {...window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons, ...{
    "poll_label_0": "A",
    "poll_label_1": "B",
    "poll_label_2": "C",
    "poll_label_3": "D",
    "poll_label_4": "E",
    "poll_label_5": "F",
    "poll_label_6": "G",
    "poll_label_7": "H",
    "poll_label_8": "I",
    "poll_label_9": "J",
    "poll_label_10": "K",
    "select_multiple_answers": "حدد إجابات متعددة",           
    "select_all_answers": "حدد كل الإجابات"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.lesson = window.locales.en.lessons.lesson || {};window.locales.en.lessons.lesson.frame_list = window.locales.en.lessons.lesson.frame_list || {};window.locales.en.lessons.lesson.frame_list.frame = window.locales.en.lessons.lesson.frame_list.frame || {};window.locales.en.lessons.lesson.frame_list.frame.componentized = window.locales.en.lessons.lesson.frame_list.frame.componentized || {};window.locales.en.lessons.lesson.frame_list.frame.componentized.component = window.locales.en.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.en.lessons.lesson.frame_list.frame.componentized.component.answer_list = window.locales.en.lessons.lesson.frame_list.frame.componentized.component.answer_list || {};window.locales.en.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons = window.locales.en.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons || {};window.locales.en.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons = {...window.locales.en.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons, ...{
    "poll_label_0": "A",
    "poll_label_1": "B",
    "poll_label_2": "C",
    "poll_label_3": "D",
    "poll_label_4": "E",
    "poll_label_5": "F",
    "poll_label_6": "G",
    "poll_label_7": "H",
    "poll_label_8": "I",
    "poll_label_9": "J",
    "poll_label_10": "K",
    "select_multiple_answers": "Select multiple answers",
    "select_all_answers": "Select all answers"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.lesson = window.locales.es.lessons.lesson || {};window.locales.es.lessons.lesson.frame_list = window.locales.es.lessons.lesson.frame_list || {};window.locales.es.lessons.lesson.frame_list.frame = window.locales.es.lessons.lesson.frame_list.frame || {};window.locales.es.lessons.lesson.frame_list.frame.componentized = window.locales.es.lessons.lesson.frame_list.frame.componentized || {};window.locales.es.lessons.lesson.frame_list.frame.componentized.component = window.locales.es.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.es.lessons.lesson.frame_list.frame.componentized.component.answer_list = window.locales.es.lessons.lesson.frame_list.frame.componentized.component.answer_list || {};window.locales.es.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons = window.locales.es.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons || {};window.locales.es.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons = {...window.locales.es.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons, ...{
    "poll_label_0": "A",
    "poll_label_1": "B",
    "poll_label_2": "C",
    "poll_label_3": "D",
    "poll_label_4": "E",
    "poll_label_5": "F",
    "poll_label_6": "G",
    "poll_label_7": "H",
    "poll_label_8": "I",
    "poll_label_9": "J",
    "poll_label_10": "K",
    "select_multiple_answers": "Seleccionar múltiples respuestas",           
    "select_all_answers": "Seleccionar todas las respuestas"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.lesson = window.locales.it.lessons.lesson || {};window.locales.it.lessons.lesson.frame_list = window.locales.it.lessons.lesson.frame_list || {};window.locales.it.lessons.lesson.frame_list.frame = window.locales.it.lessons.lesson.frame_list.frame || {};window.locales.it.lessons.lesson.frame_list.frame.componentized = window.locales.it.lessons.lesson.frame_list.frame.componentized || {};window.locales.it.lessons.lesson.frame_list.frame.componentized.component = window.locales.it.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.it.lessons.lesson.frame_list.frame.componentized.component.answer_list = window.locales.it.lessons.lesson.frame_list.frame.componentized.component.answer_list || {};window.locales.it.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons = window.locales.it.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons || {};window.locales.it.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons = {...window.locales.it.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons, ...{
    "poll_label_0": "A",           
    "poll_label_1": "B",           
    "poll_label_2": "C",           
    "poll_label_3": "D",           
    "poll_label_4": "E",           
    "poll_label_5": "F",           
    "poll_label_6": "G",           
    "poll_label_7": "H",           
    "poll_label_8": "I",           
    "poll_label_9": "J",           
    "poll_label_10": "K",           
    "select_multiple_answers": "Seleziona più risposte",           
    "select_all_answers": "Seleziona tutte le risposte"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.lesson = window.locales.zh.lessons.lesson || {};window.locales.zh.lessons.lesson.frame_list = window.locales.zh.lessons.lesson.frame_list || {};window.locales.zh.lessons.lesson.frame_list.frame = window.locales.zh.lessons.lesson.frame_list.frame || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized = window.locales.zh.lessons.lesson.frame_list.frame.componentized || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized.component = window.locales.zh.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.answer_list = window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.answer_list || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons = window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons = {...window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons, ...{
    "poll_label_0": "A",           
    "poll_label_1": "B",           
    "poll_label_2": "C",           
    "poll_label_3": "D",           
    "poll_label_4": "E",           
    "poll_label_5": "F",           
    "poll_label_6": "G",           
    "poll_label_7": "H",           
    "poll_label_8": "I",           
    "poll_label_9": "J",           
    "poll_label_10": "K",           
    "select_multiple_answers": "选择多个答案",           
    "select_all_answers": "选择所有答案"
}
};