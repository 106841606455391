import { RoomContext } from '@livekit/components-react';
import { type AnyObject } from '@Types';
import { memo, useContext, useEffect } from 'react';
import { RoomEvent } from 'livekit-client';
import { isDataMessage, isErrorMessage } from './DataMessage';
import { type OnDataReceivedCallback, type DataMessage } from './Livekit.types';

function parseMessage(rawMessage: Uint8Array): DataMessage | null {
    let messageText: string | undefined;
    try {
        messageText = new TextDecoder().decode(rawMessage); // Convert
        const messageObject = JSON.parse(messageText) as AnyObject;
        if (isDataMessage(messageObject)) {
            return messageObject;
        }
    } catch (e) {
        // FIXME: log error
        console.error(`Error parsing data message: ${messageText || rawMessage}`);
    }

    return null;
}

function onDataReceived(payload: Uint8Array, callback: OnDataReceivedCallback | null) {
    const message = parseMessage(payload);
    if (!message) {
        console.log('Could not parse message ', payload);
        return;
    }

    const result = callback && callback(message);

    // If the callback returns true, then it handled the message, and we
    // shouldn't do anything else
    if (result) return;

    if (isErrorMessage(message)) {
        console.error('Voice Agent error: ', message);
        return;
    }

    console.log('Unknown data message', message);
}

const RoomEventListenerComponent = ({
    onDataReceived: callback,
}: {
    onDataReceived: OnDataReceivedCallback | null;
}) => {
    const room = useContext(RoomContext);

    useEffect(() => {
        if (!room) return undefined;

        room.on(RoomEvent.DataReceived, (payload: Uint8Array) => {
            onDataReceived(payload, callback);
        });

        return () => {
            room?.removeAllListeners();
        };
    }, [room, callback]);

    return null;
};

export const RoomEventListener = memo(RoomEventListenerComponent) as typeof RoomEventListenerComponent;
