window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.student_dashboard_identity_verification = window.locales.am.lessons.stream.student_dashboard_identity_verification || {};window.locales.am.lessons.stream.student_dashboard_identity_verification = {...window.locales.am.lessons.stream.student_dashboard_identity_verification, ...{
    "identity": "ማንነት",           
    "verification": "ማረጋገጫ",           
    "in_order_to_continue": "ለመቀጠል፣ የማንነት ማረጋገጫ ሂደትን ማጠናቀቅ ያስፈልግዎታል።",           
    "verify_identity": "ማንነት ያረጋግጡ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.student_dashboard_identity_verification = window.locales.ar.lessons.stream.student_dashboard_identity_verification || {};window.locales.ar.lessons.stream.student_dashboard_identity_verification = {...window.locales.ar.lessons.stream.student_dashboard_identity_verification, ...{
    "identity": "هوية",
    "verification": "التحقق",
    "in_order_to_continue": "للمتابعة ، ستحتاج إلى إكمال عملية التحقق من الهوية.",
    "verify_identity": "تحقق من الهوية"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.student_dashboard_identity_verification = window.locales.en.lessons.stream.student_dashboard_identity_verification || {};window.locales.en.lessons.stream.student_dashboard_identity_verification = {...window.locales.en.lessons.stream.student_dashboard_identity_verification, ...{
    "identity": "Identity",
    "verification": "verification",
    "in_order_to_continue": "In order to continue, you will need to complete an identity verification process.",
    "verify_identity": "Verify Identity"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.student_dashboard_identity_verification = window.locales.es.lessons.stream.student_dashboard_identity_verification || {};window.locales.es.lessons.stream.student_dashboard_identity_verification = {...window.locales.es.lessons.stream.student_dashboard_identity_verification, ...{
    "identity": "Identidad",
    "verification": "verificación",
    "in_order_to_continue": "Para continuar, deberá completar un proceso de verificación de identidad.",
    "verify_identity": "Verificar identidad"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.student_dashboard_identity_verification = window.locales.it.lessons.stream.student_dashboard_identity_verification || {};window.locales.it.lessons.stream.student_dashboard_identity_verification = {...window.locales.it.lessons.stream.student_dashboard_identity_verification, ...{
    "identity": "Identità",
    "verification": "verifica",
    "in_order_to_continue": "Per continuare, dovrai completare una procedura di verifica dell'identità.",
    "verify_identity": "Verifica identità"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.student_dashboard_identity_verification = window.locales.zh.lessons.stream.student_dashboard_identity_verification || {};window.locales.zh.lessons.stream.student_dashboard_identity_verification = {...window.locales.zh.lessons.stream.student_dashboard_identity_verification, ...{
    "identity": "身份",           
    "verification": "验证",           
    "in_order_to_continue": "请完成身份验证以继续下一步骤。",           
    "verify_identity": "身份验证"           
}
};