window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.lesson = window.locales.am.lessons.lesson || {};window.locales.am.lessons.lesson.frame_list = window.locales.am.lessons.lesson.frame_list || {};window.locales.am.lessons.lesson.frame_list.frame = window.locales.am.lessons.lesson.frame_list.frame || {};window.locales.am.lessons.lesson.frame_list.frame.componentized = window.locales.am.lessons.lesson.frame_list.frame.componentized || {};window.locales.am.lessons.lesson.frame_list.frame.componentized.component = window.locales.am.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.am.lessons.lesson.frame_list.frame.componentized.component.layout = window.locales.am.lessons.lesson.frame_list.frame.componentized.component.layout || {};window.locales.am.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive = window.locales.am.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive || {};window.locales.am.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive = {...window.locales.am.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive, ...{
    "give_me_a_hint": "ፍንጭ ስጠኝ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.lesson = window.locales.ar.lessons.lesson || {};window.locales.ar.lessons.lesson.frame_list = window.locales.ar.lessons.lesson.frame_list || {};window.locales.ar.lessons.lesson.frame_list.frame = window.locales.ar.lessons.lesson.frame_list.frame || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized = window.locales.ar.lessons.lesson.frame_list.frame.componentized || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized.component = window.locales.ar.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.layout = window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.layout || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive = window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive = {...window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive, ...{
    "give_me_a_hint": "اعطني تلميحاً"           
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.lesson = window.locales.en.lessons.lesson || {};window.locales.en.lessons.lesson.frame_list = window.locales.en.lessons.lesson.frame_list || {};window.locales.en.lessons.lesson.frame_list.frame = window.locales.en.lessons.lesson.frame_list.frame || {};window.locales.en.lessons.lesson.frame_list.frame.componentized = window.locales.en.lessons.lesson.frame_list.frame.componentized || {};window.locales.en.lessons.lesson.frame_list.frame.componentized.component = window.locales.en.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.en.lessons.lesson.frame_list.frame.componentized.component.layout = window.locales.en.lessons.lesson.frame_list.frame.componentized.component.layout || {};window.locales.en.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive = window.locales.en.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive || {};window.locales.en.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive = {...window.locales.en.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive, ...{
    "give_me_a_hint": "Give me a hint"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.lesson = window.locales.es.lessons.lesson || {};window.locales.es.lessons.lesson.frame_list = window.locales.es.lessons.lesson.frame_list || {};window.locales.es.lessons.lesson.frame_list.frame = window.locales.es.lessons.lesson.frame_list.frame || {};window.locales.es.lessons.lesson.frame_list.frame.componentized = window.locales.es.lessons.lesson.frame_list.frame.componentized || {};window.locales.es.lessons.lesson.frame_list.frame.componentized.component = window.locales.es.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.es.lessons.lesson.frame_list.frame.componentized.component.layout = window.locales.es.lessons.lesson.frame_list.frame.componentized.component.layout || {};window.locales.es.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive = window.locales.es.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive || {};window.locales.es.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive = {...window.locales.es.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive, ...{
    "give_me_a_hint": "Dame una pista"           
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.lesson = window.locales.it.lessons.lesson || {};window.locales.it.lessons.lesson.frame_list = window.locales.it.lessons.lesson.frame_list || {};window.locales.it.lessons.lesson.frame_list.frame = window.locales.it.lessons.lesson.frame_list.frame || {};window.locales.it.lessons.lesson.frame_list.frame.componentized = window.locales.it.lessons.lesson.frame_list.frame.componentized || {};window.locales.it.lessons.lesson.frame_list.frame.componentized.component = window.locales.it.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.it.lessons.lesson.frame_list.frame.componentized.component.layout = window.locales.it.lessons.lesson.frame_list.frame.componentized.component.layout || {};window.locales.it.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive = window.locales.it.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive || {};window.locales.it.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive = {...window.locales.it.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive, ...{
    "give_me_a_hint": "Dammi un indizio"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.lesson = window.locales.zh.lessons.lesson || {};window.locales.zh.lessons.lesson.frame_list = window.locales.zh.lessons.lesson.frame_list || {};window.locales.zh.lessons.lesson.frame_list.frame = window.locales.zh.lessons.lesson.frame_list.frame || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized = window.locales.zh.lessons.lesson.frame_list.frame.componentized || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized.component = window.locales.zh.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.layout = window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.layout || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive = window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive = {...window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive, ...{
    "give_me_a_hint": "给我一个提示"           
}
};