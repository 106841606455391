window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.stream_dashboard_next_up = window.locales.am.lessons.stream.stream_dashboard_next_up || {};window.locales.am.lessons.stream.stream_dashboard_next_up = {...window.locales.am.lessons.stream.stream_dashboard_next_up, ...{
    "chapter_x": "ምዕራፍ {{num}}",           
    "lesson_x": "ትምህርት {{num}}",           
    "next_up": "ቀጥሎ ያለው፤"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.stream_dashboard_next_up = window.locales.ar.lessons.stream.stream_dashboard_next_up || {};window.locales.ar.lessons.stream.stream_dashboard_next_up = {...window.locales.ar.lessons.stream.stream_dashboard_next_up, ...{
    "chapter_x": "الفصل {{num}}",           
    "lesson_x": "الدرس {{num}}",           
    "next_up": "الخطوة التالية:"           
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.stream_dashboard_next_up = window.locales.en.lessons.stream.stream_dashboard_next_up || {};window.locales.en.lessons.stream.stream_dashboard_next_up = {...window.locales.en.lessons.stream.stream_dashboard_next_up, ...{
    "chapter_x": "CHAPTER {{num}}",
    "lesson_x": "LESSON {{num}}",
    "next_up": "NEXT UP:"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.stream_dashboard_next_up = window.locales.es.lessons.stream.stream_dashboard_next_up || {};window.locales.es.lessons.stream.stream_dashboard_next_up = {...window.locales.es.lessons.stream.stream_dashboard_next_up, ...{
    "chapter_x": "CAPÍTULO {{num}}",           
    "lesson_x": "LECCIÓN {{num}}",           
    "next_up": "A CONTINUACIÓN:"           
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.stream_dashboard_next_up = window.locales.it.lessons.stream.stream_dashboard_next_up || {};window.locales.it.lessons.stream.stream_dashboard_next_up = {...window.locales.it.lessons.stream.stream_dashboard_next_up, ...{
    "chapter_x": "CAPITOLO {{num}}",           
    "lesson_x": "LEZIONE {{num}}",           
    "next_up": "PROSSIMO:"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.stream_dashboard_next_up = window.locales.zh.lessons.stream.stream_dashboard_next_up || {};window.locales.zh.lessons.stream.stream_dashboard_next_up = {...window.locales.zh.lessons.stream.stream_dashboard_next_up, ...{
    "chapter_x": "第 {{num}} 章",           
    "lesson_x": "第 {{num}} 课",           
    "next_up": "接下来："           
}
};