window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.dashboard_footer = window.locales.am.lessons.stream.dashboard_footer || {};window.locales.am.lessons.stream.dashboard_footer = {...window.locales.am.lessons.stream.dashboard_footer, ...{
    "about": "ስለ",           
    "available_app_store": "በ App Store ይገኛል",           
    "blog": "ብሎግ",           
    "coming_play": "በ Google Play",           
    "contact_us": "ያግኙን",           
    "copyright": "© Quantic Holdings, Inc. ሁሉም መብቶች የተጠበቁ ናቸው።",           
    "cookies": "ኩኪዎች",           
    "companies": "ለኩባንያዎች",           
    "facebook": "ፌስቡክ",           
    "facebook_icon": "የፌስቡክ አዶ",           
    "faq": "በተደጋጋሚ የሚጠየቁ ጥያቄዎች",           
    "generate_tweet": "አዲስ ትዊት ይፍጠሩ!",           
    "homepage": "መነሻ ገጽ",           
    "courses": "ኮርሶች",           
    "method": "ዘዴ",           
    "organizations": "ድርጅቶች",           
    "press": "ፕሬስ",           
    "pricing": "ዋጋ",           
    "privacy_policy": "የግላዊነት ፖሊሲ",           
    "share_with_classmates": "ከክፍል ጓደኞችዎ ጋር ያጋሩ",           
    "terms_and_conditions": "ውሎች እና ሁኔታዎች",           
    "tweet": "ትዊት",           
    "twitter": "ትዊተር",           
    "twitter_icon": "የትዊተር አዶ",           
    "universities": "ለዩኒቨርሲቲዎች"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.dashboard_footer = window.locales.ar.lessons.stream.dashboard_footer || {};window.locales.ar.lessons.stream.dashboard_footer = {...window.locales.ar.lessons.stream.dashboard_footer, ...{
    "about": "عن",           
    "available_app_store": "متاح على App Store",           
    "blog": "المدونة",           
    "coming_play": "قريباً على جوجل بلاي",           
    "contact_us": "اتصل بنا",           
    "copyright": "© Quantic Holdings, Inc. جميع الحقوق محفوظة.",           
    "companies": "للشركات",           
    "facebook": "فيس بوك",           
    "facebook_icon": "أيقونة فيس بوك",           
    "faq": "الأسئلة الشائعة",           
    "generate_tweet": "إنشاء تغريدة جديدة!",           
    "homepage": "الصفحة الرئيسية",           
    "method": "الطريقة",           
    "organizations": "المؤسسات",           
    "press": "الصحافة",           
    "pricing": "السعر",           
    "privacy_policy": "سياسة الخصوصية",           
    "share_with_classmates": "قُم بالمشاركة مع زملاء الدراسة",           
    "terms_and_conditions": "الشروط والأحكام",           
    "tweet": "غرّد",           
    "twitter": "تويتر",           
    "twitter_icon": "أيقونة تويتر",           
    "universities": "للجامعات",           
    "cookies": "بسكويت",
    "courses": "الدورات"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.dashboard_footer = window.locales.en.lessons.stream.dashboard_footer || {};window.locales.en.lessons.stream.dashboard_footer = {...window.locales.en.lessons.stream.dashboard_footer, ...{
    "about": "About",
    "available_app_store": "Available App Store",
    "blog": "Blog",
    "coming_play": "Coming Google Play",
    "contact_us": "Contact Us",
    "copyright": "© Quantic Holdings, Inc. All rights reserved.",
    "cookies": "Cookies",
    "companies": "For Companies",
    "facebook": "Facebook",
    "facebook_icon": "Facebook Icon",
    "faq": "FAQ",
    "generate_tweet": "Generate a new tweet!",
    "homepage": "Homepage",
    "courses": "Courses",
    "method": "Method",
    "organizations": "Organizations",
    "press": "Press",
    "pricing": "Pricing",
    "privacy_policy": "Privacy Policy",
    "share_with_classmates": "Share with your classmates",
    "terms_and_conditions": "Terms and Conditions",
    "tweet": "Tweet",
    "twitter": "Twitter",
    "twitter_icon": "Twitter Icon",
    "universities": "For Universities"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.dashboard_footer = window.locales.es.lessons.stream.dashboard_footer || {};window.locales.es.lessons.stream.dashboard_footer = {...window.locales.es.lessons.stream.dashboard_footer, ...{
    "about": "Sobre nosotros",           
    "available_app_store": "Disponible en App Store",           
    "blog": "Blog",           
    "coming_play": "Próximamente en Google Play",           
    "contact_us": "Contáctanos",           
    "copyright": "© Quantic Holdings, Inc. Todos los derechos reservados.",           
    "companies": "Empresas",           
    "facebook": "Facebook",           
    "facebook_icon": "Icono de Facebook",           
    "faq": "Preguntas frecuentes",           
    "generate_tweet": "¡Genera un nuevo tweet!",           
    "homepage": "Página principal",           
    "organizations": "Organizaciones",           
    "press": "Prensa",           
    "pricing": "Precios",           
    "privacy_policy": "Política de privacidad",           
    "share_with_classmates": "Comparte con tus compañeros de clase",           
    "terms_and_conditions": "Términos y condiciones",           
    "tweet": "Tweet",           
    "twitter": "Twitter",           
    "twitter_icon": "Icono de Twitter",           
    "universities": "Universidades",           
    "method": "Método",           
    "cookies": "Cookies",
    "courses": "Cursos"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.dashboard_footer = window.locales.it.lessons.stream.dashboard_footer || {};window.locales.it.lessons.stream.dashboard_footer = {...window.locales.it.lessons.stream.dashboard_footer, ...{
    "about": "Informazioni",           
    "available_app_store": "Disponibile App Store",           
    "blog": "Blog",           
    "coming_play": "Google Play in arrivo",           
    "contact_us": "Contattaci",           
    "copyright": "© Quantic Holdings, Inc. Tutti i diritti riservati.",           
    "companies": "Per le Aziende",           
    "facebook": "Facebook",           
    "facebook_icon": "Icona Facebook",           
    "faq": "FAQ",           
    "generate_tweet": "Genera un nuovo tweet!",           
    "homepage": "Homepage",           
    "method": "Metodo",           
    "organizations": "Organizzazioni",           
    "press": "Stampa",           
    "pricing": "Tariffe",           
    "privacy_policy": "Informativa sulla Privacy",           
    "share_with_classmates": "Condividi con i tuoi compagni di studi",           
    "terms_and_conditions": "Termini e Condizioni",           
    "tweet": "Tweet",           
    "twitter": "Twitter",           
    "twitter_icon": "Icona Twitter",           
    "universities": "Per le Università",           
    "cookies": "Cookies",
    "courses": "corsi"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.dashboard_footer = window.locales.zh.lessons.stream.dashboard_footer || {};window.locales.zh.lessons.stream.dashboard_footer = {...window.locales.zh.lessons.stream.dashboard_footer, ...{
    "about": "关于",           
    "available_app_store": "可用应用商店",           
    "blog": "博客",           
    "coming_play": "来 Google Play",           
    "contact_us": "联系我们",           
    "copyright": "© Quantic Holdings, Inc。保留所有权利。",           
    "facebook": "Facebook",           
    "facebook_icon": "Facebook 图标",           
    "faq": "FAQ",           
    "generate_tweet": "生成新的 Twitter 帖子！",           
    "homepage": "主页",           
    "organizations": "组织",           
    "press": "媒体",           
    "pricing": "价格",           
    "privacy_policy": "隐私政策",           
    "share_with_classmates": "与同学分享",           
    "terms_and_conditions": "条款和条件",           
    "tweet": "Twitter 发帖",           
    "twitter": "Twitter",           
    "twitter_icon": "Twitter 图标",           
    "companies": "企业用户",           
    "universities": "大学学校用户",           
    "method": "方法",           
    "cookies": "缓存文件",           
    "courses": "课程"           
}
};