window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.has_toggleable_display_mode = window.locales.am.lessons.stream.has_toggleable_display_mode || {};window.locales.am.lessons.stream.has_toggleable_display_mode = {...window.locales.am.lessons.stream.has_toggleable_display_mode, ...{
    "other": "ሌላ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.has_toggleable_display_mode = window.locales.ar.lessons.stream.has_toggleable_display_mode || {};window.locales.ar.lessons.stream.has_toggleable_display_mode = {...window.locales.ar.lessons.stream.has_toggleable_display_mode, ...{
    "other": "أخرى"           
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.has_toggleable_display_mode = window.locales.en.lessons.stream.has_toggleable_display_mode || {};window.locales.en.lessons.stream.has_toggleable_display_mode = {...window.locales.en.lessons.stream.has_toggleable_display_mode, ...{
    "other": "Other"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.has_toggleable_display_mode = window.locales.es.lessons.stream.has_toggleable_display_mode || {};window.locales.es.lessons.stream.has_toggleable_display_mode = {...window.locales.es.lessons.stream.has_toggleable_display_mode, ...{
    "other": "Otro"           
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.has_toggleable_display_mode = window.locales.it.lessons.stream.has_toggleable_display_mode || {};window.locales.it.lessons.stream.has_toggleable_display_mode = {...window.locales.it.lessons.stream.has_toggleable_display_mode, ...{
    "other": "Altro"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.has_toggleable_display_mode = window.locales.zh.lessons.stream.has_toggleable_display_mode || {};window.locales.zh.lessons.stream.has_toggleable_display_mode = {...window.locales.zh.lessons.stream.has_toggleable_display_mode, ...{
    "other": "其他"           
}
};