window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.show_stream = window.locales.am.lessons.stream.show_stream || {};window.locales.am.lessons.stream.show_stream = {...window.locales.am.lessons.stream.show_stream, ...{
    "cannot_be_launched": "እናዝናለን፣ እርስዎ አሁን ባለዎት የ {{brandName}} ስሪት ይህ ትምህርት ሊጀመር አይችልም።",           
    "go_back": "ተመለስ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.show_stream = window.locales.ar.lessons.stream.show_stream || {};window.locales.ar.lessons.stream.show_stream = {...window.locales.ar.lessons.stream.show_stream, ...{
    "go_back": "الرجوع للخلف",           
    "cannot_be_launched": "عذرًا ، لا يمكن بدء هذا الدرس {{brandName}} الحالي من {{brandName}} ."
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.show_stream = window.locales.en.lessons.stream.show_stream || {};window.locales.en.lessons.stream.show_stream = {...window.locales.en.lessons.stream.show_stream, ...{
    "cannot_be_launched": "We're sorry, but this lesson cannot be launched with your current version of {{brandName}}.",
    "go_back": "Go back"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.show_stream = window.locales.es.lessons.stream.show_stream || {};window.locales.es.lessons.stream.show_stream = {...window.locales.es.lessons.stream.show_stream, ...{
    "go_back": "Regresar",           
    "cannot_be_launched": "Lo sentimos, pero esta lección no se puede iniciar con su versión actual de {{brandName}} ."
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.show_stream = window.locales.it.lessons.stream.show_stream || {};window.locales.it.lessons.stream.show_stream = {...window.locales.it.lessons.stream.show_stream, ...{
    "go_back": "Torna indietro",           
    "cannot_be_launched": "Siamo spiacenti, ma questa lezione non può essere avviata con la tua versione attuale di {{brandName}} ."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.show_stream = window.locales.zh.lessons.stream.show_stream || {};window.locales.zh.lessons.stream.show_stream = {...window.locales.zh.lessons.stream.show_stream, ...{
    "go_back": "返回",           
    "cannot_be_launched": "很抱歉，根据你当前版本的{{brandName}}，本课程无法启动。"           
}
};