window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.certificate_helper_mixin = window.locales.am.lessons.stream.certificate_helper_mixin || {};window.locales.am.lessons.stream.certificate_helper_mixin = {...window.locales.am.lessons.stream.certificate_helper_mixin, ...{
    "confirms": "ይህ የሚያረጋግጠው",           
    "completed": "በተሳካ ሁኔታ ተጠናቋል",           
    "authenticity": "{{brandEmail}} በማግኘት የዚህ ሰነድ ትክክለኛነትን ማረጋገጥ ይቻላል",           
    "certificate": "ሰርተፍኬት",           
    "proof_of": "የማጠናቀቂያ",           
    "completion": "ማጠናቀቅ",           
    "offered": "በ {{institutionName}} የቀረበ የጥናት ኮርስ",
    "offered_bos_high_school": "ለሰማያዊ ውቅያኖስ የስራ ፈጠራ ውድድር የሁለተኛ ደረጃ ትምህርት ቤት ተሳታፊዎች በ {{institutionName}} የቀረበ የጥናት ኮርስ",
    "offered_program": "በ {{institutionName}} የቀረበ ፕሮግራም",
    "offered_program_quantic": "በ Quantic School of Business and Technology የቀረበ የጥናት ኮርስ",
    "offered_quantic": "በኩንቲክ የንግድ እና ቴክኖሎጂ ትምህርት ቤት የቀረበ የጥናት ኮርስ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.certificate_helper_mixin = window.locales.ar.lessons.stream.certificate_helper_mixin || {};window.locales.ar.lessons.stream.certificate_helper_mixin = {...window.locales.ar.lessons.stream.certificate_helper_mixin, ...{
    "confirms": "ويؤكد هذا أن ذلك",           
    "completed": "قد تم بنجاح",           
    "certificate": "الشهادة",           
    "authenticity": "يمكن التحقق من صحة هذا المستند عن طريق الاتصال {{brandEmail}}",
    "proof_of": "الدليل ل",
    "completion": "إكمال",
    "offered": "دورة دراسية مقدمة من {{institutionName}}",
    "offered_bos_high_school": "دورة دراسية مقدمة من {{institutionName}} لمشاركي المدارس الثانوية في مسابقة Blue Ocean لريادة الأعمال",
    "offered_program": "برنامج مقدم من {{institutionName}}",
    "offered_program_quantic": "دورة دراسية مقدمة من كلية كوانتيك للأعمال والتكنولوجيا",
    "offered_quantic": "دورة دراسية مقدمة من كلية كوانتيك للأعمال والتكنولوجيا"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.certificate_helper_mixin = window.locales.en.lessons.stream.certificate_helper_mixin || {};window.locales.en.lessons.stream.certificate_helper_mixin = {...window.locales.en.lessons.stream.certificate_helper_mixin, ...{
    "confirms": "This confirms that",
    "completed": "successfully completed",
    "offered": "a course of study offered by {{institutionName}}",
    "offered_quantic": "a course of study offered by Quantic School \nof Business and Technology",
    "offered_bos_high_school": "a course of study offered by {{institutionName}}\nto high school participants of the Blue Ocean Entrepreneurship Competition",
    "offered_program": "a program offered by {{institutionName}}",
    "offered_program_quantic": "a program offered offered by Quantic School \nof Business and Technology",
    "authenticity": "Authenticity of this document can be verified by contacting {{brandEmail}}",
    "certificate": "CERTIFICATE",
    "proof_of": "PROOF OF",
    "completion": "COMPLETION"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.certificate_helper_mixin = window.locales.es.lessons.stream.certificate_helper_mixin || {};window.locales.es.lessons.stream.certificate_helper_mixin = {...window.locales.es.lessons.stream.certificate_helper_mixin, ...{
    "confirms": "Esto confirma que",           
    "completed": "completado con éxito",           
    "certificate": "CERTIFICADO",           
    "authenticity": "La autenticidad de este documento se puede verificar contactando a {{brandEmail}}",
    "proof_of": "PRUEBA DE",
    "completion": "TERMINACIÓN",
    "offered": "Un curso de estudio ofrecido por {{institutionName}}",
    "offered_bos_high_school": "Un curso de estudio ofrecido por {{institutionName}} a los participantes de la escuela secundaria del Concurso de Emprendimiento del Océano Azul",
    "offered_program": "Un programa ofrecido por {{institutionName}}",
    "offered_program_quantic": "Un programa ofrecido por \nQuantic School of Business and Technology",
    "offered_quantic": "Un curso de estudio ofrecido por \nQuantic School of Business and Technology"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.certificate_helper_mixin = window.locales.it.lessons.stream.certificate_helper_mixin || {};window.locales.it.lessons.stream.certificate_helper_mixin = {...window.locales.it.lessons.stream.certificate_helper_mixin, ...{
    "confirms": "Si conferma che",           
    "completed": "ha completato con successo",           
    "certificate": "DIPLOMA",           
    "authenticity": "L'autenticità di questo documento può essere verificata contattando {{brandEmail}}",
    "proof_of": "PROVA DI",
    "completion": "COMPLETAMENTO",
    "offered": "un corso di studi offerto da {{institutionName}}",
    "offered_bos_high_school": "un corso di studio offerto da {{institutionName}} ai partecipanti delle scuole superiori del Blue Ocean Entrepreneurship Competition",
    "offered_program": "un programma offerto da {{institutionName}}",
    "offered_program_quantic": "un corso di studi offerto dalla Quantic School \nof Business and Technology",
    "offered_quantic": "un corso di studi offerto dalla Quantic School \nof Business and Technology"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.certificate_helper_mixin = window.locales.zh.lessons.stream.certificate_helper_mixin || {};window.locales.zh.lessons.stream.certificate_helper_mixin = {...window.locales.zh.lessons.stream.certificate_helper_mixin, ...{
    "confirms": "这将确认",           
    "completed": "成功完成",           
    "certificate": "证书",           
    "authenticity": "联系{{brandEmail}}以核实此文件的真实性。",           
    "proof_of": "完成",           
    "completion": "证明",           
    "offered": "{{institutionName}}提供的学习课程",
    "offered_bos_high_school": "{{institutionName}}为蓝海创业大赛的高中生提供的学习课程",
    "offered_program": "{{institutionName}}提供的课程",
    "offered_program_quantic": "Quantic 商业与技术学院开设的课程",
    "offered_quantic": "Quantic 商业与技术学院开设的课程"
}
};