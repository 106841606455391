window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.lesson = window.locales.am.lessons.lesson || {};window.locales.am.lessons.lesson.frame_list = window.locales.am.lessons.lesson.frame_list || {};window.locales.am.lessons.lesson.frame_list.frame = window.locales.am.lessons.lesson.frame_list.frame || {};window.locales.am.lessons.lesson.frame_list.frame.componentized = window.locales.am.lessons.lesson.frame_list.frame.componentized || {};window.locales.am.lessons.lesson.frame_list.frame.componentized = {...window.locales.am.lessons.lesson.frame_list.frame.componentized, ...{
    "blanks_on_image": "ለእያንዳንዱ ባዶ መልስ ይምረጡ",           
    "compose_blanks": "ለእያንዳንዱ ባዶ መልስ ይተይቡ",           
    "compose_blanks_on_image": "ለእያንዳንዱ ባዶ መልስ ይተይቡ",           
    "fill_in_the_blanks": "ለእያንዳንዱ ባዶ መልስ ይምረጡ",           
    "matching": "እያንዳንዱን ጥያቄ ከመልስ ጋር ያዛምዱ",           
    "multiple_choice_poll": "የህዝብ አስተያየት ውጤቶችን ለማሳየት ይምረጡ",           
    "no_interaction": "ያንብቡና ይቀጥሉ",           
    "select_all_answers_that_apply": "የሚመለከታቸውን ሁሉንም መልሶች ይምረጡ",           
    "select_an": "መልስ ይምረጡ",           
    "this_or_that": "ለእያንዳንዱ ካርድ መልስ ይምረጡ",           
    "select_all_answers": "ሁሉንም መልሶች ይምረጡ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.lesson = window.locales.ar.lessons.lesson || {};window.locales.ar.lessons.lesson.frame_list = window.locales.ar.lessons.lesson.frame_list || {};window.locales.ar.lessons.lesson.frame_list.frame = window.locales.ar.lessons.lesson.frame_list.frame || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized = window.locales.ar.lessons.lesson.frame_list.frame.componentized || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized = {...window.locales.ar.lessons.lesson.frame_list.frame.componentized, ...{
    "blanks_on_image": "اختر إجابة لكل فراغ",           
    "compose_blanks": "اكتب إجابة في كل فراغ",           
    "compose_blanks_on_image": "اكتب إجابة في كل فراغ",           
    "fill_in_the_blanks": "اختر إجابة لكل فراغ",           
    "matching": "قُم بمطابقة كل أمر مع الإجابة",           
    "multiple_choice_poll": "قُم بالتصويت للكشف عن نتائج الاستطلاع",           
    "no_interaction": "اقرأ وتابع",           
    "select_all_answers_that_apply": "حدد كافة الإجابات التي تنطبق",           
    "select_an": "اختر إجابة ما",           
    "this_or_that": "اختر إجابة لكل بطاقة",           
    "select_all_answers": "حدد كل الإجابات"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.lesson = window.locales.en.lessons.lesson || {};window.locales.en.lessons.lesson.frame_list = window.locales.en.lessons.lesson.frame_list || {};window.locales.en.lessons.lesson.frame_list.frame = window.locales.en.lessons.lesson.frame_list.frame || {};window.locales.en.lessons.lesson.frame_list.frame.componentized = window.locales.en.lessons.lesson.frame_list.frame.componentized || {};window.locales.en.lessons.lesson.frame_list.frame.componentized = {...window.locales.en.lessons.lesson.frame_list.frame.componentized, ...{
    "blanks_on_image": "Select an answer for each blank",
    "compose_blanks": "Type an answer in each blank",
    "compose_blanks_on_image": "Type an answer in each blank",
    "fill_in_the_blanks": "Select an answer for each blank",
    "matching": "Match each prompt with an answer",
    "multiple_choice_poll": "Vote to reveal poll results",
    "no_interaction": "Read and continue",
    "select_all_answers_that_apply": "Select all answers that apply",
    "select_all_answers": "Select all answers",
    "select_an": "Select an answer",
    "this_or_that": "Select an answer for each card"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.lesson = window.locales.es.lessons.lesson || {};window.locales.es.lessons.lesson.frame_list = window.locales.es.lessons.lesson.frame_list || {};window.locales.es.lessons.lesson.frame_list.frame = window.locales.es.lessons.lesson.frame_list.frame || {};window.locales.es.lessons.lesson.frame_list.frame.componentized = window.locales.es.lessons.lesson.frame_list.frame.componentized || {};window.locales.es.lessons.lesson.frame_list.frame.componentized = {...window.locales.es.lessons.lesson.frame_list.frame.componentized, ...{
    "blanks_on_image": "Selecciona una respuesta",           
    "compose_blanks": "Escribe una respuesta",           
    "compose_blanks_on_image": "Escribe una respuesta",           
    "fill_in_the_blanks": "Selecciona una respuesta",           
    "matching": "Ponlas en parejas",           
    "multiple_choice_poll": "Vota para revelar los resultados",           
    "no_interaction": "Leer y seguir",           
    "select_all_answers_that_apply": "Selecciona múltiples respuestas",           
    "select_an": "Selecciona una respuesta",           
    "this_or_that": "Selecciona una respuesta",           
    "select_all_answers": "Seleccionar todas las respuestas"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.lesson = window.locales.it.lessons.lesson || {};window.locales.it.lessons.lesson.frame_list = window.locales.it.lessons.lesson.frame_list || {};window.locales.it.lessons.lesson.frame_list.frame = window.locales.it.lessons.lesson.frame_list.frame || {};window.locales.it.lessons.lesson.frame_list.frame.componentized = window.locales.it.lessons.lesson.frame_list.frame.componentized || {};window.locales.it.lessons.lesson.frame_list.frame.componentized = {...window.locales.it.lessons.lesson.frame_list.frame.componentized, ...{
    "blanks_on_image": "Seleziona una risposta per ogni spazio vuoto",           
    "compose_blanks": "Digita una risposta in ogni spazio vuoto",           
    "compose_blanks_on_image": "Digita una risposta in ogni spazio vuoto",           
    "fill_in_the_blanks": "Seleziona una risposta per ogni spazio vuoto",           
    "matching": "Associa una risposta a ogni richiesta",           
    "multiple_choice_poll": "Vota per rivelare i risultati del sondaggio",           
    "no_interaction": "Leggi e continua",           
    "select_all_answers_that_apply": "Seleziona tutte le risposte appropriate",           
    "select_an": "Seleziona una risposta",           
    "this_or_that": "Seleziona una risposta per ogni carta",           
    "select_all_answers": "Seleziona tutte le risposte"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.lesson = window.locales.zh.lessons.lesson || {};window.locales.zh.lessons.lesson.frame_list = window.locales.zh.lessons.lesson.frame_list || {};window.locales.zh.lessons.lesson.frame_list.frame = window.locales.zh.lessons.lesson.frame_list.frame || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized = window.locales.zh.lessons.lesson.frame_list.frame.componentized || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized = {...window.locales.zh.lessons.lesson.frame_list.frame.componentized, ...{
    "blanks_on_image": "为每个空白选择一个答案",           
    "compose_blanks": "在每个空白中键入一个答案",           
    "compose_blanks_on_image": "在每个空白中键入一个答案",           
    "fill_in_the_blanks": "为每个空白选择一个答案",           
    "matching": "将每个提示与一个答案匹配",           
    "multiple_choice_poll": "投票后可显示调查结果",           
    "no_interaction": "阅读并继续",           
    "select_all_answers_that_apply": "选择所有适用的答案",           
    "select_an": "选择一个答案",           
    "this_or_that": "为每个卡选择一个答案",           
    "select_all_answers": "选择所有答案"
}
};