window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.student_dashboard_enrollment = window.locales.am.lessons.stream.student_dashboard_enrollment || {};window.locales.am.lessons.stream.student_dashboard_enrollment = {...window.locales.am.lessons.stream.student_dashboard_enrollment, ...{
    "enrollment": "ምዝገባ",           
    "follow_these_steps": "የእርስዎን ምዝገባ ለማጠናቀቅ እነዚህን እርምጃዎች ይከተሉ፤",           
    "upload_your_id": "መታወቂያዎን ይስቀሉ",           
    "verify_your_identity": "ማንነትዎን ያረጋግጡ",           
    "upload_unofficial_transcripts": "ኦፊሳላዊ ያልሆነውን ትራንስክሪፕቶችዎን ይስቀሉ",           
    "upload_unofficial_transcript": "ኦፊሳላዊ ያልሆነውን ትራንስክሪፕትዎን ይስቀሉ",           
    "upload_english_language_documents": "የእንግሊዝኛ ቋንቋ ብቃት ሰነድ ይስቀሉ",           
    "provide_mailing_address": "የመልእክት አድራሻዎን ያረጋግጡ",           
    "in_review": "(በግምገማ ላይ)",           
    "sign_enrollment_agreement": "የእርስዎን የመመዝገቢያ ስምምነት ይፈርሙ",           
    "complete_student_profile": "የእርስዎን የተማሪ መገለጫ ያጥናቅቁ",           
    "show_completed_steps": "+ የተጠናቀቁ ደረጃዎችን ያሳዩ",           
    "hide_completed_steps": "- የተጠናቀቁ ደረጃዎችን ይደብቁ",           
    "follow_these_steps_online": "በመስመር ላይ ሲመለሱ ምዝገባዎን ለማጠናቀቅ እነዚህን እርምጃዎች ይከተሉ፤",           
    "due_by": "በ",
    "past_due": "ያለፉ ተግባራት",
    "send_us_your_transcripts": "ኦፊሴላዊ ግልባጭ ጠይቅ ለ፡-",
    "complete_your": "የእርስዎን ያጠናቅቁ",
    "sign_tila_disclosure": "የክፍያ እቅድዎን ይፋ ማድረግ ይፈርሙ",
    "create_exam_signatures": "ለፈተና ማንነት ማረጋገጫ ዲጂታል ፊርማ ይፍጠሩ",
    "learn_enrollment_requirements": "ስለ <a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"{enrollmentFaqLink}\" target=\"_blank\">ምዝገባ መስፈርቶች</a> የበለጠ ይረዱ።",
    "have_questions": "ጥያቄዎች? <a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"mailto:{brandEmail}\" target=\"_blank\">{brandEmail}</a> ን ያግኙ ወይም <a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"https://calendly.com/d/d7n-s3n-fx4\" target=\"_blank\">ቀጠሮ</a> ይያዙ።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.student_dashboard_enrollment = window.locales.ar.lessons.stream.student_dashboard_enrollment || {};window.locales.ar.lessons.stream.student_dashboard_enrollment = {...window.locales.ar.lessons.stream.student_dashboard_enrollment, ...{
    "enrollment": "تسجيل",
    "follow_these_steps": "اتبع هذه الخطوات لإكمال التسجيل الخاص بك:",
    "upload_your_id": "تحميل معرفك",
    "upload_english_language_documents": "تحميل وثائق إتقان اللغة الإنجليزية",
    "verify_your_identity": "تحقق من هويتك",
    "provide_mailing_address": "قم بتأكيد عنوانك البريدي",
    "in_review": "(في مراجعة)",
    "sign_enrollment_agreement": "قم بتوقيع اتفاقية التسجيل الخاصة بك",
    "complete_student_profile": "أكمل ملف الطالب الخاص بك",
    "upload_unofficial_transcripts": "تحميل النصوص غير الرسمية الخاصة بك",
    "upload_unofficial_transcript": "تحميل النسخة غير الرسمية الخاصة بك",
    "show_completed_steps": "+ إظهار الخطوات المكتملة",
    "hide_completed_steps": "- إخفاء الخطوات المكتملة",
    "follow_these_steps_online": "اتبع هذه الخطوات لإكمال التسجيل عندما تعود إلى وضع الاتصال بالإنترنت:",
    "due_by": "يسلم في",
    "past_due": "مهام الاستحقاق السابقة",
    "send_us_your_transcripts": "طلب كشف درجات رسمي لـ:",
    "complete_your": "أكمل ملف",
    "sign_tila_disclosure": "وقع على الإفصاح عن خطة الدفع الخاصة بك",
    "create_exam_signatures": "قم بإنشاء توقيع رقمي للتحقق من هوية الاختبار",
    "learn_enrollment_requirements": "تعرف على المزيد حول <a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"{enrollmentFaqLink}\" target=\"_blank\">متطلبات التسجيل</a> .",
    "have_questions": "أسئلة؟ تواصل مع <a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"mailto:{brandEmail}\" target=\"_blank\">{brandEmail}</a> أو حدد <a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"https://calendly.com/d/d7n-s3n-fx4\" target=\"_blank\">موعدًا</a> ."
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.student_dashboard_enrollment = window.locales.en.lessons.stream.student_dashboard_enrollment || {};window.locales.en.lessons.stream.student_dashboard_enrollment = {...window.locales.en.lessons.stream.student_dashboard_enrollment, ...{
    "enrollment": "Enrollment",
    "follow_these_steps": "Follow these steps to complete your enrollment:",
    "follow_these_steps_online": "Follow these steps to complete your enrollment when you're back online:",
    "upload_your_id": "Upload your ID",
    "verify_your_identity": "Verify your identity",
    "upload_unofficial_transcripts": "Upload your unofficial transcripts",
    "upload_unofficial_transcript": "Upload your unofficial transcript",
    "send_us_your_transcripts": "Request official transcripts for:",
    "upload_english_language_documents": "Upload English Language Proficiency documentation",
    "provide_mailing_address": "Confirm your mailing address",
    "in_review": "(In Review)",
    "learn_enrollment_requirements": "Learn more about <a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"{enrollmentFaqLink}\" target=\"_blank\">enrollment requirements</a>.",
    "have_questions": "Questions? Contact <a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"mailto:{brandEmail}\" target=\"_blank\">{brandEmail}</a> or schedule an <a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"https://calendly.com/d/d7n-s3n-fx4\" target=\"_blank\">appointment</a>.",
    "sign_enrollment_agreement": "Sign your Enrollment Agreement",
    "sign_tila_disclosure": "Sign your Payment Plan Disclosure",
    "complete_student_profile": "Complete your student profile",
    "show_completed_steps": "+ Show completed steps",
    "hide_completed_steps": "- Hide completed steps",
    "due_by": "Due by",
    "past_due": "Past Due Tasks",
    "complete_your": "Complete Your",
    "create_exam_signatures": "Create a digital signature for exam identity verification"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.student_dashboard_enrollment = window.locales.es.lessons.stream.student_dashboard_enrollment || {};window.locales.es.lessons.stream.student_dashboard_enrollment = {...window.locales.es.lessons.stream.student_dashboard_enrollment, ...{
    "enrollment": "Inscripción",
    "follow_these_steps": "Siga estos pasos para completar su inscripción:",
    "upload_your_id": "Cargue su ID",
    "upload_english_language_documents": "Subir documentación sobre el dominio del idioma inglés",
    "verify_your_identity": "Verifica tu identidad",
    "provide_mailing_address": "Confirme su dirección de correo",
    "in_review": "(En revisión)",
    "sign_enrollment_agreement": "Firma tu Acuerdo de Inscripción",
    "complete_student_profile": "Completa tu perfil de estudiante",
    "upload_unofficial_transcripts": "Sube tus transcripciones no oficiales",
    "upload_unofficial_transcript": "Sube tu transcripción no oficial",
    "show_completed_steps": "+ Mostrar pasos completados",
    "hide_completed_steps": "- Ocultar pasos completados",
    "follow_these_steps_online": "Siga estos pasos para completar su inscripción cuando vuelva a estar en línea:",
    "due_by": "Debido por",
    "past_due": "Tareas vencidas",
    "send_us_your_transcripts": "Solicite transcripciones oficiales para:",
    "complete_your": "Complete su",
    "sign_tila_disclosure": "Firme su Divulgación del plan de pago",
    "create_exam_signatures": "Crear una firma digital para la verificación de identidad del examen",
    "learn_enrollment_requirements": "Obtenga más información sobre <a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"{enrollmentFaqLink}\" target=\"_blank\">los requisitos de inscripción</a> .",
    "have_questions": "¿Preguntas? Comuníquese con <a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"mailto:{brandEmail}\" target=\"_blank\">{brandEmail}</a> o programe una <a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"https://calendly.com/d/d7n-s3n-fx4\" target=\"_blank\">cita</a> ."
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.student_dashboard_enrollment = window.locales.it.lessons.stream.student_dashboard_enrollment || {};window.locales.it.lessons.stream.student_dashboard_enrollment = {...window.locales.it.lessons.stream.student_dashboard_enrollment, ...{
    "enrollment": "Iscrizione",
    "follow_these_steps": "Segui questi passaggi per completare la tua iscrizione:",
    "upload_your_id": "Carica il tuo ID",
    "upload_english_language_documents": "Carica la documentazione relativa alla lingua inglese",
    "verify_your_identity": "Verifica la tua identità",
    "provide_mailing_address": "Conferma il tuo indirizzo postale",
    "in_review": "(In revisione)",
    "sign_enrollment_agreement": "Firma il tuo contratto di iscrizione",
    "complete_student_profile": "Completa il tuo profilo studente",
    "upload_unofficial_transcripts": "Carica le tue trascrizioni non ufficiali",
    "upload_unofficial_transcript": "Carica la tua trascrizione non ufficiale",
    "show_completed_steps": "+ Mostra passaggi completati",
    "hide_completed_steps": "- Nascondi i passaggi completati",
    "follow_these_steps_online": "Segui questi passaggi per completare la registrazione quando torni online:",
    "due_by": "Dovuto a",
    "past_due": "Compiti scaduti",
    "send_us_your_transcripts": "Richiedi trascrizioni ufficiali per:",
    "complete_your": "Completa la tua",           
    "sign_tila_disclosure": "Firma l'informativa sul tuo piano di pagamento",
    "create_exam_signatures": "Crea una firma digitale per la verifica dell'identità dell'esame",
    "learn_enrollment_requirements": "Ulteriori informazioni sui <a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"{enrollmentFaqLink}\" target=\"_blank\">requisiti di iscrizione</a> .",
    "have_questions": "Domande? Contatta <a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"mailto:{brandEmail}\" target=\"_blank\">{brandEmail}</a> o fissa un <a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"https://calendly.com/d/d7n-s3n-fx4\" target=\"_blank\">appuntamento</a> ."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.student_dashboard_enrollment = window.locales.zh.lessons.stream.student_dashboard_enrollment || {};window.locales.zh.lessons.stream.student_dashboard_enrollment = {...window.locales.zh.lessons.stream.student_dashboard_enrollment, ...{
    "enrollment": "注册",           
    "follow_these_steps": "按照以下步骤完成注册：",           
    "upload_your_id": "上传你的身份证",           
    "upload_english_language_documents": "上传英语语言能力证明",           
    "verify_your_identity": "完成身份验证",           
    "provide_mailing_address": "确认你的邮寄地址",           
    "in_review": "（审核中）",           
    "sign_enrollment_agreement": "签署你的注册协议",           
    "complete_student_profile": "完成你的学员资料",           
    "upload_unofficial_transcripts": "上传你的非官方成绩单",           
    "upload_unofficial_transcript": "上传你的非官方成绩单",           
    "show_completed_steps": "=+ 显示完成的步骤",           
    "hide_completed_steps": "=- 隐藏完成的步骤",           
    "follow_these_steps_online": "重新上线后，请按照以下步骤完成注册：",
    "due_by": "到期",
    "past_due": "逾期任务",
    "send_us_your_transcripts": "索取正式成绩单：",
    "complete_your": "完成你的",
    "sign_tila_disclosure": "签署您的付款计划披露",
    "create_exam_signatures": "创建用于考试身份验证的数字签名",
    "learn_enrollment_requirements": "了解有关<a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"{enrollmentFaqLink}\", target=\"_blank\">注册要求的</a>更多信息。",
    "have_questions": "问题？联系<a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"mailto:{brandEmail}\" target=\"_blank\">{brandEmail}</a>或安排<a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"https://calendly.com/d/d7n-s3n-fx4\" target=\"_blank\">预约</a>。"
}
};