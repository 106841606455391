import { type AnyObject } from '@Types';
import { type ErrorMessage, type DataMessage } from './Livekit.types';

export function isDataMessage(message: AnyObject): message is DataMessage {
    return typeof message.type === 'string';
}

export function isErrorMessage(message: AnyObject): message is ErrorMessage {
    return message.type === 'error';
}
