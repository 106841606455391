window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.toggleable_course_list_buttons = window.locales.am.lessons.stream.toggleable_course_list_buttons || {};window.locales.am.lessons.stream.toggleable_course_list_buttons = {...window.locales.am.lessons.stream.toggleable_course_list_buttons, ...{
    "flat_list": "ዝርግ ዝርዝር",           
    "grouped_by_topic": "በርዕስ የተቧደኑ",           
    "playlist": "ንቁ የአጫዋች ዝርዝር"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.toggleable_course_list_buttons = window.locales.ar.lessons.stream.toggleable_course_list_buttons || {};window.locales.ar.lessons.stream.toggleable_course_list_buttons = {...window.locales.ar.lessons.stream.toggleable_course_list_buttons, ...{
    "flat_list": "قائمة مسطحة",           
    "grouped_by_topic": "مجمّعة حسب الموضوع",           
    "playlist": "قائمة التشغيل النشطة"           
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.toggleable_course_list_buttons = window.locales.en.lessons.stream.toggleable_course_list_buttons || {};window.locales.en.lessons.stream.toggleable_course_list_buttons = {...window.locales.en.lessons.stream.toggleable_course_list_buttons, ...{
    "flat_list": "Flat List",
    "grouped_by_topic": "Grouped by Topic",
    "playlist": "Active Playlist"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.toggleable_course_list_buttons = window.locales.es.lessons.stream.toggleable_course_list_buttons || {};window.locales.es.lessons.stream.toggleable_course_list_buttons = {...window.locales.es.lessons.stream.toggleable_course_list_buttons, ...{
    "flat_list": "Lista plana",           
    "grouped_by_topic": "Agrupado por Topic",           
    "playlist": "playlist activa"           
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.toggleable_course_list_buttons = window.locales.it.lessons.stream.toggleable_course_list_buttons || {};window.locales.it.lessons.stream.toggleable_course_list_buttons = {...window.locales.it.lessons.stream.toggleable_course_list_buttons, ...{
    "flat_list": "Elenco Semplice",           
    "grouped_by_topic": "Raggruppati per Argomento",           
    "playlist": "Playlist Attiva"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.toggleable_course_list_buttons = window.locales.zh.lessons.stream.toggleable_course_list_buttons || {};window.locales.zh.lessons.stream.toggleable_course_list_buttons = {...window.locales.zh.lessons.stream.toggleable_course_list_buttons, ...{
    "flat_list": "按字母顺序",           
    "grouped_by_topic": "按主题分组",           
    "playlist": "有效任务列表"           
}
};