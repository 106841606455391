import { memo, useEffect, useMemo, useState } from 'react';
import { type TranscriptionSegment, type Participant, type TrackPublication, RoomEvent } from 'livekit-client';
import { useMaybeRoomContext } from '@livekit/components-react';
import { generateGuid } from 'guid';
import { isRemoteParticipant } from './participant';

type ParticipantSegment = {
    participant?: Participant;
    segment: TranscriptionSegment;
};

type Messages = {
    id: string;
    participant?: Participant;
    text: string;
    segments: {
        id: string;
        final: boolean;
    }[];
};

function getParticipantName(participant: Participant | undefined | null): string {
    // FIXME: localize AI TUTOR. See show_frames_player-en.json
    // localize "participant" as well
    return isRemoteParticipant(participant) ? 'AI TUTOR' : participant?.name ? participant.name : 'Participant';
}

function mergeSegments(segments: { [id: string]: ParticipantSegment }): Messages[] {
    return Object.values(segments)
        .sort((a, b) => a.segment.firstReceivedTime - b.segment.firstReceivedTime)
        .reduce<Messages[]>((acc, segment) => {
            const prevMessage = acc[acc.length - 1];
            if (prevMessage?.participant === segment.participant) {
                prevMessage.text += ` ${segment.segment.text}`;
                let existingSegment = prevMessage.segments.find(s => s.id === segment.segment.id);
                if (!existingSegment) {
                    existingSegment = {
                        id: segment.segment.id,
                        final: false,
                    };
                    prevMessage.segments.push(existingSegment);
                }
                existingSegment.final = segment.segment.final;
            } else {
                acc.push({
                    id: generateGuid(),
                    participant: segment.participant,
                    text: segment.segment.text,
                    segments: [
                        {
                            id: segment.segment.id,
                            final: segment.segment.final,
                        },
                    ],
                });
            }
            return acc;
        }, []);
}

const LiveTranscriptComponent = ({ token }: { token?: string }) => {
    const room = useMaybeRoomContext();
    const [participantSegments, setParticipantSegments] = useState<{ [id: string]: ParticipantSegment }>({});
    const transcription = useMemo(() => mergeSegments(participantSegments), [participantSegments]);

    // The only thing we use the token for is to reset the transcriptions whenever a new conversation starts
    useEffect(() => {
        if (token) setParticipantSegments({});
    }, [token]);

    useEffect(() => {
        if (!room) {
            return () => {
                // no-op
            };
        }

        const updateTranscriptions = (
            segments: TranscriptionSegment[],
            participant?: Participant,
            _publication?: TrackPublication,
        ) => {
            setParticipantSegments(prev => {
                const newEntries = { ...prev };
                segments.forEach(segment => {
                    newEntries[segment.id] = {
                        participant,
                        segment,
                    };
                });
                return newEntries;
            });
        };

        room.on(RoomEvent.TranscriptionReceived, updateTranscriptions);
        return () => {
            room.off(RoomEvent.TranscriptionReceived, updateTranscriptions);
        };
    }, [room]);

    // Don't show an empty transcript box until the conversation starts
    if (!token && transcription.length === 0) return null;

    return (
        <div className="m-4 flex h-[300px] flex-col overflow-y-auto bg-white p-2 text-black">
            <ul className="flex flex-col">
                {transcription.map(entry => (
                    <li key={entry.id} className="flex">
                        <span className="mr-4 w-32 flex-shrink-0">{getParticipantName(entry.participant)}</span>
                        <span className="flex-grow">{entry.text}</span>
                        {/* <span className="ml-4">{entry.segments.map(s => (s.final ? '✅' : '⏳'))}</span> */}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export const LiveTranscript = memo(LiveTranscriptComponent) as typeof LiveTranscriptComponent;
