window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.stream_link_box = window.locales.am.lessons.stream.stream_link_box || {};window.locales.am.lessons.stream.stream_link_box = {...window.locales.am.lessons.stream.stream_link_box, ...{
    "avg_lesson_time": "አማካይ የትምህርት ጊዜ",           
    "time_limit": "የጊዜ ገደብ",           
    "cancel": "ሰርዝ",           
    "coming_soon": "በቅርቡ ይመጣል",           
    "coming_soon_modal": "የ{{brandName}} የይዘት ቡድን በ<strong>{{title}}</strong>ላይ ጠንክሮ እየሰራ ነው!<br><br> ኮርሱ ሲኖር ማሳወቂያ እንዲደርስዎት ይፈልጋሉ?",           
    "coming_soon_modal_cordova": "የ{{brandName}} የይዘት ቡድን በ{{title}}ላይ ጠንክሮ እየሰራ ነው!\n\n ኮርሱ ሲኖር ማሳወቂያ እንዲደርስዎት ይፈልጋሉ?",           
    "coming_soon_title": "በቅርቡ ይመጣል",           
    "completed_on": "የተጠናቀቀው በ",           
    "favorite": "{optionValue, select, true{አስወግድ ከ} other{አክል ወደ}} የእኔ ኮርሶች",           
    "lessons": "ትምህርቶች",           
    "lessons_completed": "ትምህርቶች ተጠናቅቀዋል",           
    "let_you_know": "አመሰግናለሁ! ይህ ኮርስ ሲኖር እናሳውቅዎታለን።",           
    "minute_abbr": "ደቂቃ",           
    "hours_abbr": "ሰአት",           
    "new": "አዲስ",           
    "ok": "እሺ",           
    "updated": "የዘመነ",           
    "beta": "ቤታ",           
    "notify": "ያሳውቁኝ",           
    "lesson_completion_count": "ከ {{count}} {{completed}} ተጠናቋል"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.stream_link_box = window.locales.ar.lessons.stream.stream_link_box || {};window.locales.ar.lessons.stream.stream_link_box = {...window.locales.ar.lessons.stream.stream_link_box, ...{
    "avg_lesson_time": "متوسط وقت الدرس",           
    "cancel": "إلغاء",           
    "coming_soon": "قريباً",           
    "coming_soon_title": "قريباً",           
    "completed_on": "لقد اكتملت في",           
    "favorite": "{optionValue, select, true{إزالة من} other{إضافة إلى}} دوراتي",           
    "lessons": "الدروس",           
    "lessons_completed": "لقد اكتملت الدروس",           
    "let_you_know": "شكراً لك! إننا سوف نعلمك متى تكون هذه الدورة متاحة.",           
    "minute_abbr": "دقيقة",           
    "new": "جديد",           
    "ok": "حسناً",           
    "updated": "تم التحديث",           
    "beta": "BETA",           
    "notify": "أعلمني",           
    "time_limit": "الوقت المحدد",
    "hours_abbr": "HRS",
    "coming_soon_modal": "فريق المحتوى {{brandName}} يعمل بجد على <strong>{{title}}</strong> ! <br><br> هل ترغب في أن يتم إعلامك عند توفر هذه الدورة التدريبية؟",
    "coming_soon_modal_cordova": "فريق المحتوى {{brandName}} يعمل بجد على {{title}} ! هل ترغب في أن يتم إعلامك عند توفر هذه الدورة التدريبية؟",
    "lesson_completion_count": "{{completed}} من {{count}} درسًا مكتملًا"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.stream_link_box = window.locales.en.lessons.stream.stream_link_box || {};window.locales.en.lessons.stream.stream_link_box = {...window.locales.en.lessons.stream.stream_link_box, ...{
    "avg_lesson_time": "Avg Lesson Time",
    "time_limit": "Time Limit",
    "cancel": "CANCEL",
    "coming_soon": "COMING SOON",
    "coming_soon_modal": "The {{brandName}} content team is hard at work on <strong>{{title}}</strong>!<br><br>Would you like to be notified when this course is available?",
    "coming_soon_modal_cordova": "The {{brandName}} content team is hard at work on {{title}}!\n\nWould you like to be notified when this course is available?",
    "coming_soon_title": "Coming Soon",
    "completed_on": "COMPLETED ON",
    "favorite": "{optionValue, select, true{Remove From} other{Add to}} My Courses",
    "lessons": "Lessons",
    "lessons_completed": "Lessons completed",
    "let_you_know": "Thanks! We'll let you know when this course is available.",
    "minute_abbr": "MIN",
    "hours_abbr": "HRS",
    "new": "NEW",
    "ok": "OK",
    "updated": "UPDATED",
    "beta": "BETA",                                                           
    "notify": "NOTIFY ME",
    "lesson_completion_count": "{{completed}} of {{count}} lessons completed"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.stream_link_box = window.locales.es.lessons.stream.stream_link_box || {};window.locales.es.lessons.stream.stream_link_box = {...window.locales.es.lessons.stream.stream_link_box, ...{
    "avg_lesson_time": "Tiempo de la lección",           
    "cancel": "CANCELAR",           
    "coming_soon": "¡PRÓXIMAMENTE!",           
    "coming_soon_title": "¡Próximamente!",           
    "completed_on": "COMPLETADO EL",           
    "favorite": "{optionValue, select, true{Eliminar de} other{Añadir a}} Mis Cursos",           
    "lessons": "Lecciones",           
    "lessons_completed": "Lecciones",           
    "let_you_know": "¡Gracias! Nosotros te avisaremos cuando este curso esté disponible.",           
    "minute_abbr": "MIN",           
    "new": "NUEVO",           
    "notify": "NOTIFICARME",           
    "updated": "ACTUALIZADO",           
    "beta": "BETA",           
    "ok": "ESTÁ BIEN",           
    "time_limit": "Límite de tiempo",
    "hours_abbr": "HORAS",
    "coming_soon_modal": "¡El equipo de contenido de {{brandName}} está trabajando arduamente en <strong>{{title}}</strong> ! <br><br> ¿Quieres que te avisemos cuando este curso esté disponible?",
    "coming_soon_modal_cordova": "¡El equipo de contenido de {{brandName}} está trabajando arduamente en {{title}} ! ¿Quieres que te avisemos cuando este curso esté disponible?",
    "lesson_completion_count": "{{completed}} de {{count}} lecciones completadas"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.stream_link_box = window.locales.it.lessons.stream.stream_link_box || {};window.locales.it.lessons.stream.stream_link_box = {...window.locales.it.lessons.stream.stream_link_box, ...{
    "avg_lesson_time": "Tempo medio lezione",           
    "cancel": "ANNULLA",           
    "coming_soon": "IN ARRIVO",           
    "coming_soon_title": "In arrivo",           
    "completed_on": "COMPLETATO IL",           
    "favorite": "{optionValue, select, true{Rimuovi da} other{Aggiungi a}} I Miei Corsi",           
    "lessons": "Lezioni",           
    "lessons_completed": "Lezioni completate",           
    "let_you_know": "Grazie! Ti informeremo quando questo corso sarà disponibile.",           
    "minute_abbr": "MIN",           
    "new": "NUOVO",           
    "ok": "OK",           
    "updated": "AGGIORNATO",           
    "beta": "BETA",           
    "notify": "NOTIFICAMI",           
    "time_limit": "Limite di tempo",
    "hours_abbr": "HRS",
    "coming_soon_modal": "Il team di contenuti {{brandName}} è al lavoro su <strong>{{title}}</strong> ! <br><br> Vuoi essere avvisato quando questo corso è disponibile?",
    "coming_soon_modal_cordova": "Il team di contenuti {{brandName}} è al lavoro su {{title}} ! Vuoi essere avvisato quando questo corso è disponibile?",
    "lesson_completion_count": "{{completed}} di {{count}} lezioni completate"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.stream_link_box = window.locales.zh.lessons.stream.stream_link_box || {};window.locales.zh.lessons.stream.stream_link_box = {...window.locales.zh.lessons.stream.stream_link_box, ...{
    "avg_lesson_time": "平均上课时间",           
    "cancel": "取消",           
    "coming_soon": "即将推出",           
    "coming_soon_title": "即将推出",           
    "completed_on": "完成于",           
    "favorite": "{optionValue, select, true{删除自} other{添加到}} 我的课程",           
    "lessons": "课业",           
    "lessons_completed": "已完成课业",           
    "let_you_know": "谢谢！我们将在此课程可用时告诉您。",           
    "minute_abbr": "分钟",           
    "new": "新增",           
    "updated": "已更新",           
    "beta": "测试版",           
    "notify": "通知我",           
    "ok": "确定",           
    "time_limit": "时间限制",           
    "hours_abbr": "HRS",           
    "coming_soon_modal": "{{brandName}}内容团队正在努力解决<strong>{{title}}</strong>！<br><br>课程开启后，是否需要我们向你发送通知？",           
    "coming_soon_modal_cordova": "{{brandName}}内容团队正在努力解决{{title}}！\r\n\r\n课程开启后，是否需要我们向你发送通知？",           
    "lesson_completion_count": "已完成{{completed}} {{count}}节）"
}
};