window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.lesson = window.locales.am.lessons.lesson || {};window.locales.am.lessons.lesson.show_frames_player = window.locales.am.lessons.lesson.show_frames_player || {};window.locales.am.lessons.lesson.show_frames_player = {...window.locales.am.lessons.lesson.show_frames_player, ...{
    "begin": "{optionValue, select, true{SMARTCASE ጀምር} other{እንጅምር}}",           
    "can_keep_waiting": "መጠበቅዎን መቀጠል ወይም ለመውጣት ጠቅ ማድረግ ይችላሉ።",           
    "estimated_time": "የተገመተው ጊዜ፡- {{mins}} ደቂቃዎች",           
    "exit_lesson": "ከትምህርቱ ውጣ",           
    "hint": "ፍንጭ ስጠኝ",           
    "while_to_load": "ይህን ማያ ገፅ ለመጫን ትንሽ ጊዜ እየወሰደ ነው...",           
    "begin_test": "ፈተናውን ጀምር",           
    "explain": "ይህንን ማያ ገጽ ያብራሩ",
    "return_to_screen": "ወደ ማያ ገጽ ተመለስ",
    "review_this_lesson": "ይህን ትምህርት ይገምግሙ",
    "begin_exam": "ፈተናን ጀምር",
    "recap_last_lesson": "የመጨረሻውን ትምህርት እንደገና ያቅርቡ",
    "aiTutor": "{ai} አስተማሪ",
    "chat_with_ai": "ከ {ai} አስተማሪ ጋር ተወያይ",
    "review_with_ai": "ከ {ai} ሞግዚት ጋር ይገምግሙ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.lesson = window.locales.ar.lessons.lesson || {};window.locales.ar.lessons.lesson.show_frames_player = window.locales.ar.lessons.lesson.show_frames_player || {};window.locales.ar.lessons.lesson.show_frames_player = {...window.locales.ar.lessons.lesson.show_frames_player, ...{
    "begin": "{optionValue, select, true{ابدأ SMARTCASE} other{لتبدأ}}",           
    "can_keep_waiting": "يمكنك الانتظار أو انقر للخروج.",           
    "estimated_time": "الوقت المقدر: {{mins}} دقائق",           
    "exit_lesson": "الخروج من الدرس",           
    "hint": "اعطني تلميحاً",           
    "while_to_load": "تأخذ هذه الشاشة بعض الوقت كي يتم تحميلها...",           
    "begin_test": "بدء الاختبار",
    "explain": "شرح هذه الشاشة",
    "return_to_screen": "العودة إلى الشاشة",
    "review_this_lesson": "قم بمراجعة هذا الدرس",
    "begin_exam": "ابدأ الامتحان",
    "recap_last_lesson": "تلخيص الدرس الأخير",
    "aiTutor": "{ai} مدرس",
    "chat_with_ai": "الدردشة مع {ai} المعلم",
    "review_with_ai": "قم بالمراجعة مع مدرس {ai}"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.lesson = window.locales.en.lessons.lesson || {};window.locales.en.lessons.lesson.show_frames_player = window.locales.en.lessons.lesson.show_frames_player || {};window.locales.en.lessons.lesson.show_frames_player = {...window.locales.en.lessons.lesson.show_frames_player, ...{
    "begin": "{optionValue, select, true{BEGIN SMARTCASE} other{GET STARTED}}",
    "can_keep_waiting": "You can keep waiting or click to exit.",
    "estimated_time": "Estimated time: {{mins}} mins",
    "exit_lesson": "Exit Lesson",
    "hint": "Give me a hint",
    "while_to_load": "This screen is taking a while to load ...",
    "begin_test": "BEGIN TEST",
    "explain": "Explain this screen",
    "aiTutor": "{ai} TUTOR",
    "review_this_lesson": "Review this lesson",
    "recap_last_lesson": "Recap last lesson",
    "return_to_screen": "Return to Screen",
    "begin_exam": "BEGIN EXAM",
    "chat_with_ai": "Chat with {ai} Tutor",
    "review_with_ai": "Review with {ai} Tutor"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.lesson = window.locales.es.lessons.lesson || {};window.locales.es.lessons.lesson.show_frames_player = window.locales.es.lessons.lesson.show_frames_player || {};window.locales.es.lessons.lesson.show_frames_player = {...window.locales.es.lessons.lesson.show_frames_player, ...{
    "begin": "{optionValue, select, true{INICIA SMARTCASE} other{EMPIEZA}}",           
    "can_keep_waiting": "Puedes seguir esperando o hacer clic para salir.",           
    "estimated_time": "Tiempo estimado: {{mins}} minutos",           
    "exit_lesson": "Salir de la Lección",           
    "hint": "Dame una pista",           
    "while_to_load": "Esta pantalla está tardando un poco en cargar...",           
    "begin_test": "INICIAR PRUEBA",
    "explain": "Explica esta pantalla",
    "return_to_screen": "Volver a la pantalla",
    "review_this_lesson": "Revisa esta lección",
    "begin_exam": "COMENZAR EL EXAMEN",
    "recap_last_lesson": "Resumen de la última lección",
    "aiTutor": "{ai} TUTOR",
    "chat_with_ai": "Chatea con {ai} Tutor",
    "review_with_ai": "Revisar con {ai} Tutor"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.lesson = window.locales.it.lessons.lesson || {};window.locales.it.lessons.lesson.show_frames_player = window.locales.it.lessons.lesson.show_frames_player || {};window.locales.it.lessons.lesson.show_frames_player = {...window.locales.it.lessons.lesson.show_frames_player, ...{
    "begin": "{optionValue, select, true{INIZIA SMARTCASE} other{COMINCIA}}",           
    "can_keep_waiting": "Puoi attendere ancora o fare clic su esci.",           
    "estimated_time": "Tempo stimato: {{mins}} min",           
    "exit_lesson": "Esci dalla Lezione",           
    "hint": "Dammi un indizio",           
    "while_to_load": "Questa schermata ci sta mettendo un po' a caricarsi…",           
    "begin_test": "INIZIA TEST",           
    "explain": "Spiega questa schermata",
    "return_to_screen": "Ritorna allo schermo",
    "review_this_lesson": "Rivedi questa lezione",
    "begin_exam": "INIZIA L'ESAME",
    "recap_last_lesson": "Riepiloga l'ultima lezione",
    "aiTutor": "{ai} TUTORE",
    "chat_with_ai": "Chatta con il tutor {ai}",
    "review_with_ai": "Ripasso con il Tutor {ai}"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.lesson = window.locales.zh.lessons.lesson || {};window.locales.zh.lessons.lesson.show_frames_player = window.locales.zh.lessons.lesson.show_frames_player || {};window.locales.zh.lessons.lesson.show_frames_player = {...window.locales.zh.lessons.lesson.show_frames_player, ...{
    "begin": "{optionValue, select, true{开始 SMARTCASE} other{开始}}",           
    "can_keep_waiting": "您可以继续等待，或者单击以退出。",           
    "estimated_time": "估计时间：{{mins}} 分钟",           
    "exit_lesson": "退出课业",           
    "hint": "给我一个提示",           
    "while_to_load": "此屏幕正在加载中...",           
    "begin_test": "开始测试",           
    "explain": "解释一下这个屏幕",
    "return_to_screen": "返回屏幕",
    "review_this_lesson": "回顾本课",
    "begin_exam": "开始考试",
    "recap_last_lesson": "回顾上节课",
    "aiTutor": "{ai}导师",
    "chat_with_ai": "与{ai}导师聊天",
    "review_with_ai": "与{ai}导师一起复习"
}
};