window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.shared = window.locales.am.lessons.shared || {};window.locales.am.lessons.shared.content_access_helper = window.locales.am.lessons.shared.content_access_helper || {};window.locales.am.lessons.shared.content_access_helper = {...window.locales.am.lessons.shared.content_access_helper, ...{
    "locked": "ተቆልፏል",           
    "reason_message_stream_default": "ይህ ኮርስ በዚህ ጊዜ ሊከፈት አይችልም።",           
    "reason_title_stream_default": "ኮርሱ ተቆልፏል",           
    "reason_message_playlist_default": "ይህ ትኩረት በዚህ ጊዜ ሊከፈት አይችልም።",           
    "reason_title_playlist_default": "የአጫዋች ዝርዝሩ ተቆልፏል",           
    "reason_message_lesson_default": "ይህ ትምህርት በዚህ ጊዜ ሊከፈት አይችልም።",           
    "reason_title_lesson_default": "ትምህርቱ ተቆልፏል",           
    "reason_title_stream_no_user": "የተቆለፈ ኮርስ",           
    "reason_title_stream_locked": "የተቆለፈ ኮርስ",           
    "reason_message_stream_coming_soon": "ይህ ኮርስ በዚህ ጊዜ ሊከፈት አይችልም።",           
    "reason_title_stream_coming_soon": "ኮርሱ ተቆልፏል",           
    "reason_message_stream_unmet_prerequisites_playlist": "ይህንን ፈተና ለመክፈት፣ በተገቢው የአጫዋች ዝርዝር ውስጥ ሁሉንም የቅድሚያ ኮርሶችን ያጠናቅቁ።",           
    "reason_message_stream_unmet_prerequisites_concentration": "ይህንን ፈተና ለመክፈት፣ በተገቢው ትኩረት ውስጥ ያሉ ኮርሶችን ያጠናቅቁ።",           
    "reason_message_stream_unmet_prerequisites_specialization": "ይህንን ፈተና ለመክፈት፣ በተገቢው ስፔሻላይዜሽን ውስጥ ያሉ ኮርሶችን ያጠናቅቁ።",           
    "reason_title_stream_unmet_prerequisites_playlist": "ፈተና ተቆልፏል",           
    "reason_title_stream_unmet_prerequisites_concentration": "ፈተና ተቆልፏል",           
    "reason_title_stream_unmet_prerequisites_specialization": "ፈተና ተቆልፏል",           
    "reason_message_stream_unmet_prerequisites_schedule": "ይህን ፈተና ለመክፈት፣ በእርስዎ የክፍል መርሃግብር ውስጥ ያሉትን ሁሉንም የቅድሚያ አስፈላጊ ኮርሶች ያጠናቅቁ።",           
    "reason_title_stream_unmet_prerequisites_schedule": "ፈተና ተቆልፏል",           
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_schedule": "በክፍልዎ ውስጥ ያሉ ሁሉም የቅድሚያ አስፈላጊ ኮርሶች ሲጠናቀቁ፣ ይህ ፈተና በ {{openTimeStr}} ላይ እንዲገኝ ይደረጋል።",           
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_schedule": "ፈተና ተቆልፏል",           
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_playlist": "በተገቢው የአጫዋች ዝርዝር ውስጥ ያሉ ሁሉም የቅድሚያ ኮርሶች ሲጠናቀቁ፣ ይህ ፈተና በ {{openTimeStr}} ላይ እንዲገኝ ይደረጋል።",           
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_playlist": "ፈተና ተቆልፏል",           
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_concentration": "በተገቢው ትኩረት ውስጥ ያሉ ሁሉም የቅድሚያ ኮርሶች ሲጠናቀቁ፣ ይህ ፈተና በ {{openTimeStr}} ላይ እንዲገኝ ይደረጋል።",           
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_concentration": "ፈተና ተቆልፏል",           
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_specialization": "በተገቢው ስፔሻላይዜሽን ውስጥ ያሉ ሁሉም የቅድሚያ ኮርሶች ሲጠናቀቁ፣ ይህ ፈተና በ {{openTimeStr}} ላይ እንዲገኝ ይደረጋል።",           
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_specialization": "ፈተና ተቆልፏል",           
    "reason_message_stream_before_launch_window": "ይህ ፈተና በ {{openTimeStr}} ላይ እንዲገኝ ይደረጋል።",           
    "reason_title_stream_before_launch_window": "ፈተና ተቆልፏል",           
    "reason_title_stream_requires_idology_verification": "ኮርሱ ተቆልፏል",           
    "reason_message_stream_requires_idology_verification": "ለመክፈት፣ የእርስዎን የማንነት ማረጋገጫ ያጠናቅቁ።",           
    "reason_message_lesson_completed_test": "ይህ ፈተና አሁን ዝግ ነው።",           
    "reason_title_lesson_completed_test": "ፈተና ተቆልፏል",           
    "reason_title_lesson_no_user": "ትምህርቱ ተቆልፏል",           
    "reason_message_lesson_exam_time_run_out": "ይህ ፈተና አሁን ዝግ ነው።",           
    "reason_title_lesson_exam_time_run_out": "ፈተና ተቆልፏል",           
    "reason_message_playlist_requires_idology_verification": "ለመክፈት፣ የእርስዎን የማንነት ማረጋገጫ ያጠናቅቁ።",           
    "completed": "ተጠናቋል።",           
    "locked_curriculum_message_rejected": "የ {{programTitle}} መርሃግብር አካል ሆኖ ብቻ የሚገኝ ሙሉ ሥርዓተ-ትምህርት።",
    "reason_title_stream_placed_on_academic_hold": "ኮርሱ ተቆልፏል",           
    "reason_message_stream_unavailable_offline": "ይህ ኮርስ ከመስመር ውጭ በሚሆንበት ጊዜ አይገኝም።",           
    "reason_title_stream_unavailable_offline": "ከመስመር ውጪ አይገኝም",           
    "reason_message_stream_no_user": "ይህ ኮርስ የሙሉ ዲግሪ ፕሮግራም አካል ሆኖ ይገኛል።",
    "reason_message_stream_locked": "ይህ ኮርስ የሙሉ ዲግሪ ፕሮግራም አካል ሆኖ ይገኛል።",
    "reason_message_lesson_no_user": "ይህ ትምህርት እንደ የሙሉ ዲግሪ ፕሮግራም አካል ሆኖ ይገኛል።",
    "locked_curriculum_message_payment_overdue": "የእኛ መዛግብት የእርስዎ መለያ አንድ ወይም ከዚያ በላይ የሚፈለጉ ክፍያዎች ስለጎደለው ሥርዓተ ትምህርትዎ ተቆልፏል።",
    "reason_title_stream_requires_signed_tila_disclosure": "ኮርስ ተቆልፏል",
    "reason_message_stream_requires_signed_tila_disclosure": "ለመክፈት የክፍያ እቅድዎን ይፋ ማድረግ ይፈርሙ።",
    "reason_message_playlist_requires_signed_tila_disclosure": "ለመክፈት የክፍያ እቅድዎን ይፋ ማድረግ ይፈርሙ።",
    "reason_title_lesson_requires_biosig_verification": "ፈተና ተቆልፏል",
    "reason_message_lesson_requires_biosig_verification": "ማንነትህን ማረጋገጥ አለብህ።",
    "reason_message_lesson_unavailable_offline": "ይህ ትምህርት ከመስመር ውጭ ሲሆን አይገኝም።",
    "reason_title_lesson_unavailable_offline": "ከመስመር ውጭ አይገኝም",
    "reason_message_lesson_coming_soon": "ይህ ትምህርት በዚህ ጊዜ ሊከፈት አይችልም.",
    "locked_curriculum_message": "ሙሉ ስርአተ ትምህርቱ የሚከፈተው ፕሮግራሙ ሲጀመር ነው።",
    "reason_title_stream_must_switch_program_to_launch_exam": "ፈተና ተቆልፏል",
    "reason_message_stream_must_switch_program_to_launch_exam": "ይህንን ፈተና ለመክፈት፣ እባክዎ ወደተካተተበት ፕሮግራም ይቀይሩ።",
    "reason_message_stream_placed_on_academic_hold": "በአስተዳደራዊ ይዞታ ላይ ተቀምጠዋል። ለበለጠ መረጃ እባክዎን <a href=\"mailto:{{registrarEmail}}\">{{registrarEmail}}</a> ን ያግኙ።",
    "reason_message_playlist_placed_on_second_academic_probation_hold": "በአካዳሚክ ማቆያ ላይ ተጭነዋል። መያዣው እንዲወገድ <a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">ከአካዳሚክ አማካሪ ጋር መገናኘት</a> አለቦት።",
    "reason_message_playlist_placed_on_academic_hold": "በአስተዳደራዊ ይዞታ ላይ ተቀምጠዋል። ለበለጠ መረጃ እባክዎን <a href=\"mailto:{{registrarEmail}}\">{{registrarEmail}}</a> ን ያግኙ።",
    "reason_message_stream_placed_on_second_academic_probation_hold": "በአካዳሚክ ማቆያ ላይ ተጭነዋል። መያዣው እንዲወገድ <a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">ከአካዳሚክ አማካሪ ጋር መገናኘት</a> አለቦት።",
    "reason_title_stream_placed_on_second_academic_probation_hold": "የተቆለፈ ኮርስ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.shared = window.locales.ar.lessons.shared || {};window.locales.ar.lessons.shared.content_access_helper = window.locales.ar.lessons.shared.content_access_helper || {};window.locales.ar.lessons.shared.content_access_helper = {...window.locales.ar.lessons.shared.content_access_helper, ...{
    "locked": "مغلقة",           
    "reason_title_stream_locked": "دورة مغلقة",           
    "reason_message_stream_before_launch_window": "سيصبح هذا الامتحان متاح في {{openTimeStr}} .",
    "reason_title_stream_before_launch_window": "امتحان مغلق",
    "reason_message_stream_default": "لا يمكن فتح هذه الدورة التدريبية في هذا الوقت.",
    "reason_message_playlist_default": "لا يمكن فتح هذا التركيز في هذا الوقت.",
    "reason_message_lesson_default": "لا يمكن فتح هذا الدرس في الوقت الحالي.",
    "reason_title_stream_default": "بالطبع مغلق",
    "reason_title_playlist_default": "قائمة التشغيل مؤمنة",
    "reason_title_lesson_default": "الدرس مقفل",
    "reason_message_lesson_exam_time_run_out": "تم إغلاق هذا الامتحان الآن.",
    "reason_title_lesson_exam_time_run_out": "امتحان مغلق",
    "reason_message_lesson_completed_test": "تم إغلاق هذا الامتحان الآن.",
    "reason_title_lesson_completed_test": "امتحان مغلق",
    "reason_title_stream_no_user": "دورة مقفلة",
    "reason_title_lesson_no_user": "الدرس مقفل",
    "reason_message_stream_unmet_prerequisites_playlist": "لإلغاء هذا الاختبار، أكمل جميع الدورات السابقة في قائمة التشغيل المناسبة.",
    "reason_title_stream_unmet_prerequisites_playlist": "امتحان مقفل",
    "reason_message_stream_unmet_prerequisites_schedule": "لإلغاء هذا الامتحان، أكمل جميع الدورات المطلوبة مسبقا في جدول الحصص.",
    "reason_title_stream_unmet_prerequisites_schedule": "امتحان مقفل",
    "reason_message_stream_unmet_prerequisites_concentration": "لفتح هذا الامتحان، أكمل جميع الدورات السابقة في التركيز المناسب.",
    "reason_message_stream_unmet_prerequisites_specialization": "لإلغاء هذا الامتحان، أكمل جميع الدورات السابقة في التخصص المناسب.",
    "reason_title_stream_unmet_prerequisites_concentration": "امتحان مقفل",
    "reason_title_stream_unmet_prerequisites_specialization": "امتحان مقفل",
    "reason_message_stream_coming_soon": "لا يمكن فتح هذه الدورة التدريبية في هذا الوقت.",
    "reason_title_stream_coming_soon": "بالطبع مغلق",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_schedule": "سيصبح هذا الامتحان متاحا على {{openTimeStr}} بعد {{openTimeStr}} جميع الدورات المطلوبة في جدول الحصص الدراسية.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_schedule": "امتحان مقفل",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_playlist": "سيصبح هذا الامتحان متاحا على {{openTimeStr}} بعد {{openTimeStr}} جميع الدورات السابقة في قائمة التشغيل المناسبة.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_playlist": "امتحان مقفل",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_concentration": "سيصبح هذا الامتحان متاحا على {{openTimeStr}} بمجرد {{openTimeStr}} جميع الدورات السابقة في التركيز المناسب.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_concentration": "امتحان مقفل",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_specialization": "سيصبح هذا الامتحان متاحا على {{openTimeStr}} بمجرد {{openTimeStr}} جميع الدورات السابقة في التخصص المناسب.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_specialization": "امتحان مقفل",
    "completed": "مكتملة",           
    "reason_title_stream_requires_idology_verification": "بالطبع مقفل",
    "reason_message_stream_requires_idology_verification": "لإلغاء القفل ، أكمل التحقق من هويتك.",
    "reason_message_playlist_requires_idology_verification": "لإلغاء القفل ، أكمل التحقق من هويتك.",
    "reason_title_stream_placed_on_academic_hold": "بالطبع مغلق",
    "reason_message_stream_unavailable_offline": "هذه الدورة غير متاحة في وضع عدم الاتصال.",
    "reason_title_stream_unavailable_offline": "غير متاح حاليا",
    "locked_curriculum_message_rejected": "المنهج الكامل متاح فقط كجزء من برنامج {{programTitle}} .",
    "reason_message_stream_no_user": "هذه الدورة متاحة كجزء من برنامج الدرجة الكاملة.",
    "reason_message_stream_locked": "هذه الدورة متاحة كجزء من برنامج الدرجة الكاملة.",
    "reason_message_lesson_no_user": "هذا الدرس متاح كجزء من برنامج الدرجة الكاملة.",
    "locked_curriculum_message_payment_overdue": "منهجك مقفل لأن سجلاتنا تظهر أن حسابك يفتقد دفعة أو أكثر من المدفوعات المطلوبة.",
    "reason_title_stream_requires_signed_tila_disclosure": "بالطبع مغلق",
    "reason_message_stream_requires_signed_tila_disclosure": "لفتح ، قم بالتوقيع على كشف خطة الدفع الخاصة بك.",
    "reason_message_playlist_requires_signed_tila_disclosure": "لفتح ، قم بالتوقيع على كشف خطة الدفع الخاصة بك.",
    "reason_title_lesson_requires_biosig_verification": "الامتحان مغلق",
    "reason_message_lesson_requires_biosig_verification": "يجب عليك التحقق من هويتك.",
    "reason_message_lesson_unavailable_offline": "هذا الدرس غير متاح في وضع عدم الاتصال.",
    "reason_title_lesson_unavailable_offline": "غير متاح دون اتصال",
    "reason_message_lesson_coming_soon": "لا يمكن فتح هذا الدرس في هذا الوقت.",
    "locked_curriculum_message": "يفتح المنهج الكامل عند بدء البرنامج.",
    "reason_title_stream_must_switch_program_to_launch_exam": "الامتحان مغلق",
    "reason_message_stream_must_switch_program_to_launch_exam": "لفتح هذا الاختبار، يرجى التبديل إلى البرنامج الذي تم تضمينه فيه.",
    "reason_message_stream_placed_on_academic_hold": "لقد تم وضعك تحت المراقبة الإدارية. يرجى الاتصال بـ <a href=\"mailto:{{registrarEmail}}\">{{registrarEmail}}</a> لمزيد من المعلومات.",
    "reason_message_playlist_placed_on_second_academic_probation_hold": "لقد تم وضعك تحت الإيقاف الأكاديمي. يجب عليك <a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">مقابلة المستشار الأكاديمي</a> لرفع الإيقاف.",
    "reason_message_playlist_placed_on_academic_hold": "لقد تم وضعك تحت المراقبة الإدارية. يرجى الاتصال بـ <a href=\"mailto:{{registrarEmail}}\">{{registrarEmail}}</a> لمزيد من المعلومات.",
    "reason_message_stream_placed_on_second_academic_probation_hold": "لقد تم وضعك تحت الإيقاف الأكاديمي. يجب عليك <a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">مقابلة المستشار الأكاديمي</a> لرفع الإيقاف.",
    "reason_title_stream_placed_on_second_academic_probation_hold": "دورة مغلقة"           
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.shared = window.locales.en.lessons.shared || {};window.locales.en.lessons.shared.content_access_helper = window.locales.en.lessons.shared.content_access_helper || {};window.locales.en.lessons.shared.content_access_helper = {...window.locales.en.lessons.shared.content_access_helper, ...{
    "locked": "LOCKED",
    "reason_message_stream_default": "This course cannot be opened at this time.",
    "reason_title_stream_default": "Course Locked",
    "reason_message_playlist_default": "This concentration cannot be opened at this time.",
    "reason_title_playlist_default": "Playlist Locked",
    "reason_message_lesson_default": "This lesson cannot be opened at this time.",
    "reason_title_lesson_default": "Lesson Locked",
    "reason_message_stream_no_user": "This course is available as part of the full degree program.",
    "reason_title_stream_no_user": "Locked Course",
    "reason_message_stream_locked": "This course is available as part of the full degree program.",
    "reason_title_stream_locked": "Locked Course",
    "reason_message_stream_unavailable_offline": "This course is unavailable while offline.",
    "reason_message_lesson_unavailable_offline": "This lesson is unavailable while offline.",
    "reason_title_stream_unavailable_offline": "Unavailable Offline",
    "reason_title_lesson_unavailable_offline": "Unavailable Offline",
    "reason_message_lesson_coming_soon": "This lesson cannot be opened at this time.",
    "reason_message_stream_coming_soon": "This course cannot be opened at this time.",
    "reason_title_stream_coming_soon": "Course Locked",
    "reason_message_stream_unmet_prerequisites_playlist": "To unlock this exam, complete all prior courses in the appropriate playlist.",
    "reason_message_stream_unmet_prerequisites_concentration": "To unlock this exam, complete all prior courses in the appropriate concentration.",
    "reason_message_stream_unmet_prerequisites_specialization": "To unlock this exam, complete all prior courses in the appropriate specialization.",
    "reason_title_stream_unmet_prerequisites_playlist": "Exam Locked",
    "reason_title_stream_unmet_prerequisites_concentration": "Exam Locked",
    "reason_title_stream_unmet_prerequisites_specialization": "Exam Locked",
    "reason_message_stream_unmet_prerequisites_schedule": "To unlock this exam, complete all prior required courses in your class schedule.",
    "reason_title_stream_unmet_prerequisites_schedule": "Exam Locked",
    "reason_title_stream_must_switch_program_to_launch_exam": "Exam Locked",
    "reason_message_stream_must_switch_program_to_launch_exam": "To unlock this exam, please switch to the program in which it is included.",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_schedule": "This exam will become available on {{openTimeStr}} once all prior required courses in your class schedule are completed.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_schedule": "Exam Locked",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_playlist": "This exam will become available on {{openTimeStr}} once all prior courses in the appropriate playlist are completed.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_playlist": "Exam Locked",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_concentration": "This exam will become available on {{openTimeStr}} once all prior courses in the appropriate concentration are completed.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_concentration": "Exam Locked",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_specialization": "This exam will become available on {{openTimeStr}} once all prior courses in the appropriate specialization are completed.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_specialization": "Exam Locked",
    "reason_message_stream_before_launch_window": "This exam will become available on {{openTimeStr}}.",
    "reason_title_stream_before_launch_window": "Exam Locked",
    "reason_title_stream_requires_idology_verification": "Course Locked",
    "reason_message_stream_requires_idology_verification": "To unlock, complete your identity verification.",
    "reason_title_stream_requires_signed_tila_disclosure": "Course Locked",
    "reason_message_stream_requires_signed_tila_disclosure": "To unlock, sign your payment plan disclosure.",
    "reason_message_lesson_completed_test": "This exam is now closed.",
    "reason_title_lesson_completed_test": "Exam Locked",
    "reason_message_lesson_no_user": "This lesson is available as part of the full degree program.",
    "reason_title_lesson_no_user": "Lesson Locked",
    "reason_message_lesson_exam_time_run_out": "This exam is now closed.",
    "reason_title_lesson_exam_time_run_out": "Exam Locked",
    "reason_message_playlist_requires_idology_verification": "To unlock, complete your identity verification.",
    "reason_message_playlist_requires_signed_tila_disclosure": "To unlock, sign your payment plan disclosure.",
    "completed": "COMPLETED",
    "locked_curriculum_message": "The full curriculum unlocks upon commencement of the program.",
    "locked_curriculum_message_rejected": "Full curriculum only available as part of the {{programTitle}} program.",
    "locked_curriculum_message_payment_overdue": "Your curriculum is locked because our records show your account is missing one or more required payments.",

                                                                                                                    
                                                                                                                          
    "reason_title_stream_placed_on_academic_hold": "Course Locked",
    "reason_title_stream_placed_on_second_academic_probation_hold": "Course Locked",
    "reason_message_stream_placed_on_academic_hold": "You have been placed on an Administrative Hold. Please contact <a href=\"mailto:{{registrarEmail}}\">{{registrarEmail}}</a> for more information.",
    "reason_message_stream_placed_on_second_academic_probation_hold": "You have been placed on academic hold. You must <a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">meet with an Academic Advisor</a> to have the hold removed.",
    "reason_message_playlist_placed_on_second_academic_probation_hold": "You have been placed on academic hold. You must <a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">meet with an Academic Advisor</a> to have the hold removed.",
    "reason_message_playlist_placed_on_academic_hold": "You have been placed on an Administrative Hold. Please contact <a href=\"mailto:{{registrarEmail}}\">{{registrarEmail}}</a> for more information.",
    "reason_message_lesson_requires_biosig_verification": "You must verify your identity.",
    "reason_title_lesson_requires_biosig_verification": "Exam Locked"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.shared = window.locales.es.lessons.shared || {};window.locales.es.lessons.shared.content_access_helper = window.locales.es.lessons.shared.content_access_helper || {};window.locales.es.lessons.shared.content_access_helper = {...window.locales.es.lessons.shared.content_access_helper, ...{
    "locked": "BLOQUEADO",           
    "reason_title_stream_locked": "Curso bloqueado",           
    "reason_message_stream_before_launch_window": "Este examen estará disponible en {{openTimeStr}} .",
    "reason_title_stream_before_launch_window": "Examen bloqueado",
    "reason_message_stream_default": "Este curso no se puede abrir en este momento.",
    "reason_message_playlist_default": "Esta concentración no se puede abrir en este momento.",
    "reason_message_lesson_default": "Esta lección no se puede abrir en este momento.",
    "reason_title_stream_default": "Curso bloqueado",
    "reason_title_playlist_default": "Lista de reproducción bloqueada",
    "reason_title_lesson_default": "Lección bloqueada",
    "reason_message_lesson_exam_time_run_out": "Este examen se ha cerrado.",
    "reason_title_lesson_exam_time_run_out": "Examen bloqueado",
    "reason_message_lesson_completed_test": "Este examen se ha cerrado.",
    "reason_title_lesson_completed_test": "Examen bloqueado",
    "reason_title_stream_no_user": "Curso bloqueado",
    "reason_title_lesson_no_user": "Lección bloqueada",
    "reason_message_stream_unmet_prerequisites_playlist": "Para desbloquear este examen, complete todos los cursos anteriores en la lista de reproducción apropiada.",
    "reason_title_stream_unmet_prerequisites_playlist": "Examen cerrado",
    "reason_message_stream_unmet_prerequisites_schedule": "Para desbloquear este examen, complete todos los cursos previos requeridos en su horario de clases.",
    "reason_title_stream_unmet_prerequisites_schedule": "Examen cerrado",
    "reason_message_stream_unmet_prerequisites_concentration": "Para desbloquear este examen, complete todos los cursos anteriores en la concentración apropiada.",
    "reason_message_stream_unmet_prerequisites_specialization": "Para desbloquear este examen, complete todos los cursos anteriores en la especialización apropiada.",
    "reason_title_stream_unmet_prerequisites_concentration": "Examen cerrado",
    "reason_title_stream_unmet_prerequisites_specialization": "Examen cerrado",
    "reason_message_stream_coming_soon": "Este curso no se puede abrir en este momento.",
    "reason_title_stream_coming_soon": "Curso bloqueado",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_schedule": "Este examen estará disponible en {{openTimeStr}} una vez completados todos los cursos previos requeridos en su programa de clases.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_schedule": "Examen cerrado",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_playlist": "Este examen estará disponible en {{openTimeStr}} una vez completados todos los cursos anteriores en la lista de reproducción apropiada.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_playlist": "Examen cerrado",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_concentration": "Este examen estará disponible en {{openTimeStr}} una vez completados todos los cursos anteriores en la concentración apropiada.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_concentration": "Examen cerrado",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_specialization": "Este examen estará disponible en {{openTimeStr}} una vez completados todos los cursos anteriores en la especialización apropiada.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_specialization": "Examen cerrado",
    "completed": "TERMINADO",           
    "locked_curriculum_message_rejected": "Currículo completo solo disponible como parte del programa de {{programTitle}}.",
    "reason_title_stream_requires_idology_verification": "Curso bloqueado",
    "reason_message_stream_requires_idology_verification": "Para desbloquear, complete su verificación de identidad.",
    "reason_message_playlist_requires_idology_verification": "Para desbloquear, complete su verificación de identidad.",
    "reason_title_stream_placed_on_academic_hold": "Curso bloqueado",
    "reason_message_stream_unavailable_offline": "Este curso no está disponible sin conexión.",
    "reason_title_stream_unavailable_offline": "No disponible sin conexión",
    "reason_message_stream_no_user": "Este curso está disponible como parte del programa de grado completo.",
    "reason_message_stream_locked": "Este curso está disponible como parte del programa de grado completo.",
    "reason_message_lesson_no_user": "Esta lección está disponible como parte del programa de grado completo.",
    "locked_curriculum_message_payment_overdue": "Su plan de estudios está bloqueado porque nuestros registros muestran que a su cuenta le faltan uno o más pagos requeridos.",
    "reason_title_stream_requires_signed_tila_disclosure": "Curso bloqueado",
    "reason_message_stream_requires_signed_tila_disclosure": "Para desbloquear, firme la divulgación de su plan de pago.",
    "reason_message_playlist_requires_signed_tila_disclosure": "Para desbloquear, firme la divulgación de su plan de pago.",
    "reason_title_lesson_requires_biosig_verification": "examen bloqueado",
    "reason_message_lesson_requires_biosig_verification": "Debe verificar su identidad.",
    "reason_message_lesson_unavailable_offline": "Esta lección no está disponible sin conexión.",
    "reason_title_lesson_unavailable_offline": "No disponible sin conexión",
    "reason_message_lesson_coming_soon": "Esta lección no se puede abrir en este momento.",
    "locked_curriculum_message": "El plan de estudios completo se desbloquea al comienzo del programa.",
    "reason_title_stream_must_switch_program_to_launch_exam": "Examen bloqueado",
    "reason_message_stream_must_switch_program_to_launch_exam": "Para desbloquear este examen, cambie al programa en el que está incluido.",
    "reason_message_stream_placed_on_academic_hold": "Se le ha puesto en espera administrativa. Comuníquese con <a href=\"mailto:{{registrarEmail}}\">{{registrarEmail}}</a> para obtener más información.",
    "reason_message_playlist_placed_on_second_academic_probation_hold": "Se le ha suspendido el acceso a su material académico. Debe <a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">reunirse con un asesor académico</a> para que le retiren la suspensión.",
    "reason_message_playlist_placed_on_academic_hold": "Se le ha puesto en espera administrativa. Comuníquese con <a href=\"mailto:{{registrarEmail}}\">{{registrarEmail}}</a> para obtener más información.",
    "reason_message_stream_placed_on_second_academic_probation_hold": "Se le ha suspendido el acceso a su material académico. Debe <a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">reunirse con un asesor académico</a> para que le retiren la suspensión.",
    "reason_title_stream_placed_on_second_academic_probation_hold": "Curso bloqueado"           
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.shared = window.locales.it.lessons.shared || {};window.locales.it.lessons.shared.content_access_helper = window.locales.it.lessons.shared.content_access_helper || {};window.locales.it.lessons.shared.content_access_helper = {...window.locales.it.lessons.shared.content_access_helper, ...{
    "locked": "BLOCCATO",           
    "reason_message_stream_default": "Questo corso non può essere aperto in questo momento.",           
    "reason_title_stream_default": "Corso Bloccato",           
    "reason_message_playlist_default": "Questo indirizzo non può essere aperto in questo momento.",           
    "reason_title_playlist_default": "Playlist Bloccata",           
    "reason_message_lesson_default": "Questa lezione non può essere aperta in questo momento.",           
    "reason_title_lesson_default": "Lezione Bloccata",           
    "reason_title_stream_no_user": "Corso Bloccato",           
    "reason_title_stream_locked": "Corso Bloccato",           
    "reason_message_stream_unmet_prerequisites_playlist": "Per sbloccare questo esame, completa tutti i corsi propedeutici nella playlist appropriata.",           
    "reason_message_stream_unmet_prerequisites_concentration": "Per sbloccare questo esame, completa tutti i corsi propedeutici nell'indirizzo appropriato.",           
    "reason_message_stream_unmet_prerequisites_specialization": "Per sbloccare questo esame, completa tutti i corsi propedeutici nella specializzazione appropriata.",           
    "reason_title_stream_unmet_prerequisites_playlist": "Esame Bloccato",           
    "reason_title_stream_unmet_prerequisites_concentration": "Esame Bloccato",           
    "reason_title_stream_unmet_prerequisites_specialization": "Esame Bloccato",           
    "reason_message_stream_unmet_prerequisites_schedule": "Per sbloccare questo esame, completa tutti i corsi propedeutici nel calendario della tua classe.",           
    "reason_title_stream_unmet_prerequisites_schedule": "Esame Bloccato",           
    "reason_message_stream_before_launch_window": "Questo esame sarà disponibile il {{openTimeStr}}.",           
    "reason_title_stream_before_launch_window": "Esame Bloccato",           
    "reason_message_lesson_completed_test": "L'esame adesso è chiuso.",           
    "reason_title_lesson_completed_test": "Esame Bloccato",           
    "reason_title_lesson_no_user": "Lezione Bloccata",           
    "reason_message_lesson_exam_time_run_out": "L'esame adesso è chiuso.",           
    "reason_title_lesson_exam_time_run_out": "Esame Bloccato",           
    "reason_message_stream_coming_soon": "Questo corso non può essere aperto in questo momento.",
    "reason_title_stream_coming_soon": "Corso bloccato",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_schedule": "Questo esame diventa disponibile su {{openTimeStr}} una volta completati tutti i corsi obbligatori richiesti nel corso della tua classe.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_schedule": "Esame bloccato",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_playlist": "Questo esame diventa disponibile su {{openTimeStr}} una volta completati tutti i corsi precedenti nella playlist appropriata.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_playlist": "Esame bloccato",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_concentration": "Questo esame diventa disponibile su {{openTimeStr}} una volta completati tutti i corsi precedenti nella concentrazione appropriata.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_concentration": "Esame bloccato",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_specialization": "Questo esame diventa disponibile su {{openTimeStr}} una volta completati tutti i corsi precedenti nella specializzazione appropriata.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_specialization": "Esame bloccato",
    "completed": "COMPLETATO",           
    "locked_curriculum_message_rejected": "Il curriculum completo è disponibile solo all'interno del programma {{programTitle}}.",           
    "reason_title_stream_requires_idology_verification": "Corso bloccato",
    "reason_message_stream_requires_idology_verification": "Per sbloccare, completa la verifica dell'identità.",
    "reason_message_playlist_requires_idology_verification": "Per sbloccare, completa la verifica dell'identità.",
    "reason_title_stream_placed_on_academic_hold": "Corso bloccato",
    "reason_message_stream_unavailable_offline": "Questo corso non è disponibile offline.",
    "reason_title_stream_unavailable_offline": "Non disponibile offline",
    "reason_message_stream_no_user": "Questo corso è disponibile come parte del corso di laurea completo.",
    "reason_message_stream_locked": "Questo corso è disponibile come parte del corso di laurea completo.",
    "reason_message_lesson_no_user": "Questa lezione è disponibile come parte del corso di laurea completo.",
    "locked_curriculum_message_payment_overdue": "Il tuo curriculum è bloccato perché i nostri registri mostrano che nel tuo account mancano uno o più pagamenti richiesti.",
    "reason_title_stream_requires_signed_tila_disclosure": "Corso bloccato",
    "reason_message_stream_requires_signed_tila_disclosure": "Per sbloccare, firma l'informativa sul piano di pagamento.",
    "reason_message_playlist_requires_signed_tila_disclosure": "Per sbloccare, firma l'informativa sul piano di pagamento.",
    "reason_title_lesson_requires_biosig_verification": "Esame bloccato",
    "reason_message_lesson_requires_biosig_verification": "Devi verificare la tua identità.",
    "reason_message_lesson_unavailable_offline": "Questa lezione non è disponibile mentre sei offline.",
    "reason_title_lesson_unavailable_offline": "Non disponibile offline",
    "reason_message_lesson_coming_soon": "Questa lezione non può essere aperta in questo momento.",
    "locked_curriculum_message": "Il curriculum completo si sblocca all'inizio del programma.",
    "reason_title_stream_must_switch_program_to_launch_exam": "Esame bloccato",
    "reason_message_stream_must_switch_program_to_launch_exam": "Per sbloccare questo esame, passa al programma in cui è incluso.",
    "reason_message_stream_placed_on_academic_hold": "Sei stato messo in sospeso amministrativo. Contatta <a href=\"mailto:{{registrarEmail}}\">{{registrarEmail}}</a> per maggiori informazioni.",
    "reason_message_playlist_placed_on_second_academic_probation_hold": "Sei stato messo in attesa accademica. Devi <a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">incontrare un Academic Advisor</a> per far rimuovere l'attesa.",
    "reason_message_playlist_placed_on_academic_hold": "Sei stato messo in sospeso amministrativo. Contatta <a href=\"mailto:{{registrarEmail}}\">{{registrarEmail}}</a> per maggiori informazioni.",
    "reason_message_stream_placed_on_second_academic_probation_hold": "Sei stato messo in attesa accademica. Devi <a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">incontrare un Academic Advisor</a> per far rimuovere l'attesa.",
    "reason_title_stream_placed_on_second_academic_probation_hold": "Corso Bloccato"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.shared = window.locales.zh.lessons.shared || {};window.locales.zh.lessons.shared.content_access_helper = window.locales.zh.lessons.shared.content_access_helper || {};window.locales.zh.lessons.shared.content_access_helper = {...window.locales.zh.lessons.shared.content_access_helper, ...{
    "locked": "已锁定",           
    "reason_title_stream_locked": "锁定的课程",           
    "reason_message_stream_before_launch_window": "可在{{openTimeStr}}上开启该测验。",           
    "reason_title_stream_before_launch_window": "测验已锁定",           
    "reason_message_stream_default": "该课程现在不开放。",           
    "reason_message_playlist_default": "该合集现在不开放。",           
    "reason_message_lesson_default": "该课程现在不开放。",           
    "reason_title_stream_default": "课程已锁定",           
    "reason_title_playlist_default": "播放列表已锁定",           
    "reason_title_lesson_default": "课程已锁定",           
    "reason_message_lesson_exam_time_run_out": "测验现已结束。",           
    "reason_title_lesson_exam_time_run_out": "测验已锁定",           
    "reason_message_lesson_completed_test": "测验现已结束。",           
    "reason_title_lesson_completed_test": "测验已锁定",           
    "reason_title_stream_no_user": "锁定的课程",           
    "reason_title_lesson_no_user": "课程已锁定",           
    "reason_message_stream_unmet_prerequisites_playlist": "如欲解锁此测验，请在相应播放列表中完成之前的所有课程。",           
    "reason_title_stream_unmet_prerequisites_playlist": "测验已锁定",           
    "reason_message_stream_unmet_prerequisites_schedule": "如欲解锁此测验，请完成课程表中所有之前的必修课程。",           
    "reason_title_stream_unmet_prerequisites_schedule": "测验已锁定",           
    "reason_message_stream_unmet_prerequisites_concentration": "如欲解锁此测验，请在相应合集中完成之前的所有课程。",           
    "reason_message_stream_unmet_prerequisites_specialization": "如欲解锁此测验，请在相应专项中完成之前的所有课程。",           
    "reason_title_stream_unmet_prerequisites_concentration": "测验已锁定",           
    "reason_title_stream_unmet_prerequisites_specialization": "测验已锁定",           
    "reason_message_stream_coming_soon": "该课程现在不开放。",           
    "reason_title_stream_coming_soon": "课程已锁定",           
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_schedule": "一旦你已完成课程表中所有之前的必修课程，你便可在{{openTimeStr}}上开启该测验。",           
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_schedule": "测验已锁定",           
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_playlist": "一旦你已完成相应播放列表中所有之前的课程，即可在{{openTimeStr}}上开启该测验。",           
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_playlist": "测验已锁定",           
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_concentration": "一旦你已完成相应合集中所有之前的课程，即可在{{openTimeStr}}上开启该测验。",           
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_concentration": "测验已锁定",           
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_specialization": "一旦你已完成相应专项中所有之前的课程，即可在{{openTimeStr}}上开启该测验。",           
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_specialization": "测验已锁定",           
    "completed": "已完成",           
    "locked_curriculum_message_rejected": "完整的课程仅可在工商管理硕士（{{programTitle}} ）课程中访问。",           
    "reason_title_stream_requires_idology_verification": "课程已锁定",           
    "reason_message_stream_requires_idology_verification": "如欲解锁，请完成身份验证。",           
    "reason_message_playlist_requires_idology_verification": "如欲解锁，请完成身份验证。",           
    "reason_title_stream_placed_on_academic_hold": "课程已锁定",           
    "reason_message_stream_unavailable_offline": "此课程在离线状态下不可用。",
    "reason_title_stream_unavailable_offline": "离线不可用",
    "reason_message_stream_no_user": "本课程可作为完整学位课程的一部分。",
    "reason_message_stream_locked": "本课程可作为完整学位课程的一部分。",
    "reason_message_lesson_no_user": "本课程可作为完整学位课程的一部分。",
    "locked_curriculum_message_payment_overdue": "您的课程已锁定，因为我们的记录显示您的帐户缺少一笔或多笔必需的付款。",
    "reason_title_stream_requires_signed_tila_disclosure": "当然锁定",
    "reason_message_stream_requires_signed_tila_disclosure": "要解锁，请签署您的付款计划披露。",
    "reason_message_playlist_requires_signed_tila_disclosure": "要解锁，请签署您的付款计划披露。",
    "reason_title_lesson_requires_biosig_verification": "考试已锁定",
    "reason_message_lesson_requires_biosig_verification": "您必须验证您的身份。",
    "reason_message_lesson_unavailable_offline": "离线时无法使用本课程。",
    "reason_title_lesson_unavailable_offline": "无法离线使用",
    "reason_message_lesson_coming_soon": "目前无法打开本课。",
    "locked_curriculum_message": "完整的课程在课程开始后解锁。",
    "reason_title_stream_must_switch_program_to_launch_exam": "考试已锁定",
    "reason_message_stream_must_switch_program_to_launch_exam": "要解锁此考试，请切换到包含该考试的程序。",
    "reason_message_stream_placed_on_academic_hold": "您已被置于行政暂停状态。请联系<a href=\"mailto:{{registrarEmail}}\">{{registrarEmail}}</a>了解更多信息。",
    "reason_message_playlist_placed_on_second_academic_probation_hold": "您已被置于学术暂停状态。您必须<a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\", target=\"_blank\">与学术顾问会面</a>才能解除暂停状态。",
    "reason_message_playlist_placed_on_academic_hold": "您已被置于行政暂停状态。请联系<a href=\"mailto:{{registrarEmail}}\">{{registrarEmail}}</a>了解更多信息。",
    "reason_message_stream_placed_on_second_academic_probation_hold": "您已被置于学术暂停状态。您必须<a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\", target=\"_blank\">与学术顾问会面</a>才能解除暂停状态。",
    "reason_title_stream_placed_on_second_academic_probation_hold": "锁定的课程"           
}
};