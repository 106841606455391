window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.toggleable_key_terms_list_buttons = window.locales.am.lessons.stream.toggleable_key_terms_list_buttons || {};window.locales.am.lessons.stream.toggleable_key_terms_list_buttons = {...window.locales.am.lessons.stream.toggleable_key_terms_list_buttons, ...{
    "alphabetical_order": "የፊደል ቅደም ተከተል",           
    "group_by_lesson": "በትምህርት የተቧደኑ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.toggleable_key_terms_list_buttons = window.locales.ar.lessons.stream.toggleable_key_terms_list_buttons || {};window.locales.ar.lessons.stream.toggleable_key_terms_list_buttons = {...window.locales.ar.lessons.stream.toggleable_key_terms_list_buttons, ...{
    "alphabetical_order": "ترتيب ابجدي",
    "group_by_lesson": "مجموعة من الدرس"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.toggleable_key_terms_list_buttons = window.locales.en.lessons.stream.toggleable_key_terms_list_buttons || {};window.locales.en.lessons.stream.toggleable_key_terms_list_buttons = {...window.locales.en.lessons.stream.toggleable_key_terms_list_buttons, ...{
    "alphabetical_order": "Alphabetical Order",
    "group_by_lesson": "Group by Lesson"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.toggleable_key_terms_list_buttons = window.locales.es.lessons.stream.toggleable_key_terms_list_buttons || {};window.locales.es.lessons.stream.toggleable_key_terms_list_buttons = {...window.locales.es.lessons.stream.toggleable_key_terms_list_buttons, ...{
    "alphabetical_order": "Orden alfabetico",
    "group_by_lesson": "Grupo por la lección"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.toggleable_key_terms_list_buttons = window.locales.it.lessons.stream.toggleable_key_terms_list_buttons || {};window.locales.it.lessons.stream.toggleable_key_terms_list_buttons = {...window.locales.it.lessons.stream.toggleable_key_terms_list_buttons, ...{
    "alphabetical_order": "Ordine alfabetico",           
    "group_by_lesson": "Raggruppa per Lezione"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.toggleable_key_terms_list_buttons = window.locales.zh.lessons.stream.toggleable_key_terms_list_buttons || {};window.locales.zh.lessons.stream.toggleable_key_terms_list_buttons = {...window.locales.zh.lessons.stream.toggleable_key_terms_list_buttons, ...{
    "alphabetical_order": "按字母顺序",           
    "group_by_lesson": "按课程分组"           
}
};