window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.lesson = window.locales.am.lessons.lesson || {};window.locales.am.lessons.lesson.show_lesson = window.locales.am.lessons.lesson.show_lesson || {};window.locales.am.lessons.lesson.show_lesson = {...window.locales.am.lessons.lesson.show_lesson, ...{
    "this_exam_is_now_closed": "ይህ ፈተና አሁን ዝግ ነው።",           
    "return_to_dashboard": "ወደ ዳሽቦርድ ይመለሱ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.lesson = window.locales.ar.lessons.lesson || {};window.locales.ar.lessons.lesson.show_lesson = window.locales.ar.lessons.lesson.show_lesson || {};window.locales.ar.lessons.lesson.show_lesson = {...window.locales.ar.lessons.lesson.show_lesson, ...{
    "this_exam_is_now_closed": "تم إغلاق هذا الامتحان الآن.",
    "return_to_dashboard": "العودة إلى لوحة التحكم الرئيسية"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.lesson = window.locales.en.lessons.lesson || {};window.locales.en.lessons.lesson.show_lesson = window.locales.en.lessons.lesson.show_lesson || {};window.locales.en.lessons.lesson.show_lesson = {...window.locales.en.lessons.lesson.show_lesson, ...{
    "this_exam_is_now_closed": "This exam is now closed.",
    "return_to_dashboard": "Return to Dashboard"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.lesson = window.locales.es.lessons.lesson || {};window.locales.es.lessons.lesson.show_lesson = window.locales.es.lessons.lesson.show_lesson || {};window.locales.es.lessons.lesson.show_lesson = {...window.locales.es.lessons.lesson.show_lesson, ...{
    "this_exam_is_now_closed": "Este examen se ha cerrado.",
    "return_to_dashboard": "Volver al tablero de instrumentos"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.lesson = window.locales.it.lessons.lesson || {};window.locales.it.lessons.lesson.show_lesson = window.locales.it.lessons.lesson.show_lesson || {};window.locales.it.lessons.lesson.show_lesson = {...window.locales.it.lessons.lesson.show_lesson, ...{
    "this_exam_is_now_closed": "L'esame adesso è chiuso.",           
    "return_to_dashboard": "Torna alla Bacheca"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.lesson = window.locales.zh.lessons.lesson || {};window.locales.zh.lessons.lesson.show_lesson = window.locales.zh.lessons.lesson.show_lesson || {};window.locales.zh.lessons.lesson.show_lesson = {...window.locales.zh.lessons.lesson.show_lesson, ...{
    "this_exam_is_now_closed": "测验现已结束。",           
    "return_to_dashboard": "返回仪表板"           
}
};