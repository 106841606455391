window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.student_dashboard_learning_box = window.locales.am.lessons.stream.student_dashboard_learning_box || {};window.locales.am.lessons.stream.student_dashboard_learning_box = {...window.locales.am.lessons.stream.student_dashboard_learning_box, ...{
    "active": "ንቁ",           
    "box_header_concentration_complete": "ምርጥ ስራ! ይህንን ክምችት አጠናቅቀዋል።",           
    "box_header_playlist_complete": "ምርጥ ስራ! ይህንን የአጫዋች ዝርዝር አጠናቅቀዋል።",           
    "box_header_playlist_just_completed": "ምርጥ ስራ! መማር ለመቀጠል የአጫዋች ዝርዝር ይምረጡ።",           
    "box_header_concentration_just_completed": "ምርጥ ስራ! መማር ለመቀጠል ክምችትን ይምረጡ።",           
    "open_courses": "ክፍት ኮርሶች",           
    "chapter": "ምዕራፍ",           
    "chapter_x": "ምዕራፍ {{num}}",           
    "close": "ዝጋ",           
    "description_degree_program": "የእኛ {{programTitle}} ዛሬ ባለው የንግድ አካባቢ ስኬታማ ለመሆን የሚያስፈልጉዎትን እውቀት እና ክህሎቶች ያቀርባል። እሱን ለማግበር እና መማር ለመጀመር ማንኛውንም ትኩረት ይንኩ።",
    "description_certificates": "የእኛ {{cohortTitle}} ፕሮግራም በዛሬው የቢዝነስ ምህዳር ስኬታማ እንዲሆኑ የሚያስፈልግዎትን እውቀት እና ክህሎቶች ያቀርባል። እርሱን ለማንቃት እና መማር ለመጀመር ማንኛዉንም ክምችት መታ ያድርጉ።",           
    "description_jordanian_math": "የእኛ {{cohortTitle}} ፕሮግራም የ Tawjihi ፈተናን እንዲያልፉ እና ለኮሌጅ ሂሳብ እና ሳይንስ እንዲዘጋጁ የሚያስፈልግዎትን እውቀት እና ክህሎቶች ያቀርባል።",           
    "description_open_courses": "የእኛ የክፍት ቢዝነስ ኮርሶች ደስ እንደሚልዎት ተስፋ እናደርጋለን። እርሱን ለማንቃት እና መማር ለመጀመር ማንኛዉንም ክምችት መታ ያድርጉ።",           
    "description_playlists": "አጫዋች ዝርዝሮች የ{{brandName}} ኮርሶችን በግብ ላይ ወደተመሰረቱ ውጤታማ ትራኮች አደራጅተዋል።",           
    "keep_learning": "መማርዎን ይቀጥሉ",           
    "lesson": "ትምህርት",           
    "lesson_x": "ትምህርት {{num}}",           
    "degree_program_curriculum": "{{programTitle}} CURRICULUM",
    "career_network_only_curriculum": "ክፍት ኮርሶች",           
    "certificates_curriculum": "{{cohortTitle}}",           
    "next_up": "ቀጥሎ ያለው፤",           
    "percent_complete": "{{percent}}%",           
    "playlists": "የአጫዋች ዝርዝሮች",           
    "playlist_complete": "{{numCourses}} ኮርሶች • ተጠናቅቀዋል!",           
    "offline_courses": "ከመስመር ውጪ ኮርሶች",           
    "num_offline_courses_available": "{{numCourses}} {{numCourses == 1 ? 'COURSE' : 'COURSES'}} ይገኛል",           
    "recommended": "የሚመከር",           
    "select_concentration_title": "ክምችት ይምረጡ",           
    "select_playlist_title": "የአጫዋች ዝርዝር ይምረጡ",           
    "show_curriculum": "ስርአተ ትምህርት",
    "show_courses": "ኮርሶችን አሳይ",
    "show_playlists": "የአጫዋች ዝርዝሮችን ያሳዩ",           
    "start": "ጀምር",           
    "start_course": "ኮርሱን ይጀምሩ",           
    "x_minutes": "{{num}} ደቂቃዎች",           
    "home_title": "{{institutionName}}",
    "num_concentrations": "{num} {num, plural, =1{ማጎሪያ} other{ማጎሪያዎች}} • {percentComplete} % ተጠናቋል",
    "num_playlists": "{num} {num, plural, =1{አጫዋች ዝርዝር} other{አጫዋች ዝርዝሮች}}",
    "playlist_in_progress": "{{numCourses}} ኮርሶች • {{numCoursesComplete}} ተጠናቋል"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.student_dashboard_learning_box = window.locales.ar.lessons.stream.student_dashboard_learning_box || {};window.locales.ar.lessons.stream.student_dashboard_learning_box = {...window.locales.ar.lessons.stream.student_dashboard_learning_box, ...{
    "active": "نشط",           
    "box_header_concentration_complete": "عمل رائع! لقد أكملت هذا التركيز.",           
    "box_header_playlist_complete": "عمل رائع! لقد أكملت قائمة التشغيل هذه.",           
    "box_header_playlist_just_completed": "عمل رائع! حدد قائمة التشغيل لمتابعة التعلم.",           
    "box_header_concentration_just_completed": "عمل رائع! حدد التركيز لمتابعة التعلم.",           
    "chapter": "الفصل",           
    "chapter_x": "الفصل {{num}}",           
    "close": "غلق",           
    "description_degree_program": "توفر {{programTitle}} الخاصة بنا المعرفة والمهارات التي تحتاج إليها لتحقيق النجاح في بيئة الأعمال اليوم. انقر على أي تركيز لتفعيله وابدأ في التعلم.",           
    "keep_learning": "واصل التعلم",           
    "lesson": "الدرس",           
    "lesson_x": "الدرس {{num}}",           
    "degree_program_curriculum": "مناهج {{programTitle}}",           
    "next_up": "الخطوة التالية:",           
    "percent_complete": "{{percent}}%",           
    "playlists": "قوائم التشغيل",           
    "recommended": "الموصى بها",           
    "select_concentration_title": "تحديد التركيز",           
    "select_playlist_title": "تحديد قائمة التشغيل",           
    "show_playlists": "عرض قوائم التشغيل",           
    "start": "ابدأ",           
    "start_course": "ابدأ الدورة",           
    "x_minutes": "{{num}} دقائق",           
    "emba_curriculum": "إمبا المناهج",
    "career_network_only_curriculum": "الدورات المفتوحة",
    "show_courses": "عرض الدورات",
    "open_courses": "الدورات المفتوحة",
    "description_open_courses": "نأمل أن تستمتع لدينا دورات الأعمال المفتوحة. الاستفادة من أي تركيز لتنشيطه والبدء في التعلم.",
    "description_certificates": "برنامج {{cohortTitle}} يسلم المعرفة والمهارات التي تحتاجها لتحقيق النجاح في بيئة الأعمال اليوم. الاستفادة من أي تركيز لتنشيطه والبدء في التعلم.",
    "certificates_curriculum": "{{cohortTitle}}",
    "show_curriculum": "منهاج دراسي",
    "description_jordanian_math": "يقدم برنامج {{cohortTitle}} المعرفة والمهارات التي تحتاجها لاجتياز اختبار التوجيهي بنجاح والتحضير للرياضيات والعلوم في الكلية.",
    "description_playlists": "تنظم قوائم التشغيل دورات {{brandName}} في مسارات فعالة تركز على الهدف.",
    "playlist_complete": "{{numCourses}} الدورات • {{numCourses}}",
    "offline_courses": "الدورات دون اتصال بالإنترنت",

                                                                                                                      
                                                                                                                              
                                                                                                                      
                                                                                                                         
                                                                                                         
    "num_offline_courses_available": "{{numCourses}} {{numCourses == 1 || numCourses === 2 || numCourses >= 11 ? 'دورة' : 'دورات'}} متوفرة",
    "home_title": "{{institutionName}}",
    "num_concentrations": "{num} {num, plural, =1{ تركيز } other{ التركيزات }} • {percentComplete} % مكتمل",
    "num_playlists": "{num} {num, plural, =1{قائمة التشغيل} other{قوائم التشغيل}}",
    "playlist_in_progress": "{{numCourses}} دورات • {{numCoursesComplete}} مكتملة"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.student_dashboard_learning_box = window.locales.en.lessons.stream.student_dashboard_learning_box || {};window.locales.en.lessons.stream.student_dashboard_learning_box = {...window.locales.en.lessons.stream.student_dashboard_learning_box, ...{
    "active": "ACTIVE",
    "box_header_concentration_complete": "Great job! You completed this concentration.",
    "box_header_playlist_complete": "Great job! You completed this playlist.",
    "box_header_playlist_just_completed": "Great job! Select a playlist to continue learning.",
    "box_header_concentration_just_completed": "Great job! Select a concentration to continue learning.",
    "open_courses": "OPEN COURSES",
    "chapter": "CHAPTER",
    "chapter_x": "CHAPTER {{num}}",
    "close": "CLOSE",
    "description_degree_program": "Our {{programTitle}} delivers the knowledge and skills you need to succeed in today’s business environment. Tap any concentration to activate it and start learning.",
    "description_certificates": "The {{programTitle}} program delivers the knowledge and skills you need to succeed in today’s business environment. Tap any concentration to activate it and start learning.",
    "description_jordanian_math": "The {{programTitle}} program delivers the knowledge and skills you need to successfully pass the Tawjihi exam and prepare for college math and science.",
    "description_open_courses": "We hope you enjoy our open business courses. Tap any concentration to activate it and start learning.",
    "description_playlists": "Playlists organize {{brandName}} courses into efficient tracks focused on a goal.",
    "home_title": "{{institutionName}}",
    "keep_learning": "KEEP LEARNING",
    "lesson": "LESSON",
    "lesson_x": "LESSON {{num}}",
    "degree_program_curriculum": "{{programTitle}} CURRICULUM",
    "career_network_only_curriculum": "OPEN COURSES",
    "certificates_curriculum": "{{programTitle.toUpperCase()}}",
    "next_up": "NEXT UP:",
    "num_concentrations": "{num} {num, plural, =1{CONCENTRATION} other{CONCENTRATIONS}} • {percentComplete}% COMPLETE",
    "num_playlists": "{num} {num, plural, =1{PLAYLIST} other{PLAYLISTS}}",
    "percent_complete": "{{percent}}%",
    "playlists": "PLAYLISTS",
    "playlist_complete": "{{numCourses}} COURSES • COMPLETED",
    "playlist_in_progress": "{{numCourses}} COURSES • {{numCoursesComplete}} COMPLETED",
    "offline_courses": "OFFLINE COURSES",
    "num_offline_courses_available": "{{numCourses}} {{numCourses == 1 ? 'COURSE' : 'COURSES'}} AVAILABLE",
    "recommended": "Recommended",
    "select_concentration_title": "SELECT CONCENTRATION",
    "select_playlist_title": "SELECT PLAYLIST",
    "show_curriculum": "CURRICULUM",
    "show_courses": "SHOW COURSES",
    "show_playlists": "SHOW PLAYLISTS",
    "start": "START",
    "start_course": "START COURSE",
    "x_minutes": "{{num}} minutes"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.student_dashboard_learning_box = window.locales.es.lessons.stream.student_dashboard_learning_box || {};window.locales.es.lessons.stream.student_dashboard_learning_box = {...window.locales.es.lessons.stream.student_dashboard_learning_box, ...{
    "active": "ACTIVO",           
    "box_header_concentration_complete": "¡Gran trabajo! Has completado esta concentración.",           
    "box_header_playlist_complete": "¡Gran trabajo! Has completado esta lista de reproducción.",           
    "box_header_playlist_just_completed": "¡Gran trabajo! Selecciona una lista de reproducción para seguir aprendiendo.",           
    "box_header_concentration_just_completed": "¡Gran trabajo! Selecciona una concentración para seguir aprendiendo.",           
    "chapter": "CAPÍTULO",           
    "chapter_x": "CAPÍTULO {{num}}",           
    "close": "CERRAR",           
    "description_degree_program": "Nuestro {{programTitle}} proporciona el conocimiento y las habilidades que necesitas para tener éxito en el entorno empresarial actual. Pulsa cualquier concentración para activarla y comenzar a aprender.",           
    "keep_learning": "SIGUE APRENDIENDO",           
    "lesson": "LECCIÓN",           
    "lesson_x": "LECCIÓN {{num}}",           
    "degree_program_curriculum": "PLAN DE ESTUDIOS DE {{programTitle}}",           
    "next_up": "A CONTINUACIÓN:",           
    "percent_complete": "{{percent}}%",           
    "playlists": "PLAYLISTS",           
    "playlist_complete": "{{numCourses}} CURSOS • COMPLETADOS",           
    "recommended": "Recomendado",           
    "show_playlists": "MOSTRAR PLAYLISTS",           
    "start": "EMPEZAR",           
    "start_course": "INICIAR CURSO",           
    "x_minutes": "{{num}} minutos",           
    "select_concentration_title": "SELECCIONAR CONCENTRATION",           
    "select_playlist_title": "SELECCIONAR PLAYLIST",           
    "career_network_only_curriculum": "CURSOS ABIERTOS",
    "show_courses": "MOSTRAR CURSOS",
    "open_courses": "CURSOS ABIERTOS",
    "description_open_courses": "Esperamos que disfrutes nuestros cursos de negocios abiertos. Toca cualquier concentración para activarla y comenzar a aprender.",
    "description_certificates": "El programa {{cohortTitle}} brinda el conocimiento y las habilidades que necesita para tener éxito en el entorno comercial actual. Toca cualquier concentración para activarla y comenzar a aprender.",
    "certificates_curriculum": "{{cohortTitle}}",
    "show_curriculum": "PLAN DE ESTUDIOS",
    "description_jordanian_math": "El programa {{cohortTitle}} proporciona el conocimiento y las habilidades que necesitas para aprobar con éxito el examen de Tawjihi y prepararte para la universidad en matemáticas y ciencias.",
    "description_playlists": "Las listas de reproducción organizan cursos de {{brandName}} en pistas eficientes centradas en un objetivo.",
    "offline_courses": "CURSOS SIN CONEXIÓN",
    "num_offline_courses_available": "{{numCourses}} {{numCourses === 1 ? 'CURSO' : 'CURSOS'}} DISPONIBLE",
    "home_title": "{{institutionName}}",
    "num_concentrations": "{num} {num, plural, =1{CONCENTRACIÓN} other{CONCENTRACIONES}} • {percentComplete} % COMPLETADO",
    "num_playlists": "{num} {num, plural, =1{ LISTA DE REPRODUCCIÓN } other{ LISTAS DE REPRODUCCIÓN }}",
    "playlist_in_progress": "{{numCourses}} CURSOS • {{numCoursesComplete}} COMPLETADOS"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.student_dashboard_learning_box = window.locales.it.lessons.stream.student_dashboard_learning_box || {};window.locales.it.lessons.stream.student_dashboard_learning_box = {...window.locales.it.lessons.stream.student_dashboard_learning_box, ...{
    "active": "ATTIVO",           
    "box_header_concentration_complete": "Bel lavoro! Hai completato questo indirizzo.",           
    "box_header_playlist_complete": "Bel lavoro! Hai completato questa playlist.",           
    "box_header_playlist_just_completed": "Bel lavoro! Seleziona una playlist per continuare a imparare.",           
    "box_header_concentration_just_completed": "Bel lavoro! Seleziona un indirizzo per continuare a imparare.",           
    "chapter": "CAPITOLO",           
    "chapter_x": "CAPITOLO {{num}}",           
    "close": "CHIUDI",           
    "description_degree_program": "Il nostro {{programTitle}} fornisce la conoscenza e le competenze necessarie per avere successo nell'odierno contesto di impresa. Tocca su un qualsiasi indirizzo per attivarlo e cominciare a imparare.",           
    "keep_learning": "CONTINUA A IMPARARE",           
    "lesson": "LEZIONE",           
    "lesson_x": "LEZIONE {{num}}",           
    "degree_program_curriculum": "CURRICULUM MBA",           
    "career_network_only_curriculum": "CORSI LIBERI",           
    "next_up": "PROSSIMO:",           
    "percent_complete": "{{percent}}%",           
    "playlists": "PLAYLIST",           
    "playlist_complete": "{{numCourses}} CORSI • COMPLETATA",           
    "recommended": "Consigliato",           
    "select_concentration_title": "SELEZIONA INDIRIZZO",           
    "select_playlist_title": "SELEZIONA PLAYLIST",           
    "show_courses": "MOSTRA CORSI",           
    "show_playlists": "MOSTRA PLAYLIST",           
    "start": "INIZIA",           
    "start_course": "INIZIA CORSO",           
    "x_minutes": "{{num}} minuti",           
    "open_courses": "CORSI APERTI",
    "description_open_courses": "Ci auguriamo che ti piacciano i nostri corsi di open business. Tocca qualsiasi concentrazione per attivarla e iniziare a imparare.",
    "description_certificates": "Il programma {{cohortTitle}} offre le conoscenze e le competenze necessarie per avere successo nell'ambiente aziendale odierno. Tocca qualsiasi concentrazione per attivarla e iniziare a imparare.",
    "certificates_curriculum": "{{cohortTitle}}",
    "show_curriculum": "CURRICULUM",
    "description_jordanian_math": "Il programma {{cohortTitle}} offre le conoscenze e le competenze necessarie per superare con successo l'esame Tawjihi e prepararsi per la matematica e la scienza del college.",
    "description_playlists": "Le playlist organizzano corsi {{brandName}} in tracce efficienti incentrate su un obiettivo.",
    "offline_courses": "CORSI OFFLINE",
    "num_offline_courses_available": "{{numCourses}} {{numCourses === 1 ? 'CORSO' : 'CORSI'}} DISPONIBILE",
    "home_title": "{{institutionName}}",
    "num_concentrations": "{num} {num, plural, =1{ CONCENTRAZIONE } other{ CONCENTRAZIONI }} • {percentComplete} % COMPLETATO",
    "num_playlists": "{num} {num, plural, =1{ PLAYLIST } other{ PLAYLIST }}",
    "playlist_in_progress": "{{numCourses}} CORSI • {{numCoursesComplete}} COMPLETATI"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.student_dashboard_learning_box = window.locales.zh.lessons.stream.student_dashboard_learning_box || {};window.locales.zh.lessons.stream.student_dashboard_learning_box = {...window.locales.zh.lessons.stream.student_dashboard_learning_box, ...{
    "active": "有效",           
    "box_header_concentration_complete": "做得好！您完成了此精选。",           
    "box_header_playlist_complete": "做得好！您完成了此任务列表。",           
    "box_header_playlist_just_completed": "做得好！请选择一个任务列表继续学习。",           
    "box_header_concentration_just_completed": "做得好！请选择一个精选继续学习。",           
    "chapter": "篇章",           
    "chapter_x": "第 {{num}} 章",           
    "close": "关闭",           
    "description_degree_program": "我们的 {{programTitle}} 课程讲授在当今的商业环境中获得成功所需的知识和技能。点按任一精选可将其激活并开始学习。",           
    "keep_learning": "继续学习",           
    "lesson": "课业",           
    "lesson_x": "第 {{num}} 课",           
    "degree_program_curriculum": "{{programTitle}} 课程",           
    "next_up": "接下来：",           
    "percent_complete": "{{percent}}%",           
    "playlists": "任务列表",           
    "playlist_complete": "{{numCourses}} 个课程 • 已完成",           
    "recommended": "推荐",           
    "select_concentration_title": "选择精选",           
    "select_playlist_title": "选择任务列表",           
    "show_playlists": "显示任务列表",           
    "start": "开始",           
    "start_course": "开始课程",           
    "x_minutes": "{{num}} 分钟",           
    "career_network_only_curriculum": "公开课程",           
    "show_courses": "显示课程",           
    "open_courses": "公开课程",           
    "description_open_courses": "我们希望你喜欢我们的开放商务课程。点击任一合集进行激活，开始学习。",           
    "description_certificates": "{{cohortTitle}}课程可提供在当今商业环境中取得成功所需的知识和技能。点击任一合集进行激活，开始学习。",           
    "certificates_curriculum": "{{cohortTitle}}",           
    "show_curriculum": "总课程",           
    "description_jordanian_math": "{{cohortTitle}} 可为你提供成功通过Tawjihi考试并为大学数学和科学学习打好基础所需的知识和技能。",           
    "description_external": "播放列表制定了{{brandName}}系列课程以提高学习效率。",           
    "description_demo": "播放列表制定了{{brandName}}系列课程以提高学习效率。",           
    "description_playlists": "播放列表制定了{{brandName}}系列课程以提高学习效率。",           
    "offline_courses": "离线课程",           
    "num_offline_courses_available": "{{numCourses}} 可上{{numCourses == 1 ? '课程' ：'课程'}}",           
    "home_title": "{{institutionName}}",
    "num_concentrations": "{num} {num, plural, =1{个精选} other{个精选}} • {percentComplete} % 完成",
    "num_playlists": "{num} {num, plural, =1{个任务列表} other{个任务列表}}",
    "playlist_in_progress": "{{numCourses}}门课程 • {{numCoursesComplete}}门课程已完成"
}
};