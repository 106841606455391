window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.lesson = window.locales.am.lessons.lesson || {};window.locales.am.lessons.lesson.frame_list = window.locales.am.lessons.lesson.frame_list || {};window.locales.am.lessons.lesson.frame_list.frame = window.locales.am.lessons.lesson.frame_list.frame || {};window.locales.am.lessons.lesson.frame_list.frame.componentized = window.locales.am.lessons.lesson.frame_list.frame.componentized || {};window.locales.am.lessons.lesson.frame_list.frame.componentized.component = window.locales.am.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.am.lessons.lesson.frame_list.frame.componentized.component.continue_button = window.locales.am.lessons.lesson.frame_list.frame.componentized.component.continue_button || {};window.locales.am.lessons.lesson.frame_list.frame.componentized.component.continue_button = {...window.locales.am.lessons.lesson.frame_list.frame.componentized.component.continue_button, ...{
    "check_answer": "መልሱን ፈትሽ",           
    "continue": "ቀጥል",           
    "skip": "ዝለል",           
    "helpful": "አጋዥ",           
    "not_helpful": "አጋዥ አይደለም",           
    "continue_cta": "{optionValue, select, assessment{ቀጥል Smartcase} exam{ቀጥል ፈተና} other{ቀጥል}}"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.lesson = window.locales.ar.lessons.lesson || {};window.locales.ar.lessons.lesson.frame_list = window.locales.ar.lessons.lesson.frame_list || {};window.locales.ar.lessons.lesson.frame_list.frame = window.locales.ar.lessons.lesson.frame_list.frame || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized = window.locales.ar.lessons.lesson.frame_list.frame.componentized || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized.component = window.locales.ar.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.continue_button = window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.continue_button || {};window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.continue_button = {...window.locales.ar.lessons.lesson.frame_list.frame.componentized.component.continue_button, ...{
    "check_answer": "تحقق من الإجابة",           
    "continue": "استمر",           
    "skip": "تخطي",           
    "helpful": "مفيد",           
    "not_helpful": "غير مفيد",           
    "continue_cta": "{optionValue, select, assessment{Smartcase استمر}  exam{استمر امتحان} other{استمر}}"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.lesson = window.locales.en.lessons.lesson || {};window.locales.en.lessons.lesson.frame_list = window.locales.en.lessons.lesson.frame_list || {};window.locales.en.lessons.lesson.frame_list.frame = window.locales.en.lessons.lesson.frame_list.frame || {};window.locales.en.lessons.lesson.frame_list.frame.componentized = window.locales.en.lessons.lesson.frame_list.frame.componentized || {};window.locales.en.lessons.lesson.frame_list.frame.componentized.component = window.locales.en.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.en.lessons.lesson.frame_list.frame.componentized.component.continue_button = window.locales.en.lessons.lesson.frame_list.frame.componentized.component.continue_button || {};window.locales.en.lessons.lesson.frame_list.frame.componentized.component.continue_button = {...window.locales.en.lessons.lesson.frame_list.frame.componentized.component.continue_button, ...{
    "check_answer": "Check Answer",
    "continue": "Continue",
    "skip": "Skip",
    "helpful": "Helpful",
    "not_helpful": "Not Helpful",
    "continue_cta": "{optionValue, select, assessment{Continue Smartcase} exam{Continue Exam} other{Continue}}"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.lesson = window.locales.es.lessons.lesson || {};window.locales.es.lessons.lesson.frame_list = window.locales.es.lessons.lesson.frame_list || {};window.locales.es.lessons.lesson.frame_list.frame = window.locales.es.lessons.lesson.frame_list.frame || {};window.locales.es.lessons.lesson.frame_list.frame.componentized = window.locales.es.lessons.lesson.frame_list.frame.componentized || {};window.locales.es.lessons.lesson.frame_list.frame.componentized.component = window.locales.es.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.es.lessons.lesson.frame_list.frame.componentized.component.continue_button = window.locales.es.lessons.lesson.frame_list.frame.componentized.component.continue_button || {};window.locales.es.lessons.lesson.frame_list.frame.componentized.component.continue_button = {...window.locales.es.lessons.lesson.frame_list.frame.componentized.component.continue_button, ...{
    "check_answer": "Revisar respuesta",           
    "continue": "Continuar",           
    "skip": "Omitir",           
    "helpful": "Útil",           
    "not_helpful": "No útil",           
    "continue_cta": "{optionValue, select, assessment{Continuar Smartcase} exam{Continuar Examen} other{Continuar}}"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.lesson = window.locales.it.lessons.lesson || {};window.locales.it.lessons.lesson.frame_list = window.locales.it.lessons.lesson.frame_list || {};window.locales.it.lessons.lesson.frame_list.frame = window.locales.it.lessons.lesson.frame_list.frame || {};window.locales.it.lessons.lesson.frame_list.frame.componentized = window.locales.it.lessons.lesson.frame_list.frame.componentized || {};window.locales.it.lessons.lesson.frame_list.frame.componentized.component = window.locales.it.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.it.lessons.lesson.frame_list.frame.componentized.component.continue_button = window.locales.it.lessons.lesson.frame_list.frame.componentized.component.continue_button || {};window.locales.it.lessons.lesson.frame_list.frame.componentized.component.continue_button = {...window.locales.it.lessons.lesson.frame_list.frame.componentized.component.continue_button, ...{
    "check_answer": "Verifica risposta",           
    "continue": "Continua",           
    "skip": "Salta",           
    "helpful": "Utile",           
    "not_helpful": "Non utile",           
    "continue_cta": "{optionValue, select, assessment{Continua Smartcase} exam{Continua Esame} other{Continua}}"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.lesson = window.locales.zh.lessons.lesson || {};window.locales.zh.lessons.lesson.frame_list = window.locales.zh.lessons.lesson.frame_list || {};window.locales.zh.lessons.lesson.frame_list.frame = window.locales.zh.lessons.lesson.frame_list.frame || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized = window.locales.zh.lessons.lesson.frame_list.frame.componentized || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized.component = window.locales.zh.lessons.lesson.frame_list.frame.componentized.component || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.continue_button = window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.continue_button || {};window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.continue_button = {...window.locales.zh.lessons.lesson.frame_list.frame.componentized.component.continue_button, ...{
    "check_answer": "检查答案",           
    "continue": "继续",           
    "skip": "跳过",           
    "helpful": "有用",           
    "not_helpful": "无用",           
    "continue_cta": "{optionValue, select, assessment{继续 Smartcase} exam{继续 考试} other{继续}}"
}
};