window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.browse_courses = window.locales.am.lessons.stream.browse_courses || {};window.locales.am.lessons.stream.browse_courses = {...window.locales.am.lessons.stream.browse_courses, ...{
    "all_courses": "ሁሉም ኮርሶች",           
    "any_progress": "ማንኛዉም ሂደት",           
    "any_status": "ማንኛውም ሁኔታ",           
    "any_topic": "ማንኛዉም ርዕስ",           
    "available": "ይገኛል",           
    "beta": "ቤታ",           
    "coming_soon": "በቅርቡ ይመጣል",           
    "completed": "ተጠናቋል",           
    "courses": "ኮርሶች",           
    "filter_by_progress": "በሂደት ያጣሩ",           
    "filter_by_status": "በሁኔታ ያጣሩ",           
    "filter_by_topic": "በርዕስ ያጣሩ",           
    "in_progress": "በሂደት ላይ",           
    "courses_title": "ኮርሶች",           
    "new_courses": "አዲስ",           
    "no_content_found": "ይቅርታ! የእርስዎን ማጣሪያዎች የሚዛመድ ምንም ይዘት አልተገኘም።",           
    "not_started": "አልተጀመረም",           
    "reset": "ፍለጋ እና ማጣሪያዎችን እንደገና አስጀምር",           
    "save_and_close": "ያስቀምጡ እና ይዝጉ",           
    "search": "ፈልግ",           
    "search_placeholder": "ኮርሶችን፣ ትምህርቶችን፣ እና ቁልፍ ቃላትን ይፈልጉ",           
    "set_filters": "ማጣሪያዎችን ያዘጋጁ",           
    "set_filters_title": "ማጣሪያዎችን ያዘጋጁ",           
    "updated": "የዘመነ",           
    "elective": "ተመራጭ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.browse_courses = window.locales.ar.lessons.stream.browse_courses || {};window.locales.ar.lessons.stream.browse_courses = {...window.locales.ar.lessons.stream.browse_courses, ...{
    "all_courses": "جميع الدورات",           
    "any_progress": "أي تقدم",           
    "any_status": "أية حالة",           
    "any_topic": "أي موضوع",           
    "available": "متاح",           
    "beta": "الإصدار التجريبي",           
    "coming_soon": "قريباً",           
    "completed": "مكتملة",           
    "courses": "الدورات",           
    "filter_by_progress": "التصفية حسب التقدم",           
    "filter_by_status": "التصفية حسب الحالة",           
    "filter_by_topic": "التصفية حسب الموضوع",           
    "in_progress": "قيد التنفيذ",           
    "new_courses": "جديد",           
    "no_content_found": "عذراً! لم يتم العثور على أي محتوى يطابق تصفياتك.",           
    "not_started": "لم يتم البدء",           
    "reset": "إعادة البحث والتصفية",           
    "save_and_close": "حفظ وغلق",           
    "search": "البحث",           
    "search_placeholder": "ابحث عن الدورات، الدروس، والمصطلحات الأساسية",           
    "set_filters": "تعيين التصفيات",           
    "set_filters_title": "تعيين التصفيات",           
    "updated": "تم التحديث",           
    "elective": "اختياري",
    "courses_title": "الدورات"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.browse_courses = window.locales.en.lessons.stream.browse_courses || {};window.locales.en.lessons.stream.browse_courses = {...window.locales.en.lessons.stream.browse_courses, ...{
    "all_courses": "All Courses",
    "any_progress": "Any Progress",
    "any_status": "Any Status",
    "any_topic": "Any Topic",
    "available": "Available",
    "beta": "BETA",
    "coming_soon": "Coming Soon",
    "completed": "Completed",
    "courses": "COURSES",
    "filter_by_progress": "Filter By Progress",
    "filter_by_status": "Filter By Status",
    "filter_by_topic": "Filter By Topic",
    "in_progress": "In Progress",
    "courses_title": "COURSES",
    "new_courses": "New",
    "no_content_found": "Sorry! No content found matching your filters.",
    "not_started": "Not Started",
    "reset": "RESET SEARCH & FILTERS",
    "save_and_close": "Save and Close",
    "search": "Search",
    "search_placeholder": "Search Courses, Lessons, and Key Terms",
    "set_filters": "SET FILTERS",
    "set_filters_title": "Set Filters",
    "updated": "UPDATED",
    "elective": "ELECTIVE"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.browse_courses = window.locales.es.lessons.stream.browse_courses || {};window.locales.es.lessons.stream.browse_courses = {...window.locales.es.lessons.stream.browse_courses, ...{
    "all_courses": "Todos los cursos",           
    "any_progress": "Cualquier progreso",           
    "any_status": "Cualquier estado",           
    "any_topic": "Cualquier tema",           
    "coming_soon": "¡Próximamente!",           
    "completed": "Terminado",           
    "courses": "CURSOS",           
    "filter_by_progress": "Filtrar por progreso",           
    "filter_by_status": "Filtrar por estado",           
    "filter_by_topic": "Filtrar por tema",           
    "in_progress": "En progreso",           
    "new_courses": "Nuevos",           
    "no_content_found": "¡Lo lamentamos! No se han encontrado contenidos que coincidan con tus filtros.",           
    "not_started": "No empezado",           
    "reset": "RESTAURAR BÚSQUEDA Y FILTROS",           
    "save_and_close": "Guardar y cerrar",           
    "search": "Buscar",           
    "search_placeholder": "Buscar cursos, lecciones, y términos claves",           
    "set_filters": "ESTABLECER FILTROS",           
    "set_filters_title": "Establecer filtros",           
    "beta": "BETA",           
    "updated": "ACTUALIZADO",           
    "available": "Disponible",           
    "elective": "ELECTIVO",
    "courses_title": "CURSOS"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.browse_courses = window.locales.it.lessons.stream.browse_courses || {};window.locales.it.lessons.stream.browse_courses = {...window.locales.it.lessons.stream.browse_courses, ...{
    "all_courses": "Tutti i Corsi",           
    "any_progress": "Qualsiasi Progresso",           
    "any_status": "Qualsiasi Stato",           
    "any_topic": "Qualsiasi Argomento",           
    "available": "Disponibile",           
    "beta": "BETA",           
    "coming_soon": "In arrivo",           
    "completed": "Completato",           
    "courses": "CORSI",           
    "filter_by_progress": "Filtra per Progresso",           
    "filter_by_status": "Filtra per Stato",           
    "filter_by_topic": "Filtra per Argomento",           
    "in_progress": "In corso",           
    "new_courses": "Nuovo",           
    "no_content_found": "Siamo spiacenti! Non è stato trovato alcun contenuto che corrisponda ai tuoi filtri.",           
    "not_started": "Non iniziato",           
    "reset": "REIMPOSTA RICERCA E FILTRI",           
    "save_and_close": "Salva e Chiudi",           
    "search": "Cerca",           
    "search_placeholder": "Cerca Corsi, Lezioni e Parole chiave",           
    "set_filters": "IMPOSTA FILTRI",           
    "set_filters_title": "Imposta Filtri",           
    "updated": "AGGIORNATO",           
    "elective": "ELETTIVO",
    "courses_title": "CORSI"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.browse_courses = window.locales.zh.lessons.stream.browse_courses || {};window.locales.zh.lessons.stream.browse_courses = {...window.locales.zh.lessons.stream.browse_courses, ...{
    "all_courses": "所有课程",           
    "any_progress": "任何进度",           
    "any_status": "任何状态",           
    "any_topic": "任何主题",           
    "beta": "测试版",           
    "coming_soon": "即将推出",           
    "completed": "已完成",           
    "courses": "课程",           
    "filter_by_progress": "按进度筛选",           
    "filter_by_status": "按状态筛选",           
    "filter_by_topic": "按主题筛选",           
    "in_progress": "正在进行",           
    "new_courses": "新增",           
    "no_content_found": "抱歉！未找到与您的筛选条件匹配的内容。",           
    "not_started": "未开始",           
    "reset": "重置搜索和筛选条件",           
    "save_and_close": "保存并关闭",           
    "search": "搜索",           
    "search_placeholder": "搜索课程、课业和关键术语",           
    "set_filters": "设置筛选条件",           
    "set_filters_title": "设置筛选条件",           
    "updated": "已更新",           
    "available": "可用",           
    "elective": "选修",           
    "courses_title": "课程"           
}
};