window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.lesson = window.locales.am.lessons.lesson || {};window.locales.am.lessons.lesson.lesson_finish_screen = window.locales.am.lessons.lesson.lesson_finish_screen || {};window.locales.am.lessons.lesson.lesson_finish_screen = {...window.locales.am.lessons.lesson.lesson_finish_screen, ...{
    "smartcase_complete": "SMARTCASE ተጠናቋል",           
    "test_complete": "ፈተና ተጠናቋል",           
    "nice_work": "አሪፍ ስራ!",           
    "check_out_x": "አጠናቅቅ<strong>{{title}}!</strong>",           
    "key_terms": "ቁልፍ ቃላት",           
    "demo_message": "በ {{brandName}} መማር ምን ያህል ፈጣን እና ቀላል እንደሆነ የሚያሳይ ትንሽ ናሙና አግኝተዋል። ዛሬ በርካታ ተጨማሪ ትምህርቶችን በመዳረስ ይደሰቱ፣ ነፃ ነው!",           
    "your_test_score_auto": "የእርስዎ ነጥብ በራስ-ሰር ይከናወናል። ይህ የመጨረሻ ፈተናዎ ከሆነ፣ ውጤቶቹን በቅርቡ በመነሻ ማያ ገፅ ላይ ማየት አለብዎት።",           
    "offline_message": "ከመስመር ውጭ ነዎት፣ ነገር ግን ይቀጥሉ! የበይነመረብ ግንኙነትን ስናገኝ ሂደትዎን እናጣምራለን።"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.lesson = window.locales.ar.lessons.lesson || {};window.locales.ar.lessons.lesson.lesson_finish_screen = window.locales.ar.lessons.lesson.lesson_finish_screen || {};window.locales.ar.lessons.lesson.lesson_finish_screen = {...window.locales.ar.lessons.lesson.lesson_finish_screen, ...{
    "smartcase_complete": "تم الانتهاء من SMARTCASE",           
    "nice_work": "عمل جيد!",           
    "key_terms": "المصطلحات الأساسية",           
    "test_complete": "اختبار كامل",
    "your_test_score_auto": "سيتم معالجة درجاتك تلقائيا. إذا كان هذا هو الاختبار النهائي، فسترى النتائج قريبا على الشاشة الرئيسية.",
    "demo_message": "لقد حصلت للتو على عينة صغيرة من مدى سرعة التعلم باستخدام {{brandName}} . استمتع بالوصول إلى العديد من الدروس اليوم ، مجانًا!",
    "check_out_x": "راجع <strong>{{title}}!</strong>",
    "offline_message": "أنت غير متصل بالإنترنت ، لكن استمر! سنقوم بمزامنة تقدمك عندما نكتشف اتصالاً بالإنترنت."
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.lesson = window.locales.en.lessons.lesson || {};window.locales.en.lessons.lesson.lesson_finish_screen = window.locales.en.lessons.lesson.lesson_finish_screen || {};window.locales.en.lessons.lesson.lesson_finish_screen = {...window.locales.en.lessons.lesson.lesson_finish_screen, ...{
    "smartcase_complete": "SMARTCASE COMPLETE",
    "test_complete": "TEST COMPLETE",
    "nice_work": "Nice work!",
    "check_out_x": "Check out <strong>{{title}}!</strong>",
    "key_terms": "KEY TERMS",
    "demo_message": "You just got a small sample of how fast and easy it is to learn with {{brandName}}. Enjoy access to many more lessons today, free!",
    "your_test_score_auto": "Your score will be automatically processed. If this is your final exam, you should see your results shortly on the home screen.",
    "offline_message": "You are offline, but keep going! We will sync your progress when we detect an internet connection."
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.lesson = window.locales.es.lessons.lesson || {};window.locales.es.lessons.lesson.lesson_finish_screen = window.locales.es.lessons.lesson.lesson_finish_screen || {};window.locales.es.lessons.lesson.lesson_finish_screen = {...window.locales.es.lessons.lesson.lesson_finish_screen, ...{
    "smartcase_complete": "SMARTCASE COMPLETO",           
    "nice_work": "¡Buen trabajo!",           
    "key_terms": "TÉRMINOS CLAVE",           
    "test_complete": "Contestar el examen",
    "your_test_score_auto": "Su puntuación será procesada automáticamente. Si este es tu examen final, deberías ver tus resultados en breve en la pantalla de inicio.",
    "demo_message": "Acabas de recibir una pequeña muestra de lo rápido y fácil que es aprender con {{brandName}} . ¡Disfruta de acceso a muchas más lecciones hoy, gratis!",
    "check_out_x": "¡Mira <strong>{{title}}!</strong>",
    "offline_message": "Estás desconectado, ¡pero continúa! Sincronizaremos su progreso cuando detectemos una conexión a Internet."
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.lesson = window.locales.it.lessons.lesson || {};window.locales.it.lessons.lesson.lesson_finish_screen = window.locales.it.lessons.lesson.lesson_finish_screen || {};window.locales.it.lessons.lesson.lesson_finish_screen = {...window.locales.it.lessons.lesson.lesson_finish_screen, ...{
    "smartcase_complete": "SMARTCASE COMPLETATO",           
    "test_complete": "TEST COMPLETATO",           
    "nice_work": "Ottimo lavoro!",           
    "key_terms": "PAROLE CHIAVE",           
    "your_test_score_auto": "Il tuo punteggio sarà elaborato automaticamente. Se questo è il tuo ultimo esame, a breve dovresti vedere i tuoi risultati sulla schermata home.",           
    "demo_message": "Hai appena ricevuto un piccolo esempio di quanto sia facile e veloce imparare con {{brandName}} . Goditi l'accesso a molte altre lezioni oggi, gratis!",
    "check_out_x": "Dai un'occhiata a <strong>{{title}}!</strong>",
    "offline_message": "Sei offline, ma continua! Sincronizzeremo i tuoi progressi quando rileveremo una connessione Internet."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.lesson = window.locales.zh.lessons.lesson || {};window.locales.zh.lessons.lesson.lesson_finish_screen = window.locales.zh.lessons.lesson.lesson_finish_screen || {};window.locales.zh.lessons.lesson.lesson_finish_screen = {...window.locales.zh.lessons.lesson.lesson_finish_screen, ...{
    "smartcase_complete": "SMARTCASE 完成",           
    "nice_work": "做得很好！",           
    "key_terms": "关键术语",           
    "test_complete": "测试完成",           
    "your_test_score_auto": "你的分数将被自动处理。如果这是你的期末考试，你将很快在主屏幕上看到你的成绩。",           
    "demo_message": "你刚刚获得了一个小样本，以便了解使用{{brandName}}进行学习的快速和便捷程度。体验更多课程，今日免费哦！",           
    "check_out_x": "查看<strong>{{title}}！</strong>",           
    "offline_message": "你已离线，但请继续！一旦网络连接恢复，我们会同步你的进度。"           
}
};