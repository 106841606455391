window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.upload_unofficial_transcripts_modal = window.locales.am.lessons.stream.upload_unofficial_transcripts_modal || {};window.locales.am.lessons.stream.upload_unofficial_transcripts_modal = {...window.locales.am.lessons.stream.upload_unofficial_transcripts_modal, ...{
    "submit_your_transcripts": "የእርስዎን ትራንስክሪፕቶች ያስገቡ",           
    "fast_track_your_application": "የእርስዎን ማመልከቻ በፈጣን ሁኔታ ለመከታተል፣ የእርስዎን <span class=\"tooltip-anchor\" tooltip-placement=\"bottom\" tooltip-trigger=\"'outsideClick'\" uib-tooltip-template=\"'customTooltipTemplate.html'\">ኦፌሳላዊ ያልሆኑ</span> ትራንስክሪፕትዎችዎን ይስቀሉ።",           
    "tooltip_message": "ኦፌሳላዊ ያኑ ትራንስክሪፕቶች በተማሪው የተነሳ ኦፌሳላዊ ትራንስክሪፕትን ሊያካትት ይችላል ወይም ከሚመለከተው ተቋም በዲጂታል ወይም በአካላዊ ቅርጽ ሊገኝ ይችላል። ስለትራንስክሪፕቶች ላሉ ጥያቄዎች፣ <a href=\"mailto:registrar@quantic.edu\">registrar@quantic.edu</a>ያግኙ።<br><br> በፕሮግራም ቅበላው ጊዜ፣ ላገኙት ከፍተኛው መደበኛ ዲግሪ ኦፌሳላዊ ትራንስክሪፕት መጠየቅ ያስፈልግዎታል። ስለእኛ<a ng-click=\"loadFaqUrl('/help/transcript-faqs#what', '_blank')\">የትራንስክሪፕት መስፈርቶች</a> የበለጠ ይወቁ።",           
    "go_to_documents": "ወደ ሰነዶች ይሂዱ",           
    "not_now": "አሁን አይደለም"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.upload_unofficial_transcripts_modal = window.locales.ar.lessons.stream.upload_unofficial_transcripts_modal || {};window.locales.ar.lessons.stream.upload_unofficial_transcripts_modal = {...window.locales.ar.lessons.stream.upload_unofficial_transcripts_modal, ...{
    "submit_your_transcripts": "إرسال النصوص الخاصة بك",
    "fast_track_your_application": "لتتبع طلبك بسرعة ، توجه إلى منطقة المستندات لتحميل النصوص <span class=\"tooltip-anchor\" tooltip-placement=\"bottom\" tooltip-trigger=\"'outsideClick'\" uib-tooltip-template=\"'customTooltipTemplate.html'\">غير الرسمية الخاصة بك.",
    "go_to_documents": "انتقل إلى المستندات",
    "not_now": "ليس الان",
    "tooltip_message": "يمكن أن تتضمن النصوص غير الرسمية صورة لنسخة رسمية مأخوذة من الطالب أو يمكن الحصول عليها في شكل رقمي أو مادي من المؤسسة ذات الصلة. للأسئلة حول النصوص ، اتصل بـ <a href=\"mailto:registrar@quantic.edu\">registrar@quantic.edu</a> .<br><br> عند قبولك في البرنامج ، ستحتاج إلى طلب نسخة رسمية لأعلى درجة رسمية حصلت عليها. تعرف على المزيد حول <a ng-click=\"loadFaqUrl('/help/transcript-faqs#what', '_blank')\">متطلبات النسخ الخاصة</a> بنا."
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.upload_unofficial_transcripts_modal = window.locales.en.lessons.stream.upload_unofficial_transcripts_modal || {};window.locales.en.lessons.stream.upload_unofficial_transcripts_modal = {...window.locales.en.lessons.stream.upload_unofficial_transcripts_modal, ...{
    "submit_your_transcripts": "Submit your Transcripts",
    "fast_track_your_application": "To fast-track your application, head to the Documents area to upload your <span class=\"tooltip-anchor\" tooltip-placement=\"bottom\" tooltip-trigger=\"'outsideClick'\" uib-tooltip-template=\"'customTooltipTemplate.html'\">unofficial</span> transcripts.",
    "tooltip_message": "Unofficial transcripts can include a photograph of an official transcript taken by the student or can be obtained in digital or physical form from the relevant institution. For questions about transcripts, contact <a href=\"mailto:registrar@quantic.edu\">registrar@quantic.edu</a>.<br><br>Upon acceptance into the program, you will need to request an official transcript for your highest formal degree earned. Learn more about our <a ng-click=\"loadFaqUrl('/help/transcript-faqs#what', '_blank')\">transcript requirements</a>.",
    "go_to_documents": "Go to Documents",
    "not_now": "Not now"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.upload_unofficial_transcripts_modal = window.locales.es.lessons.stream.upload_unofficial_transcripts_modal || {};window.locales.es.lessons.stream.upload_unofficial_transcripts_modal = {...window.locales.es.lessons.stream.upload_unofficial_transcripts_modal, ...{
    "submit_your_transcripts": "Envíe sus transcripciones",
    "fast_track_your_application": "Para acelerar su solicitud, diríjase al área de Documentos para cargar sus transcripciones <span class=\"tooltip-anchor\" tooltip-placement=\"bottom\" tooltip-trigger=\"'outsideClick'\" uib-tooltip-template=\"'customTooltipTemplate.html'\">no oficiales .",
    "go_to_documents": "Ir a Documentos",
    "not_now": "Ahora no",
    "tooltip_message": "Las transcripciones no oficiales pueden incluir una fotografía de una transcripción oficial tomada por el estudiante o pueden obtenerse en forma digital o física en la institución correspondiente. Si tiene preguntas sobre las transcripciones, comuníquese con <a href=\"mailto:registrar@quantic.edu\">registrar@quantic.edu</a> .<br><br> Una vez aceptado en el programa, deberá solicitar una transcripción oficial de su título formal más alto obtenido. Obtenga más información sobre nuestros <a ng-click=\"loadFaqUrl('/help/transcript-faqs#what', '_blank')\">requisitos de expediente académico</a> ."
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.upload_unofficial_transcripts_modal = window.locales.it.lessons.stream.upload_unofficial_transcripts_modal || {};window.locales.it.lessons.stream.upload_unofficial_transcripts_modal = {...window.locales.it.lessons.stream.upload_unofficial_transcripts_modal, ...{
    "submit_your_transcripts": "Invia le tue trascrizioni",
    "fast_track_your_application": "Per velocizzare la tua applicazione, vai all'area Documenti per caricare le tue trascrizioni <span class=\"tooltip-anchor\" tooltip-placement=\"bottom\" tooltip-trigger=\"'outsideClick'\" uib-tooltip-template=\"'customTooltipTemplate.html'\">non ufficiali .",
    "go_to_documents": "Vai a Documenti",
    "not_now": "Non adesso",
    "tooltip_message": "Le trascrizioni non ufficiali possono includere una fotografia di una trascrizione ufficiale scattata dallo studente o possono essere ottenute in forma digitale o fisica presso l'istituto competente. Per domande sulle trascrizioni, contattare <a href=\"mailto:registrar@quantic.edu\">registrar@quantic.edu</a> .<br><br> Dopo l'accettazione nel programma, dovrai richiedere una trascrizione ufficiale per il tuo più alto grado formale conseguito. Ulteriori informazioni sui nostri <a ng-click=\"loadFaqUrl('/help/transcript-faqs#what', '_blank')\">requisiti di trascrizione</a> ."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.upload_unofficial_transcripts_modal = window.locales.zh.lessons.stream.upload_unofficial_transcripts_modal || {};window.locales.zh.lessons.stream.upload_unofficial_transcripts_modal = {...window.locales.zh.lessons.stream.upload_unofficial_transcripts_modal, ...{
    "submit_your_transcripts": "提交你的成绩单",           
    "fast_track_your_application": "为了快速处理你的申请，请前往文件区上传你的<span class=\"tooltip-anchor\" tooltip-placement=\"bottom\" tooltip-trigger=\"'outsideClick'\" uib-tooltip-template=\"'customTooltipTemplate.html'\">非官方</span>成绩单。",           
    "go_to_documents": "查看文件",           
    "not_now": "并非现在",           
    "tooltip_message": "非官方成绩单包括学员拍摄的官方成绩单的照片，或是从相关机构获得数字或实物成绩单。如对成绩单事宜存在疑问，请发送邮件至<a href=\"mailto:registrar@quantic.edu\">registrar@quantic.edu</a>进行联系。<br><br>一旦被录取，你需要向你获得最高正式学位的机构申请官方成绩单。了解更多关于我们<a ng-click=\"loadFaqUrl('/help/transcript-faqs#what', '_blank')\">成绩单的要求</a>。"           
}
};