window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.featured_events = window.locales.am.lessons.stream.featured_events || {};window.locales.am.lessons.stream.featured_events = {...window.locales.am.lessons.stream.featured_events, ...{
    "upcoming_events": "መጪ ክስተቶች",
    "featured_events": "ተለይተው የቀረቡ ክስተቶች",
    "view_featured_events": "ተለይተው የቀረቡ ክስተቶችን ይመልከቱ",
    "view_more_upcoming_events": "ተጨማሪ ክስተቶችን ይመልከቱ",
    "view_all_events": "ሁሉንም ክስተቶች ይመልከቱ",
    "view_event_tooltip_details_unavailable": "የክስተት ዝርዝሮች ለ MBA እና ለስራ አስፈፃሚ MBA ተማሪዎች እና ተማሪዎች ብቻ ይገኛሉ።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.featured_events = window.locales.ar.lessons.stream.featured_events || {};window.locales.ar.lessons.stream.featured_events = {...window.locales.ar.lessons.stream.featured_events, ...{
    "upcoming_events": "الأحداث القادمة",
    "featured_events": "فعاليات مميزة",
    "view_featured_events": "عرض الأحداث المميزة",
    "view_more_upcoming_events": "عرض المزيد من الأحداث",
    "view_all_events": "عرض جميع الأحداث",
    "view_event_tooltip_details_unavailable": "تفاصيل الحدث متاحة فقط لطلاب ماجستير إدارة الأعمال وماجستير إدارة الأعمال التنفيذية والخريجين."
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.featured_events = window.locales.en.lessons.stream.featured_events || {};window.locales.en.lessons.stream.featured_events = {...window.locales.en.lessons.stream.featured_events, ...{
    "upcoming_events": "Upcoming Events",
    "featured_events": "Featured Events",
    "view_featured_events": "View Featured Events",
    "view_more_upcoming_events": "View More Events",
    "view_all_events": "View All Events",
    "view_event_tooltip_details_unavailable": "Event details are only available to MBA and Executive MBA students and alumni."
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.featured_events = window.locales.es.lessons.stream.featured_events || {};window.locales.es.lessons.stream.featured_events = {...window.locales.es.lessons.stream.featured_events, ...{
    "upcoming_events": "Próximos Eventos",
    "featured_events": "Eventos especiales",
    "view_featured_events": "Ver eventos destacados",
    "view_more_upcoming_events": "Ver más eventos",
    "view_all_events": "Ver todos los eventos",
    "view_event_tooltip_details_unavailable": "Los detalles del evento solo están disponibles para estudiantes y ex alumnos de MBA y Executive MBA."
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.featured_events = window.locales.it.lessons.stream.featured_events || {};window.locales.it.lessons.stream.featured_events = {...window.locales.it.lessons.stream.featured_events, ...{
    "upcoming_events": "Prossimi eventi",
    "featured_events": "Eventi in primo piano",
    "view_featured_events": "Visualizza gli eventi in primo piano",
    "view_more_upcoming_events": "Visualizza altri eventi",
    "view_all_events": "Visualizza tutti gli eventi",
    "view_event_tooltip_details_unavailable": "I dettagli dell'evento sono disponibili solo per gli studenti e gli ex studenti MBA ed Executive MBA."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.featured_events = window.locales.zh.lessons.stream.featured_events || {};window.locales.zh.lessons.stream.featured_events = {...window.locales.zh.lessons.stream.featured_events, ...{
    "upcoming_events": "即将举行的活动",
    "featured_events": "特色活动",
    "view_featured_events": "查看特色活动",
    "view_more_upcoming_events": "查看更多活动",
    "view_all_events": "查看所有活动",
    "view_event_tooltip_details_unavailable": "活动详情仅适用于 MBA 和 Executive MBA 学生和校友。"
}
};