window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.student_dashboard_completed_courses = window.locales.am.lessons.stream.student_dashboard_completed_courses || {};window.locales.am.lessons.stream.student_dashboard_completed_courses = {...window.locales.am.lessons.stream.student_dashboard_completed_courses, ...{
    "completed_courses": "የተጠናቀቁ ኮርሶች",           
    "earn_certificates": "ሰርተፍኬቶችን እና የሚወርዱ ማጠቃለያዎችን ለማግኘት ኮርሶችን ያጠናቅቁ!",           
    "show_all": "ሁሉንም አሳይ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.student_dashboard_completed_courses = window.locales.ar.lessons.stream.student_dashboard_completed_courses || {};window.locales.ar.lessons.stream.student_dashboard_completed_courses = {...window.locales.ar.lessons.stream.student_dashboard_completed_courses, ...{
    "completed_courses": "الدورات المكتملة",           
    "earn_certificates": "أكمل الدورات كي تحصل على الشهادات والملخصات القابلة تحميل!",           
    "show_all": "عرض الكل"           
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.student_dashboard_completed_courses = window.locales.en.lessons.stream.student_dashboard_completed_courses || {};window.locales.en.lessons.stream.student_dashboard_completed_courses = {...window.locales.en.lessons.stream.student_dashboard_completed_courses, ...{
    "completed_courses": "COMPLETED COURSES",
    "earn_certificates": "Complete courses to earn certificates and downloadable summaries!",
    "show_all": "SHOW ALL"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.student_dashboard_completed_courses = window.locales.es.lessons.stream.student_dashboard_completed_courses || {};window.locales.es.lessons.stream.student_dashboard_completed_courses = {...window.locales.es.lessons.stream.student_dashboard_completed_courses, ...{
    "completed_courses": "CURSOS COMPLETADOS",           
    "earn_certificates": "¡Cursos completos para ganar títulos y resúmenes descargables!",           
    "show_all": "MOSTRAR TODOS"           
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.student_dashboard_completed_courses = window.locales.it.lessons.stream.student_dashboard_completed_courses || {};window.locales.it.lessons.stream.student_dashboard_completed_courses = {...window.locales.it.lessons.stream.student_dashboard_completed_courses, ...{
    "completed_courses": "CORSI COMPLETATI",           
    "earn_certificates": "Completa i corsi per ottenere diplomi e riepiloghi scaricabili!",           
    "show_all": "MOSTRA TUTTO"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.student_dashboard_completed_courses = window.locales.zh.lessons.stream.student_dashboard_completed_courses || {};window.locales.zh.lessons.stream.student_dashboard_completed_courses = {...window.locales.zh.lessons.stream.student_dashboard_completed_courses, ...{
    "completed_courses": "已完成课程",           
    "earn_certificates": "完成课程可获得证书以及下载摘要！",           
    "show_all": "全部显示"           
}
};