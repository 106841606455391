window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.lesson = window.locales.am.lessons.lesson || {};window.locales.am.lessons.lesson.assessment_lesson_end = window.locales.am.lessons.lesson.assessment_lesson_end || {};window.locales.am.lessons.lesson.assessment_lesson_end = {...window.locales.am.lessons.lesson.assessment_lesson_end, ...{
    "total_time": "ጠቅላላ ጊዜ",           
    "score": "ነጥብ",           
    "challenges": "ተግዳሮቶች"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.lesson = window.locales.ar.lessons.lesson || {};window.locales.ar.lessons.lesson.assessment_lesson_end = window.locales.ar.lessons.lesson.assessment_lesson_end || {};window.locales.ar.lessons.lesson.assessment_lesson_end = {...window.locales.ar.lessons.lesson.assessment_lesson_end, ...{
    "total_time": "الوقت الكلي",           
    "score": "النتيجة",           
    "challenges": "التحديات"           
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.lesson = window.locales.en.lessons.lesson || {};window.locales.en.lessons.lesson.assessment_lesson_end = window.locales.en.lessons.lesson.assessment_lesson_end || {};window.locales.en.lessons.lesson.assessment_lesson_end = {...window.locales.en.lessons.lesson.assessment_lesson_end, ...{
    "total_time": "Total Time",
    "score": "Score",
    "challenges": "Challenges"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.lesson = window.locales.es.lessons.lesson || {};window.locales.es.lessons.lesson.assessment_lesson_end = window.locales.es.lessons.lesson.assessment_lesson_end || {};window.locales.es.lessons.lesson.assessment_lesson_end = {...window.locales.es.lessons.lesson.assessment_lesson_end, ...{
    "total_time": "Tiempo total",           
    "score": "Puntuación",           
    "challenges": "Desafíos"           
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.lesson = window.locales.it.lessons.lesson || {};window.locales.it.lessons.lesson.assessment_lesson_end = window.locales.it.lessons.lesson.assessment_lesson_end || {};window.locales.it.lessons.lesson.assessment_lesson_end = {...window.locales.it.lessons.lesson.assessment_lesson_end, ...{
    "total_time": "Tempo totale",           
    "score": "Punteggio",           
    "challenges": "Sfide"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.lesson = window.locales.zh.lessons.lesson || {};window.locales.zh.lessons.lesson.assessment_lesson_end = window.locales.zh.lessons.lesson.assessment_lesson_end || {};window.locales.zh.lessons.lesson.assessment_lesson_end = {...window.locales.zh.lessons.lesson.assessment_lesson_end, ...{
    "total_time": "总时间",           
    "score": "得分",           
    "challenges": "挑战"           
}
};