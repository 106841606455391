window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.stream_completed_link_box = window.locales.am.lessons.stream.stream_completed_link_box || {};window.locales.am.lessons.stream.stream_completed_link_box = {...window.locales.am.lessons.stream.stream_completed_link_box, ...{
    "download": "አውርድ",           
    "signing": "በመፈረም ላይ",           
    "certificate": "ሰርተፍኬት",           
    "completed_at_x": "ተጠናቋል፤{{completed_at}}",           
    "summaries": "ማጠቃለያዎች"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.stream_completed_link_box = window.locales.ar.lessons.stream.stream_completed_link_box || {};window.locales.ar.lessons.stream.stream_completed_link_box = {...window.locales.ar.lessons.stream.stream_completed_link_box, ...{
    "download": "تحميل",           
    "signing": "جاري التوقيع",           
    "certificate": "الشهادة",           
    "completed_at_x": "تم الانتهاء في: {{completed_at}}",           
    "summaries": "الملخصات"           
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.stream_completed_link_box = window.locales.en.lessons.stream.stream_completed_link_box || {};window.locales.en.lessons.stream.stream_completed_link_box = {...window.locales.en.lessons.stream.stream_completed_link_box, ...{
    "download": "Download",
    "signing": "Signing",
    "certificate": "Certificate",
    "completed_at_x": "Completed: {{completed_at}}",
    "summaries": "Summaries"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.stream_completed_link_box = window.locales.es.lessons.stream.stream_completed_link_box || {};window.locales.es.lessons.stream.stream_completed_link_box = {...window.locales.es.lessons.stream.stream_completed_link_box, ...{
    "download": "Descargar",           
    "signing": "Firma",           
    "certificate": "Certificado",           
    "completed_at_x": "Completado: {{completed_at}}",           
    "summaries": "Resúmenes"           
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.stream_completed_link_box = window.locales.it.lessons.stream.stream_completed_link_box || {};window.locales.it.lessons.stream.stream_completed_link_box = {...window.locales.it.lessons.stream.stream_completed_link_box, ...{
    "download": "Scarica",           
    "signing": "Firma",           
    "certificate": "Diploma",           
    "completed_at_x": "Completato: {{completed_at}}",           
    "summaries": "Riepiloghi"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.stream_completed_link_box = window.locales.zh.lessons.stream.stream_completed_link_box || {};window.locales.zh.lessons.stream.stream_completed_link_box = {...window.locales.zh.lessons.stream.stream_completed_link_box, ...{
    "download": "下载",           
    "signing": "签署",           
    "certificate": "证书",           
    "completed_at_x": "已完成：{{completed_at}}",           
    "summaries": "摘要"           
}
};